export const M_TOOLS = [
  {
    id: 1111,
    category: 'Accounting',
    name: 'Google Analytics',
    link: 'https://analytics.google.com/analytics/web/',
  },
  {
    id: 2222,
    name: 'Jira',
    category: 'Productivity',

    link: 'https://www.atlassian.com/software/jira',
  },
  {
    id: 3333,
    name: 'Firebase',
    category: 'Productivity',

    link: 'https://console.firebase.google.com/',
  },
  {
    id: 4444,
    category: 'Productivity',
    name: 'Trello',
    link: 'https://trello.com/',
  },
  {
    id: 5555,
    category: 'Productivity',
    name: 'Slack',
    link: 'https://slack.com/',
  },
  {
    id: 1111,
    name: 'Gitlab',
    category: 'Administration',
    link: 'https://gitlab.com/',
  },
];

export const M_TOOLS_2 = [
  {
    id: 1111,
    name: 'Google Analytics',
    link: 'https://analytics.google.com/analytics/web/',
  },
  {
    id: 6666,
    name: 'Gitlab',
    link: 'https://gitlab.com/',
  },
  {
    id: 2222,
    name: 'Jira',
    link: 'https://www.atlassian.com/software/jira',
  },
  {
    id: 3333,
    name: 'Mongo Atlas',
    link: 'https://www.mongodb.com/atlas/database',
  },
  {
    id: 5555,
    name: 'Slack',
    link: 'https://slack.com/',
  },
  {
    id: 7777,
    name: 'Stripe',
    link: 'https://stripe.com/',
  },
  {
    id: 4444,
    name: 'Trello',
    link: 'https://trello.com/',
  },
];

export const M_TOOLS_3 = [
  {
    id: 1111,
    name: 'Google Analytics',
    link: 'https://analytics.google.com/analytics/web/',
  },
  {
    id: 6666,
    name: 'Bitbucket',
    link: 'https://bitbucket.com/',
  },
  {
    id: 2222,
    name: 'Redmine',
    link: 'https://www.redmine.org',
  },
  {
    id: 3333,
    name: 'Mongo Atlas',
    link: 'https://www.mongodb.com/atlas/database',
  },
  {
    id: 5555,
    name: 'Microsoft Teams',
    link: 'https://microsoft.com/',
  },
  {
    id: 7777,
    name: 'Google Adsense',
    link: 'https://google.com/',
  },
  {
    id: 4444,
    name: 'Trello',
    link: 'https://trello.com/',
  },
];
