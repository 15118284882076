import { innerRoutesFreelancer } from 'navigation/routes';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import APP_ROUTES from 'navigation/app.routes';
import { AppContext } from 'store/app.context';
import { MODULES } from 'config/core';
import SHARED_STATE from 'store/shared';

const HomeContainer = () => {
  const { dispatchSharedState } = useContext(AppContext);

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.HOME,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  const getInnerRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.parent === 'HOME') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });

  return (
    <>
      <Switch>
        {getInnerRoutes(innerRoutesFreelancer)}
        <Redirect from="*" to={`/private${APP_ROUTES.HOME.DASHBOARD}`} />
      </Switch>
    </>
  );
};

export default HomeContainer;
