import TYPES from './types';
import reducer from './reducer';

const INITIAL_STATE = {
  projects: null,
  selectedProject: null,
};

const PROJECT_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default PROJECT_STATE;
