import React, { useContext, useEffect, useState } from 'react';
import HeaderRecruiter from 'components/Recruiter/HeaderRecruiter';
import { Button, Card, CardBody, CardHeader, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledDropdown } from 'reactstrap';
import './contractors.css';
import { AppContext } from 'store/app.context';
import { MODULES } from 'config/core';
import SHARED_STATE from 'store/shared';
import useJobsService from 'services/jobs/jobs.service';
import { useParams } from 'react-router-dom';
import { IMG_PREFIX } from 'config/core';
import { toast } from 'react-toastify';
import APP_ROUTES from 'navigation/app.routes';
import { useHistory } from 'react-router-dom';

const Contractors = () => {
  const [showModal, setShowModal] = useState(false);
  const { languageState, dispatchSharedState } = useContext(AppContext);
  const { updateApplication } = useJobsService()
  const { getContractorsUserByJobs } = useJobsService()
  const [contractors, setContractors] = useState([])

  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.MISSION_OVERVIEW,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  useEffect(() => {
    if (id) getContractors(id)
  }, [id])

  const getContractors = async (currentId) => {
    const result = await getContractorsUserByJobs(currentId)
    if (result) {
      setContractors(result)
    }
  }

  const openModal = () => {
    setShowModal(true);
  };

  const handleApplication = async (jobId, idUser, status) => {
    const result = await updateApplication(jobId, { user: idUser, status })
    if (result) {
      toast.success(languageState.translation.ALERT.SUCCESS_UPDATE_APPLICANT, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
      })
      getContractors(id)
    }
  }

  return (
    <>
      <HeaderRecruiter />
      <Container className='contractors-container'>
        <Row>
          <div className="col">
            <Card className='p-4'>
              <CardHeader>
                <h3 className="mb-0">{languageState.translation.RECRUITER.MISSIONS.ACTIVE_MIISION_FREELANCER}</h3>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className='thead-light'>
                    <th className="sort">{languageState.translation.COMMON.FREELANCER}</th>
                    <th className="sort">{languageState.translation.COMMON.PROFILE}</th>
                    <th className="sort">{languageState.translation.COMMON.TYPE}</th>
                    <th className="sort">{languageState.translation.RECRUITER.MISSIONS.DAILY_BUDGET}</th>
                    <th className="sort">{languageState.translation.COMMON.ACTIONS}</th>
                  </thead>
                  <tbody>
                    {contractors && contractors.map((mock, index) => (
                      <tr key={index}>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 15,
                            }}
                          >
                            <input type="checkbox" />
                            <img src={`${IMG_PREFIX}${mock.user?.profileImage}`}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  require('assets/img/man.png').default;
                              }} width={35} height={35} className='rounded' />
                            <span>{mock.user?.firstName} {mock.user?.lastName.charAt(0)}.</span>
                          </div>
                        </td>
                        <td>{mock.user.profession}</td>
                        <td>{mock.user.kind}</td>
                        <td>{mock.dailyRate} EURO</td>
                        <td>
                          <div className="d-flex justify-content-end align-items-center">
                            <Button
                              Button
                              color="primary"
                              outline
                              className="action-icon"
                              onClick={() =>
                                history.push(`/private${APP_ROUTES.CONNECT.PROFILE_DETAILS}/${mock.user?.id}`)
                              }
                            >
                              <i className="fa fa-eye" />
                            </Button>
                            <Button
                              color="danger"
                              outline
                              className="action-icon"
                              onClick={() => {
                                handleApplication(id, mock.user.id, 'REJECTED')
                              }}
                            >
                              <i className="fa fa-times" />
                            </Button>
                            <UncontrolledDropdown nav>
                              <DropdownToggle
                                className="nav-link pl-0 pr-0"
                                color=""
                                tag="a"
                              >
                                <Button
                                  color="primary"
                                  className="action-icon"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Button>
                              </DropdownToggle>
                              <DropdownMenu right container="body">
                                <DropdownItem
                                  className="noti-title hover_title"
                                  header
                                  tag="div"
                                >
                                  <h5 className="text-overflow m-0">
                                    {languageState.translation.RECRUITER.MISSIONS.START_MISSION}
                                  </h5>
                                </DropdownItem>
                                <DropdownItem
                                  className="noti-title hover_title"
                                  header
                                  tag="div"
                                >
                                  <h5 onClick={openModal} className="text-overflow m-0">
                                    {languageState.translation.RECRUITER.MISSIONS.PAY_FREELANCER}
                                  </h5>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={showModal} centered className="modal-payment">
        <span
          className="closable-modal"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <i className='fa fa-times' />
        </span>
        <ModalHeader>
          <h3>{languageState.translation.RECRUITER.MISSIONS.EASY_PAYMENT}</h3>
        </ModalHeader>
        <ModalBody>
          <div className="payment-modal-container">
            <div className="terms-condition-payment">
              <p className="checklist-validation">
                {languageState.translation.RECRUITER.MISSIONS.VALIDATE_PAYMENT}
              </p>
              <div className="terms-condition-payment-container">
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    id="received-timesheet"
                    type="checkbox"
                    onChange={(e) => { }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="received-timesheet"
                  >
                    {languageState.translation.RECRUITER.MISSIONS.RECEIVE_TIMESHEET}
                  </label>
                </div>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    id="vat-invoice"
                    type="checkbox"
                    onChange={(e) => { }}
                  />
                  <label className="custom-control-label" htmlFor="vat-invoice">
                    {languageState.translation.RECRUITER.MISSIONS.INVOICE_VALID_VAT}
                  </label>
                </div>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    id="feedback"
                    type="checkbox"
                    onChange={(e) => { }}
                  />
                  <label className="custom-control-label" htmlFor="feedback">
                    {languageState.translation.RECRUITER.MISSIONS.PROVIDE_FEEDBACK_TO_FREELANCER}
                  </label>
                </div>
              </div>
            </div>
            <div className="payment-details">
              <div className="payment-amount">
                <h3>{languageState.translation.RECRUITER.MISSIONS.TOTAL_AMOUNT}</h3>

                <div className="amount-details">
                  <h4>{languageState.translation.RECRUITER.MISSIONS.OPEN_AMOUNT}: </h4>
                  <h5>1400euro</h5>
                </div>
                <div className="amount-details">
                  <h4>{languageState.translation.RECRUITER.MISSIONS.VAT_AMOUNT}: </h4>
                  <h5>1400euro</h5>
                </div>
                <div className="amount-details">
                  <h4>{languageState.translation.RECRUITER.MISSIONS.TOTAL_AMOUNT}: </h4>
                  <h5>1400euro</h5>
                </div>
              </div>
              <div className="payment-methods">
                <p>{languageState.translation.RECRUITER.MISSIONS.SECURE_PAYMENT}:</p>
                <img
                  src={require('assets/img/brand/payment-method.png').default}
                />
              </div>
              <button className="pay-freelancer">{languageState.translation.RECRUITER.MISSIONS.PAY_FREELANCE}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Contractors;
