import TYPES from './types';
import reducer from './reducer';

const INITIAL_STATE = {
  currentStep: 0,
  stepperStatus: [
    { step: 1, isCompleted: false },
    { step: 2, isCompleted: false },
    { step: 3, isCompleted: false },
  ],
  isStepperEnded: false,
  openStepper: false,
  shouldStepperRemainClose: false,
};

const STEPPER_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default STEPPER_STATE;
