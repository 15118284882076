import React from 'react'
import { Spinner } from 'reactstrap';
import "./LoaderPage.scss"

const LoaderPage = () => {
    return (
        <div className='loader-page-container'>
            <Spinner animation="border" />
        </div>
    )
}

export default LoaderPage