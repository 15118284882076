import { MODULES } from 'config/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import useSettingsService from 'services/settings/settings.service';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';
import USER_STATE from 'store/user';

const DeleteAccount = () => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const { languageState, dispatchUserState, dispatchSharedState } =
    useContext(AppContext);
  const { deleteAccount } = useSettingsService();

  const toggleModal = () => setOpenModal((previousState) => !previousState);

  const logout = () => {
    dispatchUserState({
      type: USER_STATE.types.RESET_USER_INFO,
    });
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.AUTH,
    });

    history.push('/login');
  };

  const deleteAccountHandler = async () => {
    const result = await deleteAccount();
    if (result && result.status === 200) {
      toggleModal();
      toast.success(
        languageState.translation.SETTINGS.ACCOUNT_DISABLED_SUCCESSFULLY,
        {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
      logout();
    }
  };

  return (
    <div>
      <Button className="delete-button" onClick={toggleModal}>
        {languageState.translation.SETTINGS.DELETE_MY_ACCOUNT}
      </Button>
      <Modal centered isOpen={openModal}>
        <ModalHeader>
          <h3>
            {languageState.translation.SETTINGS.DELETE_ACCOUNT_MODAL_HEADER}
          </h3>
          <h4
            style={{
              color: '#8898AA',
              fontSize: '12px',
              lineHeight: '1.5rem',
            }}
          >
            {languageState.translation.SETTINGS.DELETE_ACCOUNT}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Button
            style={{
              backgroundColor: '#0bba54',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={deleteAccountHandler}
          >
            {languageState.translation.COMMON.BUTTONS.YES_SURE}
          </Button>
          <Button
            style={{
              backgroundColor: 'red',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={toggleModal}
          >
            {languageState.translation.COMMON.BUTTONS.CANCEL}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteAccount;
