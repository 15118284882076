import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import useChatService from 'services/chat/chat.service';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import { getChatId } from 'utils';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import "./users-list.scss"
import _ from 'lodash';

const Modalstyle = {
  height: '30rem',
  maxHeight: '90vh',
  paddingBottom: '3rem',
  marginTop: '-1rem',
  zIndex: 50,
};

const ChatUsersList = ({ opened, closeModal, startNewConversation, location }) => {
  const history = useHistory();
  const { getUsers, getOnlineUsers } = useChatService();
  const { getConnectedUsers } = useConnectService();
  const { chatState, connectState, languageState } = useContext(AppContext);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [roomName, setRoomName] = useState('');
  const [connectedUsers, setConnectedUsers] = useState([]);

  useEffect(() => {
    if (location && location.state && location.state.user) {
      setSelectedUsers([...selectedUsers, location.state.user.id]);
    }
  }, [location])

  useEffect(() => {
    const cid = getChatId();
    if (cid) {
      getUsers(cid);
      getConnectedUsers();
      getOnlineUsers();
    }
  }, []);

  const onSelectUser = (user) => {
    if (selectedUsers.includes(user?.id)) {
      setSelectedUsers(selectedUsers.filter((item) => item !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user.id]);
    }
  };

  const startConversation = () => {
    if (!selectedUsers?.length) {
      toast.warning(languageState.translation.CHAT.WARNING_NO_SELECTED_USER, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return
    }

    if (selectedUsers?.length > 1 && !roomName) {
      toast.warning(languageState.translation.CHAT.WARNING_EMPTY_ROOM, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return
    }
    if (
      (selectedUsers.length && roomName) ||
      (selectedUsers?.length === 1 && !roomName)
    ) {
      startNewConversation(selectedUsers, roomName);
      setSelectedUsers([]);
      setRoomName('');
    }
  };
  const checkIfuserConnected = (id) => {
    if (
      connectState &&
      connectState.connectedUsers &&
      connectState.connectedUsers.length > 0
    ) {
      return connectState.connectedUsers.find((data) => data.email === id);
    }
    return false;
  };

  useEffect(() => {
    if (chatState?.users?.length) {
      const data = [];
      _.map(chatState.users, (user) => {
        if (checkIfuserConnected(user.email)) {
          data.push(user)
        }
      })
      setConnectedUsers(data)
    }
  }, [chatState])

  return (
    <Modal isOpen={opened} toggle={closeModal}>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        {languageState.translation.CHAT.CHOOSE_USERS}
      </ModalHeader>
      <ModalBody style={Modalstyle}>
        {selectedUsers && selectedUsers?.length > 1 && (
          <>
            <InputGroup className="d-flex flex-column  mr-2">
              <Label>{languageState.translation.CHAT.ROOM_NAME} :</Label>
              <Input
                type="text"
                value={roomName}
                placeholder={languageState.translation.CHAT.ROOM_NAME}
                className="w-100 rounded"
                onChange={(e) => setRoomName(e.target.value)}
              />
            </InputGroup>
            <hr />
          </>
        )}
        <div className="d-flex flex-column gap-1">
          {connectedUsers && connectedUsers.length ?
            connectedUsers.map((user) => {
              return (
                <Row
                  key={user?.id}
                  className={classnames(
                    'c-pointer bg-secondary p-2 rounded text-dark',
                    {
                      'linear-gradient-primary': selectedUsers?.includes(user.id),
                    }
                  )}
                >
                  <Col onClick={() => onSelectUser(user)} md="8">
                    <span>{`${user.first_name} ${user.last_name.charAt(0)}.`}</span>
                  </Col>
                  {chatState?.onlineUsers &&
                    chatState?.onlineUsers?.includes(user?.id) && (
                      <Col md="4">
                        <div
                          className={classnames('chat-online', {
                            'border-white border': selectedUsers?.includes(
                              user.id
                            ),
                          })}
                        ></div>
                      </Col>
                    )}
                </Row>
              );
            })
            : <Alert color="primary">
              {languageState.translation.CHAT.NO_CONNECTIONS}
            </Alert>
          }
        </div>
      </ModalBody>
      <ModalFooter>
        {
          !connectedUsers?.length
            ? <Button color="primary"
              onClick={() => history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CONNECT.ALL_NETWORK)}>
              {languageState.translation.CHAT.ADD_CONNECTIONS}
            </Button>
            : <Button
              color="primary"
              onClick={() => startConversation()}
            >
              {languageState.translation.CHAT.START_CONVERSATION}
            </Button>
        }

      </ModalFooter>
    </Modal>
  );
};

export default ChatUsersList;
