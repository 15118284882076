import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, CardHeader, Button, ListGroup, ListGroupItem, Progress } from 'reactstrap';
import { AppContext } from 'store/app.context';
import AddLanguage from 'pages/settings/components/add-language/add-language';
import { IMG_PREFIX } from 'config/core';
import { Document, Page, pdfjs } from 'react-pdf';
import useFilesService from 'services/files/files.services';
import useUserService from 'services/profile/user.service';
import AddSoftSkillsSelect from 'pages/settings/components/add-soft-skills/add-soft-skills';
import { FileUploader } from "react-drag-drop-files";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const fileTypes = ["JPG", "PNG", "GIF"];

const DetailsSkillsSettings = () => {
  const { languageState, userState } = useContext(AppContext);
  const [file, setFile] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [userResume, setUserResume] = useState(null);
  const { uploadFile } = useFilesService();
  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } = useUserService();

  useEffect(() => {
    if (userState && userState.userInfo && userState.userInfo.resume) {
      setUserResume(userState.userInfo.resume);
    }
    if (userState && userState.userInfo) {
      setProfileImagePreview(userState.userInfo.profileImage);
    }
  }, [userState]);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onRemoveFile = () => {
    setFile(null);
  };

  const onAddResume = async () => {
    if (file) {
      const result = await uploadFile(file);
      if (result && result.status === 201) {
        const reqData = {
          resume: result.data.filename,
        };
        const result2 = await updateBasicInfos(reqData);
        if (result2 && result2.status === 200) {
          setFile(null);
          autoUpdateUserInfo();
          getUserInfo()
        }
      }
    }
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onRemoveResume = async () => {
    const reqData = {
      resume: '',
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
      setFile(null);
      setUserResume(null);
      // toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
      //   position: 'bottom-right',
      //   hideProgressBar: false,
      // autoClose: 4000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: 'dark',
      // });
    }
  };

  const deleteProfilePicture = async () => {
    const reqData = {
      profileImage: null,
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
      // toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_AVATAR, {
      //   position: 'bottom-right',
      //   hideProgressBar: false,
      // autoClose: 4000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: 'dark',
      // });
    }
  };

  const onProfilePictureChange = async (file) => {
    const result = await uploadFile(file);

    if (result && result.status === 201) {
      const reqData = {
        profileImage: result.data.filename,
        chatToken: userState?.chatToken
      };
      const result2 = await updateBasicInfos(reqData);
      if (result2 && result2.status === 200 || result2.status === 201) {
        autoUpdateUserInfo();
        getUserInfo()
        // toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_AVATAR, {
        //   position: 'bottom-right',
        //   autoClose: 4000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'dark',
        // });
      }
    }
  };
  return (
    <>
      {/* <Card>
        <CardHeader>
          <h3 className="mb-0">
            {languageState.translation.SETTINGS.HOURLY_RATE_EXPERIENCE_LEVEL}
          </h3>
        </CardHeader>
        <CardBody>
          <HourlyExperiences />
        </CardBody>
      </Card> */}
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            {languageState.translation.SETTINGS.ADD_LANGUAGE}
          </h3>
        </CardHeader>
        <CardBody>
          <AddLanguage />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3 className="mb-0">{languageState.translation.COMMON.UPLOAD_PROFILE_PICTURE}</h3>
        </CardHeader>
        <CardBody>
          <div className="dropzone dropzone-multiple profile-image-upload" id="dropzone-multiple">
            <div className="fallback">
              <FileUploader label={languageState.translation.COMMON.UPLOAD_PROFILE_PICTURE_LABEL} handleChange={onProfilePictureChange} name="file" types={fileTypes} />
            </div>
            {profileImagePreview ? (
              <Row className='preview-image' >
                <Col lg="10" className='d-flex align-items-center'>
                  <img src={`${IMG_PREFIX}${profileImagePreview}`} />
                  <span className='ml-3'>{profileImagePreview}</span>
                </Col>
                <Col lg='2' className='text-right'>
                  {/* <Button
                    color="danger"
                    outline
                    className='delete-image'
                    onClick={deleteProfilePicture}
                  >
                    <i className="fa fa-trash" />
                  </Button> */}
                </Col>
              </Row>
            ) : (
              <h3>{languageState.translation.COMMON.NO_PICTURE_PROFILE}</h3>
            )}
          </div>
        </CardBody>
      </Card>
      <AddSoftSkillsSelect />
      <Card>
        <CardHeader>
          <h3 className="mb-0">{languageState.translation.SETTINGS.RESUME}</h3>
        </CardHeader>
        <CardBody>
          <div className="dropzone dropzone-multiple" id="dropzone-multiple">
            <div className="fallback">
              <div className="custom-file">
                <input
                  className="custom-file-input v-none"
                  id="customFileUploadMultiple"
                  onClick={(e) => {
                    e.target.value = '';
                  }}
                  onChange={(e) => {
                    console.log('changing');
                    onFileChange(e);
                  }}
                  type="file"
                  accept="application/pdf"
                />
                <label
                  className="custom-file-label"
                  htmlFor="customFileUploadMultiple"
                >
                  {!file ? 'Choose file' : file.name}
                </label>
              </div>
            </div>
            {file && (
              <ListGroup
                className=" dz-preview dz-preview-multiple list-group-lg"
                flush
              >
                <ListGroupItem className=" px-0">
                  <Row className=" align-items-end justify-content-end">
                    <Col className=" col-auto">
                      <Button
                        size="sm"
                        color="danger"
                        data-dz-remove
                        onClick={onRemoveFile}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            )}
          </div>

          <Button
            color="primary"
            className="d-block ml-auto mt- btn-exprimary mt-3"
            onClick={onAddResume}
            disabled={!file}
          >
            {languageState.translation.COMMON.BUTTONS.SAVE}
          </Button>
        </CardBody>
      </Card>
      {userResume && (
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.RESUME_OVERVIEW}{' '}
            </h3>
          </CardHeader>
          <CardBody>
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                color="danger"
                data-dz-remove
                onClick={onRemoveResume}
              >
                <i className="fas fa-trash" />
              </Button>
            </div>
            <div className="d-flex justify-content-center">
              <Document
                file={`${IMG_PREFIX}${userResume}`}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
            <div className="d-flex justify-content-center">
              {pageNumber > 1 && (
                <Button onClick={() => setPageNumber(pageNumber - 1)}>
                  {languageState.translation.COMMON.PREVIOUS_PAGE}
                </Button>
              )}
              {pageNumber < numPages && (
                <Button onClick={() => setPageNumber(pageNumber + 1)}>
                  {languageState.translation.COMMON.NEXT_PAGE}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default DetailsSkillsSettings;
