import React, { useContext, useEffect, useState } from 'react';

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Label,
  Badge,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import CardsHeaderAviability from 'components/cards/card-header-aviability';
import useConnectService from 'services/connect/connect.service';
import { useParams } from 'react-router-dom';
import { Range } from 'rc-slider';
import { TIME_SLOTS } from 'config/const';
import moment from 'moment';
import CONNECT_STATE from 'store/connect/connect.state';
import { MODULES } from 'config/core';
import SHARED_STATE from 'store/shared';
import { useLocation } from 'react-router-dom';

const Availibilty = () => {
  const marks = {
    0: '8 AM',
    20: '12 AM',
    40: '4 PM',
    60: '8 PM',
    80: '12 PM',
    100: '2 PM',
  };

  const ALL_DAYS = ['MONDAY', 'THURSDAY', 'WEDNESDAY', 'TUESDAY', 'FRIDAY'];

  const [availibilty, setAvailibility] = useState([]);
  const [days, setDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  const {
    languageState,
    connectState,
    dispatchSharedState,
    dispatchConnectState,
  } = useContext(AppContext);

  const { getAvailibilityByUser, getHolidaysByUser, getUserById } =
    useConnectService();
  const location = useLocation();

  const { id } = useParams();

  const getAvailibility = async (userId) => {
    const result = await getAvailibilityByUser(userId);
    if (result && result.status === 200) {
      const onlyDays = result.data.map((item) => item.day);
      setDays(onlyDays);
      setAvailibility(result.data);
    }
  };

  const getHolidays = async (userId) => {
    const result = await getHolidaysByUser(userId);
    if (result && result.status === 200) {
      setHolidays(result.data);
    }
  };

  const getUser = async () => {
    const result = await getUserById(id);
    if (result && result.status === 200) {
      setUserInfo(result.data);
    }
  };

  useEffect(() => {
    getUser();
    getAvailibility(id);
    getHolidays(id);
    dispatchConnectState({
      type: CONNECT_STATE.types.SELECT_USER,
      activeUser: id,
    });

    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  const getDayRange = (workDiary) => {
    const dayConfig = availibilty.find((item) => item.day === workDiary.day);
    if (dayConfig) {
      const slotsNumber = dayConfig.workdiary.length;
      const keys = Object.keys(TIME_SLOTS);
      const items = Object.values(TIME_SLOTS);

      if (slotsNumber) {
        const startDate = new Date(dayConfig.workdiary[0].start).getHours() - 1;
        const endDate = new Date(dayConfig.workdiary[0].end).getHours() - 1;
        const startIndex = items.findIndex((item) => +item === startDate);
        const endIndex = items.findIndex((item) => +item === endDate);

        if (slotsNumber === 1) {
          const r = [
            +keys[startIndex],
            +keys[endIndex],
            +keys[endIndex],
            +keys[endIndex],
          ];
          return r;
        } else {
          const secondStartDate =
            new Date(dayConfig.workdiary[1].start).getHours() - 1;
          const secondEndDate =
            new Date(dayConfig.workdiary[1].end).getHours() - 1;
          const secondStartIndex = items.findIndex(
            (item) => +item === secondStartDate
          );
          const secondEndIndex = items.findIndex(
            (item) => +item === secondEndDate
          );
          const r = [
            +keys[startIndex],
            +keys[endIndex],
            +keys[secondStartIndex],
            +keys[secondEndIndex],
          ];
          return r;
        }
      }
    }
    return [10, 45, 45, 45];
  };

  return (
    <>
      <CardsHeaderAviability title="AVAILIBILITY" previousPath={location.state && location.state.previousPath}
      />
      <Container className="mt-3" fluid>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.FREELANCER_TYPE}
            </h3>
          </CardHeader>
          <CardBody>
            <Label>
              {userInfo && userInfo.workType && (
                <span>
                  {languageState.translation.COMMON[userInfo.workType]}
                </span>
              )}
              {userInfo && !userInfo.workType && (
                <span>
                  {languageState.translation.COMMON.NOT_SPECIFIED_YET}
                </span>
              )}
            </Label>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.WORKING_TYPE}
            </h3>
          </CardHeader>
          <CardBody>
            <Label>
              {userInfo && userInfo.workingTime && (
                <span>
                  {languageState.translation.COMMON[userInfo.workingTime]}
                </span>
              )}
              {userInfo && !userInfo.workingTime && (
                <span>
                  {languageState.translation.COMMON.NOT_SPECIFIED_YET}
                </span>
              )}
            </Label>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.DAYS_OF_WEEK}
            </h3>
          </CardHeader>
          <CardBody>
            {days &&
              userInfo &&
              ALL_DAYS.map((item, index) => (
                <Button
                  color={
                    days.includes(item) || userInfo.workingTime === 'FULL_TIME'
                      ? 'primary'
                      : 'secondary'
                  }
                  outline={
                    !days.includes(item) && userInfo.workingTime === 'PART_TIME'
                  }
                  className="m-1"
                  key={index}
                >
                  {languageState.translation.COMMON.DAYS[item]}
                </Button>
              ))}
          </CardBody>
        </Card>

        {userInfo && userInfo.workingTime === 'PART_TIME' && (
          <Card>
            <CardHeader>
              <h3 className="mb-0">
                {languageState.translation.SETTINGS.PER_WEEKDAY}
              </h3>
            </CardHeader>
            <CardBody>
              {availibilty &&
                availibilty.length > 0 &&
                availibilty.map((workDiary) => (
                  <Row className="mb-5" key={workDiary.id}>
                    <Col md="12">
                      <label>
                        <Badge color="secondary">
                          {languageState.translation.COMMON.DAYS[workDiary.day]}
                        </Badge>
                        <Badge color="success" className="ml-1 c-pointer" pill>
                          {languageState.translation.COMMON.WORKDAY.toUpperCase()}
                        </Badge>
                      </label>
                      <Range
                        marks={marks}
                        count={3}
                        trackStyle={[
                          { backgroundColor: '#5e72e4' },
                          { backgroundColor: 'transparent' },
                          { backgroundColor: '#5e72e4' },
                        ]}
                        defaultValue={getDayRange(workDiary)}
                        step={5}
                        disabled
                      />
                    </Col>
                  </Row>
                ))}
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.YEARLY_HOLIDAYS}{' '}
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              {holidays &&
                holidays.length > 0 &&
                holidays.map((item) => (
                  <ListGroup className="m-auto" key={item.id}>
                    <ListGroupItem className="m-auto d-flex justify-content-between align-items-center">
                      <span>
                        <Badge color="secondary">
                          {languageState.translation.COMMON.FROM + ':'}{' '}
                        </Badge>

                        <span className="fw-600">
                          {moment(item.startDate).format('DD-MM-yyyy')}{' '}
                        </span>
                      </span>
                      <span className="ml-4">
                        <Badge color="secondary">
                          {languageState.translation.COMMON.TO + ':'}{' '}
                        </Badge>
                        <span className="fw-600">
                          {moment(item.endDate).format('DD-MM-yyyy')}{' '}
                        </span>
                      </span>

                      <Badge color="danger" className="ml-4 c-pointer" pill>
                        {languageState.translation.COMMON.HOLIDAY.toUpperCase()}
                      </Badge>
                    </ListGroupItem>
                  </ListGroup>
                ))}
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.COMMON.EXTRA_INFO}{' '}
            </h3>
          </CardHeader>
          <CardBody>
            <Label>
              {userInfo && userInfo.extraInfo && (
                <span>{userInfo.extraInfo}</span>
              )}
              {userInfo && !userInfo.extraInfo && (
                <span>
                  {languageState.translation.COMMON.NOT_SPECIFIED_YET}
                </span>
              )}
            </Label>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Availibilty;
