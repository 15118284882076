import { IMG_PREFIX } from 'config/core';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import useInvitationService from 'services/invitations/invitation.service';
import { AppContext } from 'store/app.context';

const modalPendingInvitations = () => {
  const { getMyPendingInvitation, declineInvitation, acceptInvitation } = useInvitationService();

  const { getConnectedUsers } = useConnectService();

  const {
    invitationsState: { pendingInvitations },
    languageState,
  } = useContext(AppContext);
  useEffect(() => {
    getMyPendingInvitation();
  }, []);

  const handleDeclineInvit = async (idInvitation) => {
    const result = await declineInvitation(idInvitation);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.INVITATION_DECLINED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      getMyPendingInvitation();
    }
  };
  const handleAcceptInvit = async (idInvitation) => {
    const result = await acceptInvitation(idInvitation);

    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.INVITATION_ACCEPTED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      getConnectedUsers();
      getMyPendingInvitation();
    }
  };
  return (
    <>
      <ListGroup className="list mt-2" flush>
        {pendingInvitations &&
          pendingInvitations.length > 0 &&
          pendingInvitations.map((data, index) => (
            <ListGroupItem key={index} className="px-0">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      src={`${IMG_PREFIX}${data.owner?.profileImage}`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          require('assets/img/man.png').default;
                      }}
                    />
                  </a>
                </Col>
                <div className="col">
                  <h4 className="mb-0">
                    {' '}
                    {data.owner?.firstName + ' ' + data.owner?.lastName.charAt(0) + '.'}{' '}
                  </h4>
                  <small>{data.owner?.kind}</small>
                </div>
                <Col className="col-auto">
                  <Button
                    color="success"
                    size="sm"
                    type="button"
                    onClick={() => {
                      handleAcceptInvit(data.id);
                    }}
                  >
                    {languageState.translation.CONNECT.ACCEPTER}
                  </Button>
                </Col>
                <Col className="col-auto">
                  <Button
                    className="mr-3"
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() => {
                      handleDeclineInvit(data.id);
                    }}
                  >
                    {languageState.translation.CONNECT.REFUSER}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          ))}
      </ListGroup>
      {pendingInvitations && pendingInvitations.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center h-100 display-1 mt--4 ">
          <i className="fas fa-user-friends"></i>
          <p className=" font-weight-bold">
            {languageState.translation.CONNECT.YOU_HAVE_NO_INVITATION}
          </p>
        </div>
      )}
    </>
  );
};
export default modalPendingInvitations;
