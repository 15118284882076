import TYPES from './types';
import reducer from './reducer';
import { MODULES } from 'config/core';

const INITIAL_STATE = {
  currentModule: MODULES.AUTH,
  ModalError: false,
  email: '',
  ModalErrorShared: false,
  msg: '',
};

const SHARED_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default SHARED_STATE;
