import TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_CURRENT_MODULE:
      return {
        ...state,
        currentModule: action.module,
      };
    case TYPES.SET_MODAL_OPEN:
      return {
        ...state,
        ModalError: true,
        email: action.email,
      };

    case TYPES.SET_MODAL_SHARED_OPEN:
      return {
        ...state,
        ModalErrorShared: true,
        msg: action.msg,
      };

    case TYPES.SET_MODAL_SHARED_CLOSE:
      return {
        ...state,
        ModalErrorShared: false,
        msg: '',
      };
    case TYPES.SET_MODAL_CLOSE:
      return {
        ...state,
        ModalError: false,
        email: '',
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
