import { Field, Formik } from 'formik';
import { passwordSchema } from 'pages/profile/schema';
import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import useAuthService from 'services/auth/auth.service';

const UpdatePassword = () => {
  const { languageState } = useContext(AppContext);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  const { updateCurrentPassword } = useAuthService();

  const updatePasswordHandler = async (values) => {
    const result = await updateCurrentPassword(values);
    if (result && result.status === 200) {
      setModalResetPassword(true);
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              {languageState.translation.PROFILE.UPDATE_PASSWORD}
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          }}
          validationSchema={passwordSchema(
            languageState.translation.FORM_VALIDATION
          )}
          onSubmit={(values) => {
            console.log('UpdatePassword', values);
            updatePasswordHandler(values);
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            handleReset,
          }) => (
            <div>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="7">
                    <FormGroup>
                      <Field
                        className="form-control"
                        placeholder={'Geef jouw huidige paswoord in'}

                        type="password"
                        name="currentPassword"
                      />
                      {errors.currentPassword && touched.currentPassword && (
                        <div className="invalid-feedback">
                          {errors.currentPassword}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="7">
                    <FormGroup>
                      <Field
                        className="form-control"
                        placeholder={'Geef een nieuw wachtwoord in'}
                        type="text"
                        name="newPassword"
                      />
                      {errors.newPassword && touched.newPassword && (
                        <div className="invalid-feedback">
                          {errors.newPassword}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="7">
                    <FormGroup>
                      <Field
                        className="form-control"
                        placeholder={'Bevestig jouw nieuw wachtwoord '}
                        type="text"
                        name="confirmNewPassword"
                      />
                      {errors.confirmNewPassword &&
                        touched.confirmNewPassword && (
                          <div className="invalid-feedback">
                            {errors.confirmNewPassword}
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </button>
              <Modal isOpen={modalResetPassword} centered>
                <ModalBody
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 15,
                  }}
                >
                  <i
                    className="fas fa-check"
                    style={{
                      color: 'green',
                      fontSize: 50,
                    }}
                  ></i>

                  <h3>
                    {languageState.translation.AUTH.FORGOT_PWD.RESET_SUCCESS}
                  </h3>

                  <Button
                    className="btn-neutral"
                    color="default"
                    size="sm"
                    onClick={() => {
                      setModalResetPassword(false);
                      handleReset();
                    }}
                  >
                    {languageState.translation.COMMON.CLOSE}
                  </Button>
                </ModalBody>
              </Modal>
            </div>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default UpdatePassword;
