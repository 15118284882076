import React, { useRef, useContext } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AppContext } from 'store/app.context';

const CropAvatarEditor = ({ image, setImage, onProfilePictureChange }) => {
    const { languageState } = useContext(AppContext);
    const editorRef = useRef(null);

    const handleSaveClick = () => {
        // Use the editorRef to access the methods of the editor
        const canvas = editorRef.current.getImageScaledToCanvas()
        // You can now use the canvas data (e.g., send it to the server or display it)
        canvas.toBlob((blob) => {
            if (blob) {
                // Determine the image format based on the blob's type
                const imageFormat = blob.type.split('/')[1];

                // Create a new File instance with the correct file extension
                const file = new File([blob], `avatar.${imageFormat}`, { type: blob.type });

                // Now you can use the 'file' variable as needed
                onProfilePictureChange(file)
            }
        });

    };

    return <Modal className='first-step-modal' isOpen={image ? true : false} size="sm" centered>
        <ModalBody>
            <AvatarEditor
                ref={editorRef}
                image={image}
                width={250}
                height={250}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={1.2}
            />
        </ModalBody>
        <ModalFooter className='mb-2 d-flex justify-content-between'>
            <Button
                className='btn-close'
                color="link"
                onClick={() => {
                    setImage(null)
                }}
            >
                {languageState.translation.COMMON.CLOSE}
            </Button>
            <Button
                color="danger"
                onClick={handleSaveClick}
            >
                {languageState.translation.COMMON.UPLOAD_PICTURE}
            </Button>
        </ModalFooter>
    </Modal>
}

export default CropAvatarEditor;