import TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_ROOMS:
      return {
        ...state,
        rooms: action.rooms,
        page: action.page,
        hasMoreConversations: action.hasMoreConversations,
      };
    case TYPES.GET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
        hasMoreMessages: action?.hasMoreMessages,
      };
    case TYPES.GET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case TYPES.GET_ONLINE_USERS:
      return {
        ...state,
        onlineUsers: action.onlineUsers,
      };
    case TYPES.ADD_ONLINE_USER:
      return {
        ...state,
        onlineUsers: updateOnlineUsers(state?.onlineUsers, action.id, 'ADD'),
      };
    case TYPES.REMOVE_ONLINE_USER:
      return {
        ...state,
        onlineUsers: updateOnlineUsers(state?.onlineUsers, action.id, 'REMOVE'),
      };
    case TYPES.SET_CHAT_STATUS:
      return {
        ...state,
        status: action.status,
        socketId: action.socketId,
        chatUserId: action.chatUserId,
      };
    case TYPES.UPDATE_ROOMS:
      return {
        ...state,
        rooms: action.rooms,
      };
    case TYPES.SET_NEW_MESSAGE_NOTIFICATION:
      return {
        ...state,
        newMessage: action.newMessage,
      };
    case TYPES.DELETE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((val) => val.id !== action.messageId),
      };

    default:
      return state;
  }
};

const updateOnlineUsers = (onlineUsers, userId, type) => {
  if (type === 'REMOVE') {
    return onlineUsers?.filter((item) => item !== userId) || [];
  } else {
    if (onlineUsers) {
      return [...onlineUsers, userId];
    }
  }
  return [];
};

export default reducer;
