import React, { useContext } from 'react';
// nodejs library to set properties for components

import PropTypes from 'prop-types';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import { useHistory } from 'react-router-dom';
import APP_ROUTES from 'navigation/app.routes';

const CardsHeaderAviability = ({ title, previousPath }) => {
  const { languageState } = useContext(AppContext);
  const history = useHistory();

  return (
    <>
      <div className="header bg-exprimary pb-7 pt-3 mt--7	">
        <Container
          fluid
          className="d-flex flex-column  flex-row justify-content-between custom-header mt-7		align-items-left "
        >
          <div
            style={{
              color: 'white',
              cursor: 'pointer',
              padding: 15,
              zIndex: 2,
              gap: 5,
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              if (previousPath) {
                history.push(previousPath);
                return;
              }

              history.push(APP_ROUTES.CONNECT.ALL_NETWORK);
            }}
          >
            <i
              tabIndex={0}
              className="fas fa-chevron-circle-left"
              style={{
                color: 'white',
                fontSize: 20,
                cursor: 'pointer',
              }}
            ></i>
            <span>{languageState.translation.COMMON.RETURN_TO_OVERVIEW}</span>
          </div>
          <div>
            <h1 className="text-white big-writing">
              {title ? languageState.translation.COMMON[title] : ' '}
            </h1>
          </div>

          {title ? (
            ''
          ) : (
            <Button className="h-max-content pt-2 pb-2  pl-5 pr-5 ml-2">
              ADD NEW PROJECT{' '}
            </Button>
          )}
        </Container>
      </div>
    </>
  );
};

export default CardsHeaderAviability;
