import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { AppContext } from 'store/app.context';

const ModalMainInfo = ({ modalShow, closeModal, mainInfo, header }) => {
  const { languageState } = useContext(AppContext);

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        {header}
      </ModalHeader>
      <ModalBody>
        <Input
          id="exampleText"
          name="text"
          type="textarea"
          rows="5"
          value={mainInfo}
        />
      </ModalBody>
    </Modal>
  );
};
export default ModalMainInfo;
