import TYPES from './types';

const saveUserInfo = (userInfo, token, chatToken) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  localStorage.setItem('token', token);
  localStorage.setItem('chat-token', chatToken);
};

const changeUserInfo = (userInfo) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

const clearUserInfo = () => {
  localStorage.clear();
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_USER_INFO:
      saveUserInfo(action.userInfo, action.token, action.chatToken);
      return {
        ...state,
        userInfo: action.userInfo,
        token: action.token,
        chatToken: action.chatToken,
      };
    case TYPES.CHANGE_USER_INFO:
      changeUserInfo(action.userInfo);
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case TYPES.RESET_USER_INFO:
      clearUserInfo();
      return {
        ...state,
        userInfo: null,
        token: '',
        chatToken: '',
      };
    case TYPES.SET_USER_ADDRESS:
      return {
        ...state,
        basicInformation: {
          ...state.basicInformation,
          address: action.address,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
