export const M_DEGREES = [
  {
    id: 1,
    position: 'IT bachelor',
    description: '3 years of IT education',
    startDate: '2009-12-13T22:05:36.694Z',
    endDate: '2011-12-25T22:05:36.694Z',
    location: 'University of Kasserine',
  },
  {
    id: 2,
    position: 'Software engineering',
    description:
      'Learnin software development, algorithmes and data structures',
    startDate: '2011-12-13T22:05:36.694Z',
    endDate: '2014-12-25T22:05:36.694Z',
    location: 'University of Hay Taamir',
  },
];
