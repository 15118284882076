import React, { useEffect, useState } from 'react';

const Avatar = ({ firstName, lastName, name, forNavbar, forSidebar }) => {
  const [abv, setAbv] = useState('');

  useEffect(() => {
    let userName = '';
    if (firstName && lastName) {
      userName = firstName[0].toUpperCase() + lastName[0].toUpperCase();
    } else if (name) {
      if (name?.includes(' ')) {
        if (name.split(' ').length > 2) {
          userName = `${name?.split(' ')[0]?.charAt(0)?.toUpperCase()}${name
            ?.split(' ')[2]
            ?.charAt(0)
            ?.toUpperCase()}`;
        } else {
          userName = `${name?.split(' ')[0]?.charAt(0)?.toUpperCase()}${name
            ?.split(' ')[1]
            ?.charAt(0)
            ?.toUpperCase()}`;
        }
      } else {
        userName = `${name?.charAt(0)?.toUpperCase()}${name?.charAt(1)}`;
      }
    } else {
      userName = '';
    }
    setAbv(userName);
  }, [name, firstName, lastName]);

  return (
    <div
      className={
        forNavbar || forSidebar
          ? 'rounded-circle c-pointer'
          : 'rounded-circle custom-avatar sb-avatar sb-avatar--text  c-pointer'
      }
    >
      <div
        className={
          forNavbar
            ? 'avatar avatar-sm rounded-circle bg-white text-red'
            : forSidebar
              ? 'avatar avatar-sm rounded-circle bg-light text-red'
              : 'rounded-circle custom-avatar-inner  sb-avatar__text bg-white text-red'
        }
        title={name}
      >
        <div className={forNavbar || forSidebar ? '' : 'custom-avatar-inner-2'}>
          <span className="custom-avatar-inner-circle ">
            <span>{abv}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Avatar;
