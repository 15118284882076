import TYPES from './languages.types';
import reducer from './languages.reducer';

const INITIAL_STATE = {
  languages: null,
};

const LANGUAGES_SETTINGS_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default LANGUAGES_SETTINGS_STATE;
