import HeaderRecruiter from 'components/Recruiter/HeaderRecruiter';
import { MODULES } from 'config/core';
import React, { useContext, useEffect } from 'react';
import { Card, CardBody, CardHeader, Container, Form } from 'reactstrap';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';

export default function Import() {
  const { dispatchSharedState, languageState } = useContext(AppContext);

  const Cardstyle = {
    width: '100%',
  };
  const Containerstyle = {
    minHeight: '55vh',
  };
  const imageStyle = {
    width: '5rem',
    margin: 'auto',
    marginBottom: '2rem',
  };
  useEffect(() => {
    dispatchSharedState({
      module: MODULES.RECRUITER,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);
  return (
    <div>
      <HeaderRecruiter namePage="IMPORT_FREELANCERS" />

      <Container
        className="mt-4  d-flex align-items-center"
        style={Containerstyle}
        fluid
      >
        {' '}
        <Card className=" shadow-none py-5 px-3 box " style={Cardstyle}>
          <img style={imageStyle} src={require('assets/img/crm.png').default} />
          <Card className="shadow-sm " style={Cardstyle}>
            <CardHeader>
              <h3 className="mb-0">
                {languageState.translation.RECRUITER.IMPORT_CRM}
              </h3>
            </CardHeader>
            <CardBody>
              <Form>
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="customFileLang"
                    lang="en"
                    type="file"
                  />
                  <label className="custom-file-label" htmlFor="customFileLang">
                    S {languageState.translation.COMMON.SELECT_FILE}
                  </label>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Card>
      </Container>
    </div>
  );
}
