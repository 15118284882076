const TYPES = {
  SET_MISSIONS: '[MISSIONS] SET_MISSIONS',
  SET_TEAM: '[TEAM] SET_TEAM',
  SET_EVENTS: '[EVENT] SET_EVENTS',

  SELECT_MISSION: '[MISSIONS] SELECT_MISSION',
  DELETE_MISSION: '[MISSIONS] DELETE_MISSION',
  UPDATE_MISSION: '[MISSIONS] UPDATE_MISSION',
};

export default TYPES;
