import CardsHeaderGroup from 'components/cards/card-header-group';
import EditGroupModal from 'components/modalGroup/EditGroupModal';
import ModalGroupsUsers from 'components/ModalGroupUsers/ModalGroupsUsers';
import { IMG_PREFIX } from 'config/core';
import { MODULES } from 'config/core';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Button, ButtonGroup } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';

const Groups = () => {
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [mode, setMode] = useState('private');
  const [groups, setGroups] = useState([]);
  const [paginationList, setPagination] = useState([]);
  const [ownerId, setOwnerId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [groupeIdToEdit, setGroupeIdToEdit] = useState('');
  const { dispatchSharedState } = useContext(AppContext);
  const { languageState, connectState, userState: { userInfo } } = useContext(AppContext);
  const { getGroups, archiveGroup, deleteGroup, gellArchives, unarchiveGroup, getAllPublicGroups, changeGroupType } = useConnectService();

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  useEffect(() => {
    if (mode === 'archive') {
      gellArchives();
    } else if (mode === 'public') {
      getAllPublicGroups();
    } else getAllGroups();
  }, [mode]);

  const getAllGroups = () => {
    getGroups();
  };

  useEffect(() => {
    if (mode === 'archive') {
      setGroups(connectState.groupArchive);
    } else {
      if (connectState.configPublicGroup === null) setMode('private');
      else {
        setMode('public');
        getPaginate();
      }
      setGroups(connectState.groups);
    }
  }, [connectState]);

  const handleArchiveGroup = async (groupId) => {
    const result = await archiveGroup(groupId);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.GROUP_ARCHIVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      fetchGroups();
    }
  };
  const handleUnarchiveGroup = async (groupId) => {
    const result = await unarchiveGroup(groupId);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.GROUP_ACTIVE, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      gellArchives();
    }
  };
  const handleDeleteGroup = async (groupId) => {
    const result = await deleteGroup(groupId);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.GROUP_DELETED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      fetchGroups();
    }
  };
  const handleChangeGroupType = async (groupId, type) => {
    const result = await changeGroupType(groupId, type);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.GROUP_TYPE, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      fetchGroups();
    }
  };
  const fetchGroups = (page) => {
    if (mode === 'archive') {
      gellArchives();
    } else if (mode === 'public') {
      getAllPublicGroups(page ? page : null);
    } else getAllGroups();
  };

  const hasNext = () => {
    if (
      connectState.configPublicGroup &&
      connectState.configPublicGroup.currentPage
    ) {
      if (
        connectState.configPublicGroup.currentPage <
        connectState.configPublicGroup.totalPages
      ) {
        return false;
      }
    }
    return true;
  };
  const hasPrev = () => {
    if (
      connectState.configPublicGroup &&
      connectState.configPublicGroup.currentPage
    ) {
      if (connectState.configPublicGroup?.currentPage > 1) {
        return false;
      }
      return true;
    }
  };

  const getMoreUsers = (page) => {
    if (page !== connectState.configPublicGroup.currentPage) {
      fetchGroups(page);
      window.scrollTo(0, 0);
    }
  };
  const nextPage = () => {
    if (
      connectState.configPublicGroup.currentPage <
      connectState.configPublicGroup.totalPages
    ) {
      const page = connectState.configPublicGroup.currentPage + 1;
      fetchGroups(page);
      window.scrollTo(0, 0);
    }
  };
  const prevPage = () => {
    if (connectState.configPublicGroup?.currentPage > 1) {
      const page = connectState.configPublicGroup.currentPage - 1;
      fetchGroups(page);
      window.scrollTo(0, 0);
    }
  };
  const getPaginate = () => {
    const listpag = [];
    if (
      connectState.configPublicGroup &&
      connectState.configPublicGroup.totalPages
    ) {
      if (connectState.configPublicGroup?.currentPage > 1) {
        listpag.push(connectState.configPublicGroup?.currentPage - 1);
      }
      listpag.push(connectState.configPublicGroup?.currentPage);
      if (
        connectState.configPublicGroup?.currentPage <
        connectState.configPublicGroup.totalPages
      ) {
        listpag.push(connectState.configPublicGroup?.currentPage + 1);
      }
      setPagination(listpag);
    }
  };
  return (
    <>
      <CardsHeaderGroup
        groupeNumbers={groups?.length}
        setMode={setMode}
        mode={mode}
      />
      <Container fluid className="mt-3 min-height-30 d-flex flex-column">
        <Row>
          {groups &&
            groups.map((group, index) => (
              <Col key={index} className="col-lg-6 col-md-6 col-12">
                <Card
                  style={group.icon ? {
                    backgroundImage: `url(${`${IMG_PREFIX}${group.icon}`})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  } : {
                    backgroundColor: group.color
                  }}
                >
                  {/* <div className="icon icon-shape bg-gradient-white text-black  rounded-circle shadow icon-network">
                    <i className={group.icon} />
                  </div> */}
                  <CardBody>
                    <Row className="justify-content-between align-items-center"></Row>
                    <div className="my-4">
                      {mode && mode === 'public' && group.owner && (
                        <span className="text-white d-flex align-items-center mb-3">
                          <span className="font-weight-bold mr-2">
                            {languageState.translation.CONNECT.GROUP_OWNER}:
                          </span>
                          <span className="avatar avatar-sm rounded-circle mr-2">
                            <img
                              src={`${IMG_PREFIX}${group.owner.profileImage}`}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  require('assets/img/man.png').default;
                              }}
                            />
                          </span>
                          <span>
                            {group.owner.name} {group.owner.lastName}
                          </span>
                        </span>
                      )}
                      <span className="h4 surtitle text-white">
                        {group.groupName}
                      </span>
                      <div className="h1 text-white">
                        {' '}
                        {group.members}{' '}
                        {languageState.translation.COMMON.MEMBERS}
                      </div>
                    </div>
                    <UncontrolledDropdown
                      className="icon-network icon-groups"
                      nav
                    >
                      <DropdownToggle
                        className="nav-link pr-0"
                        color=""
                        tag="a"
                      >
                        <div className="icon icon-shape bg-gradient-blue text-white rounded   shadow ">
                          <i className="ni ni-settings-gear-65" />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          className="noti-title hover_title"
                          header
                          tag="div"
                        >
                          <h5
                            className="text-overflow m-0 p-0"
                            role="button"
                            onClick={() => {
                              setGroupId(group.id);
                              setOwnerId(group?.owner?.id);
                              setModalShow(true);
                            }}
                          >
                            {languageState.translation.CONNECT.SHOW_GROUP_USERS}
                          </h5>
                        </DropdownItem>
                        {((mode !== 'archive' && mode === 'private') ||
                          (group.owner && userInfo.id === group.owner.id)) && (
                            <DropdownItem
                              className="noti-title hover_title"
                              header
                              tag="div"
                            >
                              <h5
                                className="text-overflow m-0 p-0"
                                role="button"
                                onClick={() => {
                                  handleArchiveGroup(group.id);
                                }}
                              >
                                {languageState.translation.CONNECT.ARCHIVE_GROUP}
                              </h5>
                            </DropdownItem>
                          )}
                        {mode === 'archive' && (
                          <DropdownItem
                            className="noti-title hover_title"
                            header
                            tag="div"
                          >
                            <h5
                              className="text-overflow m-0 p-0"
                              role="button"
                              onClick={() => {
                                handleUnarchiveGroup(group.id);
                              }}
                            >
                              {languageState.translation.CONNECT.ACTIVE_GROUP}
                            </h5>
                          </DropdownItem>
                        )}
                        {mode === 'public' &&
                          group.owner &&
                          userInfo.id === group.owner.id && (
                            <DropdownItem
                              className="noti-title hover_title"
                              header
                              tag="div"
                            >
                              <h5
                                className="text-overflow m-0 p-0"
                                role="button"
                                onClick={() => {
                                  handleChangeGroupType(group.id, 'private');
                                }}
                              >
                                {
                                  languageState.translation.CONNECT
                                    .MAKE_IT_PRIVATE
                                }
                              </h5>
                            </DropdownItem>
                          )}
                        {mode === 'private' && (
                          <DropdownItem
                            className="noti-title hover_title"
                            header
                            tag="div"
                          >
                            <h5
                              className="text-overflow m-0 p-0"
                              role="button"
                              onClick={() => {
                                handleChangeGroupType(group.id, 'public');
                              }}
                            >
                              {languageState.translation.CONNECT.MAKE_IT_PUBLIC}
                            </h5>
                          </DropdownItem>
                        )}
                        {(mode === 'private' ||
                          (group.owner && userInfo.id === group.owner.id)) && (
                            <DropdownItem
                              className="noti-title hover_title"
                              header
                              tag="div"
                            >
                              <h5
                                className="text-overflow m-0 p-0"
                                role="button"
                                onClick={() => {
                                  handleDeleteGroup(group.id);
                                }}
                              >
                                {languageState.translation.CONNECT.DELETE_GROUP}
                              </h5>
                            </DropdownItem>
                          )}

                        <DropdownItem
                          className="noti-title hover_title"
                          header
                          tag="div"
                        >
                          <h5
                            className="text-overflow m-0 p-0"
                            role="button"
                            onClick={() => {
                              setEditModalShow(true);
                              setGroupeIdToEdit(group?.id);
                            }}
                          >
                            {languageState.translation.COMMON.EDIT}
                          </h5>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </CardBody>
                </Card>
              </Col>
            ))}
          {groups && groups.length === 0 && (
            <div className="d-flex justify-content-center  h-100 display-1 w-100 mt-6 mb-8 bigFontSize ">
              <div className=" p-5 text-center  rounded shadow-sm ">
                <i className="fas fa-user-friends"></i>
                <p className=" font-weight-bold display-4">
                  {languageState.translation.CONNECT.YOU_HAVE_NO_GROUPS}
                  <span className="text-lowercase d-inline font-weight-bold  ">
                    {
                      languageState.translation.CONNECT[
                      mode === 'public'
                        ? 'PUBLIC_GROUPS'
                        : mode === 'private'
                          ? 'PRIVATE_GROUPS'
                          : 'ARCHIVED_GROUPS'
                      ]
                    }
                  </span>
                </p>
              </div>
            </div>
          )}
        </Row>
        {mode === 'public' &&
          connectState.configPublicGroup &&
          connectState.configPublicGroup?.totalItems !== 0 && (
            <ButtonGroup className="d-flex justify-content-center gap-1 mt-auto">
              <Button
                className=" flex-grow-0 rounded-circle paginate-button"
                type="button"
                color="primary"
                onClick={() => prevPage()}
                disabled={hasPrev()}
              >
                <i className="fas fa-angle-left"></i>
              </Button>
              {paginationList &&
                paginationList.map((data, index) => (
                  <Button
                    key={index}
                    className={
                      connectState.configPublicGroup?.currentPage == data
                        ? '  flex-grow-0 rounded-circle paginate-button active shadow-lg'
                        : 'flex-grow-0 rounded-circle paginate-button shadow-lg'
                    }
                    color="primary"
                    type="button"
                    onClick={() => getMoreUsers(data)}
                  >
                    {data}
                  </Button>
                ))}
              <Button
                className=" flex-grow-0 rounded-circle paginate-button "
                type="button"
                color="primary"
                onClick={() => nextPage()}
                disabled={hasNext()}
              >
                <i className="fas fa-angle-right"></i>
              </Button>
            </ButtonGroup>
          )}
      </Container>
      <ModalGroupsUsers
        modalShow={modalShow}
        closeModal={() => {
          setModalShow(false);
        }}
        mode={mode}
        ownerId={ownerId}
        groupId={groupId}
        activeUserId={userInfo.id}
      />
      <EditGroupModal
        modalShow={editModalShow}
        groupsRetrieved={groups}
        groupeIdToEdit={groupeIdToEdit}
        closeModal={() => {
          fetchGroups();
          setEditModalShow(false);
        }}
      />
    </>
  );
};

export default Groups;
