import DeleteModalGeneric from 'components/layout/DeleteModal/DeleteModalGeneric';
import { IMG_PREFIX } from 'config/core';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import moment from 'moment';

const ListNotes = ({ userId }) => {
  const { GetUserNotes, deleteNote } = useConnectService();
  const { languageState } = useContext(AppContext);
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState([]);

  const [modalDelete, setModalDelete] = useState(false);
  useEffect(() => {
    getNotes(userId);
  }, [userId]);
  const getNotes = async (id) => {
    const result = await GetUserNotes(id);
    if (result && result.status === 200) {
      setNotes(result.data);
    }
  };
  const handleDeleteNote = async () => {
    const result = await deleteNote(note);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.NOTES_DELETED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setModalDelete(false);
      getNotes(userId);
    }
  };
  const formatDate = (date) => moment(date).format('MM/DD/YYYY HH:mm');
  return (
    <div className="max-note-list-height">
      {notes && notes.length > 0 ? (
        notes.map((data, index) => (
          <div key={index} className="d-flex align-items-center mt-3  ">
            <span className="avatar avatar-sm rounded-circle mr-2 flex-shrink-0">
              <img
                src={`${IMG_PREFIX}${data.owner.profileImage}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = require('assets/img/man.png').default;
                }}
              />
            </span>
            {data.owner.name} : {data.note}{' '}
            <span className="ml-auto mr-3">
              {data.created_at && formatDate(data.created_at)}
            </span>
            <Button
              size="sm"
              color="danger"
              onClick={() => {
                setNote(data.id);
                setModalDelete(true);
              }}
            >
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ))
      ) : (
        <div className="d-flex justify-content-center">
          <h5>{languageState.translation.CONNECT.No_Notes}</h5>
        </div>
      )}
      <DeleteModalGeneric
        onClick={handleDeleteNote}
        modalShow={modalDelete}
        closeModal={() => {
          setModalDelete(false);
        }}
        body={languageState.translation.CONNECT.DO_YOU_REALLY_NOTE}
      />
    </div>
  );
};
export default ListNotes;
