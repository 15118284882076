import { MISSION_STATUS } from "config/core";
import APP_ROUTES from "navigation/app.routes";
import { Fragment, useContext } from "react";
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, InputGroupText, Label, UncontrolledDropdown } from "reactstrap";
import { AppContext } from "store/app.context";
import './overview.scss'

const HeaderMissionList = ({ missionCount, searchKey, setSearchKey, missionStatus, setMissionStatus, handlePerRowsChange, pageSize }) => {
    const { languageState } = useContext(AppContext);
    const history = useHistory()
    return (
        <Fragment>
            <div className="header-mission-list-content">
                <div className="d-flex align-items-center justify-content-between text-lowercase mb-3 filter-block">
                    <div className="d-flex gap-2 align-items-center">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search" aria-hidden="true" style={{ color: '#fa5252', fontSize: 10 }} />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                style={{ backgroundColor: 'white', color: 'Black' }}
                                placeholder={languageState.translation.CONNECT.ENTER_NAME}
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                        </InputGroup>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="nav-link pl-0 pr-0" color="" tag="a">
                                <Button color="secondary" outline className="d-flex align-items-center gap-1 w-max-content">
                                    {missionStatus ? languageState.translation.COMMON.STATUS_LIST[missionStatus] : languageState.translation.COMMON.STATUS} {missionStatus && <i className="fa fa-times ml-2" onClick={() => setMissionStatus(null)} />} <i className="fa fa-chevron-down" />
                                </Button>
                            </DropdownToggle>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectionMode="multiple"
                            >
                                {Object.values(MISSION_STATUS).map((status, index) => (
                                    <DropdownItem onClick={() => setMissionStatus(status)} key={index} className="capitalize">
                                        {languageState.translation.COMMON.STATUS_LIST[status]}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <Button
                        className="ml-auto"
                        onClick={() =>
                            history.push(`/private${APP_ROUTES.RECRUITER.ADD_MISSION}`)
                        }
                        color="primary"
                    >
                        {languageState.translation.RECRUITER.MISSIONS.ADD_MISSION}
                    </Button>
                </div>
                <div className="d-flex align-items-center justify-content-between title-block">
                    <h3 className="mb-0">
                        {missionCount} {languageState.translation.RECRUITER.MISSIONS.ACTIVE}
                    </h3>
                    <div className="d-flex align-items-end gap-2">
                        <Label>{languageState.translation.COMMON.ROWS_PER_PAGE}</Label>
                        <Select
                            value={{ value: pageSize, label: `${pageSize}` }}
                            name="pageSize"
                            onChange={handlePerRowsChange}
                            className="basic-multi-select mt-2 select-per-page-form"
                            classNamePrefix="select"
                            options={[{ value: 5, label: '5' }, { value: 10, label: '10' }, { value: 20, label: '20' }, { value: 50, label: '50' }, { value: 100, label: '100' }]}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HeaderMissionList