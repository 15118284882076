import { DEFAULT_PRIVATE_PATH } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardFooter } from 'reactstrap';
import { AppContext } from 'store/app.context';

// nodejs library to set properties for components
// reactstrap components
const CardsFocusTeam = ({ person, type }) => {
  const { languageState } = useContext(AppContext);
  const [myId, setMyId] = useState();
  const history = useHistory();
  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const user = JSON.parse(userInfo);
      setMyId(user.id);
    }
  }, []);
  const {
    id = '',
    name,
    role,
    address,
    phoneNumber,
    firstName = '',
    lastName = '',
  } = person;

  return (
    <>
      <Card className=" card-team   bg-secondary ">
        <div className="d-flex align-items-center justify-content-between m-3 p-3">
          <div>
            <p className="text-muted m-1">{role}</p>
            <p className="font-weight-bold m-1 fs-2">
              {name ? name : firstName + ' ' + lastName}
            </p>
            <p className="text-muted m-1"> </p>

            <p className="text-muted m-1 h-4">
              {address && (
                <span>
                  <i className="ni ni-building" />{' '}
                  {languageState.translation.FOCUS.TEAMS.CARD.ADRESS}: {address}
                </span>
              )}
            </p>

            <p className="text-muted m-1 h-4">
              {phoneNumber && (
                <span>
                  {' '}
                  <i className="ni ni-mobile-button" />{' '}
                  {languageState.translation.FOCUS.TEAMS.CARD.PHONE}:{' '}
                  {phoneNumber}{' '}
                </span>
              )}
            </p>
          </div>
          <img
            className="icon"
            src={require('../../assets/img/avatar.png').default}
          />
        </div>
        <CardFooter className=" bg-light h-4 h-100">
          {type && type !== 'PROJET' && myId != id && (
            <div className="float-right">
              <Button
                color="success"
                onClick={(e) =>
                  history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT)
                }
              >
                {' '}
                <i className="ni ni-chat-round" />
              </Button>
              <Button
                color="info"
                className="btn-exprimary"
                onClick={() =>
                  history.push(
                    '/private' + APP_ROUTES.CONNECT.PROFILE_DETAILS + '/' + id
                  )
                }
              >
                {languageState.translation.FOCUS.TEAMS.CARD.PROFILE}
              </Button>
            </div>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

export default CardsFocusTeam;
