import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Button, Row, Col, Modal, ModalHeader, Input, ModalBody } from 'reactstrap';
import { AppContext } from 'store/app.context';
import AddExperience from 'pages/settings/components/add-experience/add-experience';
import Experiences from 'components/experiences/experiences';
import AddEducation from 'pages/settings/components/add-education/add-education';
import Educations from 'components/educations/educations';
import AddHardSkillsSelect from 'pages/settings/components/add-hard-skills/add-hard-skills'
import Select from 'react-select';
import useSettingsService from 'services/settings/settings.service';
import useUserService from 'services/profile/user.service';
import ReactSelect from 'react-select';
import useJobsService from 'services/jobs/jobs.service';

const ExperienceEducationSettings = () => {
  const { getCategories } = useJobsService();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [positions, setPositions] = useState([]);
  const [addPositionModalShow, setPositionModalShow] = useState(false);
  const [positionToAdd, setPositionToAdd] = useState('');
  const [positionToAddInModal, setPositionToAddInModal] = useState('');
  const [isPositionNotFound, setIsPositionNotFound] = useState(false);
  const [categoryToBeAdded, setCategoryToBeAdded] = useState(null);
  const { getPositions } = useSettingsService();
  const { updateBasicInfos, autoUpdateUserInfo, addPosition, getUserInfo } = useUserService();
  const { languageState, userState, positionState, jobsState } = useContext(AppContext);

  useEffect(() => {
    getPositions();
    getCategories();
  }, []);

  useEffect(() => {
    if (jobsState && jobsState.categories) {
      jobsState.categories
    }
  }, [jobsState])

  useEffect(() => {
    if (positionState.positions) {
      const mappedPositions = positionState.positions.map((item) => ({
        label: item?.label,
        value: item.id,
      }));
      setPositions(mappedPositions);
    }
  }, [positionState]);

  useEffect(() => {
    const pos = userState.userInfo.position;
    if (pos) {
      const activeItem = positions.findIndex((item) => item.value === pos.id);
      if (activeItem) { }
      if (pos) {
        setCurrentPosition(positions[activeItem]);
      }
    }
  }, [userState, positions]);

  const addPositionHandler = async (positionToBeAdded) => {
    if (positionToBeAdded.trim() === '') return;

    if (searchPosition(positionToBeAdded)) {
      setPositionToAdd(searchPosition(positionToBeAdded).id);
      return;
    }
    const result = await addPosition({
      label: positionToBeAdded,
    });

    if (result && result.status === 201) {
      const payload = {
        position: result.data.id,
      };
      const updatedResult = await updateBasicInfos(payload);
      if (updatedResult && updatedResult.status === 200) {
        autoUpdateUserInfo();
        getUserInfo()
        // toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
        //   position: 'bottom-right',
        //   autoClose: 4000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'dark',
        // });
      }
    }
    autoUpdateUserInfo();
    setPositionToAddInModal('');
    setPositionToAdd('');
    setPositionModalShow(false);
    setIsPositionNotFound(false);
    getPositions();
  };

  const onChangePosition = async () => {
    if (currentPosition) {
      const payload = {
        position: currentPosition.value,
      };
      const result = await updateBasicInfos(payload);
      if (result && result.status === 200) {
        autoUpdateUserInfo();
        getUserInfo();
        // toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
        //   position: 'bottom-right',
        //   autoClose: 4000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'dark',
        // });
      }
    }
  };

  const saveCategory = async () => {
    if (!categoryToBeAdded) return;
    const payload = {
      category: categoryToBeAdded.value,
    };
    const result = await updateBasicInfos(payload);

    if (result && result.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
      // toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
      //   position: 'bottom-right',
      //   autoClose: 4000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: 'dark',
      // });
      setCategoryToBeAdded(null);
    }
  };

  const searchPosition = useCallback(
    (positionToBeAdded) => {
      return positions.find((item) =>
        item.label.toLowerCase().includes(positionToBeAdded.toLowerCase())
      )
    },
    [positions]
  );

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            {languageState.translation.SETTINGS.ADD_NEW_EXP}
          </h3>
        </CardHeader>
        <CardBody>
          <AddExperience />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            {languageState.translation.SETTINGS.EXPERIENCES_OVERVIEW}
          </h3>
        </CardHeader>
        <CardBody>
          <Experiences />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            {languageState.translation.SETTINGS.ADD_NEW_EDU}
          </h3>
        </CardHeader>
        <CardBody>
          <AddEducation />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            {languageState.translation.SETTINGS.EDUCATIONS_OVERVIEW}
          </h3>
        </CardHeader>
        <CardBody>
          <Educations />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3 className="mb-0">{languageState.translation.SETTINGS.HARD_SKILLS}</h3>
        </CardHeader>
        <CardBody>
          <AddHardSkillsSelect />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            {languageState.translation.COMMON.SELECT_CATEGORIES}
          </h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs="8" className="d-none">
              <ReactSelect
                options={jobsState && jobsState.categories.map(el => {
                  return {
                    value: el.id,
                    label: el.label
                  }
                })}
                onChange={(category) => setCategoryToBeAdded(category.value)}
                defaultValue={jobsState && jobsState.categories.find((el) => el.value === userState?.userInfo?.category) || null}
                placeholder={languageState.translation.COMMON.SELECT_CATEGORIES}
              />
            </Col>
            <Col>
              <Button onClick={saveCategory} className="bg-exprimary">
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3>{languageState.translation.SETTINGS.I_CONSIDER_MY_SELF} </h3>
        </CardHeader>
        <CardBody>
          {currentPosition && (
            <div>
              <h3>{currentPosition.label}</h3>
              <span>
                {languageState.translation.SETTINGS.CHANGE_CURRENT_POSITION}:{' '}
              </span>
            </div>
          )}
          <Row>
            <Col xs="8">
              <Select
                isSearchable
                onChange={(e) => {
                  setCurrentPosition(e);
                  if (isPositionNotFound) setIsPositionNotFound(false);
                }}
                inputValue={positionToAdd}
                onInputChange={(e) => {
                  setPositionToAdd(e);

                  if (!searchPosition(e)) {
                    if (!isPositionNotFound) setIsPositionNotFound(true);
                  } else {
                    if (isPositionNotFound && e !== '')
                      setIsPositionNotFound(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addPositionHandler(positionToAdd);
                  }
                }}
                options={positions}
                isClearable
                value={{
                  label: currentPosition?.label,
                  value: currentPosition?.value,
                }}
                placeholder={languageState.translation.COMMON.POSITION}
              />
            </Col>
            <Row>
              <Col>
                <Button
                  color="primary"
                  className=" d-block ml-auto btn-exprimary"
                  onClick={onChangePosition}
                >
                  {languageState.translation.COMMON.BUTTONS.SAVE}
                </Button>
              </Col>

              {isPositionNotFound && (
                <Col>
                  <Button
                    color="dark"
                    onClick={() => {
                      setPositionModalShow(true);
                    }}
                  >
                    {languageState.translation.COMMON.BUTTONS.ADD}
                  </Button>
                </Col>
              )}
            </Row>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={addPositionModalShow} centered>
        <ModalHeader>Add new position</ModalHeader>
        <ModalBody>
          <Input
            placeholder="add position"
            onChange={(e) => setPositionToAddInModal(e.target.value)}
          />
          <Button
            style={{
              marginTop: '10px',
            }}
            color="dark"
            disabled={positionToAddInModal === ''}
            onClick={() => {
              addPositionHandler(positionToAddInModal);
            }}
          >
            Add position
          </Button>
          <Button
            style={{
              marginTop: '10px',
            }}
            color="dark"
            onClick={() => {
              setPositionModalShow(false);
              setPositionToAdd('');
            }}
          >
            {languageState.translation.COMMON.CLOSE}
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ExperienceEducationSettings;
