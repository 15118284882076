import TYPES from './positions.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_POSITIONS:
      return {
        ...state,
        positions: action.positions,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
