import routes from 'navigation/public.routes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicNavbar from './navbar/PublicNavbar';
import { useHistory } from 'react-router-dom';

const PublicDashboard = () => {
  const history = useHistory()
  const getRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/public') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  return (
    <div>
      <PublicNavbar />
      <div className={`${history.location.pathname.includes('/jobs/jobboard') ? 'public-dashboard-jobboard' : 'public-dashboard-detail-profile'} public-dashboard-container px-4 rounded py-3 shadow-lg`}>
        <Switch>{getRoutes(routes)}</Switch>
      </div>
    </div>
  );
};

export default PublicDashboard;
