const TYPES = {
  SET_QUICKLINKS: '[HOME] SET_QUICKLINKS',
  SET_EVENTS: '[HOME] SET_EVENTS',
  GET_EVENTS: '[HOME] GET_EVENTS',
  GET_QUICKLINKS: '[HOME] GET_QUICKLINKS',
  SET_FEEDS: '[HOME] SET_FEEDS',
  GET_FEEDS: '[HOME] GET_FEEDS',
  SET_NB_NEW_JOBS: '[HOME] GET_NB_NEW_JOBS'
};

export default TYPES;
