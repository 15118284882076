const TYPES = {
  CREATE_CONVERSATION: '[CHAT] CREATE_CONVERSATION',
  GET_MESSAGES: '[CHAT] GET_MESSAGES',
  GET_ROOMS: '[CHAT] GET_ROOMS',
  UPDATE_ROOMS: '[CHAT] UPDATE_ROOMS',
  GET_ONLINE_USERS: '[CHAT] GET_ONLINE_USERS',
  ADD_ONLINE_USER: '[CHAT] ADD_ONLINE_USER',
  REMOVE_ONLINE_USER: '[CHAT] REMOVE_ONLINE_USER',
  GET_USERS: '[CHAT] GET_USERS',
  SET_CHAT_STATUS: '[CHAT] SET_CHAT_STATUS',
  SET_NEW_MESSAGE_NOTIFICATION: '[CHAT] SET_NEW_MESSAGE_NOTIFICATION',
  DELETE_MESSAGE: '[CHAT] DELETE_MESSAGE',
};

export default TYPES;
