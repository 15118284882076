import React, { useContext, useEffect } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AuthHeader from 'components/layout/header/auth-header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import { Formik, Field } from 'formik';
import schema from './schema';
import useAuthService from 'services/auth/auth.service';
import { toast } from 'react-toastify';

const UpdatePassword = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { languageState, dispatchUserState } = useContext(AppContext);
  const { updatePassword } = useAuthService();
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [focusedConfirmPassword, setfocusedConfirmPassword] =
    React.useState(false);

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params && params.get('token')) {
      setToken(params.get('token'));
    }
  }, []);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const submitForm = async (values) => {
    const result = await updatePassword(values.password, token);
    if (result && (result.status === 201 || result.status === 200)) {
      toast.success(languageState.translation.AUTH.FORGOT_PWD.RESET_SUCCESS, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push('/login');
    }
  };

  return (
    <div className="auth-container">
      <AuthHeader
        title={languageState.translation.AUTH.FORGOT_PWD.RESET_PASSWORD}
        lead="Use these awesome forms to login or create new account in your project for free."
      />

      <Container className="mt--8 pb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={(values) => submitForm(values)}
        >
          {({ errors, touched, values, handleSubmit }) => (
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 ">
                      <h2>ExtraExpertise</h2>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>
                        {
                          languageState.translation.AUTH.FORGOT_PWD
                            .TYPE_PASSWORD
                        }
                      </small>
                    </div>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          className="form-control"
                          placeholder={
                            languageState.translation.COMMON.PASSWORD
                          }
                          type="password"
                          name="password"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                        />
                      </InputGroup>
                      {errors.password && touched.password && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        focused: focusedConfirmPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          className="form-control"
                          placeholder={
                            languageState.translation.COMMON.CONFIRM_PASSWORD
                          }
                          type="password"
                          name="confirmPassword"
                          onFocus={() => setfocusedConfirmPassword(true)}
                          onBlur={() => setfocusedConfirmPassword(true)}
                        />
                      </InputGroup>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="invalid-feedback">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        className="my-4 btn-exprimary"
                        color="info"
                        onClick={() => handleSubmit()}
                        type="button"
                      >
                        {languageState.translation.COMMON.SUBMIT}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default UpdatePassword;
