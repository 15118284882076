import TYPES from './languages.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
