import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from 'store/app.context';
import { MODULES } from 'config/core';
import Datetime from 'react-datetime';
import SHARED_STATE from 'store/shared';
import HeaderRecruiter from 'components/Recruiter/HeaderRecruiter';
import { Field, Formik } from 'formik';
import schema from './schema';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import useMissionService from 'services/missions/mission.service';
import { useHistory } from 'react-router-dom';
import APP_ROUTES from 'navigation/app.routes';
import useFilesService from 'services/files/files.services';
import Select from 'react-select';
import MultipleImageUpload from 'components/multiple-image-upload/multiple-image-upload';
import ExEditor from 'components/editor/editor';
import useJobsService from 'services/jobs/jobs.service';
import MissionsTags from 'components/missions-tags/MissionsTags';
import { useParams } from 'react-router-dom';
import MISSION_STATE from 'store/missions/missions.state';
import _ from 'lodash';
import LoaderPage from 'components/LoaderPage/LoaderPage';
import moment from 'moment';
import './add-mission.css';

const Addmission = ({ mission }) => {
  const params = useParams();
  const { languageState, dispatchSharedState, missionState, dispatchMissionState, userState, jobsState } = useContext(AppContext);
  const [isCheck, setIsCheck] = useState(false);
  const [tags, setTags] = useState([]);
  const [skills, setSkills] = useState([])
  const [images, setImages] = useState([]);
  const [isAllDetails, setIsAllDetails] = useState(false);
  const [selectedCategories, setAllSelectedCategories] = useState([]);
  const { uploadFile } = useFilesService();
  const { createMission, getMissionById, updateMission } = useMissionService();
  const { getCategories } = useJobsService();
  const history = useHistory();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [missionDetails, setMissionDetails] = useState(mission);
  const [requirements, setRequirements] = useState([
    languageState.translation.COMMON.DEFAULT_REQUIREMENTS.VALID_VAT,
    languageState.translation.COMMON.DEFAULT_REQUIREMENTS.INTRODUCTION_LETTER,
    languageState.translation.COMMON.DEFAULT_REQUIREMENTS.SIGN_NDA,
    languageState.translation.COMMON.DEFAULT_REQUIREMENTS.EUROPE_REGISTRED_COMPANY,
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const [requirement, setRequirement] = useState('');
  const [initialValues, setValues] = useState(
    missionDetails
      ? missionDetails
      : {
        firstName: '',
        lastName: '',
        companyWebsite: '',
        remote: false,
        city: '',
        country: '',
        missionCategories: [],
        hourlyRate: 0,
        hoursPerWeek: 0,
        client: '',
        email: '',
        location: '',
        phoneNumber: '',
        dailyBudget: 0,
        currency: 'EUR',
        marginWithClient: 0,
        startDate: moment(),
        endDate: moment(),
        functionTitle: '',
        company: '',
        mainInfo: '',
        socialMessage: '',
        extraInfo: '',
        language: '',
      }
  );

  useEffect(() => {
    if (params?.id && missionState?.selectedMission) {
      setValues(missionState?.selectedMission);
      setTags(
        missionState?.selectedMission?.tags.map((data) => {
          return { label: data.label, value: data.id };
        })
      );
      setSkills(
        missionState?.selectedMission?.missionsSkills?.map((data) => {
          return { label: data.key, value: data.id };
        })
      );
      setRequirements(missionState?.selectedMission?.requirements);
      if (missionState?.selectedMission?.documents) {
        setImages(missionState?.selectedMission?.documents);
      }
      if (missionState?.selectedMission?.missionCategories) {
        setValues({
          ...missionState?.selectedMission,
          missionCategories: missionState?.selectedMission?.missionCategories.map(el => el.id)
        });
      }
      if (missionState?.selectedMission.startDate || missionState?.selectedMission.endDate || missionState?.selectedMission.dailyBudget || missionState?.selectedMission.hourlyRate || missionState?.selectedMission.hoursPerWeek) {
        setIsAllDetails(true);
      }
      if (isCheck) {
        setValues({
          firstName: userState?.userInfo?.firstName,
          lastName: userState?.userInfo?.lastName,
          companyWebsite: userState?.userInfo?.companyWebsite ? userState?.userInfo?.companyWebsite : missionState?.selectedMission.companyWebsite,
          city: userState?.userInfo?.city ? userState?.userInfo?.city : missionState?.selectedMission.city,
          country: userState?.userInfo?.country ? userState?.userInfo?.country : missionState?.selectedMission.country,
          phoneNumber: userState?.userInfo?.phoneNumber ? userState?.userInfo?.phoneNumber : missionState?.selectedMission.phoneNumber,
          email: userState?.userInfo?.email,
          company: userState?.userInfo?.company ? userState?.userInfo?.company : missionState?.selectedMission.company,
          hourlyRate: userState?.userInfo?.hourlyRate ? userState?.userInfo?.hourlyRate : missionState?.selectedMission.hourlyRate,
          extraInfo: userState?.userInfo?.extraInfo ? userState?.userInfo?.extraInfo : missionState?.selectedMission.extraInfo,
          functionTitle: missionState?.selectedMission.functionTitle,
        });
      }
    }
  }, [missionState, isCheck]);

  useEffect(() => {
    if (params?.id) {
      setIsLoading(true)
      getMissionById(params.id).then(
        (res) => {
          setIsLoading(false)
        },
        (err) => {
          setIsLoading(false)
          history.push('/private' + APP_ROUTES.RECRUITER.MISSIONS);
        })
    }
  }, []);

  useEffect(() => {
    dispatchMissionState({
      type: MISSION_STATE.types.SELECT_MISSION,
      mission: null,
    });
    return () => {
      dispatchMissionState({
        type: MISSION_STATE.types.SELECT_MISSION,
        mission: null,
      });
    };
  }, []);

  useEffect(() => {
    if (mission) {
      setMissionDetails(mission);
    }
  }, [mission]);

  useEffect(() => {
    if (params?.id && missionState && missionState.selectedMission) {
      setMissionDetails(missionState.selectedMission);
    } else {
      setMissionDetails(null);
    }
  }, [missionState]);

  useEffect(() => {
    if (jobsState?.categories) {
      const newCategoryOptions = [];
      jobsState.categories.map((item) => {
        newCategoryOptions.push({
          label: item.label,
          value: item.id,
        });
      });
      if (missionState?.selectedMission?.missionCategories) {
        setAllSelectedCategories(
          newCategoryOptions.filter((item) =>
            missionState?.selectedMission?.missionCategories
              .map((data) => data?.id)
              .includes(item.value)
          )
        );
      }
      setCategoryOptions(newCategoryOptions);
    }
  }, [jobsState, missionState]);
  useEffect(() => {
    if (!jobsState?.categories.length) {
      getCategories();
    }
    dispatchSharedState({
      module: MODULES.MISSION_OVERVIEW,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  const onChangeCheckbox = (e, values) => {
    setIsCheck(e)
    if (e) {
      setValues({
        ...values,
        firstName: userState?.userInfo?.firstName,
        lastName: userState?.userInfo?.lastName,
        companyWebsite: userState?.userInfo?.companyWebsite ? userState?.userInfo?.companyWebsite : values.companyWebsite,
        city: userState?.userInfo?.city ? userState?.userInfo?.city : values.city,
        country: userState?.userInfo?.country ? userState?.userInfo?.country : values.country,
        phoneNumber: userState?.userInfo?.phoneNumber ? userState?.userInfo?.phoneNumber : values.phoneNumber,
        email: userState?.userInfo?.email,
        company: userState?.userInfo?.company ? userState?.userInfo?.company : values.company,
        hourlyRate: userState?.userInfo?.hourlyRate ? userState?.userInfo?.hourlyRate : values.hourlyRate,
        extraInfo: userState?.userInfo?.extraInfo ? userState?.userInfo?.extraInfo : values.extraInfo,
        functionTitle: values.functionTitle,
      });
    } else {
      setValues({
        ...values,
        firstName: '',
        lastName: '',
        companyWebsite: '',
        city: '',
        country: '',
        phoneNumber: '',
        email: '',
        company: '',
        hourlyRate: '',
        extraInfo: '',
      });
    }
  }

  const onCategoriesChange = (categories, values) => {
    setValues({
      ...values,
      missionCategories: categories.map((item) => item.value),
    });
    setAllSelectedCategories(categories);
  };

  const submitForm = async (values) => {
    for (const key in values) {
      if (values[key] === null || values[key] === '') {
        delete values[key];
      }
    }
    let image = [];
    if (images && images.length > 0) {
      for (let index = 0; index < images.length; index++) {
        if (images[index] instanceof File) {
          const myimage = await uploadFile(images[index]);
          if (myimage.data) image = [...image, myimage.data.filename];
        } else {
          image = [...image, images[index]?.name];
        }
      }
    }
    if (images && images.length > 0) {
      for (let index = 0; index < images.length; index++) {
        if (images[index] instanceof File) {
          const myimage = await uploadFile(images[index]);
          if (myimage.data) image = [...image, myimage.data.filename];
        } else {
          image = [...image, images[index]?.name];
        }
      }
    }
    const reqData = values?.startDate && values.endDate ? {
      ...values,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      documents: image,
      tags: tags.map((item) => item.value),
      skills: skills.map((item) => item.value),
      requirements,
    } : values?.startDate ? {
      ...values,
      startDate: new Date(values.startDate).toISOString(),
      documents: image,
      tags: tags.map((item) => item.value),
      skills: skills.map((item) => item.value),
      requirements,
    } : values?.endDate ? {
      ...values,
      endDate: new Date(values.endDate).toISOString(),
      documents: image,
      tags: tags.map((item) => item.value),
      skills: skills.map((item) => item.value),
      requirements,
    } : {
      ...values,
      documents: image,
      tags: tags.map((item) => item.value),
      skills: skills.map((item) => item.value),
      requirements,
    };
    if (!isAllDetails) {
      delete reqData.startDate;
      delete reqData.endDate;
    }
    if (!params?.id) {
      const result = await createMission(reqData, history.location.pathname.includes('/public'));
      if (result && result.status === 201) {
        toast.success(languageState.translation.ALERT.SUCCESS_ADD_MISSION, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        history.location.pathname.includes('/public') ? setValues({
          firstName: '',
          lastName: '',
          companyWebsite: '',
          remote: false,
          city: '',
          country: '',
          missionCategories: [],
          hourlyRate: 0,
          hoursPerWeek: 0,
          client: '',
          email: '',
          location: '',
          phoneNumber: '',
          dailyBudget: 0,
          currency: 'EUR',
          marginWithClient: 0,
          startDate: moment(),
          endDate: moment(),
          functionTitle: '',
          company: '',
          mainInfo: '',
          socialMessage: '',
          extraInfo: '',
          language: '',
        }) : history.push('/private' + APP_ROUTES.RECRUITER.MISSIONS);
      }
    } else {
      const result = await updateMission(params?.id, reqData);
      if (result && result.status === 200) {
        toast.success(languageState.translation.ALERT.SUCCESS_UPDATE_MISSION, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        !history.location.pathname.includes('/public') && history.push('/private' + APP_ROUTES.RECRUITER.MISSIONS);
      }
    }
  };
  const handleDeleteRequirement = (data) => {
    const newRequirements = requirements.filter((item) => item !== data);
    setRequirements(newRequirements);
  };

  const handleAddRequirement = () => {
    if (!requirement) {
      toast.warning(languageState.translation.ALERT.WARNING_EMPTY_REQUIREMENT, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }
    if (_.includes(requirements, requirement)) {
      toast.warning(languageState.translation.ALERT.WARNING_ADD_REQUIREMENT, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    else {
      setRequirements([...requirements, requirement]);
      setRequirement('');
    }
  };

  return (
    <div>
      {
        <HeaderRecruiter
          breadcrumb
          breadCrumbActive={initialValues.functionTitle}
          breadCrumbLinks={[{ label: languageState.translation.COMMON.RETURN_TO_OVERVIEW, link: APP_ROUTES.CONNECT.MISSIONS, type: 'return_to_previous' }]}
          namePage={missionDetails ? 'EDIT_MISSION' : 'ADD_MISSION'}
        />
      }
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema(languageState.translation.FORM_VALIDATION)}
        onSubmit={(values) => submitForm(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, touched, values, handleSubmit, handleChange, setFieldValue }) => (
          <Container fluid className="mt-4">
            <Row className="gx-2">
              <Col md="12" className="col-md-12 col-lg-8 ">
                <Card className="p-2">
                  <div className="d-flex justify-content-between align-items-center mx-2 border-bottom pb-3">
                    <h2 className="mb-0 mr-4">
                      {missionDetails ? null : languageState.translation.RECRUITER.MISSIONS.ADD_INFO}
                    </h2>
                    {
                      userState && userState.userInfo ? (
                        <label className="text-sm mb-0">
                          <input
                            type="checkbox"
                            className="mr-2"
                            onChange={(e) => onChangeCheckbox(e.target.checked, values)}
                          />
                          {languageState.translation.RECRUITER.MISSIONS.AUTOCOMPLETE_USER_INFO}
                        </label>
                      ) : null
                    }
                  </div>
                  <div>
                    <CardHeader>
                      <h4 className="mb-0">{languageState.translation.RECRUITER.MISSIONS.DETAIL_CONTACT_PERSON}</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-user" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Field
                                className={`form-control ${errors.firstName && touched.firstName ? "error-input" : ""}`}
                                placeholder={'First Name'}
                                type="text"
                                name="firstName"
                              />
                            </InputGroup>
                            {errors.firstName && touched.firstName && (
                              <div className="invalid-feedback">
                                {errors.firstName}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-user" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Field
                                // className="form-control"
                                placeholder={'Last Name'}
                                type="text"
                                name="lastName"
                                className={`form-control ${errors.lastName && touched.lastName ? "error-input" : ""}`}
                              />
                            </InputGroup>
                            {errors.lastName && touched.lastName && (
                              <div className="invalid-feedback">
                                {errors.lastName}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup>
                              <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="BE"
                                className="form-control"
                                id="input-phone"
                                name="phoneNumber"
                                placeholder={languageState.translation.COMMON.PHONE_NUMBER}
                                value={values.phoneNumber}
                                onChange={(val, event) =>
                                  handleChange({
                                    ...event,
                                    target: { name: 'phoneNumber', value: val },
                                  })
                                }
                                error={
                                  values.phoneNumber
                                    ? isValidPhoneNumber(values.phoneNumber)
                                      ? undefined
                                      : languageState.translation.FORM_VALIDATION.INVALID_PHONE_NUMBER
                                    : languageState.translation.FORM_VALIDATION.REQUIRED_PHONE_NUMBER
                                }
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-phone" />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {values.phoneNumber &&
                              !isValidPhoneNumber(values.phoneNumber) && (
                                <div className="invalid-feedback">
                                  {
                                    languageState.translation.FORM_VALIDATION.PHONE
                                  }
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-envelope" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Field
                                className={`form-control ${errors.email && touched.email ? "error-input" : ""}`}
                                placeholder={
                                  languageState.translation.COMMON.EMAIL
                                }
                                type="email"
                                name="email"
                              />
                            </InputGroup>
                            {errors.email && touched.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <Field
                            className={`form-control ${errors.company && touched.company ? "error-input" : ""}`}
                            type="text"
                            placeholder="Company"
                            name="company"
                          />
                          {errors.company && (
                            <div className="invalid-feedback">
                              {errors.company}
                            </div>
                          )}
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup>
                              <Field
                                className={`form-control ${errors.companyWebsite && touched.companyWebsite ? "error-input" : ""}`}
                                placeholder={'Company Website'}
                                type="text"
                                name="companyWebsite"
                              />
                            </InputGroup>
                            {errors.companyWebsite && touched.companyWebsite && (
                              <div className="invalid-feedback">
                                {errors.companyWebsite}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup>
                              <Field
                                className={`form-control ${errors.city && touched.city ? "error-input" : ""}`}
                                placeholder={
                                  languageState.translation.COMMON.CITY
                                }
                                type="text"
                                name="city"
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-map-marker" />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {errors.city && touched.city && (
                              <div className="invalid-feedback">
                                {errors.city}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup>
                              <Field
                                className={`form-control ${errors.country && touched.country ? "error-input" : ""}`}
                                placeholder={languageState.translation.COMMON.COUNTRY}
                                type="text"
                                name="country"
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-map-marker" />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {errors.country && touched.country && (
                              <div className="invalid-feedback">
                                {errors.country}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col
                          md="12"
                          className="align-end d-flex justify-content-between align-items-center"
                        >
                          <p className="font-weight-bold">{languageState.translation.RECRUITER.MISSIONS.JOB_DETAILS_TITLE}</p>
                          <div className="d-flex justify-content-end align-items-center">
                            <input
                              id="remote"
                              className="ml-3"
                              name="remote"
                              type="checkbox"
                              onChange={(e) =>
                                setFieldValue('remote', e.target.checked)
                              }
                            />
                            <Label className="ml-2" for="remote">
                              {languageState.translation.RECRUITER.MISSIONS.TYPE_MISSION_LABEL}
                            </Label>
                          </div>
                        </Col>
                        <Col md="12" className="my-2">
                          <Field
                            type="text"
                            className={`mt-4 form-control ${errors.functionTitle && touched.functionTitle ? "error-input" : ""}`}
                            onChange={handleChange}
                            name="functionTitle"
                            placeholder="Job Title"
                          />
                          {errors.functionTitle && touched.functionTitle && (
                            <div className="invalid-feedback">
                              {errors.functionTitle}
                            </div>
                          )}
                        </Col>
                        <Col md="12" className="my-2">
                          <Select
                            isMulti
                            isClearable
                            isSearchable
                            name="colors"
                            onChange={(e) => onCategoriesChange(e, values)}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            value={selectedCategories}
                            options={categoryOptions}
                            placeholder={'Select ' + languageState.translation.COMMON.CATEGORIES
                            }
                          />
                        </Col>
                        <Col md="12">
                          <MissionsTags skills={skills} setSkills={setSkills} tags={tags} setTags={setTags} />
                        </Col>
                        <Col md="12">
                          <ExEditor
                            placeholder={languageState.translation.COMMON.DESCRIPTION}
                            onChangeContent={(html) => {
                              setFieldValue('mainInfo', html)
                            }}
                            className="mb-4 mt-4"
                            name="mainInfo"
                            initialContent={initialValues?.mainInfo}
                          />
                        </Col>
                        <Col md="12">
                          <label>
                            {languageState.translation.RECRUITER.MISSIONS.SELECT_EXIST_FREELANCERS_LABEL}
                          </label>
                          <Select
                            className="basic-multi-select mt-2"
                            isSearchable
                            isClearable
                            name="extra-deatils"
                            id="extra-deatils"
                            options={[
                              { value: 'true', label: languageState.translation.RECRUITER.MISSIONS.EXIST_FREELANCERS_OPTION },
                              { value: 'false', label: languageState.translation.RECRUITER.MISSIONS.NO_FREELANCERS_OPTION }]}
                            value={isAllDetails.toString() === 'true' ? { value: 'true', label: languageState.translation.RECRUITER.MISSIONS.EXIST_FREELANCERS_OPTION } : { value: 'false', label: languageState.translation.RECRUITER.MISSIONS.NO_FREELANCERS_OPTION }}
                            onChange={(e) => {
                              setIsAllDetails(e.value == 'true');
                            }}
                          />
                        </Col>
                        {isAllDetails && (
                          <>
                            <Col lg={12} md={12} sm={12} className='pt-3'>
                              <span className="d-block font-weight-bold">
                                {languageState.translation.COMMON.DETAILS}
                              </span></Col>
                            <Col className="col-lg-6 col-12">
                              <Label>
                                <small>
                                  {languageState.translation.COMMON.START_DATE}
                                </small>
                              </Label>
                              <Datetime
                                className={`${errors.startDate && touched.startDate ? "error-input" : ""}`}
                                onChange={(e) => setFieldValue('startDate', e)}
                                inputProps={{ placeholder: 'DD-MM-YYYY' }}
                                dateFormat={'DD-MM-YYYY'}
                                closeOnSelect={true}
                                timeFormat={false}
                                initialValue={initialValues?.startDate ?
                                  new Date(initialValues?.startDate) : new Date()
                                }
                              />
                              {errors.startDate && touched.startDate && (
                                <div className="invalid-feedback">
                                  {errors.startDate}
                                </div>
                              )}
                            </Col>
                            <Col className="col-lg-6 col-12">
                              <Label>
                                <small>
                                  {languageState.translation.COMMON.END_DATE}
                                </small>
                              </Label>
                              <Datetime
                                className={`${errors.endDate && touched.endDate ? "error-input" : ""}`}
                                onChange={(e) => setFieldValue('endDate', e)}
                                inputProps={{ placeholder: 'DD-MM-YYYY' }}
                                closeOnSelect={true}
                                timeFormat={false}
                                dateFormat={'DD-MM-YYYY'}
                                initialValue={initialValues?.endDate ? new Date(initialValues?.endDate) : new Date()}
                              />
                              {errors.endDate && touched.endDate && (
                                <div className="invalid-feedback">
                                  {errors.endDate}
                                </div>
                              )}
                            </Col>
                            <Col md="6" className="mt-4">
                              <FormGroup>
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend" className='h-40'>
                                    <InputGroupText>
                                      <i className="fas fa-credit-card" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className="form-control h-40"
                                    placeholder={
                                      languageState.translation.RECRUITER.MISSIONS.DAILY_BUDGET
                                    }
                                    onChange={handleChange}
                                    type="number"
                                    name="dailyBudget"
                                    defaultValue={initialValues?.dailyBudget ? initialValues?.dailyBudget : null}
                                  />
                                  <InputGroupAddon addonType="append" className='h-40'>
                                    <InputGroupText>
                                      <small className="font-weight-bold">
                                        EUR
                                      </small>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                                {errors.dailyBudget && (
                                  <div className="invalid-feedback">
                                    {errors.dailyBudget}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6" className="mt-4">
                              <FormGroup>
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend" className='h-40'>
                                    <InputGroupText>
                                      <i className="fas fa-credit-card" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className={`form-control ${errors.hourlyRate && touched.hourlyRate ? "error-input" : ""}`}
                                    onChange={handleChange}
                                    placeholder={
                                      languageState.translation.COMMON
                                        .HOURLY_RATE
                                    }
                                    type="number"
                                    name="hourlyRate"
                                    defaultValue={initialValues?.hourlyRate ? initialValues?.hourlyRate : null}
                                  />
                                  <InputGroupAddon addonType="append" className='h-40'>
                                    <InputGroupText>
                                      <small className="font-weight-bold">
                                        EUR
                                      </small>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                                {errors.hourlyRate && touched.hourlyRate && (
                                  <div className="invalid-feedback">
                                    {errors.hourlyRate}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Input
                                  className={`form-control ${errors.hoursPerWeek && touched.hoursPerWeek ? "error-input" : ""}`}
                                  placeholder={'Hours per week'}
                                  type="number"
                                  name="hoursPerWeek"
                                  onChange={handleChange}
                                  defaultValue={initialValues?.hoursPerWeek ? initialValues?.hoursPerWeek : null}
                                />
                                {errors.hoursPerWeek && touched.hoursPerWeek && (
                                  <div className="invalid-feedback">
                                    {errors.hoursPerWeek}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </>
                        )}
                      </Row>
                    </CardBody>
                  </div>
                </Card>
              </Col>

              <Col className="col-lg-4">
                <Card className="p-3">
                  <h2>{languageState.translation.RECRUITER.MISSIONS.DOCUMENTS}</h2>
                  <MultipleImageUpload
                    onChanges={(e) => setImages(e)}
                    defaultValues={initialValues.documents}
                    placeholder={languageState.translation.RECRUITER.MISSIONS.PLACEHOLDERS.DRAG_SELECT_PLACEHOLDER}
                  />
                </Card>
                <Card className="p-3 mt-4">
                  <h3 className="mb-4 mt-4 ">{languageState.translation.RECRUITER.MISSIONS.ONBOARDING_REQUIREMENTS}</h3>
                  {requirements?.map((data, index) => (
                    <p key={index} className="text-sm w-full d-flex justify-space-between align-items-center mt-2">
                      {data}{' '}
                      <Button
                        size="sm"
                        color="danger"
                        outline
                        className="ml-auto "
                        onClick={() => handleDeleteRequirement(data)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </p>
                  ))}
                  <div className="d-flex mt-4 requirement-input">
                    <Input
                      placeholder={languageState.translation.RECRUITER.MISSIONS.PLACEHOLDERS.ADD_EXTRA_REQUIREMENT_PLACEHOLDER}
                      value={requirement}
                      onChange={(e) => setRequirement(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleAddRequirement()
                        }
                      }}
                    />
                    <Button
                      color="primary"
                      onClick={handleAddRequirement}
                    >
                      {languageState.translation.COMMON.BUTTONS.ADD}
                    </Button>
                  </div>
                </Card>
                <Card className="p-3 mt-4">
                  <div className="mb-7">
                    <h3 className="mb-2 mt-4">
                      {languageState.translation.ADD_CONTACT.PRIVATE_NOTES}{' '}
                    </h3>
                    <ExEditor
                      placeholder={languageState.translation.RECRUITER.MISSIONS.PLACEHOLDERS.PRIVATE_NOTE_PLACEHOLDER}
                      onChangeContent={(html) => {
                        setFieldValue('extraInfo', html);
                      }}
                      initialContent={initialValues?.extraInfo}
                    />
                  </div>
                  <Button
                    color="primary"
                    className="w-full mt-9"
                    onClick={() => handleSubmit()}
                  >
                    {params?.id ? languageState.translation.RECRUITER.MISSIONS.EDIT_MISSION : languageState.translation.RECRUITER.POST_NEW_MISSION}
                  </Button>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </Formik>
      {isLoading && <LoaderPage />}
    </div>
  );
};

export default Addmission;
