import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Label, Input } from 'reactstrap';
import useFilesService from 'services/files/files.services';
import useFocusService from 'services/focus/focus.service';
import { AppContext } from 'store/app.context';
import schema from './validators';
import ReactDatetime from 'react-datetime';
import useMissionService from 'services/missions/mission.service';
import Select from 'react-select';

const ModalEditprojet = ({ modalShow, closeModal, projet }) => {
  const { languageState } = useContext(AppContext);
  const [endDate, setEndDate] = useState(false);
  const [startdDate, setStartDate] = useState(false);
  const { addProject, getMisions } = useFocusService();
  const { uploadFile } = useFilesService();
  const { updateMission } = useMissionService();

  const updateProject = async (values) => {
    const result1 = await updateMission(projet.id, {
      ...values,
    });
    if (result1 && result1.status === 200) {
      await getMisions();
      closeModal();

      toast.success(
        languageState.translation.FOCUS.DASHBOARD.PROJECT_UPDATED,
        {
          position: 'bottom-right',
          hideProgressBar: false,
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
    }
  }

  const periorityTypes = [
    { value: "Priority", label: languageState.translation.COMMON.PRIORITY },
    { value: "High", label: languageState.translation.COMMON.HIGH },
    { value: "Medium", label: languageState.translation.COMMON.MEDIUM },
    { value: "Low", label: languageState.translation.COMMON.LOW }
  ]

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            <i className='fa fa-times' />
          </button>
        }
        className="mb-0"
      >
        {languageState.translation.FOCUS.DASHBOARD.EDIT_PROJECT}
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <Formik
          initialValues={{
            name: projet.name,
            startDate: new Date(projet.startDate),
            endDate: new Date(projet.endDate),
            mainInfo: projet.mainInfo,
            status: projet.status,
            company: projet.company,
            priority: projet.priority,
          }}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={async (values) => {
            updateProject(values)
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
            <div>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.NAME}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.NAME}
                name="name"
                onChange={handleChange}
                defaultValue={projet.name}
              />
              <small className="text-red">
                {' '}
                {errors.name && touched.name && errors.name}
              </small>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.COMPANY}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.COMPANY}
                name="company"
                onChange={handleChange}
                defaultValue={projet.company}
              />
              <small className="text-red">
                {' '}
                {errors.company && touched.company && errors.company}
              </small>
              <Label className="d-block mt-1">
                {' '}
                {languageState.translation.COMMON.DESCRIPTION}
              </Label>
              <Input
                type="textarea"
                placeholder={languageState.translation.COMMON.DESCRIPTION}
                name="mainInfo"
                onChange={handleChange}
                defaultValue={projet.mainInfo}
              />
              <Label className="d-block mt-1">
                {' '}
                {languageState.translation.COMMON.PRIORITY}
              </Label>
              <Select
                className="basic-multi-select mt-2 mb-2"
                classNamePrefix="select"
                isClearable
                isSearchable
                id='priority'
                name="priority"
                defaultValue={periorityTypes.filter(el => el.value === projet.priority)}
                onChange={(e) => { if (e) values.priority = e.value }}
                options={periorityTypes}
              />
              <Label className="d-block">
                {' '}
                {languageState.translation.COMMON.START_DATE}
              </Label>
              <ReactDatetime
                closeOnSelect
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('startDate', date)}
                timeFormat={false}
                dateFormat={'DD-MM-YYYY'}
                initialValue={projet.startDate ? new Date(projet.startDate) : new Date()}
                onFocus={(e) => setStartDate(true)}
                onBlur={(e) => setStartDate(false)}
              />
              <small className="text-red">
                {errors.startDate && touched.startDate && errors.startDate}
              </small>

              <Label className="d-block">
                {languageState.translation.COMMON.END_DATE}
              </Label>
              <ReactDatetime
                closeOnSelect
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('endDate', date)}
                timeFormat={false}
                dateFormat={'DD-MM-YYYY'}
                initialValue={projet.endDate ? new Date(projet.endDate) : new Date()}
                onFocus={(e) => setEndDate(true)}
                onBlur={(e) => setEndDate(false)}
              />
              <small className="text-red">
                {errors.endDate && touched.endDate && errors.endDate}
              </small>
              {/* <label
                  className="btn btn-info m-2 w-100 mt-3"
                  color="default"
                  for="cover"
                >
                  <i className="ni ni-image"></i> Upload image
                </label>
                <input
                  type="file"
                  className="upload-input"
                  id="cover"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setImage(e.target.files[0]);
                  }}
                /> */}
              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditprojet;
