import ModalDeclined from 'components/modalDeclinedInvitations/ModalDeclined';
import ModalPending from 'components/modalPendingInvitations/ModalPending';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Badge, Button, Container } from 'reactstrap';
import { AppContext } from 'store/app.context';

// nodejs library to set properties for components
// reactstrap components
const CardsHeaderNetwork = ({ type, onBtnClick, numberOfUsers }) => {
  const { languageState } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [modalDeclinedShow, setDeclinedModalShow] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {

    if (pathname && pathname.includes('pending-invitations')) {
      setModalShow(true);

    }
  }, [pathname]);
  return (
    <>
      <div className="header bg-exprimary pb-7 pt-3 mt--7	">
        <Container
          fluid
          className="d-flex flex-row justify-content-between custom-header mt-7		align-items-center "
        >
          <div>
            <h1 className="text-white big-writing">
              {type && type === 'CONNECTED'
                ? languageState.translation.CONNECT.YOUR_NETWORK
                : languageState.translation.CONNECT.NETWORK}
            </h1>
            <p className="text-white font-weight-bold">
              {numberOfUsers != undefined && numberOfUsers} {languageState.translation.COMMON.MEMBERS}
            </p>
            {type && type === 'CONNECTED' && (
              <React.Fragment>
                <p className="text-white font-weight-bold d-flex  flex-wrap">
                  <Badge
                    className="badge-lg"
                    color="warning"
                    size="sm"
                    type="button"
                    onClick={() => {
                      setDeclinedModalShow(true);
                    }}
                  >
                    {languageState.translation.CONNECT.ALL_FREELANCERS_DECLINED}
                  </Badge>
                  <Badge
                    className="badge-lg"
                    color="success"
                    size="sm"
                    type="button"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    {languageState.translation.CONNECT.PENDING_INVITATION}
                  </Badge>
                </p>
              </React.Fragment>
            )}
          </div>

          <Button
            className="h-max-content pt-2 pb-2  pl-5 pr-5"
            onClick={() => onBtnClick()}
          >
            {type && type === 'CONNECTED'
              ? languageState.translation.CONNECT.FIND_NEW_CNX
              : languageState.translation.CONNECT.VIEW_MY_NETWORK}
          </Button>
        </Container>
        <ModalPending
          modalShow={modalShow}
          closeModal={() => {
            setModalShow(false);
            history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CONNECT.YOUR_NETWORK)
          }}
        />
        <ModalDeclined
          modalShow={modalDeclinedShow}
          closeModal={() => {
            setDeclinedModalShow(false);
          }}
        />
      </div>
    </>
  );
};

export default CardsHeaderNetwork;
