const TYPES = {
  GET_ALL_USERS: '[CONNECT] GET_ALL_USERS',
  GET_CONNECTED_USERS: '[CONNECT] GET_CONNECTED_USERS',
  SELECT_USER: '[CONNECT] SELECT_USER',
  GET_ALL_NOTES: '[CONNECT] GET_ALL_NOTES',
  GET_ALL_GROUPS: '[CONNECT] GET_ALL_GROUPS',
  GET_ALL_GROUP_USERS: '[CONNECT] GET_ALL_GROUP_USERS',
  GET_ALL_FAVORITES: '[CONNECT] GET_ALL_FAVORITES',
  GET_ALL_ARCHIVE: '[CONNECT] GET_ALL_ARCHIVES',
  GET_ALL_TAGS: '[CONNECT] GET_ALL_TAGS',
  GET_USER_TAGS: '[CONNECT] GET_USER_TAGS',
  GET_TAGGED_USERS: '[CONNECT] GET_TAGGED_USERS',
  GET_USERS_COUNTRIES: '[CONNECT] GET_USERS_COUNTRIES',
};

export default TYPES;
