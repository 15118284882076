import moment from 'moment';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';
import { MODULES } from '.';
const permissions = ['connect', 'dashboard', 'grow'];
const PerrmissionProvider = ({ children }) => {
  const { dispatchSharedState, sharedState, userState } =
    useContext(AppContext);
  const { userInfo } = userState;
  const history = useHistory();
  const freeModules = () => {
    if (process.env.REACT_APP_ENV == 'demo') {
      return ['CONNECT', 'YOUR_NETWORK', 'CHAT', 'JOBS'];
    } else if (process.env.REACT_APP_ENV == 'qa') {
      return ['CONNECT', 'YOUR_NETWORK', 'DASHBOARD', 'CHAT', 'JOBS'];
    } else if (process.env.REACT_APP_ENV == 'prod') {
      return ['CONNECT', 'YOUR_NETWORK', 'CHAT', 'JOBS'];
    } else {
      return [
        'HOME',
        'CONNECT',
        'YOUR_NETWORK',
        'DASHBOARD',
        'GROW',
        'FOCUS',
        'CHAT',
        'JOBS',
      ];
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.kind === 'COMPANY') return;
    if (
      userInfo &&
      userInfo?.subToUser &&
      sharedState?.currentModule != 'AUTH'
    ) {
      if (userInfo?.subToUser?.length === 0) {
        const date =
          30 - moment(Date.now()).diff(moment(userInfo?.created_at), 'days');
        if (date < 0) {
          if (freeModules().includes(sharedState.currentModule.toUpperCase())) {
            return;
          } else {
            dispatchSharedState({
              module: MODULES.SHARED,
              type: SHARED_STATE.types.SET_CURRENT_MODULE,
            });
            history.push('/private/payments');
          }
        }

        return;
      } else if (freeModules().includes(sharedState.currentModule)) return;
      else if (userInfo?.subToUser?.find((data) => data.isExpired === false)) {
        const data = userInfo?.subToUser?.find(
          (data) => data.isExpired === false
        );
        if (
          sharedState.currentModule === 'HOME' &&
          data.subscription.modules.includes('HOME')
        ) {
          return;
        } else if (
          data.subscription.modules.includes(
            sharedState.currentModule.toUpperCase()
          )
        ) {
          return;
        } else {
          dispatchSharedState({
            module: MODULES.SHARED,
            type: SHARED_STATE.types.SET_CURRENT_MODULE,
          });
          history.push('/private/payments');
        }
      } else {
        dispatchSharedState({
          module: MODULES.SHARED,
          type: SHARED_STATE.types.SET_CURRENT_MODULE,
        });
        history.push('/private/payments');
      }
    }
  }, [sharedState.currentModule, userInfo]);

  return <>{children}</>;
};

export default PerrmissionProvider;
