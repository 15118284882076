import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { AppContext } from 'store/app.context';
import { Formik } from 'formik';
import schema from './validators';
import useFocusService from 'services/focus/focus.service';
import { toast } from 'react-toastify';
import './style.css';
import useDashboardService from 'services/dashboard/dashboard.service';
import Select from 'react-select';

const ModalAddProject = ({ modalShow, closeModal }) => {
  const { languageState } = useContext(AppContext);
  const [endDate, setEndDate] = useState(false);
  const [startdDate, setStartDate] = useState(false);
  const { addProject, getMisions } = useFocusService();
  const { getDashBoardStats } = useDashboardService();

  const periorityTypes = [
    { value: "Priority", label: languageState.translation.COMMON.PRIORITY },
    { value: "High", label: languageState.translation.COMMON.HIGH },
    { value: "Medium", label: languageState.translation.COMMON.MEDIUM },
    { value: "Low", label: languageState.translation.COMMON.LOW }
  ]

  const addProjectHandler = async (values) => {
    const result1 = await addProject({
      ...values,
    });
    if (result1 && result1.status === 201) {
      await getMisions();

      toast.success(languageState.translation.FOCUS.DASHBOARD.PROJECT_ADDED, {
        position: 'bottom-right',
        hideProgressBar: false,
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      await getDashBoardStats();
      closeModal();
    }
  };

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            <i className='fa fa-times' />
          </button>
        }
        className="mb-0"
      >
        {languageState.translation.FOCUS.DASHBOARD.ADD_PROJECT}
      </ModalHeader>
      <ModalBody
        className="mt-0 pt-0"
        style={{
          overflow: 'hidden',
        }}
      >
        <Formik
          initialValues={{
            name: '',
            startDate: new Date(),
            endDate: new Date(),
            mainInfo: '',
            status: 'NEW',
            company: '',
            priority: '',
          }}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={async (values) => {
            addProjectHandler(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
            <div>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.RECRUITER.NAME_YOUR_PROJECT}
              </Label>
              <Input
                name="name"
                onChange={handleChange}
              />
              <small className="text-red">
                {' '}
                {errors.name && touched.name && errors.name}
              </small>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.COMPANY}
              </Label>
              <Input
                name="company"
                onChange={handleChange}
              />
              <small className="text-red">
                {' '}
                {errors.company && touched.company && errors.company}
              </small>
              <Label className="d-block mt-1">
                {' '}
                {languageState.translation.COMMON.DESCRIPTION}
              </Label>
              <Input
                type="textarea"
                name="mainInfo"
                onChange={handleChange}
              />
              <Label className="d-block mt-1">
                {' '}
                {languageState.translation.COMMON.PRIORITY}
              </Label>
              <Select
                className="basic-multi-select mt-2 mb-2"
                classNamePrefix="select"
                isClearable
                isSearchable
                id='priority'
                name="priority"
                onChange={(e) => { if (e) values.priority = e.value }}
                defaultValue={{ value: "Priority", label: languageState.translation.COMMON.PRIORITY }}
                options={periorityTypes}
              />
              <Label className="d-block">
                {' '}
                {languageState.translation.COMMON.START_DATE}
              </Label>
              <ReactDatetime
                dateFormat={'DD-MM-YYYY'}
                closeOnSelect
                initialValue={new Date()}
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('startDate', date)}
                timeFormat={false}
                onFocus={(e) => setStartDate(true)}
                onBlur={(e) => setStartDate(false)}
              />
              <small className="text-red">
                {errors.startDate && touched.startDate && errors.startDate}
              </small>

              <Label className="d-block">
                {languageState.translation.COMMON.END_DATE}
              </Label>
              <ReactDatetime
                initialValue={new Date()}
                closeOnSelect
                dateFormat={'DD-MM-YYYY'}
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('endDate', date)}
                timeFormat={false}
                onFocus={(e) => setEndDate(true)}
                onBlur={(e) => setEndDate(false)}
              />
              <small className="text-red">
                {errors.endDate && touched.endDate && errors.endDate}
              </small>
              {/* <label
                className="btn btn-info m-2 w-100 mt-3"
                color="default"
                for="cover"
              >
                <i className="ni ni-image"></i> Upload image
              </label>
              <input
                type="file"
                className="upload-input"
                id="cover"
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  setImage(e.target.files[0]);
                }}
              /> */}
              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalAddProject;
