import React from 'react';
import { components } from 'react-select';

export const IconOption = (props) => {
  const { Option } = components;

  if (props.data.value)
    return (
      <Option {...props} className="d-flex align-items-center">
        <i className={props.data.value} />
        <span className="ml-1"> {props.data.label}</span>
      </Option>
    );
  return <></>;
};

export const Control = ({ children, ...props }) => {
  const { Control } = components;

  const icon = props.getValue()[0]?.value;
  return (
    <Control {...props}>
      <i className={icon + ' ml-2'} /> {children}
    </Control>
  );
};
