import { IMG_PREFIX } from 'config/core';
import { MODULES } from 'config/core';
import { M_USERS } from 'config/mock/users/users.mock';
import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Media,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';
import { Range } from 'rc-slider';

export default function Sync() {
  const { dispatchSharedState } = useContext(AppContext);
  const [users, setUsers] = useState(M_USERS);
  const [selectAllEnabled, setSelectAllEnabled] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [minSlider, setMinSlider] = useState(0);
  const [maxSlider, setMaxSlider] = useState(100);
  const onCheckUser = (user) => {
    let newChecked = [...checkedUsers];
    if (newChecked.includes(user.id)) {
      newChecked = newChecked.filter((item) => item !== user.id);
    } else {
      newChecked.push(user.id);
    }

    setCheckedUsers(newChecked);
  };

  const selectAll = () => {
    if (!selectAllEnabled) {
      const newChecked = [...checkedUsers];
      users.forEach((user) => {
        if (!checkedUsers.includes(user.id)) {
          newChecked.push(user.id);
        }
      });
      setCheckedUsers(newChecked);
    } else {
      setCheckedUsers([]);
    }
    setSelectAllEnabled(!selectAllEnabled);
  };

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.RECRUITER,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);
  const { languageState } = useContext(AppContext);

  return (
    <div>
      <Container fluid className="mt-4">
        <Row>
          <Col className="col-lg-3 col-sm-12 col-12">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.TYPE}
                </h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck1"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        {languageState.translation.COMMON.FULL_TIME}
                      </label>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck2"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck2"
                      >
                        {languageState.translation.COMMON.PART_TIME}
                      </label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {' '}
                  {languageState.translation.COMMON.EXPERIENCE_LEVEL}{' '}
                </h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck3"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck3"
                      >
                        0-2 {languageState.translation.COMMON.YEARS}{' '}
                      </label>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck4"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck4"
                      >
                        2-5 {languageState.translation.COMMON.YEARS}
                      </label>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck5"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck5"
                      >
                        5-10 {languageState.translation.COMMON.YEARS}
                      </label>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck6"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck6"
                      >
                        10+ {languageState.translation.COMMON.YEARS}
                      </label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <h3 className="mb-0">
                    {languageState.translation.COMMON.HOURLY_RATE}
                  </h3>
                  <Badge className="mt-1 ml-4" color="secondary">
                    {minSlider} - {maxSlider}
                  </Badge>
                </Row>
              </CardHeader>
              <CardBody>
                <Range
                  marks={{
                    0: 0,
                    25: 25,
                    50: 50,
                    75: 75,
                    100: 100,
                  }}
                  count={2}
                  trackStyle={[
                    { backgroundColor: '#5e72e4' },
                    { backgroundColor: '#5e72e4' },
                  ]}
                  defaultValue={[0, 100]}
                  step={5}
                />
                <Badge
                  className="mt-5 d-flex justify-content-center c-pointer"
                  color="primary"
                >
                  {languageState.translation.COMMON.BUTTONS.SAVE}
                </Badge>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.CATEGORY}
                </h3>
              </CardHeader>
              <CardBody>
                <Row>
                  {' '}
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input className="custom-control-input" type="checkbox" />
                      <label className="custom-control-label">Copywriter</label>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input className="custom-control-input" type="checkbox" />
                      <label className="custom-control-label">
                        Virtual Assistant
                      </label>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      s
                      <input className="custom-control-input" type="checkbox" />
                      <label className="custom-control-label">
                        UX-Designer
                      </label>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="custom-control custom-checkbox mb-3">
                      <input className="custom-control-input" type="checkbox" />
                      <label className="custom-control-label">
                        Content Editor
                      </label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <div className="d-flex align-items-center justify-content-end mb-3">
              <Button color="primary">
                {languageState.translation.RECRUITER.IMPORT_FREELANCERS}
              </Button>

              <Button color="primary">
                {languageState.translation.RECRUITER.MISSIONS.ADD_FREELANCER}
              </Button>
              <Button color="primary">
                {
                  languageState.translation.RECRUITER.MISSIONS
                    .REQUEST_UPDATE_ALL
                }
              </Button>
              <label className="mr-2 mb-0">
                {languageState.translation.COMMON.SELECT_ALL}
              </label>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  checked={selectAllEnabled}
                  onChange={() => selectAll()}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off={languageState.translation.COMMON.NO}
                  data-label-on={languageState.translation.COMMON.YES}
                />
              </label>
            </div>
            <Card>
              <CardHeader className="border-0 d-flex align-items-center">
                <h3 className="mb-0">Freelancers in your Netowrk</h3>
                <div className="d-flex align-items-center ml-auto">
                  <label className="mt-1 mr-2">
                    {languageState.translation.COMMON.SEARCH}
                  </label>
                  <Input
                    type="text"
                    placeholder={languageState.translation.COMMON.SEARCH}
                  />
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort">
                      {languageState.translation.COMMON.FREELANCER.toUpperCase()}
                    </th>
                    <th className="sort" data-sort="budget">
                      Profile
                    </th>
                    <th>Type</th>
                    <th className="sort" data-sort="status">
                      {languageState.translation.COMMON.STATUS}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {users &&
                    users.length > 0 &&
                    users.map((user, index) => (
                      <tr key={user.id}>
                        <th scope="row ">
                          <div className="d-flex align-items-center">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id={'table-check-all' + index}
                                type="checkbox"
                                checked={checkedUsers.includes(user.id)}
                                onChange={() => onCheckUser(user)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={'table-check-all' + index}
                              />
                            </div>
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={`${IMG_PREFIX}${user.profileImage}`}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      require('assets/img/man.png').default;
                                  }}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  {user.firstName && user.lastName
                                    ? `${user.firstName} ${user.lastName.charAt(0)}.`
                                    : user.name}
                                </span>
                              </Media>
                            </Media>
                          </div>
                        </th>
                        <td>Webdeveloper</td>

                        <td>Full time</td>
                        <td>
                          <Badge className="badge-dot mr-4" color="">
                            <i className="bg-warning" />
                            <span className="status">
                              {
                                languageState.translation.RECRUITER.MISSIONS
                                  .NOT_DATE
                              }
                            </span>
                          </Badge>
                        </td>
                        <td>
                          <Button color="primary">
                            {' '}
                            {
                              languageState.translation.RECRUITER.MISSIONS
                                .REQUEST_UPDATE
                            }
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
