import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import MISSION_STATE from 'store/missions/missions.state';
import useHttpClient from '../http-client-hook';

const useMissionService = () => {
  const { httpClient } = useHttpClient();
  const { dispatchMissionState } = useContext(AppContext);

  const createMission = async (payload, isPublic) =>
    httpClient.post(isPublic ? '/missions/public-mission' : `/missions`, payload);

  const getMisions = async (params) => {
    const result = await httpClient.get('/missions', { params: params });
    if (result && result.status == 200) {
      dispatchMissionState({
        type: MISSION_STATE.types.SET_MISSIONS,
        missions: result?.data,
      });
    }
  };

  const getAllMisions = async () => {
    const result = await httpClient.get('/missions/all-missions');
    if (result && result.status == 200) {
      dispatchMissionState({
        type: MISSION_STATE.types.SET_MISSIONS,
        allMissions: result?.data,
      });
    }
  };

  const getMissionById = async (missionId) => {
    const result = await httpClient.get('/jobs/' + missionId);
    if (result && result.status == 200) {
      dispatchMissionState({
        type: MISSION_STATE.types.SELECT_MISSION,
        mission: result?.data,
      });
    }
    return result
  };

  const archiveMission = async (missionId, status) =>
    httpClient.put(`/missions/${missionId}/${status}`);

  const updateMission = async (missionId, payload) =>
    httpClient.patch(`/missions/${missionId}`, payload);

  const deleteMission = async (missionId) =>
    httpClient.delete(`/missions/${missionId}`);

  const getPositionsByMission = async (missionId) =>
    httpClient.get(`/missions/${missionId}/positions`);

  const getPositions = async () => httpClient.get('/positions');
  const getTags = async () => {
    const result = await httpClient.get('/missions/mission-tags')
    if (result && result.status == 200) {
      return result.data
    }
    return []
  }
  return {
    getMisions,
    getAllMisions,
    createMission,
    updateMission,
    deleteMission,
    getMissionById,
    getPositionsByMission,
    getPositions,
    archiveMission,
    getTags
  };
};

export default useMissionService;
