import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import { Container } from 'reactstrap';
import './style.scss'
import BreadcrumbComponent from 'components/breadcrumb';

const HeaderRecruiter = ({ namePage, breadcrumb, breadCrumbActive, breadCrumbLinks }) => {
  const { languageState } = useContext(AppContext);

  return (
    <>
      <div className="header header-recruiter pb-1 pt-3">
        {breadcrumb && (
          <BreadcrumbComponent breadCrumbActive={breadCrumbActive} breadCrumbLinks={breadCrumbLinks} />
        )
        }
        <Container
          fluid
          className="d-flex flex-row justify-content-between custom-header align-items-center "
        >
          <h1 className="text-dark big-writing">
            {' '}
            {languageState.translation.RECRUITER.TITLES[namePage]}
          </h1>
        </Container>
      </div>
    </>
  );
};

export default HeaderRecruiter;
