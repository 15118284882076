import 'react-phone-number-input/style.css';
import { cities_belgium } from 'config/const';
import * as _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import useFilesService from 'services/files/files.services';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';
import AddressBasicInformation from './components/basic-information/address/address-basic-information';
import UserInformation from './components/basic-information/user-information/user-information';
import AboutMe from './components/basic-information/about-me/about-me';
import Links from './components/basic-information/links/links';
import UpdatePassword from './components/update-password/update-password';
import DailyHourlyRate from './components/basic-information/dailyhourly-rate/daily-hourly-rate';
import DeleteAccount from 'pages/settings/inner-pages/availability/delete-account';

const Profile = () => {
  const { languageState, userState } = useContext(AppContext);

  const [initialValues, setValues] = useState({
    email: '',
    name: '',
    firstName: '',
    lastName: '',
    address: '',
    region: '',
    country: 'BELGIUM',
    postalCode: '',
    phoneNumber: '',
    aboutMe: '',
    VATNumber: '',
  });

  const [cities, setCities] = useState();
  const [postalCode] = useState('');
  const [isRegionChanged, setIsRegionChanged] = useState(false);
  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } = useUserService();

  const { uploadFile } = useFilesService();

  useEffect(() => {
    const mappedCities = cities_belgium.map((city) => ({
      label: city.name,
      value: city.name,
    }));
    setCities(mappedCities);
  }, []);

  useEffect(() => {
    if (userState.userInfo) {
      const values = {
        email: userState.userInfo.email || '',
        name: userState.userInfo.name || '',
        firstName: userState.userInfo.firstName || '',
        lastName: userState.userInfo.lastName || '',
        address: userState.userInfo.address || '',
        region: userState.userInfo.region || '',
        country: userState.userInfo.country || '',
        postalCode: userState.userInfo.postalCode || '',
        phoneNumber: userState.userInfo.phoneNumber || '',
        aboutMe: userState.userInfo.aboutMe || '',
        VATNumber: userState.userInfo.VATNumber || '',
      };
      setValues(values);
    }
  }, [userState]);

  const onRegionChange = (e, values) => {
    const postalCode = cities_belgium.find(
      (city) => city.name === e.value
    ).postalCode;
    setIsRegionChanged(true);
    setValues({
      ...values,
      postalCode,
      region: e.value,
    });
  };

  const handleImageUpload = async (event) => {
    const result = await uploadFile(event.target.files[0]);
    if (result && result.status === 201) {
      const reqData = {
        profileImage: result.data.filename,
      };
      const result2 = await updateBasicInfos(reqData);
      if (result2 && result2.status === 200) {
        autoUpdateUserInfo();
        getUserInfo()
        toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_AVATAR, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    }
  };

  const submitForm = async (values) => {
    if (values.phoneNumber && !isValidPhoneNumber(values.phoneNumber)) {
      return;
    }
    if (_.isEqual(initialValues, values) && !isRegionChanged) {
      return;
    }
    const result = await updateBasicInfos(values);
    if (result && result.status === 200) {
      autoUpdateUserInfo();
      getUserInfo();
      toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
        position: 'bottom-right',
        hideProgressBar: false,
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  return (
    userState.userInfo && (
      <Container fluid>
        <UserInformation />
        {userState.userInfo.kind !== "COMPANY" && <AboutMe />}
        {userState.userInfo.kind !== "COMPANY" && <DailyHourlyRate />}
        <Links />
        <AddressBasicInformation />
        <UpdatePassword />

        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.DELETE_ACCOUNT_QUESTION}
            </h3>
            <CardBody className="d-flex justify-content-center">
              <DeleteAccount />
            </CardBody>
          </CardHeader>
        </Card>
      </Container>
    )
  );
};

export default Profile;
