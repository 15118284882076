import { currencies } from 'config/const';
import { Formik } from 'formik';
import { dailyHourlyRateSchema } from 'pages/profile/schema';
import React, { useContext, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardHeader, Row, Col, CardBody, FormGroup, Input, Container } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';

const PAYMENT_TYPE = {
  HOURLY_BASED: 'HOURLY_BASED',
  PROJECT_BASED: 'PROJECT_BASED',
  DAILY_BASED: 'DAILY_BASED',
};

const DEFAULT_CURRENCY = {
  label: 'Euro',
  value: 'EUR',
};

const DailyHourlyRate = () => {
  const { languageState, userState } = useContext(AppContext);
  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } = useUserService();
  const currentAmount = useRef(0);
  const [initialValues, setValues] = useState({
    paymentType: userState.userInfo.paymentType || '',
    currency: userState.userInfo.currency || DEFAULT_CURRENCY.value,
    amount: userState.userInfo.amount || 0,
    shouldBeVisible: userState.userInfo.shouldBeVisible ?? false,
  });

  const defaultCurrency = useMemo(() => {
    return currencies.filter(
      (currentCurrency) => currentCurrency.value === userState.userInfo.currency
    );
  }, [userState]);

  const dataToSend = (values) => {
    let data = {};
    if (values.paymentType === PAYMENT_TYPE.HOURLY_BASED) {
      data = {
        ...values,
        hourlyRate: values.amount,
      };
    } else if (values.paymentType === PAYMENT_TYPE.DAILY_BASED) {
      data = {
        ...values,
        dailyRate: values.amount,
      };
    } else {
      data = values;
    }
    return data;
  };

  const updateUserRate = async (values) => {
    const result = await updateBasicInfos(dataToSend(values));
    if (result && result.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
      toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.INVOICE}
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Formik
          // enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            updateUserRate(values);
          }}
          validationSchema={dailyHourlyRateSchema(
            languageState.translation.FORM_VALIDATION,
            PAYMENT_TYPE.HOURLY_BASED,
            PAYMENT_TYPE.DAILY_BASED
          )}
        >
          {({ errors, values, handleSubmit, handleChange, touched, setFieldValue, setTouched }) => {
            return (
              <div className="pl-lg-4">
                <FormGroup>
                  <h3>{languageState.translation.SETTINGS.PAYMENT_BASE}</h3>
                  <Row className="pl-lg-4">
                    <Col>
                      <input
                        className="custom-control-input"
                        id="hourlyRate"
                        type="checkbox"
                        checked={
                          values.paymentType === PAYMENT_TYPE.HOURLY_BASED
                        }
                        onChange={(e) => {
                          setFieldValue(
                            'paymentType',
                            values.paymentType === PAYMENT_TYPE.HOURLY_BASED ? "" : PAYMENT_TYPE.HOURLY_BASED
                          );
                          setFieldValue('amount', 0)
                          setFieldValue('currency', DEFAULT_CURRENCY.value)
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="hourlyRate"
                      >
                        {languageState.translation.SETTINGS.HOURLY_RATE_BASED}
                      </label>
                    </Col>
                    <Col>
                      <input
                        className="custom-control-input"
                        id="dailyRate"
                        type="checkbox"
                        checked={
                          values.paymentType === PAYMENT_TYPE.DAILY_BASED
                        }
                        onChange={(e) => {
                          setFieldValue(
                            'paymentType',
                            values.paymentType === PAYMENT_TYPE.DAILY_BASED ? "" : PAYMENT_TYPE.DAILY_BASED
                          );
                          setFieldValue('amount', 0)
                          setFieldValue('currency', DEFAULT_CURRENCY.value)
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="dailyRate"
                      >
                        {languageState.translation.SETTINGS.DAILY_RATE_BASED}
                      </label>
                    </Col>
                    <Col>
                      <input
                        className="custom-control-input"
                        id="projectBased"
                        type="checkbox"
                        checked={
                          values.paymentType === PAYMENT_TYPE.PROJECT_BASED
                        }
                        onChange={(e) => {
                          setFieldValue(
                            'paymentType',
                            values.paymentType === PAYMENT_TYPE.PROJECT_BASED ? "" : PAYMENT_TYPE.PROJECT_BASED
                          );
                          setFieldValue('amount', 0)
                          setFieldValue('currency', "")
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="projectBased"
                      >
                        {languageState.translation.SETTINGS.PROJECT_BASED}
                      </label>
                    </Col>
                  </Row>
                  {errors.paymentType && touched.paymentType && (
                    <div className="invalid-feedback">{errors.paymentType}</div>
                  )}
                </FormGroup>
                {values.paymentType !== PAYMENT_TYPE.PROJECT_BASED && (
                  <FormGroup>
                    <h3>{languageState.translation.SETTINGS.FEE}</h3>
                    <Row>
                      <Col>
                        <Input
                          type="number"
                          name="amount"
                          value={values.amount}
                          onChange={handleChange}
                          placeholder="45.50"
                        />
                        {errors.amount && touched.amount && (
                          <div className="invalid-feedback">{errors.amount}</div>
                        )}
                      </Col>
                      <Col>
                        <Select
                          options={currencies}
                          isSearchable
                          isClearable
                          value={values.currency ? currencies.find((el) => el.value === values.currency) : ""}
                          onChange={(e) => {
                            setFieldValue('currency', e && e.value ? e.value : "")
                          }}
                        />
                        {errors.currency && touched.currency && (
                          <div className="invalid-feedback">{errors.currency}</div>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                )}
                <Container>
                  <input
                    className="custom-control-input"
                    id="shouldBeVisible"
                    type="checkbox"
                    name="shouldBeVisible"
                    checked={values.shouldBeVisible}
                    onChange={(e) => {
                      setFieldValue('shouldBeVisible', !values.shouldBeVisible);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="shouldBeVisible"
                  >
                    {
                      languageState.translation.SETTINGS
                        .SHOULD_AMOUNT_BE_VISIBLE
                    }
                  </label>
                </Container>
                <button
                  className="btn bg-exprimary mt-2 ml-auto d-block"
                  onClick={() => {
                    // Set all fields as touched
                    setTouched({
                      paymentType: true,
                      amount: true,
                      currency: true,
                      shouldBeVisible: true,
                    });
                    // handle Submit form
                    handleSubmit()
                  }}
                >
                  {languageState.translation.COMMON.BUTTONS.SAVE_INFO}
                </button>
              </div>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default DailyHourlyRate;
