import classnames from 'classnames';
import Avatar from 'components/avatar/avatar';
import React, { useContext } from 'react';
import { AppContext } from 'store/app.context';
import { isRoomSeen, getLastMessage, getRoomName } from 'utils';

const Contact = ({ onClick, room, isActive }) => {
  const { languageState, userState, chatState } = useContext(AppContext);

  return (
    <div
      onClick={onClick}
      className={classnames(
        'd-flex align-items-start w-100  gap-1  p-3 rounded c-pointer contact-card position-relative',
        {
          'active-contact ': isActive,
          'not-seen': !isRoomSeen(room, chatState?.chatUserId),
        }
      )}
    >
      <div
        className={
          !isRoomSeen(room, chatState?.chatUserId)
            ? 'chat-online bg-primary top-50'
            : ''
        }
        style={{ top: '40%', transform: 'translateY(-50%)' }}
      />
      <Avatar
        forNavbar={true}
        name={getRoomName(room, chatState?.chatUserId) || languageState.translation.CHAT.UNKNOWN_USER}
        className="avatar mr-2 rounded-circle custom-avatar "
      />

      <div className="d-flex flex-column w-100">
        <span className="h2 mb-1 chat-white">
          {getRoomName(room, chatState?.chatUserId) || languageState.translation.CHAT.UNKNOWN_USER}
        </span>
        {!isActive && (
          <div className="d-flex flex-column text-muted w-100 overflow-hidden">
            <small className="chat-white mt--1 mb-2">{room?.updatedDate}</small>
            <span className=" text-gray-dark chat-message chat-white ">
              {getLastMessage(room, chatState?.chatUserId) || languageState.translation.CHAT.SAY_HELLO + ' ' + languageState.translation.CHAT.UNKNOWN_USER}
            </span>
          </div>
        )}
        {isActive && (
          <span className="mb-3">
            {getLastMessage(room, chatState?.chatUserId)}
          </span>
        )}
      </div>
    </div>
  );
};

export default Contact;
