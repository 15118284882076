import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import JobsContainer from '../JobsContainer';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import useJobsService from 'services/jobs/jobs.service';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import { AppContext } from 'store/app.context';
import Empty from 'components/Empty/Empty';
import LoaderPage from 'components/LoaderPage/LoaderPage';
import './JobDetails.scss';
import JobCard from '../jobboard/JobCard';
import ExEditor from 'components/editor/editor';

const JobDetails = () => {
  const { getJobDetail, checkIsApplied, applyJob, deleteApplication, postViewJob } = useJobsService();
  const history = useHistory();
  const { userState, languageState } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [myJob, setMyjob] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [personalMessage, setPersonalMessage] = useState('')
  const { userInfo } = userState;
  const [job, setJob] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [requirementsError, setRequirementsError] = useState(false)
  const [allSkills, setAllSkills] = useState([])

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getAsyncJob(id);
      checkIfApplied(id);
    }
  }, [id]);
  useEffect(() => {
    if (job?.owner?.id === userInfo?.id) {
      setMyjob(true);
    }
  }, [userState, job]);
  useEffect(() => {
    if (job) {
      const array = [
        ...(job.missionsSkills || []),
        ...((job.tags || []).map((el) => {
          return { id: el, key: el }
        }))
      ]
      setAllSkills(array.sort((a, b) => a.key.localeCompare(b.key, undefined, { sensitivity: 'base' })))
    }

  }, [job])

  const getAsyncJob = async (currentID) => {
    getJobDetail(currentID).then(
      (res) => {
        setJob(res.data);
        setIsLoading(false);
      },
      (err) => {
        setJob(null);
        setIsLoading(false);
      }
    );
  };
  const checkIfApplied = async (currentID) => {
    const result = await checkIsApplied(currentID);
    setIsApplied(result?.isApplied);
  };
  const handleApplication = async () => {
    if (!requirements.length || requirements.length <= 0) {
      !requirements.length || requirements.length <= 0 ? setRequirementsError(true) : setRequirementsError(false)
    } else {
      if (!isApplied) {
        const result = await applyJob(id, { validatedRequirements: requirements, form: personalMessage });
        if (result) {
          toast.success('Applied Succesfully', {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          checkIfApplied(id);
        }
        return;
      }
      const result = await deleteApplication(id);
      if (result) {
        toast.success('Deleted application Succesfully', {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        checkIfApplied(id);
      }
    }
  };
  const chatWithEmployer = () => {
    history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
      email: job?.owner?.email,
    });
  };
  const handleShowShareWithFriend = () => {
    setIsShared((prev) => !prev);
  };
  if (isLoading) {
    return <JobsContainer>
      <LoaderPage />
    </JobsContainer>
  } else if (!job) {
    return (
      <JobsContainer>
        <Empty />
      </JobsContainer>
    );
  }

  const changeRequirement = (e) => {
    if (e.target.checked) {
      setRequirements([...requirements, e.target.value])
    } else {
      let requirementFiltered = requirements.filter(el => el !== e.target.value)
      setRequirements(requirementFiltered)
    }
  }

  const openDetails = (id,) => {
    if (userState && userState.userInfo) {
      postViewJob(id)
      history.push('/private/jobs/job-details/' + id)
    }
  }

  return (
    <JobsContainer>
      <CardsHeaderFocus
        name={job?.functionTitle}
        breadcrumb
        className='job-breadcrumb'
        published={moment(job?.createdAt).format('DD MMMM YYYY')}
      />
      <Container fluid className="mt-4 job-details">
        <div className="job-detail-header">
        </div>
        <Row>
          <Col lg="7" md="12">
            <Card>
              <CardBody>
                <div className="job-description">
                  <h1>{languageState.translation.COMMON.JOB_DESCRIPTION}</h1>
                  <p className="job-description-text" dangerouslySetInnerHTML={{ __html: job?.mainInfo }}>

                  </p>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>

                <div className="job-description">
                  <h1>{languageState.translation.COMMON.LIKE_TO_APPLY_FOR_THIS_JOB}</h1>
                  {allSkills && allSkills.length ? (
                    <>
                      <p className="font-weight-bold mb-2">
                        {languageState.translation.COMMON.HAVE_FOLLOWING_SKILLS_FOR_JOB}
                      </p>
                      <div className="required-skills">
                        <p className="required-skills-text">{languageState.translation.COMMON.REQUIRED_SKILLS}:</p>
                        {allSkills?.map((missionSkill) => (
                          <div key={missionSkill.id} className="skill">
                            <p className="skill-text text-nowwrap">
                              {missionSkill.key}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                  {
                    job && job.requirements && job.requirements.length ?
                      <div>
                        <p className="font-weight-bold mb-2">
                          {languageState.translation.COMMON.EXTRA_REQUIREMENTS_FOR_THIS_JOB}
                        </p>
                        <div className='row mx-3'>
                          {
                            job.requirements.map((req, index) => (
                              <div className='mb-2 col-lg-12 col-md-12 col-sm-12' key={index}>
                                <Input
                                  className="custom-control-input position-relative"
                                  id={`requirement-${index}`}
                                  name={`requirement-${index}`}
                                  value={req}
                                  type="checkbox"
                                  onChange={(e) => changeRequirement(e)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`requirement-${index}`}
                                >
                                  {req}
                                </label>
                              </div>
                            ))
                          }
                        </div>
                        {
                          requirementsError && requirements.length <= 0 && (
                            <span className='text-danger'>{languageState.translation.COMMON.EXTRA_REQUIREMENTS_FOR_THIS_JOB_ERROR_MESSAGE}</span>
                          )
                        }
                      </div> : null
                  }
                  <p className="font-weight-bold mb-2">
                    {languageState.translation.COMMON.PERSONAL_MESSAGE_FOR_THE_JOBPOSTER_LABEL}
                  </p>
                  <ExEditor
                    name="personalMessage"
                    onChangeContent={(html) => setPersonalMessage(html)}
                  />
                </div>
                <div className='mt-3 d-flex justify-content-end'>
                  <Button disabled={isApplied} onClick={handleApplication} color='danger'>{languageState.translation.COMMON.BUTTONS.SEND_APPLICATION}</Button>
                </div>
              </CardBody>
            </Card>
            {/* <Card>
              <CardBody>

                <div className="job-description">
                  <h1>{languageState.translation.COMMON.EXTRA_INFO_ABOUT_THIS_TYPE_JOB}</h1>
                  <p className="job-description-text">
                    {job?.extraInfo}
                  </p>
                </div>
              </CardBody>
            </Card> */}
          </Col>
          <Col lg="5" md="12">
            <Card>
              <CardBody>
                <div className="job-description">
                  <h1>{languageState.translation.COMMON.EXTRA_INFO_ABOUT_THIS_JOB}</h1>
                  <div className='d-flex align-items-center flex-wrap'>
                    {job?.dailyBudget ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.dailyBudget} {languageState.translation.JOBBOARD.EURO_PER_DAY}
                        </p>
                      </div>
                    ) : null}
                    {job?.missionLocation ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.missionLocation}
                        </p>
                      </div>
                    ) : null}
                    {job?.nbViews ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.nbViews} {languageState.translation.JOBBOARD.VIEWS}
                        </p>
                      </div>
                    ) : null}
                    {/* {job?.company ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {languageState.translation.COMMON.COMPANY} {job.company}
                        </p>
                      </div>
                    ) : null} */}
                    {job?.country ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.country}
                        </p>
                      </div>
                    ) : null}
                    {job?.city ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.city}
                        </p>
                      </div>
                    ) : null}
                    {job?.missionCategories?.length ? (
                      job.missionCategories.map(el => (
                        <div key={el.id} className="skill mr-2 mb-2">
                          <p className="skill-text">
                            {el.label}
                          </p>
                        </div>
                      ))
                    ) : null}
                    {job?.hoursPerWeek ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.hoursPerWeek} {languageState.translation.JOBBOARD.HOURS_PER_WEEK}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>
            {job?.similarJobs?.length ?
              (<>
                <h1 className='mt-0'>{job.similarJobs.length} {languageState.translation.COMMON.SIMILAR_OPEN_POSITION}</h1>
                {job.similarJobs.map((job, index) => (
                  <Card key={`job-${index}`}>
                    <JobCard
                      {...job}
                      openDetails={openDetails}
                      isPublished
                    />
                  </Card>
                ))}
              </>) : null
            }
          </Col>
        </Row>
      </Container>
    </JobsContainer>
  );
};

export default JobDetails;
