import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.scss'

const ExEditor = ({ initialContent, onChangeContent, placeholder, className }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [HTML, setHTML] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHTML(html);
    onChangeContent(html);
  };

  useEffect(() => {
    if (initialContent) {
      const blocksFromHTML = convertFromHTML(initialContent);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(
        initialContent
          ? EditorState.createWithContent(content)
          : EditorState.createEmpty()
      );
    }
  }, [initialContent]);

  return (
    <React.Fragment>
      <Editor
        toolbar={{
          options: ['inline', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],

          }
        }}
        placeholder={placeholder}
        editorState={editorState}
        toolbarClassName={`toolbarClassName`}
        wrapperClassName={`wrapperClassName bordered ${className}`}
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
    </React.Fragment>
  );
};

export default ExEditor;
