import React, { useEffect, useState } from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import useChatService from 'services/chat/chat.service';

const FileMessage = ({ path }) => {
  const [file, setFile] = useState(null);
  const [type, setType] = useState(null);
  const [isImageOpened, setIsImageOpened] = useState(false);

  const { getMessageFile } = useChatService();

  const downloadFile = async (path) => {
    const blob = await getMessageFile(path);
    if (blob.data.type.includes('image')) {
      setType('image');
    } else {
      setType('fileType');
    }
    setFile(URL.createObjectURL(blob.data));
  };

  useEffect(() => {
    if (path) {
      downloadFile(path);
    }
  }, [path]);

  function download(fileToDownload) {
    const a = document.createElement('a');
    a.href = fileToDownload;
    a.click();
  }

  return !file ? (
    <Spinner animation="border" />
  ) : (
    <>
      {type === 'image' && (
        <img
          className="chat-image"
          src={file}
          onClick={() => {
            setIsImageOpened(true);
          }}
        />
      )}
      {type === 'fileType' && (
        <div>
          <Row>
            <Col>
              <i className="far fa-file fa-5x" />
            </Col>
            <Col>
              <i
                className="fas fa-arrow-alt-circle-down fa-2x"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => download(file)}
              />
            </Col>
          </Row>
        </div>
      )}

      <Modal
        isOpen={isImageOpened}
        centered
        backdrop={true}
        style={{
          maxWidth: '80%',
        }}
        toggle={() => {
          setIsImageOpened(false);
        }}
      >
        <ModalBody
          style={{
            textAlign: 'center',
            padding: 0,
          }}
        >
          <img
            src={file}
            style={{
              borderRadius: '25px',
              padding: '5px',
              maxWidth: '60%',
              height: 'auto',
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default FileMessage;
