import TYPES from './experiences.types';
import reducer from './experiences.reducer';

const INITIAL_STATE = {
  experiences: null,
};

const EXPERIENCES_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default EXPERIENCES_STATE;
