import { useContext } from "react";
import { AppContext } from "store/app.context";

const ExtraDetails = ({ details }) => {
    const { languageState } = useContext(AppContext);
    return (
        <div className="max-note-list-height" >
            <div>
                <p dangerouslySetInnerHTML={{ __html: details ? details : languageState.translation.CONNECT.NO_EXTRA_DETAILS }}></p>
            </div>
        </div>
    )
}

export default ExtraDetails;