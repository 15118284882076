import React, { useContext, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button } from 'reactstrap';
import { AppContext } from 'store/app.context';
import { toast } from 'react-toastify';
import useSettingsService from 'services/settings/settings.service';
import { isNil, isEmpty } from "lodash"

const MissionsTags = ({ skills, setSkills, tags, setTags }) => {
  const { languageState, skillsState } = useContext(AppContext);
  const { getSkills } = useSettingsService();
  const [options, setOptions] = useState([]);
  const [tag, setTag] = useState(null);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    if (skillsState?.skills) {
      const newOptions = skillsState.skills.map(skill => ({
        value: skill.id,
        label: skill.key
      }));
      setOptions(newOptions);
    }
  }, [skillsState]);

  const CustomNoOptionsMessage = () => (
    <div className='text-center my-1'>
      {languageState.translation.COMMON.CREATE_TAG}
    </div>
  );

  return (
    <div>
      <div className="d-flex mt-4 align-items-center">
        <CreatableSelect
          components={{ NoOptionsMessage: CustomNoOptionsMessage }}
          onInputChange={(e) => setTag(e)}
          value={tag}
          options={skills?.length ? options.filter((el) => {
            return !skills.includes(el)
          }) : tag ? options.filter(el => el.label.toUpperCase().includes(tag.toUpperCase())) : []}
          isClearable
          className="w-100 h-40"
          placeholder={languageState.translation.COMMON.SEARCH_TAG}
          onChange={(e) => {
            setTag(null)
            !isNil(e) && !isEmpty(e) && setSkills([...skills, e]);
          }}
          onCreateOption={(e) => {
            setTag(null)
            setTags([...tags, { value: e, label: e }]);
          }}
        />
        <Button
          color="primary"
          className="ml-4 pr-6 pl-6 h-40"
          onClick={() => {
            if (tag) {
              setTags([...tags, tag]);
              setTag(null);
            }
            else {
              toast.warn(
                "Please select a tag befor add",
                {
                  position: 'bottom-right',
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                }
              );
            }
          }}
        >
          {languageState.translation.COMMON.BUTTONS.ADD}
        </Button>
      </div>
      {(tags && tags.length > 0) || (skills && skills.length > 0) ? (
        <div className="d-flex mt-4 align-items-center gap-1 flex-wrap tags-mission">
          <span className="text-bold">Selected tags:</span>
          {tags &&
            tags.map((myItem, index) => (
              <span key={index} className="tag btn-tags text-white cursor-pointer pl-4 pr-4">
                {myItem && myItem.label}
                <a onClick={() => setTags(tags.filter((item) => item.value !== myItem.value))} />
              </span>
            ))}
          {skills &&
            skills.map((myItem, index) => (
              <span key={index} className="tag btn-tags text-white cursor-pointer pl-4 pr-4">
                {myItem && myItem.label}
                <a onClick={() => setSkills(skills.filter((item) => item.value !== myItem.value))} />
              </span>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default MissionsTags;
