import axios from 'axios';
import { useContext, useEffect, useRef } from 'react';
import { AppContext } from 'store/app.context';
import { API_URL_CHAT } from 'config/core/index';
import socketClient from 'socket.io-client';
import { ChatSocketContext } from 'store/chat.context';
import { CHAT_EVENTS } from 'config/core';
import { getChatToken } from 'utils';
import CHAT_STATE from 'store/chat';
import { markRoomAsUnseen } from 'utils';
import useChatService from './chat/chat.service';

const ChatProvider = ({ children }) => {
  const { chatState, userState, dispatchChatState } = useContext(AppContext);

  const { getRooms } = useChatService();

  let socket = useRef(null);
  const chatStateRef = useRef(chatState);
  const userStateRef = useRef(userState);

  useEffect(() => {
    chatStateRef.current = chatState;
    userStateRef.current = userState;
  }, [chatState, userState]);

  useEffect(() => {
    const chatToken = userState?.chatToken;
    if (chatToken && !socket.current) {
      socket.current = socketClient(API_URL_CHAT, {
        path: '/realtime',
        forceNew: true,
        secure: process.env.NODE_ENV === 'production',
        query: {
          accessToken: chatToken,
        },
        transports: ['websocket', 'polling'],
      });
      socket.current.on(CHAT_EVENTS.CONNECT, (e) => {
        const chatUserId = userStateRef?.current?.userInfo?.cid;
        dispatchChatState({
          type: CHAT_STATE.types.SET_CHAT_STATUS,
          status: 'CONNECTED',
          socketId: socket.current?.id,
          chatUserId,
        });
      });

      socket.current.on(CHAT_EVENTS.USER_ONLINE, ({ id }) => {
        dispatchChatState({
          type: CHAT_STATE.types.ADD_ONLINE_USER,
          id,
        });
      });

      socket.current.on(CHAT_EVENTS.USER_OFFLINE, ({ id }) => {
        dispatchChatState({
          type: CHAT_STATE.types.REMOVE_ONLINE_USER,
          id,
        });
      });

      socket.current.on(CHAT_EVENTS.NEW_ROOM, (room) => {
        getRooms();
      });

      socket.current.on(CHAT_EVENTS.NEW_MESSAGE, ({ room, message }) => {
        updateRoomWhenNewMessageRecieved(room.id, message);
        dispatchChatState({
          type: CHAT_STATE.types.SET_NEW_MESSAGE_NOTIFICATION,
          newMessage: {
            message,
            room,
          },
        });
      });
      socket.current.on(CHAT_EVENTS.DELETE_MESSAGE, ({ messageId }) => {
        dispatchChatState({
          type: CHAT_STATE.types.DELETE_MESSAGE,
          messageId,
        });
      });
      socket.current.on(CHAT_EVENTS.DISCONNECT, () => {
        dispatchChatState({
          type: CHAT_STATE.types.SET_CHAT_STATUS,
          status: 'NOT_CONNECTED',
          socketId: '',
        });
      });
    }
  }, [userState?.chatToken, socket.current]);

  const chatClient = axios.create({
    baseURL: `${API_URL_CHAT}`,
    timeout: 60000,
  });

  const updateRoomWhenNewMessageRecieved = (roomId, message) => {
    if (chatStateRef?.current?.rooms) {
      const rooms = chatStateRef.current.rooms;
      const targetRoom = rooms.find((item) => item.id === roomId);
      if (targetRoom && targetRoom?.messages) {
        targetRoom.messages = [message];
      }
      markRoomAsUnseen(targetRoom, chatStateRef.current.chatUserId);
      const targetRoomIndex = rooms.findIndex((item) => item.id === roomId);
      rooms.splice(targetRoomIndex, 1);
      dispatchChatState({
        type: CHAT_STATE.types.UPDATE_ROOMS,
        rooms: [targetRoom, ...rooms],
      });
    }
  };

  return (
    <ChatSocketContext.Provider value={socket.current}>
      {children}
    </ChatSocketContext.Provider>
  );
};

export default ChatProvider;
