import MultipleImageUpload from 'components/multiple-image-upload/multiple-image-upload';
import SingelImageUpload from 'components/singel-image-uplaod/SingelImageUpload';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { AppContext } from 'store/app.context';

const FormItem = ({
  submitData,
  title = 'Add new item',
  intial = {
    title: '',
    link: '',
    category: '',
    videoUrl: '',
    description: '',
    company: '',
    screenShots: [],
  },
}) => {
  const [image, setImage] = useState();
  const [screenShots, setScreenShots] = useState(
    Array.isArray(intial?.screenShots) ? intial.screenShots : []
  );
  const { languageState } = useContext(AppContext);

  return (
    <>
      <h2>{title}</h2>{' '}
      <Formik
        initialValues={intial}
        // validationSchema={schema(languageState.translation.FORM_VALIDATION)}
        onSubmit={(values) => submitData({ values, image, screenShots })}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div>
            <div>
              <div className="d-flex mt-3">
                <div className="w-50">
                  <Input
                    placeholder={languageState.translation.SETTINGS.ITEM_TITLE}
                    name="title"
                    onChange={handleChange}
                    defaultValue={intial.title}
                  />
                  <small className="text-red">
                    {' '}
                    {errors.name && touched.name && errors.name}
                  </small>
                </div>
                <Input
                  className="w-50 ml-2"
                  placeholder={languageState.translation.SETTINGS.LINK}
                  name="link"
                  onChange={handleChange}
                  defaultValue={intial.link}
                />
              </div>
            </div>
            <Label className="d-block mt-1">
              {' '}
              {languageState.translation.SETTINGS.SHORT_SUMMARY}
            </Label>
            <Input
              type="textarea"
              placeholder={languageState.translation.SETTINGS.SHORT_SUMMARY}
              name="description"
              onChange={handleChange}
              defaultValue={intial.description}
            />
            <div className="d-flex mt-3 mb-3">
              <Input
                type="text"
                placeholder={languageState.translation.SETTINGS.COMPANY_NAME}
                name="company"
                onChange={handleChange}
                defaultValue={intial.company}
              />

              <SingelImageUpload onChanges={(e) => setImage(e)} />
            </div>
            <Label className="d-block mt-2">
              {' '}
              {languageState.translation.SETTINGS.UPLOAD_SCREENSHOTS}{' '}
              {languageState.translation.SETTINGS.ALLOW_SCREENSHOT}
            </Label>
            {
              <MultipleImageUpload
                defaultImages={intial?.screenShots}
                onChanges={(e) => setScreenShots(e)}
                onDeleteImage={(e) => {
                  console.log('delete', e);
                  setScreenShots(screenShots.filter((item) => item !== e));
                  intial.screenShots = intial.screenShots.filter(
                    (item) => item !== e
                  );
                }}
              />
            }
            <Label className="d-block mt-1">
              {' '}
              {languageState.translation.SETTINGS.VIDEO_URL}
            </Label>
            <Input
              type="text"
              placeholder={languageState.translation.SETTINGS.VIDEO_URL}
              name="videoUrl"
              onChange={handleChange}
              defaultValue={intial.videoUrl}
            />
            <Label className="d-block mt-1">
              {' '}
              {languageState.translation.SETTINGS.CATEGORY}
            </Label>
            <Input
              type="textarea"
              placeholder={languageState.translation.SETTINGS.CATEGORY}
              name="category"
              onChange={handleChange}
              defaultValue={intial.category}
            />
            <button
              className="btn bg-exprimary mt-4 mb-4 ml-auto d-block"
              type="submit"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {languageState.translation.SETTINGS.SAVE_PORTFOLIO_ITEM}
            </button>
          </div>
        )}
      </Formik>
    </>
  );
};

export default FormItem;
