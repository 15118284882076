import React, { useContext, useEffect, useState } from 'react';
import CardHeaderTagged from 'components/cards/card-header-tagged';
import TaggedUsersCard from 'components/tagged-users-card/tagged-users-card';
import { MODULES } from 'config/core';
import { Card, CardBody, CardTitle, Container } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';
import Select from 'react-select';
import './index.css';
import _ from 'lodash';
import { getQueryParamsFromObject } from 'utils';

const Tagged = () => {
  const { connectState, dispatchSharedState, languageState } = useContext(AppContext);
  const { getTaggedUsers, getUserTags } = useConnectService();
  const [selectedTags, setSelectedTags] = useState([
    { value: 'all', label: 'All' },
  ]);
  const [taggedUsersCount, setTaggedUsersCount] = useState(0);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersToFilter, setUsersToFilter] = useState([])

  useEffect(() => {
    if (selectedTags && selectedTags[0].value !== 'all') {
      setUsers(filterUsersByTags(usersToFilter, selectedTags))
    }
  }, [selectedTags])

  function filterUsersByTags(users, selectedTags) {
    const selectedTagIds = selectedTags.map(tag => tag.value);
    return users.filter(user =>
      user.tags.some(tag => selectedTagIds.includes(tag.id))
    );
  }

  useEffect(() => {
    getUserTags();
    getTaggedUsers()
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  useEffect(() => {
    if (connectState && connectState.taggedUsers && connectState.taggedUsers.length) {
      setUsers(connectState.taggedUsers);
      setUsersToFilter(connectState.taggedUsers)
    }
  }, [connectState]);

  useEffect(() => {
    if (connectState?.userTags?.length) {
      setFilteredTags(connectState?.userTags);
      const options = [
        { value: 'all', label: 'All' },
        ...((connectState && connectState.userTags) || []).map((tag) => {
          return { value: tag.id, label: tag.name };
        }),
      ];
      setTagsOptions(options);
    }
  }, [connectState]);

  useEffect(() => {
    const userIdsCount = [];
    connectState?.userTags?.forEach((tag) => {
      tag?.users?.forEach((user) => {
        if (userIdsCount.indexOf(user?.id) === -1) {
          userIdsCount.push(user?.id);
        }
      });
    });
    setTaggedUsersCount(userIdsCount.length);
  }, [connectState]);

  // Handle the change event when an option is selected
  const handleChange = (selectedOptions, event) => {
    if (event.option) {
      if (event.option.value === 'all') {
        setSelectedTags([event.option.value]);
        setFilteredTags(connectState?.userTags);
      } else {
        const newOptions = selectedOptions.filter((el) => el.value !== 'all');
        setSelectedTags(newOptions);
        const filterApplied = connectState?.userTags?.filter((tag) =>
          newOptions.map((el) => el.value).includes(tag.id)
        );
        setFilteredTags(filterApplied);
      }
    } else {
      if (selectedOptions && selectedOptions.length) {
        const newOptions = selectedOptions.filter((el) => el.value !== 'all');
        setSelectedTags(newOptions);
        const filterApplied = connectState?.userTags?.filter((tag) =>
          newOptions.map((el) => el.value).includes(tag.id)
        );
        setFilteredTags(filterApplied);
      } else {
        setSelectedTags([{ value: 'all', label: 'All' }]);
        setFilteredTags(connectState?.userTags);
      }
    }
  };

  // Custom styles for the selected label
  const customStyles = {
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
      textTransform: 'capitalize',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      ':hover': {
        border: '2px solid #172b4d',
        backgroundColor: '#fff', // Change this to the desired hover background color for the X icon
        color: '#172b4d',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#172b4d', // Change this to the desired background color
      borderRadius: 5,
    }),
    control: (provided) => ({
      ...provided,
      width: '100%', // Adjust the width as needed
      fontSize: 'medium', // Adjust the font size to medium
    }),
    option: (provided) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
  };

  return (
    <>
      <CardHeaderTagged numberOfTaggedUsers={taggedUsersCount} />
      <Container>
        <Card style={{ height: '100%', marginTop: '2rem' }}>
          <CardBody>
            <CardTitle tag="h5" className="text-lg font-bold">
              {languageState.translation.CONNECT.SELECT_TAG_TO_FILTER}
            </CardTitle>
            <hr />
            <div className="d-flex">
              <div className="counter mr-2" style={{ marginTop: 5 }}>
                <span className="metric">{users.length || 0} </span>
                {languageState.translation.COMMON.RESULTS}
              </div>
              <div style={{ width: 400 }}>
                <Select
                  isMulti
                  isSearchable
                  isClearable
                  value={selectedTags}
                  onChange={handleChange}
                  options={tagsOptions}
                  placeholder="Select one or multiple tag(s)"
                  styles={customStyles}
                />
              </div>
            </div>
          </CardBody>

          <Container style={{ maxWidth: '100%', width: '100%' }}>
            <TaggedUsersCard users={users} />
          </Container>
        </Card>
      </Container>
    </>
  );
};

export default Tagged;
