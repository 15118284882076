import * as yup from 'yup';

const schema = (validationMessages) =>
  yup.object({
    degree: yup
      .string()
      .min(
        3,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      )
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      )
      .required(validationMessages.REQUIRED_FIELD),
    description: yup.string().required(validationMessages.REQUIRED_FIELD),
    university: yup.string().required(validationMessages.REQUIRED_FIELD),
    startDate: yup.string().required(validationMessages.REQUIRED_FIELD),
    endDate: yup.string(),
    section: yup
      .string()
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      ),
  });

export default schema;
