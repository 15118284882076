import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import DASHBOARD_STATE from 'store/dashboard/dashboard.state';
import useHttpClient from '../http-client-hook';

const useDashboardService = () => {
  const { httpClient } = useHttpClient();

  const { dispatchDashboardState } = useContext(AppContext);

  const getDashBoardStats = async () => {
    const result = await httpClient.get('/dashboard/stats');
    if (result && result.status === 200) {
      dispatchDashboardState({
        type: DASHBOARD_STATE.types.GET_DASHBOARD_STATS,
        data: result.data,
      });
    }
  };

  return {
    getDashBoardStats,
  };
};

export default useDashboardService;
