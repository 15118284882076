import React, { useContext, useEffect } from 'react';
import { AppContext } from 'store/app.context';
import { css } from '@emotion/react/macro';
import PropagateLoader from 'react-spinners/PropagateLoader';

const override = css`
  display: block;
  margin: auto;
  border-color: #3f9ae0;
`;

export const Loader = () => {
  const { loaderState } = useContext(AppContext);

  useEffect(() => {}, [loaderState]);

  return (
    loaderState.showLoader && (
      <div className="loader-container">
        <PropagateLoader
          color={'#3F9AE0'}
          loading={loaderState.showLoader}
          css={override}
          size={30}
          margin={2}
        />
      </div>
    )
  );
};

export default Loader;
