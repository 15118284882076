import classnames from 'classnames';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  CardFooter,
  Table,
  Input,
} from 'reactstrap';
import useSubscriptionSerivce from 'services/subscriptions/subscriptions.service';
import { AppContext } from 'store/app.context';
import ee_logo from '../../assets/img/brand/ee_logo_whitebg.png';
import schema from './schema';

const Payments = () => {
  const { getSubscriptions } = useSubscriptionSerivce();
  const { subsriptionState, languageState } = useContext(AppContext);
  const [certificate, setCertificate] = useState();
  const fileRef = useRef();
  const formRef = useRef();
  useEffect(() => {
    getSubscriptions();
  }, []);

  const submitHandler = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleChange = (onChangeHandler, fieldName, value) => {
    onChangeHandler(fieldName, value);
  };

  return (
    <Container className="fluid mt-5 pb-5">
      {/* <Row className="justify-content-center">
        <Col lg="12">
          <div className="pricing card-group flex-column flex-md-row mb-3">
            {subsriptionState?.subscriptions?.length > 0 &&
              subsriptionState.subscriptions.map((subscription) => (
                <Card
                  className={classnames(
                    'card-pricing  text-white border-0 text-center mb-4',
                    {
                      'bg-gradient-warning': subscription.name === 'gold',
                      'bg-gradient-info': subscription.name === 'silver',
                      'bg-gradient-success': subscription.name === 'platinum',
                      'bg-info-light':
                        subscription.name !== 'gold' &&
                        subscription.name !== 'silver' &&
                        subscription.name !== 'platinum',
                    }
                  )}
                >
                  <CardHeader className="bg-transparent">
                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                      {subscription.name} pack
                    </h4>
                  </CardHeader>
                  <CardBody className="px-lg-7 d-flex flex-column">
                    <div className="display-2">${subscription.price}</div>
                    <span className="text-muted text-white ">
                      per application
                    </span>
                    <ul className="list-unstyled my-4">
                      {subscription?.modules?.length > 0 &&
                        subscription.modules.map((module) => (
                          <li>
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs icon-shape bg-gradient-white text-gray shadow rounded-circle text-white">
                                  <i className="fas fa-terminal" />
                                </div>
                              </div>
                              <div>
                                <span className="pl-2 text-white">
                                  {module}
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <Button
                      className="mb-3 mt-auto d-block"
                      color="default"
                      type="button"
                    >
                      Buy Now{' '}
                    </Button>
                  </CardBody>
                </Card>
              ))}
          </div>
        </Col>
      </Row> */}
      <div>
        <Card>
          <div className="card-payment-container">
            <div className="d-flex justify-content-center">
              <img src={ee_logo} />
            </div>

            <CardHeader className="card-header-container">
              <span className="title-header-payment">
                {languageState.translation.PAYMENT.TRIAL_PERIOD}
              </span>
            </CardHeader>


          </div>
        </Card>
      </div>
    </Container>
  );
};

export default Payments;
