import CardsHeaderUser from 'components/cards/card-header-portfolio';
import { IMG_PREFIX } from 'config/core';
import { MODULES } from 'config/core';
import React, { useContext, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import CONNECT_STATE from 'store/connect/connect.state';
import SHARED_STATE from 'store/shared';

const Resume = () => {
  const {
    languageState,
    connectState,
    dispatchSharedState,
    dispatchConnectState,
  } = useContext(AppContext);
  const {
    getExperiencesByUser,
    getEducationsByUser,
    getLanguagesByUser,
    getUserById,
  } = useConnectService();
  const [userId, setUserId] = useState();
  const [user, setUser] = useState(null);
  const [userResume, setUserResume] = useState();

  const { id } = useParams();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getUser = async () => {
    const result = await getUserById(userId);
    if (result && result.status === 200) {
      setUser(result.data);
      if (result.data.resume) {
        setUserResume(result.data.resume);
      }
    }
  };

  const availability = {
    workingDays: ['MONDAY', 'WEDNESDAY', 'FRIDAY'],
    workDiary: [
      {
        start: '2022-01-17T22:33:34.962Z',
        end: '2022-01-17T22:33:34.962Z',
        isWorkingHours: true,
      },
      {
        start: '2022-01-17T22:33:34.962Z',
        end: '2022-01-17T22:33:34.962Z',
        isWorkingHours: true,
      },
    ],
    holidays: [
      {
        startDate: '0154ISO',
        endDate: '0154ISO',
      },
      {
        startDate: '0154ISO',
        endDate: '0154ISO',
      },
    ],
    status: 'string',
    extraInfo: 'string',
  };

  useEffect(() => {
    if (userId) {
      getUser();
    }
  }, [userId]);

  useEffect(() => {
    if (id) {
      setUserId(id);
      dispatchConnectState({
        type: CONNECT_STATE.types.SELECT_USER,
        activeUser: id,
      });
    }
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  return (
    <React.Fragment>
      <CardsHeaderUser title={languageState.translation.COMMON.RESUME} />
      {userResume ? (
        <Container fluid className="mt-3">
          <div className="d-flex justify-content-center">
            <Document
              file={`${IMG_PREFIX}${userResume}`}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page size="A3" pageNumber={pageNumber} />
            </Document>
          </div>
          <div className="d-flex justify-content-center mt-5">
            {pageNumber > 1 && (
              <Button onClick={() => setPageNumber(pageNumber - 1)}>
                {languageState.translation.COMMON.PREVIOUS_PAGE}
              </Button>
            )}
            {pageNumber < numPages && (
              <Button onClick={() => setPageNumber(pageNumber + 1)}>
                {languageState.translation.COMMON.NEXT_PAGE}
              </Button>
            )}
          </div>
        </Container>
      ) : (
        <div className="d-flex justify-content-center">
          <h5 className="mt-3">
            {languageState.translation.CONNECT.NO_RESUME}
          </h5>
        </div>
      )}
    </React.Fragment>
  );
};

export default Resume;
