import { Formik } from 'formik';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Label, Input } from 'reactstrap';
import useFocusService from 'services/focus/focus.service';
import useHomeService from 'services/home/home.service';
import { AppContext } from 'store/app.context';
import schema from './validators';

const ModalEditLink = ({ modalShow, closeModal, idproject, data }) => {
  const { languageState } = useContext(AppContext);
  const { createQuickLink, getMisions, editQuickLink } = useFocusService();
  const { getQuickLinks } = useHomeService();
  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
        className="mb-0"
      >
        Modifier link
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <Formik
          initialValues={data}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={async (values) => {
            const result = await editQuickLink({
              ...values,
              id: data.id,
              projet: idproject,
            });
            if (result && result.status === 200) {
              toast.success(languageState.translation.ALERT.TASK_UPDATED, {
                position: 'bottom-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
              });
              if (idproject) {
                getMisions();
              } else getQuickLinks();

              closeModal();
            }
          }}
        // closeModal();
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.NAME}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.NAME}
                name="name"
                onChange={handleChange}
                defaultValue={data.name}
              />
              <small className="text-red">
                {' '}
                {errors.name && touched.name && errors.name}
              </small>
              <Label className="d-block mt-1">
                {' '}
                {languageState.translation.COMMON.LINK}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.LINK}
                name="link"
                onChange={handleChange}
                defaultValue={data.link}
              />
              {!idproject && (
                <Label className="d-block mt-1">
                  {' '}
                  {languageState.translation.COMMON.CATEGORY}
                </Label>
              )}
              {!idproject && (
                <Input
                  placeholder={languageState.translation.COMMON.CATEGORY}
                  name="category"
                  onChange={handleChange}
                  defaultValue={data.category ? data.category : ''}
                />
              )}

              <button
                className="btn btn-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditLink;
