import TYPES from './dashboard.type';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_DASHBOARD_STATS:
      return {
        ...state,
        dashBoardStats: action.data,
      };
  }
};

export default reducer;
