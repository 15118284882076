const { default: reducer } = require('./dashboard.reducer');
const { default: TYPES } = require('./dashboard.type');

const INITIAL_STATE = {
  dashBoardStats: null,
};

const DASHBOARD_STATE = {
  types: TYPES,
  reducer: reducer,
  initialState: INITIAL_STATE,
};

export default DASHBOARD_STATE;
