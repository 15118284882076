import TYPES from './educations.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_EDUCATIONS:
      return {
        ...state,
        educations: action.educations,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
