import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Button, Form, Container } from 'reactstrap';
import { languagesList, skillLevels } from 'config/const';
import { AppContext } from 'store/app.context';
import useSettingsService from 'services/settings/settings.service';
import Select from 'react-select';

const AddLanguage = () => {
  const { languageState, languageSettingsState } = useContext(AppContext);

  const [language, setLanguage] = useState(null);
  const [level, setLevel] = useState('');

  const [levels] = useState(skillLevels);
  const [languages, setLanguages] = useState([]);

  const [myLanguages, setMyLanguages] = useState([]);

  const { getLanguages, addLanguage, deleteLanguage } = useSettingsService();

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    let mappedLanugages = languagesList.map((el) => {
      return {
        ...el,
        value: el.value.toUpperCase(),
      };
    });
    if (languageSettingsState.languages) {
      setMyLanguages(languageSettingsState.languages);
      const myLanguages = Array.from(
        languageSettingsState.languages,
        ({ key }) => key
      );

      mappedLanugages = mappedLanugages.filter(
        (item) => !myLanguages.includes(item.value)
      );
    }
    setLanguages(mappedLanugages);
  }, [languageSettingsState]);

  const removeLanguage = async (languageId) => {
    const result = await deleteLanguage(languageId);
    if (result && result.status === 200) {
      getLanguages();
    }
  };

  const onAddLanguage = async (e) => {
    const reqData = {
      key: language.value,
      level: level.value,
    };
    addLanguage(reqData).then(() => {
      setLanguage(null);
      setLevel(null);
      getLanguages();

    })
  };

  return (
    <Form>
      <Row
        style={{
          width: "100%",
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md="5">
          <label className=" form-control-label">
            {languageState.translation.COMMON.LANGUAGE}
          </label>
          <span className="invalid-feedback"> *</span>
          <Select
            value={language}
            options={languages.map((el) => {
              return {
                ...el,
                label: languageState.translation.LANGUAGES[el.value.toLowerCase()],
              }
            })}
            isSearchable
            menuPortalTarget={document.body}
            isClearable
            onChange={(e) => setLanguage(e)}
            placeholder={languageState.translation.COMMON.LANGUAGE}
          />
        </Col>
        <Col md="5">
          <label className=" form-control-label">
            {languageState.translation.COMMON.LEVEL}
          </label>
          <span className="invalid-feedback"> *</span>
          <Select
            value={level}
            isSearchable
            isClearable
            options={levels.map((el) => {
              return {
                ...el,
                label: languageState.translation.COMMON.LEVELS[el.value]
              }
            })}
            onChange={(e) => setLevel(e)}
            placeholder={languageState.translation.COMMON.LEVEL}
          />
        </Col>
        <Col>
          <Button
            color="primary"
            className="mt-3 d-block ml-auto btn-exprimary"
            onClick={onAddLanguage}
            disabled={!language || !level}
          >
            <i className="ni ni-fat-add" />

            <span>{languageState.translation.COMMON.BUTTONS.ADD}</span>
          </Button>
        </Col>
      </Row>
      <Container className="mt-3">
        {myLanguages?.map((item, index) => {
          return item.key && item.level ?
            <Row key={`language-${index}`} className="mt-4">
              <Col>
                <strong>
                  {languageState.translation.LANGUAGES[item.key.toLowerCase()]}
                </strong>
              </Col>
              <Col>
                <strong>{languageState.translation.COMMON.LEVELS[item.level]}</strong>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    color="danger"
                    onClick={() => removeLanguage(item?.id)}
                    outline
                    className="action-icon"
                  >
                    <i className="fa fa-trash" />
                  </Button>
                </div>
              </Col>
            </Row> : null
        })}
      </Container>
    </Form>
  );
};

export default AddLanguage;
