import { MODULES } from 'config/core';
import { innerRoutesCompany } from 'navigation/routes';
import { innerRoutesFreelancer } from 'navigation/routes';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';

import MissionOverview from './mission-overview';

const MissionOverviewContainer = () => {
  const [missionInnerRoutes, setMissionInnerRoutes] = useState([]);
  const [networkType, setNewtorkType] = useState('');

  const { dispatchSharedState } = useContext(AppContext);

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.MISSION_OVERVIEW,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  const getInnerRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.parent === MODULES.MISSION_OVERVIEW) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });

  useEffect(() => {
    const routes = getInnerRoutes(innerRoutesCompany);
    setMissionInnerRoutes(routes);
  }, []);

  return (
    <>
      <Switch>
        {missionInnerRoutes}
        <Route
          path="*"
          render={(props) => <MissionOverview type={networkType} />}
        />
      </Switch>
    </>
  );
};

export default MissionOverviewContainer;
