import Favorites from 'pages/connect/favorites';
import Groups from 'pages/connect/groups';
import Availibilty from 'pages/connect/inner-pages/availibility';
import ProfileDetails from 'pages/connect/inner-pages/profile-details';
import Tagged from 'pages/connect/tagged';
import FocusDashboard from 'pages/focus/dashboard';
import OnBoarding from 'pages/focus/on-boarding';
import Team from 'pages/focus/team';
import HomeContainer from 'pages/home';
import HomeCalendar from 'pages/home/calendar';
import Dashboard from 'pages/home/dashboard';
import APP_ROUTES from './app.routes';
import Profile from '../pages/profile';
import YourNetworkContainer from 'pages/connect/your-network/container';
import { recruiterDashboard } from 'pages/recruiter/recruiterDashboard/recruiterDashboard';
import Shareonboarding from 'pages/recruiter/onboarding/Shareonboarding';
import Sync from 'pages/recruiter/sync/Sync';
import Settings from 'pages/settings/settings';
import FreelancerDeals from 'pages/grow/deals';
import Jobboard from 'pages/jobs/jobboard';
import Import from 'pages/recruiter/import/import';
import MissionOverviewContainer from 'pages/recruiter/mission-overview/container';
import MissionInfo from 'pages/recruiter/mission-overview/informations/mission-info';
import MissionApplicant from 'pages/recruiter/mission-overview/applicants/mission-appli';
import AddEditMission from 'pages/recruiter/Addmission/Addmission.jsx';
import Chat from 'pages/chat/chat';
import Payments from 'pages/payments/Payments';
import AddContact from 'pages/connect/add-contact/AddContact';
import AddOneContact from 'pages/connect/add-contact/AddOneContact';
import Contractors from 'pages/recruiter/mission-overview/contractos/contractors';
import MissionOverview from 'pages/recruiter/mission-overview/mission-overview';
// import Company from 'pages/jobs/companies/Company';
import MicroJobs from 'pages/jobs/micro-jobs/MicroJobs';
import JobDetails from 'pages/jobs/job-details/JobDetails';
import PostJobs from 'pages/jobs/post-job/PostJobs';
import Resume from 'pages/connect/inner-pages/resume';

const routes = [
  {
    collapse: false,
    path: APP_ROUTES.HOME.HOME,
    name: 'HOME',
    layout: '/private',
    component: HomeContainer,
    icon: 'ni ni ni-compass-04 text-red',
    state: 'dashboardsCollapse',
    role: 'FREELANCER',
  },
  {
    collapse: false,
    path: APP_ROUTES.HOME.HOME,
    name: 'HOME',
    layout: '/private',
    component: HomeContainer,
    icon: 'ni ni-shop text-red',
    state: 'dashboardsCollapse',
    role: 'OTHER',
  },

  {
    collapse: true,
    name: 'FOCUS',
    icon: 'ni ni-bullet-list-67 text-red',
    state: 'focusCollapse',
    role: 'FREELANCER',
    views: [],
    hide: true
  },
  {
    path: APP_ROUTES.CONNECT.YOUR_NETWORK + '/:id',
    name: 'YOUR_NETWORK',
    miniName: 'Yn',
    component: YourNetworkContainer,
    layout: '/private',
    hide: true,
  },
  {
    collapse: true,
    name: 'CONTACT',
    icon: 'ni ni-world-2 text-red',
    state: 'connectCollapse',
    role: 'FREELANCER',
    views: [
      {
        path: APP_ROUTES.CONNECT.ALL_NETWORK,
        name: 'ALL_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.YOUR_NETWORK,
        name: 'YOUR_CONTACT',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },
      // {
      //   path: APP_ROUTES.CONNECT.YOUR_NETWORK,
      //   name: 'YOUR_TEAM',
      //   miniName: 'Yn',
      //   component: YourNetworkContainer,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.JOBS.MICROJOBS,
      //   name: 'MICROJOBS',
      //   miniName: 'Jb',
      //   component: MicroJobs,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.CONNECT.ADD_CONTACT,
      //   name: 'ADD_CONTACT',
      //   miniName: 'Yn',
      //   component: AddOneContact,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.CONNECT.ADD_MULTIPLE_CONTACTS,
      //   name: 'ADD_MULTIPLE_CONTACTS',
      //   miniName: 'Yn',
      //   component: AddContact,
      //   layout: '/private',
      // },

      {
        path: APP_ROUTES.CONNECT.GROUPS,
        name: 'YOUR_TEAMS',
        miniName: 'Gr',
        component: Groups,
        layout: '/private',
        iconLast: 'ni ni-fat-add text-secondary',
      },
      // {
      //   path: APP_ROUTES.CONNECT.TAGGED,
      //   name: 'TAGGED',
      //   miniName: 'Ta',
      //   component: Tagged,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.CONNECT.FAVORITES,
      //   name: 'FAVORITES',
      //   miniName: 'Fa',
      //   component: Favorites,
      //   layout: '/private',
      // },
    ],
  },

  {
    collapse: true,
    name: 'GROW',
    icon: 'ni ni-user-run text-red',
    state: 'growCollapse',
    role: 'FREELANCER',
    hide: true,
    views: [
      {
        path: APP_ROUTES.GROW.DEALS,
        name: 'DEALS',
        miniName: 'Fd',
        component: FreelancerDeals,
        layout: '/private',
      },
      {
        path: APP_ROUTES.GROW.LEARN,
        name: 'LEARN',
        miniName: 'Fd',
        component: FreelancerDeals,
        layout: '/private',
      },
      {
        path: APP_ROUTES.GROW.EVENTS,
        name: 'EVENTS',
        miniName: 'Fd',
        component: FreelancerDeals,
        layout: '/private',
      },
    ],
  },
  {
    collapse: false,
    path: APP_ROUTES.JOBS.BOARD,
    name: 'JOBS',
    layout: '/private',
    component: Jobboard,
    icon: 'ni ni-briefcase-24 text-red',
    state: 'jobsCollapse',
    role: 'FREELANCER',
    NBJobs: true
  },
  {
    collapse: true,
    name: 'JOBS',
    icon: 'ni ni-briefcase-24 text-red',
    state: 'jobsCollapse',
    role: 'FREELANCER',
    hide: true,
    views: [
      {
        path: APP_ROUTES.JOBS.BOARD,
        name: 'BOARD',
        miniName: 'Jb',
        component: Jobboard,
        layout: '/private',
        NBJobs: true
      },

      // {
      //   path: APP_ROUTES.JOBS.COMPANIES,
      //   name: 'COMPANIES',
      //   miniName: 'Jb',
      //   component: Company,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.JOBS.POST_JOB,
      //   name: 'POST_JOB',
      //   miniName: 'Jb',
      //   component: PostJobs,
      //   layout: '/private',
      // },
      {
        path: APP_ROUTES.JOBS.JOB_DETAILS,
        name: 'JOB_DETAILS',
        miniName: 'Jb',
        component: JobDetails,
        layout: '/private',
        hide: true,
      }
    ],
  },
  {
    parent: 'PROFILE',
    collapse: false,
    path: APP_ROUTES.PROFILE,
    name: 'PROFILE',
    layout: '/private',
    component: Profile,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'PROFILE',
    collapse: false,
    path: APP_ROUTES.PROFILE,
    name: 'PROFILE',
    layout: '/private',
    component: Profile,
    icon: 'ni ni-shop text-primary',
    role: 'OTHER',
    hide: true,
  },
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'OTHER',
    hide: true,
  },
  {
    parent: 'CHAT',
    collapse: false,
    path: APP_ROUTES.CHAT,
    name: 'CHAT',
    layout: '/private',
    component: Chat,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  // RECRUITER ROUTES
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.DASHBOARD,
    name: 'RECRUITER_HOME',
    layout: '/private',
    component: recruiterDashboard,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
  },
  {
    parent: 'CHAT',
    collapse: false,
    path: APP_ROUTES.CHAT,
    name: 'CHAT',
    layout: '/private',
    component: Chat,
    icon: 'ni ni-shop text-primary',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS,
    name: 'MISSIONS',
    layout: '/private',
    component: MissionOverviewContainer,
    icon: 'ni ni-bullet-list-67 text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSION_CONTRACTORS + '/:id',
    name: 'MISSIONS_OVERVIEW',
    parent: 'MISSIONS_OVERVIEW',
    layout: '/private',
    component: Contractors,
    icon: 'ni ni-bullet-list-67 text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.ADD_MISSION,
    name: 'ADD_MISSION',
    layout: '/private',
    component: AddEditMission,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.EDIT_MISSION,
    name: 'ADD_MISSION',
    layout: '/private',
    component: AddEditMission,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: true,
    name: 'NETWORK',
    icon: 'ni ni-world-2 text-primary',
    state: 'connectCollapse',
    role: 'COMPANY',
    views: [
      {
        path: APP_ROUTES.CONNECT.ALL_NETWORK,
        name: 'ALL_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },
      // {
      //   path: APP_ROUTES.CONNECT.ADD_CONTACT,
      //   name: 'ADD_CONTACT',
      //   miniName: 'Yn',
      //   component: AddOneContact,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.CONNECT.ADD_MULTIPLE_CONTACTS,
      //   name: 'ADD_MULTIPLE_CONTACTS',
      //   miniName: 'Yn',
      //   component: AddContact,
      //   layout: '/private',
      // },
      {
        path: APP_ROUTES.CONNECT.GROUPS,
        name: 'TALENTPOOLS',
        miniName: 'Gr',
        component: Groups,
        layout: '/private',
        iconLast: 'ni ni-fat-add text-secondary',
      },
      {
        exact: true,
        path: APP_ROUTES.CONNECT.YOUR_NETWORK,
        name: 'YOUR_TALENTS',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },
      // {
      //   path: APP_ROUTES.CONNECT.TAGGED,
      //   name: 'TAGGED',
      //   miniName: 'Ta',
      //   component: Tagged,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.CONNECT.FAVORITES,
      //   name: 'FAVORITES',
      //   miniName: 'Fa',
      //   component: Favorites,
      //   layout: '/private',
      // },
    ],
  },
  {
    parent: 'PAYMENETS',
    collapse: false,
    path: APP_ROUTES.PAYMENTS,
    name: 'PAYMENTS',
    layout: '/private',
    component: Payments,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SYNC,
    name: 'NETWORK',
    iconLast: 'ni ni-fat-add text-secondary',
    layout: '/private',
    component: Sync,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SHARE_ONBOARDING,
    name: 'SHARE_ONBOARDING',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.IMPORT_FREELANCERS,
    name: 'MISSION_OVERVIEW',
    layout: '/private',
    component: Import,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSION_OVERVIEW,
    name: 'MISSIONS_OVERVIEW',
    layout: '/private',
    component: MissionOverviewContainer,
    icon: 'ni ni-bullet-list-67 text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: true,
    name: 'JOBS',
    icon: 'ni ni-briefcase-24 text-primary',
    state: 'jobsCollapse',
    role: 'COMPANY',
    // hide: true,
    views: [
      {
        path: APP_ROUTES.JOBS.BOARD,
        name: 'BOARD',
        miniName: 'Jb',
        component: Jobboard,
        layout: '/private',
        NBJobs: true
      },
      // {
      //   path: APP_ROUTES.JOBS.MICROJOBS,
      //   name: 'MICROJOBS',
      //   miniName: 'Jb',
      //   component: MicroJobs,
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.JOBS.COMPANIES,
      //   name: 'COMPANIES',
      //   miniName: 'Jb',
      //   component: Company,
      //   layout: '/private',
      // },
      {
        path: APP_ROUTES.RECRUITER.ADD_MISSION,
        name: 'POST_JOB',
        miniName: 'Jb',
        component: PostJobs,
        layout: '/private',

      },
      {
        path: APP_ROUTES.JOBS.JOB_DETAILS,
        name: 'JOB_DETAILS',
        // miniName: 'Jb',
        component: JobDetails,
        layout: '/private',
        hide: true
      }
    ],
  },
];

const prodRoutes = [
  // {
  //   collapse: false,
  //   path: APP_ROUTES.HOME.HOME,
  //   name: 'HOME',
  //   layout: '/private',
  //   component: HomeContainer,
  //   icon: 'ni ni-shop text-primary',
  //   state: 'dashboardsCollapse',
  //   role: 'FREELANCER',
  // },
  // {
  //   collapse: false,
  //   path: APP_ROUTES.HOME.HOME,
  //   name: 'HOME',
  //   layout: '/private',
  //   component: HomeContainer,
  //   icon: 'ni ni-shop text-red',
  //   state: 'dashboardsCollapse',
  //   role: 'OTHER',
  // },
  {
    path: APP_ROUTES.CONNECT.YOUR_NETWORK + '/:id',
    name: 'YOUR_NETWORK',
    miniName: 'Yn',
    component: YourNetworkContainer,
    layout: '/private',
    hide: true,
  },

  // {
  //   collapse: true,
  //   name: 'FOCUS',
  //   icon: 'ni ni-bullet-list-67 text-red',
  //   state: 'focusCollapse',
  //   role: 'FREELANCER',
  //   views: [],
  // },
  {
    collapse: true,
    name: 'CONNECT',
    icon: 'ni ni-world-2 text-red',
    state: 'connectCollapse',
    role: 'FREELANCER',

    views: [
      {
        path: APP_ROUTES.CONNECT.ALL_NETWORK,
        name: 'ALL_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },
      // {
      //   path: APP_ROUTES.CONNECT.ADD_CONTACT,
      //   name: 'ADD_CONTACT',
      //   miniName: 'Yn',
      //   component: AddOneContact,
      // },
      //   layout: '/private',
      // },
      // {
      //   path: APP_ROUTES.CONNECT.ADD_MULTIPLE_CONTACTS,
      //   name: 'ADD_MULTIPLE_CONTACTS',
      //   miniName: 'Yn',
      //   component: AddContact,
      //   layout: '/private',
      // },

      {
        excat: true,

        path: APP_ROUTES.CONNECT.YOUR_NETWORK,
        name: 'YOUR_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },

      {
        path: APP_ROUTES.CONNECT.GROUPS,
        name: 'GROUPS',
        miniName: 'Gr',
        component: Groups,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.TAGGED,
        name: 'TAGGED',
        miniName: 'Ta',
        component: Tagged,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.FAVORITES,
        name: 'FAVORITES',
        miniName: 'Fa',
        component: Favorites,
        layout: '/private',
      },
    ],
  },

  // {
  //   collapse: true,
  //   name: 'GROW',
  //   icon: 'ni ni-user-run text-red',
  //   state: 'growCollapse',
  //   role: 'FREELANCER',
  //   hide: true,
  //   views: [
  //     {
  //       path: APP_ROUTES.GROW.DEALS,
  //       name: 'DEALS',
  //       miniName: 'Fd',
  //       component: FreelancerDeals,
  //       layout: '/private',
  //     },
  //     {
  //       path: APP_ROUTES.GROW.LEARN,
  //       name: 'LEARN',
  //       miniName: 'Fd',
  //       component: FreelancerDeals,
  //       layout: '/private',
  //     },
  //     {
  //       path: APP_ROUTES.GROW.EVENTS,
  //       name: 'EVENTS',
  //       miniName: 'Fd',
  //       component: FreelancerDeals,
  //       layout: '/private',
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: 'JOBS',
    icon: 'ni ni-briefcase-24 text-red',
    state: 'jobsCollapse',
    role: 'FREELANCER',
    hide: true,
    views: [
      {
        path: APP_ROUTES.JOBS.BOARD,
        name: 'BOARD',
        miniName: 'Jb',
        component: Jobboard,
        layout: '/private',
        NBJobs: true
      },
      // {
      //   path: APP_ROUTES.JOBS.MICROJOBS,
      //   name: 'MICROJOBS',
      //   miniName: 'Jb',
      //   component: Favorites,
      //   layout: '/private',
      // },
      {
        path: APP_ROUTES.JOBS.CONSULTANCY,
        name: 'CONSULTANCY',
        miniName: 'Jb',
        component: Favorites,
        layout: '/private',
      },
    ],
  },

  {
    parent: 'PAYMENETS',
    collapse: false,
    path: APP_ROUTES.PAYMENTS,
    name: 'PAYMENTS',
    layout: '/private',
    component: Payments,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'PROFILE',
    collapse: false,
    path: APP_ROUTES.PROFILE,
    name: 'PROFILE',
    layout: '/private',
    component: Profile,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'PROFILE',
    collapse: false,
    path: APP_ROUTES.PROFILE,
    name: 'PROFILE',
    layout: '/private',
    component: Profile,
    icon: 'ni ni-shop text-primary',
    role: 'OTHER',
    hide: true,
  },
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'OTHER',
    hide: true,
  },
  {
    parent: 'CHAT',
    collapse: false,
    path: APP_ROUTES.CHAT,
    name: 'CHAT',
    layout: '/private',
    component: Chat,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  // RECRUITER ROUTES
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.DASHBOARD,
    name: 'RECRUITER_HOME',
    layout: '/private',
    component: recruiterDashboard,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS,
    name: 'MISSIONS',
    layout: '/private',
    component: MissionOverviewContainer,
    icon: 'ni ni-bullet-list-67 text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.ADD_MISSION,
    name: 'ADD_MISSION',
    layout: '/private',
    component: AddEditMission,
    icon: 'ni ni-shop text-red',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: true,
    name: 'NETWORK',
    icon: 'ni ni-world-2 text-red',
    state: 'connectCollapse',
    role: 'COMPANY',
    iconLast: 'ni ni-fat-add text-secondary',
    views: [
      {
        path: APP_ROUTES.CONNECT.ALL_NETWORK,
        name: 'ALL_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },

      {
        path: APP_ROUTES.CONNECT.YOUR_NETWORK,
        name: 'YOUR_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },
      // {
      //   path: APP_ROUTES.CONNECT.ADD_CONTACT,
      //   name: 'ADD_CONTACT',
      //   miniName: 'Yn',
      //   component: AddOneContact,
      //   layout: '/private',
      // },
      {
        path: APP_ROUTES.CONNECT.ADD_MULTIPLE_CONTACTS,
        name: 'ADD_MULTIPLE_CONTACTS',
        miniName: 'Yn',
        component: AddContact,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.GROUPS,
        name: 'GROUPS',
        miniName: 'Gr',
        component: Groups,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.TAGGED,
        name: 'TAGGED',
        miniName: 'Ta',
        component: Tagged,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.FAVORITES,
        name: 'FAVORITES',
        miniName: 'Fa',
        component: Favorites,
        layout: '/private',
      },
    ],
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SYNC,
    name: 'NETWORK',
    layout: '/private',
    component: Sync,
    icon: 'ni ni-shop text-primary',
    iconLast: 'ni ni-fat-add text-secondary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SHARE_ONBOARDING,
    name: 'SHARE_ONBOARDING',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.IMPORT_FREELANCERS,
    name: 'MISSION_OVERVIEW',
    layout: '/private',
    component: Import,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSION_OVERVIEW,
    name: 'MISSIONS_OVERVIEW',
    layout: '/private',
    component: MissionOverviewContainer,
    icon: 'ni ni-bullet-list-67 text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
];

export const innerRoutesFreelancer = [
  // Home routes
  {
    parent: 'HOME',
    collapse: false,
    path: APP_ROUTES.HOME.DASHBOARD,
    // name: 'DASHBOARD',
    layout: '/private',
    component: Dashboard,
    icon: 'ni ni-shop text-primary',
  },
  {
    parent: 'HOME',
    collapse: false,
    path: APP_ROUTES.HOME.CALENDAR,
    // name: 'CALENDAR',
    layout: '/private',
    component: HomeCalendar,
    icon: 'ni ni-shop text-primary',
  },
  {
    path: APP_ROUTES.CONNECT.YOUR_NETWORK + '/:id',
    name: 'YOUR_NETWORK',
    miniName: 'Yn',
    component: YourNetworkContainer,
    layout: '/private',
    hide: true,
  },
  /*{
    parent: 'HOME',
    collapse: false,
    path: APP_ROUTES.HOME.CLIENTS,
    name: 'CLIENTS',
    layout: '/private',
    component: Clients,
    icon: 'ni ni-shop text-primary',
  },*/
  // Focus routes
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.DASHBOARD + '/:id',
    to: APP_ROUTES.FOCUS.DASHBOARD,
    name: 'DASHBOARD',
    layout: '/private',
    component: FocusDashboard,
    icon: 'ni ni-shop text-primary',
    hide: true,
  },
  // {
  //   parent: 'FOCUS',
  //   collapse: false,
  //   path: APP_ROUTES.FOCUS.NOTES,
  //   name: 'NOTES',
  //   layout: '/private',
  //   component: Notes,
  //   icon: 'ni ni-shop text-primary',
  // },
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.TEAM + '/:id',
    to: APP_ROUTES.FOCUS.TEAM,
    name: 'TEAM',
    layout: '/private',
    component: Team,
    icon: 'ni ni-shop text-primary',
  },
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.REC + '/:id',
    to: APP_ROUTES.FOCUS.REC,
    name: 'recruiter',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-primary',
  },
  // {
  //   parent: 'FOCUS',
  //   collapse: false,
  //   path: APP_ROUTES.FOCUS.ON_BOARDING + '/:id',
  //   to: APP_ROUTES.FOCUS.ON_BOARDING,
  //   name: 'ON_BOARDING',
  //   layout: '/private',
  //   component: OnBoarding,
  //   icon: 'ni ni-shop text-primary',
  // },
  // CONNECT routes
  // - your network
  {
    parent: 'YOUR_NETWORK',
    collapse: false,
    path: APP_ROUTES.CONNECT.PROFILE_DETAILS + '/:id',
    name: 'PROFILE_DETAILS',
    layout: '/private',
    component: ProfileDetails,
    icon: 'ni ni-shop text-primary',
  },

  {
    // parent: 'YOUR_NETWORK',
    collapse: false,
    path: APP_ROUTES.CONNECT.RESUME + '/:id',
    name: 'RESUME',
    layout: '/private',
    component: Resume,
    icon: 'ni ni-shop text-primary',
  },

  {
    // parent: 'YOUR_NETWORK',
    collapse: false,
    path: APP_ROUTES.CONNECT.AVAILIBILITY + '/:id',
    name: 'AVAILIBILITY',
    layout: '/private',
    component: Availibilty,
    icon: 'ni ni-shop text-primary',
  },
  // {
  //   parent: 'YOUR_NETWORK',
  //   collapse: false,
  //   path: APP_ROUTES.CONNECT.PORTFOLIO + '/:id',
  //   name: 'PORTFOLIO',
  //   layout: '/private',
  //   component: Portfolio,
  //   icon: 'ni ni-shop text-primary',
  // },

  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS_APPLICANTS + '/:id',
    name: 'APPLICANTS',
    layout: '/private',
    component: MissionApplicant,
    icon: 'ni ni-shop text-primary',
  },

];

export const innerRoutesCompany = [
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.EDIT_MISSION,
    name: 'OVERVIEW',
    layout: '/private',
    component: MissionOverview,
    icon: 'ni ni-shop text-primary',
    exact: true,
  },
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.SHARE_ONBOARDING,
    name: 'BRIEFING',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-primary',
    exact: true,
  },
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS_APPLICANTS + '/:id',
    name: 'APPLICANTS',
    layout: '/private',
    component: MissionApplicant,
    icon: 'ni ni-shop text-primary',
    exact: true,
  }, ,
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSION_CONTRACTORS + '/:id',
    name: 'MISSIONS_OVERVIEW',
    layout: '/private',
    component: Contractors,
    icon: 'ni ni-bullet-list-67 text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
    exact: true
  },
  {
    path: APP_ROUTES.CONNECT.YOUR_NETWORK + '/:id',
    name: 'YOUR_NETWORK',
    miniName: 'Yn',
    component: YourNetworkContainer,
    layout: '/private',
    hide: true,
  },
  {
    parent: 'YOUR_NETWORK',
    collapse: false,
    path: APP_ROUTES.CONNECT.PROFILE_DETAILS + '/:id',
    name: 'PROFILE_DETAILS',
    layout: '/private',
    component: ProfileDetails,
    icon: 'ni ni-shop text-primary',
  }
];


let routersToExport;
try {
  if (
    process.env.REACT_APP_ENV == 'qa' ||
    process.env.REACT_APP_ENV == 'prod'
  ) {
    routersToExport = prodRoutes;
  } else {
    //TODO: update to routes after release
    routersToExport = routes;
  }
} catch (error) {
  console.log('error', error);
  routersToExport = routes;
}

export default routes;
