import MultipleImageUpload from 'components/multiple-image-upload/multiple-image-upload';
import SingelImageUpload from 'components/singel-image-uplaod/SingelImageUpload';
import { IMG_PREFIX } from 'config/core';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown,
} from 'reactstrap';
import useFilesService from 'services/files/files.services';
import useSettingsService from 'services/settings/settings.service';
import url from 'socket.io-client/lib/url';
import { AppContext } from 'store/app.context';
import FormItem from './FormItem';
import schema from './validators';

const PrevieousExperience = () => {
  const [isNewItem, setIsnewItem] = useState(false);
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const editScreen = useRef(null);
  const { uploadFile } = useFilesService();
  const { addExperience, getExperiences, deleteExperience, editExperience } =
    useSettingsService();

  const { languageState, experienceState } = useContext(AppContext);
  useEffect(() => {
    getExperiences();
  }, []);
  const DeleteExperience = async (id) => {
    const result = await deleteExperience({ id });
    if (result.status === 200) {
      // toast.success(languageState.settings.deleteExperienceSuccess, {

      // autoClose: 4000,
      // });
      getExperiences();
    }
  };
  useEffect(() => {
    if (isNewItem || isUpdateItem) scrollToEdit();
  }, [isUpdateItem]);
  const scrollToEdit = () => {
    if (editScreen.current)
      editScreen.current.scrollIntoView({ behavior: 'smooth' });
  };
  const submitData = async ({ values, image = '', screenShots = [] }) => {
    const reqData = {
      image,
      screenShots,
    };

    if (image) {
      const img = await uploadFile(image);
      if (img && img.status === 201) {
        reqData.image = img.data.filename;
      }
    }
    console.log('list0', screenShots);

    if (
      screenShots &&
      screenShots?.length > 0 &&
      screenShots[0] instanceof File
    ) {
      console.log('list', screenShots);

      const screenshots = await Promise.all(
        screenShots.map(async (screenShot) => {
          console.log('screeenshot0', screenShot);

          if (!(typeof screenShot === 'string')) {
            console.log('screeenshot', screenShot);
            const img = await uploadFile(screenShot);
            if (img && img.status === 201) {
              return img.data.filename;
            }
          } else return screenShot;
        })
      );
      if (screenshots) {
        reqData.screenShots = screenshots;
      }
    }
    if (isNewItem) {
      const result = await addExperience({ ...values, ...reqData });
      if (result && result.status == 201) {
        // toast.success(languageState.translation.ALERT.EXPEREIENCE_SAVED, {
        //   position: 'bottom-right',
        //   hideProgressBar: false,
        // autoClose: 4000,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'dark',
        // });
        getExperiences();

        setIsnewItem(false);
      }
    } else if (isUpdateItem) {
      if (!reqData.image) reqData.image = initialValues?.image;
      const result = await editExperience({ ...values, ...reqData });
      if (result && result.status == 200) {
        // toast.success(languageState.translation.ALERT.EXPEREIENCE_SAVED, {
        //   position: 'bottom-right',
        //   hideProgressBar: false,
        // autoClose: 4000,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'dark',
        // });
        getExperiences();

        setIsUpdateItem(false);
      }
    }
  };

  return (
    <div>
      <div className="bg-white p-3 shadow-lg">
        <h2>Your Items</h2>
        <div className="d-flex flex-wrap justify-content-center bg-white ">
          {experienceState.experiences &&
            experienceState.experiences.map(
              (data, index) =>
                data.title && (
                  <div
                    key={`data-${index}`}
                    className=" m-3 rounded-lg"
                    style={{
                      width: '300px',
                      height: '10rem',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={`${IMG_PREFIX}${data?.image}`}
                      className="w-100 h-100 rounded-lg"
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        zIndex: '0',
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          require('assets/img/nofound.jpg').default;
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        width: '100%',
                        height: '100%',
                        zIndex: '1',
                        background: 'rgba(0,0,0,0.5)',
                      }}
                    />
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <h5 className="text-white" style={{ zIndex: 2 }}>
                        {data.title}
                        <UncontrolledDropdown className="icon-network" nav>
                          <DropdownToggle
                            className="nav-link pr-0"
                            color=""
                            tag="a"
                          >
                            <div className="p-1 pl-2 pr-2 bg-transparent text-white rounded   shadow c-pointer ">
                              <i className="fas fa-ellipsis-h"></i>
                            </div>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setIsnewItem(false);
                                setIsUpdateItem(true);
                                setInitialValues(data);
                                scrollToEdit();
                              }}
                            >
                              {languageState.translation.COMMON.BUTTONS.EDIT}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                console.log('data', data);

                                DeleteExperience(data?.id);
                              }}
                            >
                              {languageState.translation.COMMON.BUTTONS.DELETE}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </h5>
                    </div>
                  </div>
                )
            )}
        </div>
        {experienceState.experiences &&
          experienceState.experiences.length < 6 && (
            <Button
              className="btn-exprimary mt-3 d-block mx-auto mb-3"
              onClick={() => {
                setIsUpdateItem(false);
                setIsnewItem(true);
              }}
              disabled={
                experienceState.experiences &&
                experienceState.experiences.length > 5
              }
            >
              Add new item
            </Button>
          )}
      </div>
      {isNewItem && (
        <div className="bg-white mt-2 shadow-lg p-3" ref={editScreen}>
          <FormItem submitData={submitData} />
        </div>
      )}
      {isUpdateItem && (
        <div className="bg-white mt-2 shadow-lg p-3" ref={editScreen}>
          <FormItem
            submitData={submitData}
            intial={initialValues}
            title="Edit Item"
          />
        </div>
      )}
    </div>
  );
};

export default PrevieousExperience;
