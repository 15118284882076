export const M_LANGUAGES = [
  {
    id: 11,
    name: 'ENGLISH',
    level: 1,
  },
  {
    id: 11,
    name: 'FRENCH',
    level: 3,
  },
  {
    id: 11,
    name: 'ITALIAN',
    level: 2,
  },
  {
    id: 22,
    name: 'ARABIC',
    level: 2,
  },
];
