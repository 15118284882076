import axios from 'axios';
import { MODULES } from 'config/core';
import { API_URL_CHAT } from 'config/core';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import LOADER_STATE from 'store/loader';
import SHARED_STATE from 'store/shared';
import USER_STATE from 'store/user';
import { getChatToken } from 'utils';

let counter = 0;

const useChatHttpClient = () => {
  const {
    languageState,
    loaderState,
    dispatchLoaderState,
    dispatchUserState,
    dispatchSharedState,
  } = useContext(AppContext);
  const history = useHistory();
  const chatClient = axios.create({
    baseURL: `${API_URL_CHAT}/chat-api/1.0.0`,
    timeout: 60000,
  });
  const logout = () => {
    dispatchUserState({
      type: USER_STATE.types.RESET_USER_INFO,
    });
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.AUTH,
    });
    history.push('/login');
  };

  chatClient.interceptors.request.use((config) => {
    dispatchLoaderState({
      type: LOADER_STATE.types.SHOW_LOADER,
    });

    counter += 1;
    const chatToken = getChatToken();

    if (chatToken) {
      // add header for Authorization
      config.headers.Authorization = `Bearer ${chatToken}`;
      // set language header
      config.headers['Accept-Language'] = '*';
    } else {
      delete config.headers.Authorization;
    }

    return config;
  });

  chatClient.interceptors.response.use(
    (response) => {
      counter -= 1;
      if (counter === 0) {
        dispatchLoaderState({
          type: LOADER_STATE.types.HIDE_LOADER,
        });
      }
      return response;
    },
    (error) => {
      if (
        error.response?.data?.statusCode === 401 ||
        error.response?.data?.statusCode === 403
      ) {
        logout();
        return Promise.reject(error);
      }
      counter -= 1;
      if (counter === 0) {
        dispatchLoaderState({
          type: LOADER_STATE.types.HIDE_LOADER,
        });
      }
      // handle errors globally
      return Promise.reject(error);
    }
  );

  return { chatClient };
};

export default useChatHttpClient;
