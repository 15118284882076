import { MODULES } from 'config/core';
import { innerRoutesFreelancer } from 'navigation/routes';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';

import YourNetwork from '.';

const YourNetworkContainer = () => {
  const [yourNetworkInnerRoutes, setYourNetworkInnerRoutes] = useState([]);
  const [networkType, setNewtorkType] = useState('');

  const { dispatchSharedState } = useContext(AppContext);
  const url = useLocation();

  useEffect(() => {
    if (url.pathname && url.pathname.includes('my-network')) {
      setNewtorkType('CONNECTED');
    } else {
      setNewtorkType('ALL');
    }
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  const getInnerRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.parent === MODULES.CONNECT_YOUR_NETWORK) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });

  useEffect(() => {
    const routes = getInnerRoutes(innerRoutesFreelancer);
    setYourNetworkInnerRoutes(routes);
  }, []);

  return (
    <>
      <Switch>
        {yourNetworkInnerRoutes}
        <Route
          path="*"
          render={(props) => <YourNetwork type={networkType} />}
        />
      </Switch>
    </>
  );
};

export default YourNetworkContainer;
