import { IMG_PREFIX } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';

const ModalGroupsUsers = ({ modalShow, closeModal, groupId, ownerId = '', activeUserId = '', mode = '' }) => {
  const { languageState, connectState: { groupUsers } } = useContext(AppContext);
  const { getUsersGroup, removeUserFromGroup, getGroups } = useConnectService();
  const history = useHistory();

  const Modalstyle = {
    height: '30rem',
    maxHeight: '90vh',
    paddingBottom: '3rem',
    marginTop: '-1rem',
    overflowY: 'auto',
  };

  useEffect(() => {
    getAllUsersGroup();
  }, [groupId]);
  const getAllUsersGroup = async () => {
    await getUsersGroup(groupId);
  };
  const Delete = async (idUser) => {
    const result = await removeUserFromGroup(groupId, idUser);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.REMOVE_USER_FAVORITES, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      getAllUsersGroup();
      getGroups();
    }
  };

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        {languageState.translation.CONNECT.GROUP_USERS}
      </ModalHeader>
      <ModalBody
        // @ts-ignore
        style={Modalstyle}
      >
        <ListGroup className="list mt-2" flush>
          {groupUsers &&
            groupUsers.length > 0 &&
            groupUsers.map((data, index) => (
              <ListGroupItem key={index} className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src={`${IMG_PREFIX}${data.target?.profileImage}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            require('assets/img/man.png').default;
                        }}
                      />
                    </a>
                  </Col>
                  <div className="col align-items-center">
                    <h4 className="mb-0">{data.target?.firstName} </h4>

                    <small>{data.target?.lastName.charAt(0) + '.'}</small>
                  </div>
                  <Button
                    color="primary"
                    size="sm"
                    className="ml-auto d-block btn-exprimary"
                    onClick={() =>
                      history.push(
                        '/private' +
                        APP_ROUTES.CONNECT.PROFILE_DETAILS +
                        '/' +
                        data.target.id
                      )
                    }
                  >
                    {languageState.translation.CONNECT.VIEW_PROFILE}
                  </Button>
                  {(ownerId === activeUserId || mode != 'public') && (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => {
                        Delete(data.target.id);
                      }}
                    >
                      {languageState.translation.COMMON.BUTTONS.DELETE}
                    </Button>
                  )}
                </Row>
              </ListGroupItem>
            ))}
        </ListGroup>
        {groupUsers && groupUsers.length === 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center h-100 display-1 mt--4 ">
            <i className="fas fa-user-friends"></i>
            <p className=" font-weight-bold">
              {languageState.translation.CONNECT.YOU_HAVE_NO_USERS}
            </p>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalGroupsUsers;
