import * as yup from 'yup';

const schema = (validationMessages) =>
  yup.object({
    password: yup
      .string()
      .min(8, validationMessages.MIN_PASSWORD)
      .oneOf(
        [yup.ref('confirmPassword'), null],
        validationMessages.PASSWORD_MATCH
      )
      .required(validationMessages.REQUIRED_FIELD),
    confirmPassword: yup
      .string()
      .min(8, validationMessages.MIN_PASSWORD)
      .oneOf([yup.ref('password'), null], validationMessages.PASSWORD_MATCH)
      .required(validationMessages.REQUIRED_FIELD),
  });

export default schema;
