import * as Yup from 'yup';

export const addContactSchema = (validationMessages) =>
  Yup.object({
    // linkedinProfile: Yup.string().required(validationMessages.REQUIRED_FIELD),
    firstName: Yup.string().required(validationMessages.REQUIRED_FIELD),
    lastName: Yup.string().required(validationMessages.REQUIRED_FIELD),
    email: Yup.string()
      .email(validationMessages.EMAIL)
      .required(validationMessages.REQUIRED_FIELD),
    showExtraInfoValue: Yup.boolean(),
    jobTitle: Yup.string().when('showExtraInfoValue', {
      is: true,
      then: Yup.string().required(validationMessages.REQUIRED_FIELD),
    }),
    portfolio: Yup.string().when('showExtraInfoValue', {
      is: true,
      then: Yup.string().optional().nullable(),
    }),
    privateNotes: Yup.string().when('showExtraInfoValue', {
      is: true,
      then: Yup.string().optional().nullable(),
    }),
    personTag: Yup.string().when('showExtraInfoValue', {
      is: true,
      then: Yup.string().optional().nullable(),
    }),
  });
