import reducer from './reducer';
import TYPES from './types';

const INITIAL_STATE = {
  showLoader: false,
  counter: 0,
};

const LOADER_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default LOADER_STATE;
