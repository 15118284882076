export const M_EXPERIENCES = [
  {
    id: 1,
    position: 'Web developer',
    description:
      'It Was a great experience working in such company like ExtraExpertise',
    startDate: '2015-12-13T22:05:36.694Z',
    endDate: '2017-12-25T22:05:36.694Z',
    location: 'ExtraExpertise',
  },
  {
    id: 2,
    position: 'Web developer',
    description:
      'It Was a great experience working in such company like ExtraExpertise',
    startDate: '2017-12-13T22:05:36.694Z',
    endDate: '2019-12-25T22:05:36.694Z',
    location: 'ExtraExpertise',
  },
  {
    id: 3,
    position: 'Graphic designer',
    description:
      'It Was a great experience working in such company like Upwork',
    startDate: '2019-12-13T22:05:36.694Z',
    endDate: null,
    location: 'Upwork',
  },
];
