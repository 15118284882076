import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Button, Row, Col } from 'reactstrap';
import useSettingsService from 'services/settings/settings.service';
import { AppContext } from 'store/app.context';
import { getDuration } from 'utils';
import './experience.scss'

const Experiences = () => {
  const { getExperiences, deleteExperience } = useSettingsService();

  const { experienceState, languageState } = useContext(AppContext);

  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    getExperiences();
  }, []);

  useEffect(() => {
    if (experienceState.experiences) {
      const experiencesWithDuration = experienceState.experiences.map(
        (item) => {
          const duration = getDuration(
            item.startDate,
            item.endDate,
            languageState
          );

          return {
            ...item,
            duration,
          };
        }
      );
      setExperiences(experiencesWithDuration);
    }
  }, [experienceState]);

  const onDeleteExperience = async (experience) => {
    const result = await deleteExperience(experience);
    if (result && result.status === 200) {
      getExperiences();
    }
  };

  return (
    <>
      {experiences && experiences.length > 0 && (
        <div
          className="timeline timeline-one-side"
          data-timeline-axis-style="dashed"
          data-timeline-content="axis"
        >
          {experiences.map(
            (experience) =>
              experience.startDate && (
                <Row key={experience?.id}>
                  <Col md="6">
                    <div className="timeline-block" key={experience.id}>
                      <span className="timeline-step badge-success">
                        <i className="ni ni-bag-17" />
                      </span>
                      <div className="timeline-content">
                        <div className="d-flex  pt-1 w-100">
                          <div>
                            <span className="text-muted text-sm font-weight-bold">
                              {experience.position}
                            </span>
                          </div>
                          <div className="ml-3">
                            <small className="text-muted">
                              <i className="ni ni-pin-3" /> {experience.company}
                            </small>
                          </div>
                        </div>
                        <h6 className="text-sm mt-1 mb-0">
                          {experience.description || 'No description added !'}
                        </h6>
                        <div className="text-left">
                          <small className="text-muted">
                            {experience.duration}
                          </small>
                        </div>
                        <div className="">
                          <small className="text-muted">
                            <i className="ni ni-pin-3" />{' '}
                            {`${moment(experience.startDate).format(
                              'DD/MM/YYYY'
                            )} - ${experience.endDate
                              ? moment(experience.endDate).format(
                                'DD/MM/YYYY'
                              )
                              : languageState.translation.COMMON.TODAY
                              }`}
                          </small>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <Button
                      size="sm"
                      color="danger"
                      data-dz-remove
                      onClick={() => onDeleteExperience(experience)}
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </Col>
                </Row>
              )
          )}
        </div>
      )}
      {experiences && !experiences.length && (
        <div className="d-flex justify-content-center">
          <h5>{languageState.translation.SETTINGS.NO_EXPERIENCES}</h5>
        </div>
      )}
    </>
  );
};

export default Experiences;
