import React, { useContext, useRef, useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import { Link } from 'react-router-dom';
import ModalAddLink from 'components/modaladdlink/ModalAddLink';
import ModalEditLink from 'components/modaladdlink/ModalEditLink';
import useFocusService from 'services/focus/focus.service';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import useHomeService from 'services/home/home.service';

const CardsFocus = ({ name, parentName, link, idproject, id, category }) => {
  const { languageState } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({});
  const { deleteQuickLink, getMisions } = useFocusService();
  const { getQuickLinks } = useHomeService();
  const [modalEditShow, setModalEditShow] = useState(false);
  const cardRef = useRef();

  if (name === 'ADD NEW SHORTCUT' || name === 'AJOUTER NOUVEAU SHORTCUT')
    return (
      <>
        <Card
          className=" m-1 d-flex justify-content-center align-items-center bg-white  focus-card-size cursor-pointer relative c-pointer dashed"
          onClick={() => setModalShow(true)}
        >
          <span className="text-black h3 text-center ">
            {languageState.translation.FOCUS.DASHBOARD.CARDS.ADD_SHORTCUT}{' '}
            <br />
            <span className="h1 text-black">+</span>
          </span>
        </Card>
        <ModalAddLink
          modalShow={modalShow}
          closeModal={() => setModalShow(false)}
          idproject={idproject}
        />
      </>
    );

  return (
    <Card
      style={{
        cursor: 'pointer',
      }}
      className={classNames(' m-1 d-flex   quickLink-bg focus-card-size  ', {
        'p-4': category,
        'align-items-center justify-content-center': !category,
      })}
    >
      <a ref={cardRef} href={link} target="_blank" className="c-pointer ">
        {category && <span className="text-white d-block ">{category}</span>}
        <span className="text-white h3 text-center  ">
          <strong> {name}</strong>
        </span>
      </a>

      <UncontrolledDropdown className="icon-network" nav>
        <DropdownToggle className="nav-link pr-0" color="" tag="a">
          <div className="p-1 pl-2 pr-2 bg-transparent text-white rounded   shadow c-pointer ">
            <i className="fas fa-ellipsis-h"></i>
          </div>
        </DropdownToggle>
        <DropdownMenu left>
          <DropdownItem
            onClick={() => {
              setData({ name, link, id, category });
              setModalEditShow(true);
            }}
          >
            {languageState.translation.COMMON.BUTTONS.EDIT}
          </DropdownItem>
          <DropdownItem
            onClick={async () => {
              const result = await deleteQuickLink(id);
              if (result && result.status === 200) {
                toast.success(
                  languageState.translation.ALERT.QUICK_LINK_DELETED,
                  {
                    position: 'bottom-right',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                  }
                );
                if (idproject) {
                  getMisions();
                } else getQuickLinks();
              }
            }}
          >
            {languageState.translation.COMMON.BUTTONS.DELETE}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <ModalEditLink
        modalShow={modalEditShow}
        closeModal={() => setModalEditShow(false)}
        idproject={idproject}
        data={data}
      />
    </Card>
  );
};

CardsFocus.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsFocus;
