import { IMG_PREFIX } from 'config/core';
import React, { useContext } from 'react';
import { AppContext } from 'store/app.context';
import './experience.scss'

const UserExperiences = ({ experiences, width }) => {
  const { languageState } = useContext(AppContext);

  return (
    <div className="d-flex flex-wrap justify-content-center bg-white user-experiences-block">
      {experiences && experiences.length !== 0 ? (
        experiences.map((data, index) => (
          data.title && (<div key={index} className=" m-3 rounded-lg" style={{
            backgroundImage: `url(${IMG_PREFIX}${data?.image})`,
            backgroundPosition: 'center',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
          }} >
            <div
              style={{
                position: 'absolute',
                left: '0',
                top: '0',
                width: '100%',
                height: '100%',
                zIndex: '1',
                background: 'rgba(0,0,0,0.5)',
              }}
            />
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <h5 className="text-white" style={{ zIndex: 2 }}>
                {data.title}
              </h5>
            </div>
          </div>)
        ))
      ) : (
        <span className="align-self-center">
          {languageState.translation.PROFILE.NOT_PROJECT}
        </span>
      )}
    </div>
  );
};

export default UserExperiences;
