import CardsHeaderAviability from 'components/cards/card-header-aviability';
import { MODULES } from 'config/core';
import React, { useContext, useEffect } from 'react';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';
import 'react-csv-importer/dist/index.css';
import { Container } from 'reactstrap';
import { Importer, ImporterField } from 'react-csv-importer';
import useUserService from 'services/profile/user.service';

const AddContact = () => {
  const { dispatchSharedState } = useContext(AppContext);
  const { inviteMultipleUsers } = useUserService();

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);
  const handleImport = async (data) => {
    const result = await inviteMultipleUsers(data);
    console.log(result);
  };
  return (
    <div>
      <CardsHeaderAviability title="ADD_MULTIPLE_CONTACTS" />
      <Container fluid className="mt-3 min-height-30 d-flex flex-column ">
        <Importer dataHandler={handleImport}>
          <ImporterField name="firstName" label={'First Name'} />
          <ImporterField name="lastName" label={'Last Name'} />
          <ImporterField name="email" label={'Email'} />
          <ImporterField name="birthDate" label={'Birth Date'} optional />
          <ImporterField name="phoneNumber" label={'Phone'} optional />
          <ImporterField name="address" label={'Address'} optional />
        </Importer>
      </Container>
    </div>
  );
};

export default AddContact;
