import CardsFocusOnboarding from 'components/cards/card-focus-onboarding';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import CardsTeamMember from 'components/cards/card-teammember';
import { MODULES } from 'config/core';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import PROJECT_STATE from 'store/projects';
import SHARED_STATE from 'store/shared';

const OnBoarding = () => {
  const {
    languageState,
    projectState,
    dispatchSharedState,
    dispatchProjectState,
  } = useContext(AppContext);

  const [selectedProject, setSelectedProject] = useState(null);

  const location = useLocation();

  const { id } = useParams();

  useEffect(() => {
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.FOCUS,
    });
  }, []);

  useEffect(() => {
    if (projectState.projects) {
      const project = projectState.projects.find((project) => project.id == id);
      dispatchProjectState({
        type: PROJECT_STATE.types.SELECT_PROJECT,
        project,
      });
    }
  }, [projectState.projects]);

  useEffect(() => {
    setSelectedProject(projectState.selectedProject);
  }, [projectState]);

  return (
    <>
      {selectedProject && (
        <>
          <CardsHeaderFocus name={selectedProject.name} parentName="Teams" />
          <Container fluid className="d-flex wrap">
            <CardsFocusOnboarding />
            <div className="flex-shrink-0 flex-grow-1">
              <Card className="mt-4">
                <CardHeader className="d-flex align-items-center ">
                  <h5 className="h3 mb-0 mr-3">Manager</h5>{' '}
                </CardHeader>
                <CardBody>
                  <ListGroup className="list my--3" flush>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={
                                require('assets/img/theme/team-1.jpg').default
                              }
                            />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              John Michael
                            </a>
                          </h4>
                          <span className="text-success">●</span>{' '}
                          <small>Online</small>
                        </div>
                        <Col className="col-auto">
                          <Button color="primary " type="button">
                            Send message{' '}
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
              <CardsTeamMember />
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">On boarding tasks</h5>
                </CardHeader>

                <CardBody className="p-0">
                  <ListGroup data-toggle="checklist" flush>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-success checklist-item-checked">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            {' '}
                            Introduction with team{' '}
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-success">
                            <input
                              className="custom-control-input"
                              defaultChecked
                              id="chk-todo-task-1"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-1"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-warning">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            {' '}
                            Receive badge
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-warning">
                            <input
                              className="custom-control-input"
                              id="chk-todo-task-2"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-2"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>

                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-danger checklist-item-checked">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Winter Hackaton
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-danger">
                            <input
                              className="custom-control-input"
                              defaultChecked
                              id="chk-todo-task-4"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-4"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default OnBoarding;
