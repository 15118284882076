import moment from 'moment';
import React from 'react';
import { useContext } from 'react';
import Avatar from 'components/avatar/avatar';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import { getRoomName } from 'utils';
import { useEffect } from 'react';
import useUserService from 'services/profile/user.service';
import InvitationNotification from 'components/Notifications/InvitationNotification';
import { useState } from 'react';
import ModalPending from 'components/modalPendingInvitations/ModalPending';

const CardNotifications = () => {
  const { chatState, notificationsState, languageState } =
    useContext(AppContext);

  const { getNotifications } = useUserService();

  const [pagination, setPagination] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    getPaginate();
  }, [notificationsState]);

  const getPaginate = () => {
    const listPage = [];
    if (
      notificationsState.notifications?.meta &&
      notificationsState.notifications?.meta.totalPages
    ) {
      if (notificationsState.notifications?.meta?.currentPage > 1) {
        listPage.push(notificationsState.notifications?.meta?.currentPage - 1);
      }
      listPage.push(notificationsState.notifications?.meta?.currentPage);
      if (
        notificationsState.notifications?.meta?.currentPage <
        notificationsState.notifications?.meta.totalPages
      ) {
        listPage.push(notificationsState.notifications?.meta?.currentPage + 1);
      }
      setPagination(listPage);
    }
  };

  const nextPage = () => {
    if (
      notificationsState.notifications?.meta.currentPage <
      notificationsState.notifications?.meta.totalPages
    ) {
      const page = notificationsState.notifications?.meta.currentPage + 1;
      getNotifications(page);
      window.scrollTo(0, 0);
    }
  };
  const prevPage = () => {
    if (notificationsState.notifications?.meta?.currentPage > 1) {
      const page = notificationsState.notifications?.meta.currentPage - 1;
      getNotifications(page);
      window.scrollTo(0, 0);
    }
  };

  const hasPrev = () => {
    if (
      notificationsState.notifications?.meta &&
      notificationsState.notifications?.meta?.currentPage
    ) {
      if (notificationsState.notifications?.meta?.currentPage > 1) {
        return false;
      }
      return true;
    }
  };

  const hasNext = () => {
    if (
      notificationsState.notifications?.meta &&
      notificationsState.notifications?.meta.currentPage
    ) {
      if (
        notificationsState.notifications?.meta.currentPage <
        notificationsState.notifications?.meta.totalPages
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <Card>
      <CardHeader className="pl-3 font-weight-bold">
        {languageState.translation.HOME.DASHBOARD.YOUR_NOTIFICATIONS}
      </CardHeader>
      {notificationsState.notifications?.items?.length ? (
        <CardBody className="p-0">
          <Row>
            <Container>
              {notificationsState.notifications.items?.map((notification, index) =>
                notification.type === 'INVITATION' ||
                  notification.type === 'ACCEPT-INVITATION' ? (
                  <Col
                    key={index}
                    xs="12"
                    md="8"
                    className="invitation-item"
                    onClick={() => setShowModal(true)}
                  >
                    <InvitationNotification notification={notification} />
                  </Col>
                ) : (
                  <></>
                )
              )}

              {notificationsState?.notifications?.meta?.totalItems > 6 && (
                <Container>
                  <ButtonGroup className="d-flex justify-content-center gap-2 mt-auto mb-3">
                    <Button
                      className=" flex-grow-0 rounded-circle paginate-button"
                      type="button"
                      color="primary"
                      onClick={() => prevPage()}
                      disabled={hasPrev()}
                    >
                      <i className="fas fa-angle-left"></i>
                    </Button>
                    {pagination &&
                      pagination.map((data, index) => (
                        <Button
                          key={index}
                          className={
                            notificationsState.notifications?.meta
                              .currentPage == data
                              ? '  flex-grow-0 rounded-circle paginate-button active shadow-lg'
                              : 'flex-grow-0 rounded-circle paginate-button shadow-lg'
                          }
                          color="primary"
                          type="button"
                          onClick={() => getNotifications(data)}
                        >
                          {data}
                        </Button>
                      ))}
                    <Button
                      className=" flex-grow-0 rounded-circle paginate-button "
                      type="button"
                      color="primary"
                      onClick={() => nextPage()}
                      disabled={hasNext()}
                    >
                      <i className="fas fa-angle-right"></i>
                    </Button>
                  </ButtonGroup>
                </Container>
              )}
            </Container>
          </Row>
          <ModalPending
            modalShow={showModal}
            closeModal={() => {
              setShowModal(false);
            }}
          />
        </CardBody>
      ) : (
        <div className="d-flex justify-content-center p-4">
          <span>
            {languageState.translation.HOME.DASHBOARD.NO_NOTIFICATIONS}
          </span>
        </div>
      )}
    </Card>
  );
};

// chatState?.newMessage ? (
//   <CardBody className="p-0">
//     <ListGroup>
//       <ListGroupItem className="d-flex flex-column">
//         <Row className="align-items-center ">
//           <Col className="col-auto">
//             <Avatar
//               name={getRoomName(
//                 chatState?.newMessage?.room,
//                 chatState?.chatUserId
//               )}
//               forNavbar={true}
//               className="rounded-circle custom-avatar "
//             />
//           </Col>
//           <div className="col ml--2">
//             <div className="d-flex justify-content-between align-items-center ">
//               <div>
//                 <h4 className="mb-0 text-sm">
//                   {getRoomName(
//                     chatState?.newMessage?.room,
//                     chatState?.chatUserId
//                   )}
//                 </h4>
//               </div>
//               <div className="text-right text-muted">
//                 <small>
//                   {moment(
//                     chatState?.newMessage?.message?.createdAt
//                   ).format('hh:mm a')}
//                 </small>
//               </div>
//             </div>
//             <p className="text-sm mb-0">
//               {chatState?.newMessage?.message?.content}
//             </p>
//           </div>
//         </Row>
//       </ListGroupItem>
//     </ListGroup>
//   </CardBody>
export default CardNotifications;
