import React, { useContext, useEffect } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import { AppContext } from 'store/app.context';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import useDashboardService from 'services/dashboard/dashboard.service';
import useConnectService from 'services/connect/connect.service';
import { getQueryParamsFromObject } from 'utils';

const CardsHeader = () => {
  const history = useHistory();
  const { languageState, userState, dashboardState } = useContext(AppContext);
  const { getDashBoardStats } = useDashboardService();
  const { getAllUsers, getConnectedUsers } = useConnectService();
  const getGreeting = () => {
    const currentDate = new Date();
    const time = currentDate.getHours();
    if (time >= 12 && time < 18)
      return (languageState.translation.COMMON.GOOD_AFTERNOON + ' ' + userState?.userInfo?.firstName);
    if (time < 12 && time > 4)
      return (languageState.translation.COMMON.GOOD_MORNING + ' ' + userState?.userInfo?.firstName);
    return (languageState.translation.COMMON.GOOD_EVENING + ' ' + userState?.userInfo?.firstName);
  };

  useEffect(() => {
    getDashBoardStats();
    getAllUsers(getQueryParamsFromObject({
      dailyRateMin: 0,
      dailyRateMax: 100,
      page: 8
    }))
    getConnectedUsers(getQueryParamsFromObject({
      dailyRateMin: 0,
      dailyRateMax: 100,
      page: 8
    }))
  }, []);

  const getCurrentQuarter = () => {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() + 3) / 3);
    return `Q${quarter} ${today.getFullYear()}`;
  };

  return (
    <>
      <div className="header bg-exprimary py-3">
        <Container fluid>
          <div className="header-body">
            <h2 className="text-white mb-4">{getGreeting()}</h2>
            <Row>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {languageState.translation.CONNECT.CURRENT_FREELANCER_STATUS} :{languageState.translation.STEPPER.FIRST_STEP_OPTIONS[userState?.userInfo?.statusAviability]}
                        </CardTitle>
                        <span className="h3 font-weight-bold mb-0  text-underline d-block c-pointer "

                          onClick={() =>
                            history.push(
                              DEFAULT_PRIVATE_PATH + APP_ROUTES.SETTINGS,
                              {
                                activeItem: 'AVAILABILITY',
                              }
                            )
                          }
                        >
                          {
                            languageState.translation.AVAILABILITY
                              .UPDATE_YOUR_AV
                          }
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {getCurrentQuarter()}, {languageState.translation.HOME.DASHBOARD.DAYS_LEFT}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                        20 oktober 2024
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0" >
                          {languageState.translation.HOME.DASHBOARD.OPEN_PROJECTS}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {dashboardState?.dashBoardStats?.onGoingMissions}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="8">
                        <div>
                          {' '}
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {languageState.translation.HOME.DASHBOARD.VAT_NUMBER}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {dashboardState?.dashBoardStats?.myVATNumber ?? 'N/A'}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {languageState.translation.HOME.DASHBOARD.FREELANCERS_NETWORK}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {dashboardState?.dashBoardStats?.myNetworkCount}{' '} {languageState.translation.COMMON.FREELANCERS}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {languageState.translation.HOME.DASHBOARD.UNPAID_AMMOUNT}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {languageState.translation.COMMON.NOT_AVAILABLE}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats d-none">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {languageState.translation.HOME.DASHBOARD.BALANCE_ON_COMPANY}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          &euro; 2500
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i className="fas fa-money-bill-wave" />{' '}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row></Row>
          </div>
        </Container>
      </div>
    </>
  );
};

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
