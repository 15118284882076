import TYPES from './experiences.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_EXPERIENCES:
      return {
        ...state,
        experiences: action.experiences,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
