import * as yup from 'yup';

const schema = (validationMessages) =>
  yup.object({
    email: yup
      .string()
      .email(validationMessages.EMAIL)
      .required(validationMessages.REQUIRED_FIELD),
  });

export default schema;
