// @ts-ignore
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import notes from 'pages/focus/notes';
import schema from './schema';
import React, { useContext, useEffect, useState } from 'react';
import { CirclePicker } from 'react-color';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
// @ts-ignore
import useConnectService from 'services/connect/connect.service';
// @ts-ignore
import { AppContext } from 'store/app.context';
import { toast } from 'react-toastify';
import Reactselect from './Reactselect';
import useFilesService from 'services/files/files.services';

const EditGroupModal = ({
  modalShow,
  closeModal,
  groupsRetrieved,
  groupeIdToEdit,
}) => {
  const { languageState } = useContext(AppContext);
  const { editGroup, getGroups } = useConnectService();
  const [color, setColor] = useState('#63b1bd');
  const [backgroundImage, setBackgroundImage] = useState('');
  const { uploadFile } = useFilesService();

  const [initialValues, setInitialValues] = useState({
    groupName: '',
    color: '',
    icon: '',
    type: '',
  });
  const groupeTypeOptions = [
    {
      value: 'public',
      label: languageState.translation.CONNECT.PUBLIC,
    },
    {
      value: 'private',
      label: languageState.translation.CONNECT.PRIVATE,
    },
  ];

  useEffect(() => {
    if (groupsRetrieved?.length && groupeIdToEdit !== '') {
      const groupeDataToEdit = groupsRetrieved?.filter(
        (groupe) => groupe?.id === groupeIdToEdit
      );

      if (groupeDataToEdit?.length) {
        setInitialValues({
          groupName: groupeDataToEdit[0]?.groupName,
          color: groupeDataToEdit[0]?.color,
          icon: groupeDataToEdit[0]?.icon,
          type: groupeDataToEdit[0]?.type,
        });
      }
    }
  }, [groupsRetrieved, groupeIdToEdit]);




  const submitForm = async (data) => {
    let reqData;
    let resultFile;
    if (backgroundImage != '') {
      resultFile = await uploadFile(backgroundImage);
    }
    if ((resultFile && resultFile?.status === 201) || backgroundImage === '') {
      reqData = { ...data, id: groupeIdToEdit, icon: resultFile ? resultFile.data.filename : initialValues.icon };
      const result = await editGroup(reqData);
      if (result && result.status === 200) {
        toast.success(languageState.translation.ALERT.GROUP_UPDATED, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setBackgroundImage('')
        closeModal();
      }
    }
  };

  return (
    <Modal isOpen={modalShow} centered toggle={closeModal} className="groupe-modal"
    >
      <ModalHeader
        className="text-center"
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        <p className="text-center font-weight-bold w-100">
          {languageState.translation.CONNECT.ADD_GROUP}
        </p>
      </ModalHeader>
      <ModalBody
      // @ts-ignore
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={(values) => submitForm(values)}
        >
          {({ errors, touched, values, handleSubmit, setFieldValue }) => (
            <>
              <div className="groupe-container">
                <div className="groupe-first-block">
                  <FormGroup>
                    <Label className="label-style">
                      {languageState.translation.CONNECT.GROUPE_NAME}
                    </Label>

                    <InputGroup className="input-group-merge input-group-alternative">
                      <Field
                        className="form-control"
                        placeholder={
                          languageState.translation.CONNECT.GROUPE_NAME
                        }
                        name="groupName"
                        type="text"
                      />
                    </InputGroup>
                    {errors.groupName && touched.groupName && (
                      <div className="invalid-feedback">
                        {errors.groupName}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-style">
                      {
                        languageState.translation.INNER_NAVIGATION.GROUPS
                          .GROUPE_TYPE
                      }
                    </Label>
                    <Reactselect options={groupeTypeOptions} name="type" defaultValue={groupeTypeOptions.filter(
                      (option) => option.value === values.type
                    )} />
                    {errors.type && touched.type && (
                      <div className="invalid-feedback">{errors.type}</div>
                    )}
                    {/* <label className="mr-2">
                   <Field
                     type="radio"
                     name="type"
                     value="private"
                     className="mr-2"
                   />
                   {languageState.translation.CONNECT.PRIVATE}
                 </label>
                 <label>
                   <Field
                     type="radio"
                     name="type"
                     value="public"
                     className="mr-2"
                   />
                   {languageState.translation.CONNECT.PUBLIC}
                 </label> */}
                  </FormGroup>
                </div>
                <div className="groupe-second-block">
                  <FormGroup>
                    <div className="file-title-container">
                      <Label className="label-style">
                        {' '}
                        {
                          languageState.translation.INNER_NAVIGATION.GROUPS
                            .GROUPE_BACKGROUND_IMAGE
                        }
                      </Label>
                      <Label className="label-style">
                        {
                          languageState.translation.INNER_NAVIGATION.GROUPS
                            .GROUPE_FILE_SIZE
                        }
                      </Label>
                    </div>
                    <div className="input-file-container">
                      <label className="file-label">
                        <input
                          type={'file'}
                          style={{ display: 'none' }}
                          name="icon"
                          onChange={(e) => {
                            setFieldValue('icon', e.target.files[0]);
                            setBackgroundImage(e.target.files[0]);
                          }}
                        />
                        {backgroundImage === ''
                          ? initialValues.icon && initialValues.icon !== '' ? initialValues.icon : languageState.translation.INNER_NAVIGATION.GROUPS
                            .GROUPE_FILE_SIZE
                          : backgroundImage.name}
                      </label>
                    </div>
                    {errors.icon && touched.icon && (
                      <div className="invalid-feedback">{errors.icon}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-style">
                      {' '}
                      {
                        languageState.translation.INNER_NAVIGATION.GROUPS
                          .GROUPE_INSPIRATION
                      }
                    </Label>
                    <div>
                      {' '}
                      <CirclePicker
                        color={color}
                        onChangeComplete={(color) => setColor(color.hex)}
                      />
                    </div>
                  </FormGroup>
                </div>

                {/* <FormGroup>
   <Label>{languageState.translation.COMMON.ICON}</Label>
   <Reactselect options={options} name="icon" />
 </FormGroup> */}
              </div>
              <div className="buttons-container">
                <Button
                  onClick={() => handleSubmit()}
                  className="btn-exprimary"
                  color="primary"
                >
                  {
                    languageState.translation.INNER_NAVIGATION.GROUPS
                      .SAVE_GROUPE
                  }
                </Button>

                <button className="custom-button" onClick={closeModal}>
                  {languageState.translation.COMMON.CLOSE}
                </button>
              </div>
            </>

          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default EditGroupModal;
