const API_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_QA = process.env.REACT_APP_API_BASE_URL_QA;
const API_URL_DEMO = process.env.REACT_APP_API_BASE_URL_DEMO;
const API_URL_PROD = process.env.REACT_APP_API_BASE_URL_PROD;
const CHAT_URL_DEV = process.env.REACT_APP_API_BASE_URL_CHAT;
const CHAT_URL_QA = process.env.REACT_APP_API_BASE_URL_QA_CHAT;
const CHAT_URL_PROD = process.env.REACT_APP_CHAT_API_BASE_URL_PROD;

export const LINKEDIN_APP_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;

export const API_BASE_URL = (() => {
  if (process.env.REACT_APP_ENV == 'demo') {
    return API_URL_DEMO;
  } else if (process.env.REACT_APP_ENV == 'qa') {
    return API_URL_QA;
  } else if (process.env.REACT_APP_ENV == 'prod') {
    return API_URL_PROD;
  } else {
    return `${API_URL}api`;
  }
})();

export const API_URL_CHAT = (() => {
  if (process.env.REACT_APP_ENV == 'qa') {
    return CHAT_URL_QA;
  } else if (process.env.REACT_APP_ENV == 'prod') {
    return CHAT_URL_PROD;
  } else {
    return CHAT_URL_DEV;
  }
})();

export const IMG_PREFIX = (() => {
  if (process.env.REACT_APP_ENV == 'demo') {
    return `${API_URL_DEMO}upload/`;
  } else if (process.env.REACT_APP_ENV == 'qa') {
    return `${API_URL_QA}upload/`;
  } else if (process.env.REACT_APP_ENV == 'prod') {
    return `${API_URL_PROD}upload/`;
  } else {
    return `${API_BASE_URL}/upload/`;
  }
})();

export const LANGUAGES = ['EN', 'FR'];
export const CURRENCY = process.env.REACT_APP_DEFAULT_CURRENCY || 'Euro';

export const MODULES = {
  AUTH: 'AUTH',
  HOME: 'HOME',
  FOCUS: 'FOCUS',
  CONNECT: 'CONNECT',
  CONNECT_YOUR_NETWORK: 'YOUR_NETWORK',
  GROW: 'GROW',
  RECRUITER: 'RECRUITER',
  MISSION_OVERVIEW: 'MISSION_OVERVIEW',
  CHAT: 'CHAT',
  SETTINGS: 'SETTINGS',
  SHARED: 'SHARED',
  JOBS: 'JOBS',
};

export const TASK_STATUS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
};

export const KINDS = {
  CLIENT: 'CLIENT',
  FREELANCER: 'FREELANCER',
  COMPANY: 'COMPANY',
};

export const MISSION_STATUS = {
  PUBLISHED: 'PUBLISHED',
  CANDIDATE_FOUND: 'CANDIDATE_FOUND',
  NOT_STARTED: 'NOT_STARTED',
  ON_HOLD: 'ON_HOLD',
  READY_TO_START: 'READY_TO_START',
  ON_GOING: 'ON_GOING',
  FINISHED: 'FINISHED',
  CLOSED: 'CLOSED',
  INTERVIEWED: 'INTERVIEWED',
  INTERVIEWING: 'INTERVIEWING',
  WAITING: 'WAITING',
  WAITING_FOR_PUBLICATION: 'WAITING_FOR_PUBLICATION',
  NOT_STARTED_YET: 'NOT_STARTED_YET',
  DECLINED: 'DECLINED',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL'
};

export const COLUMN_LIST = {
  NAME: 'NAME',
  APPLICANTS_NUMBER: 'APPLICANTS_NUMBER',
  JOB_POSTER: 'JOB_POSTER',
  BUDGET: 'BUDGET',
  STATUS: 'STATUS',
  PUBLICATION_DATE: 'PUBLICATION_DATE',
};

export const APPLICANT_STATUS = {
  SELECTED: 'SELECTED',
  INTERVIEWED: 'INTERVIEWED'
};

export const CHAT_EVENTS = {
  CONNECT: 'connect',
  SEEN_ROOM: 'SEEN_ROOM',
  NEW_MESSAGE: 'NEW_MESSAGE',
  NEW_ROOM: 'NEW_ROOM',
  USER_ONLINE: 'USER_ONLINE',
  USER_OFFLINE: 'USER_OFFLINE',
  DELETE_MESSAGE: "DELETE_MESSAGE",
  DISCONNECT: 'disconnect',
};

export const ROOM_TYPES = {
  DIRECT: 'DIRECT',
  GROUP: 'GROUP',
};

export const DEFAULT_PRIVATE_PATH = '/private';
