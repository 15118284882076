import * as yup from 'yup';

const schema = (validationMessages) =>
  yup.object({
    groupName: yup.string().required(validationMessages.REQUIRED_FIELD),
    type: yup.string().required(validationMessages.REQUIRED_FIELD),
    icon: yup.string(),
  });

export default schema;
