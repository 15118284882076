import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import useSettingsService from 'services/settings/settings.service';
import { AppContext } from 'store/app.context';
import useUserService from 'services/profile/user.service';

const AddSoftSkillsSelect = () => {
  const { languageState, userState, skillsState } = useContext(AppContext);
  const { getSkills } = useSettingsService();
  const { autoUpdateUserInfo, updateBasicInfos } = useUserService();
  const [skills, setSkills] = useState([]);
  const [mySkills, setMySkills] = useState([]);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    if (skillsState && skillsState.skills) {
      const newOptions = (skillsState.skills || [])
        .filter((skill) => skill.type === 'SOFT')
        .map((skill) => ({ value: skill.id, label: skill.key }));
      setSkills(newOptions);
    }
  }, [skillsState]);

  useEffect(() => {
    if (userState?.userInfo) {
      setMySkills(
        [
          ...(userState?.userInfo?.skills || [])
            .filter((skill) => skill.type === "SOFT")
            .map(skill => ({
              value: skill.id,
              label: skill.key,
              isOther: false
            }))
          ,
          ...(userState?.userInfo?.otherSkills || []).map(skill => ({
            value: skill,
            label: skill,
            isOther: true
          }))
        ]
      )
    }
  }, [userState])

  const AddSoftSkills = async (selectedSkills) => {
    // To conserve the old Skills that are not soft 
    const oldSkills = (userState?.userInfo?.skills || []).filter((skill) => skill.type !== "SOFT").map(el => el.id);
    // To get new no Soft Skills
    const newSkills = selectedSkills.filter((el) => !el.isOther).map(el => el.value);
    const dataToSend = {
      skills: [...(oldSkills || []), ...(newSkills || [])],
      otherSkills: selectedSkills.filter((el) => el.isOther).map(el => el.value),
    }
    const result = await updateBasicInfos(dataToSend);
    if (result && result.status === 200) {
      autoUpdateUserInfo();
      toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }


  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          {languageState.translation.SETTINGS.SOFT_SKILLS}
        </h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="10">
            <CreatableSelect
              name="skills"
              noOptionsMessage={() => languageState.translation.COMMON.SKILLS_SELECT_EMPTY}
              value={mySkills}
              options={skills}
              isMulti
              isClearable
              isSearchable
              placeholder={languageState.translation.COMMON.SEARCH_TAG}
              onChange={(e) => {
                const userSkills = (e || []).map((el) => {
                  const isOther = !(skills || []).some((item) => item.value === el.value);
                  return { ...el, isOther }
                })
                AddSoftSkills(userSkills)
              }}
              onCreateOption={(newSkill) => {
                AddSoftSkills([...mySkills, { label: newSkill, value: newSkill, isOther: true }])
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddSoftSkillsSelect;
