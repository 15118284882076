import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import HOME_STATE from 'store/home/home.state';
import useHttpClient from '../http-client-hook';

const useHomeService = () => {
  const { httpClient } = useHttpClient();
  const { dispatchHomeState } = useContext(AppContext);

  const getQuickLinks = async () => {
    const result = await httpClient.get(`/quick-links`);
    if (result && result.status == 200) {
      dispatchHomeState({
        type: HOME_STATE.types.SET_QUICKLINKS,
        quickLinks: result?.data,
      });
    }
  };
  const getFeed = async () => {
    const result = await httpClient.get(`/quick-links/feeds/test`);
    if (result && result.status == 200) {
      dispatchHomeState({
        type: HOME_STATE.types.SET_FEEDS,
        feeds: result?.data,
      });
    }
  };
  const getNbNewJob = async () => {
    const result = await httpClient.get('/jobs/recent-jobs');
    if (result && result.status == 200) {
      dispatchHomeState({
        type: HOME_STATE.types.SET_NB_NEW_JOBS,
        nbNewJobs: result?.data,
      });
    }
  }
  return {
    getQuickLinks,
    getFeed,
    getNbNewJob
  };
};
export default useHomeService;
