import React, { useContext } from 'react';
import { AppContext } from 'store/app.context';

import './Invitation.css';
const InvitationNotification = ({ notification }) => {
  const { languageState } = useContext(AppContext);
  console.log('notification', notification);

  return (
    <div className="invitation-item">
      <h4>{notification.title}</h4>

      <p>
        {notification?.sender?.firstName} {notification?.sender?.lastName.charAt(0) + '.'}{' '}
        {languageState.translation.HOME.DASHBOARD.NOTIFICATION}
      </p>
    </div>
  );
};

export default InvitationNotification;
