import { ROOM_TYPES } from 'config/core';
import CryptoJS from 'crypto-js';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

/**
 * Check if all object's fields are falsy
 * @param {*} object
 */
export const isFalsyObject = (object) => {
  return Object.keys(object).every((key) => {
    return !object[key];
  });
};

/**
 * Extract query params from a given object
 * @param {*} payload
 */
export const getQueryParamsFromObject = (payload) => {
  let url;
  const isFiltred = Object.values(payload).some((value) => value);

  if (isFiltred) {
    url = '?';
    for (const key in payload) {
      if (payload[key] || payload[key] === 0) {
        if (
          typeof payload[key] == 'string' ||
          typeof payload[key] == 'number' ||
          typeof payload[key] == 'boolean'
        ) {
          url += `${key}=${payload[key]}&`;
        } else {
          payload[key].forEach((item) => {
            url += `${key}=${item}&`;
          });
        }
      }
    }
    url = url.slice(0, url.length - 1);
  } else {
    url = '';
  }

  return url;
};

//testsadok1@yopmail.com

/**
 * Check if a user is connected or not
 */
export const isAuthorized = () => {
  if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
    return true;
  }
  return false;
};

export const getDuration = (startDate, endDate, languageState) => {
  let duration = '';
  let untilNow = false;
  let dateOfTheEnd;

  if (!endDate) {
    untilNow = true;
    dateOfTheEnd = new Date();
  } else {
    dateOfTheEnd = endDate;
  }

  if (untilNow) {
  }
  const nativeDuration = moment.duration(
    moment(dateOfTheEnd, 'YYYY/MM/DD HH:mm').diff(
      moment(startDate, 'YYYY/MM/DD HH:mm')
    )
  );

  if (nativeDuration.asYears() >= 1) {
    duration += `${Math.trunc(nativeDuration.asYears())} ${languageState.translation.COMMON.YEAR
      }(s)`;
  }
  if (nativeDuration.asMonths() >= 1) {
    let months;
    if (nativeDuration.asYears() >= 1) {
      months =
        Math.trunc(nativeDuration.asMonths()) -
        Math.trunc(nativeDuration.asYears()) * 12;
    } else {
      months = Math.trunc(nativeDuration.asMonths());
    }
    if (months) {
      duration += ` ${months} ${languageState.translation.COMMON.MONTH}${languageState.defaultLanguage === 'EN' ? '(s)' : ''
        }.`;
    }
  }
  if (untilNow) {
    duration = `${languageState.translation.COMMON.SINCE} ${duration}`;
  }

  return duration;
};

export const getChatToken = () => {
  const token = localStorage.getItem('chat-token');
  if (token) return token;
  return null;
};

export const getChatId = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (userInfo) return userInfo?.cid;
  return null;
};

const _getTargetUserNameForRoom = (room, userId) => {
  const userIndex = room?.users_rooms[0]?.user?.id === userId ? 1 : 0;
  return room?.users_rooms[userIndex] ? `${room?.users_rooms[userIndex]?.user?.first_name} ${room?.users_rooms[userIndex]?.user?.last_name}` : null;
};

export const getRoomName = (room, userId) => {
  let roomName = 'New Conversation';
  if (room?.type === ROOM_TYPES.GROUP) {
    if (room?.name) return room?.name;

    if (
      room?.users_rooms &&
      room?.users_rooms?.length &&
      room?.users_rooms?.length > 2
    ) {
      roomName = `${room?.users_rooms[0]?.user?.first_name}, ${room?.users_rooms[1]?.user?.first_name}, ${room?.users_rooms[2]?.user?.first_name} ...`;
    } else if (
      room?.users_rooms &&
      room?.users_rooms?.length &&
      room?.users_rooms?.length === 2
    ) {
      roomName = _getTargetUserNameForRoom(room, userId);
    }
  } else {
    roomName = _getTargetUserNameForRoom(room, userId);
  }

  return roomName;
};

export const getFirstCharsFromMessage = (message) => {
  if (message && message.content) {
    if (message?.content?.length > 9)
      return `${message?.content?.substr(0, 9)}...`;
    return message?.content;
  } else return 'attachment';
};

export const getLastMessage = (room, userId) => {
  let lastMessage = '';
  if (room && room.messages && room.messages.length) {
    lastMessage = `${getFirstCharsFromMessage(room?.messages[0])} - ${moment(
      room?.messages[0]?.created_at
    ).fromNow(true)} ago`;
  } else {
    lastMessage = `Say hello to ${getRoomName(room, userId)}`;
  }
  return getRoomName(room, userId) ? lastMessage : null;
};

export const isMessageOwner = (message, userId) => {
  return message?.author?.id === userId;
};

export const isRoomSeen = (room, userId) => {
  const currentUser = room?.users_rooms?.find(
    (user) => user?.user?.id === userId
  );
  return currentUser?.seen;
};

export const markRoomAsUnseen = (room, userId) => {
  const currentUser = room?.users_rooms?.find(
    (user) => user?.user?.id === userId
  );
  if (currentUser.seen) {
    currentUser.seen = false;
  }
};

export const markRoomAsSeen = (room, userId) => {
  const currentUser = room?.users_rooms?.find(
    (user) => user?.user?.id === userId
  );

  if (currentUser) {
    currentUser.seen = true;
  }
};

export const isMessageSeenByOtherUsers = (room, userId) => {
  if (room?.messages?.length && room?.messages[0]?.newMessage) return false;
  const otherUsers = room?.users_rooms?.filter(
    (user) => user?.user?.id !== userId
  );
  let seen = false;
  otherUsers.forEach((user) => {
    if (user?.seen) seen = true;
  });
  return seen;
};

export const searchRooms = (searchValue, room) => {
  if (room?.type === ROOM_TYPES.GROUP) {
    return room?.name?.toLowerCase()?.includes(searchValue?.toLowerCase());
  } else {
    if (room && room?.users_rooms && room?.users_rooms?.length) {
      for (let index = 0; index < room?.users_rooms?.length; index++) {
        if (
          room?.users_rooms[index]?.user?.first_name
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          room?.users_rooms[index]?.user?.last_name
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase())
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isOnline = (room, onlineUsers, currentUserId) => {
  if (room && room?.users_rooms?.length) {
    const user = room?.users_rooms?.find(
      (item) => item?.user?.id !== currentUserId
    );
    return onlineUsers?.includes(user?.user?.id);
  }
  return false;
};
export const getTargetUserRoom = (room, userId) => {
  const userIndex = room?.users_rooms[0]?.user?.id === userId ? 1 : 0;
  return room?.users_rooms[userIndex]?.user;
};
