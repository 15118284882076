export const M_SKILLS = [
  {
    id: 11,
    name: 'WEB_DEVELOPMENT',
  },
  {
    id: 22,
    name: 'GRAPHIC_DESIGN',
  },
  {
    id: 33,
    name: 'PROJECT_MANAGEMENT',
  },
  {
    id: 44,
    name: 'ACCOUNTER',
  },
  {
    id: 55,
    name: 'MARKETING',
  },
  {
    id: 55,
    name: 'MARKETING',
  },
];
