import React, { useContext } from 'react'
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { AppContext } from 'store/app.context';

const ConfirmationModal = ({modalShow, closeModal, submitHandler, headerTitle}) => {

    const {languageState} = useContext(AppContext)
  return (
    <Modal isOpen={modalShow} centered>
        <ModalHeader>
            <h3>{headerTitle}</h3>
        </ModalHeader>
        <ModalBody >
            <Container style={{
                display:'flex',
                justifyContent:'center'
            }}>
            <Button color='primary' onClick={submitHandler}>{languageState.translation.COMMON.YES}</Button>
            <Button color='dark' onClick={closeModal}>{languageState.translation.COMMON.NO}</Button>
            </Container>
        </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal