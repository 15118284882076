import { TASK_STATUS } from 'config/core';

export const M_TASKS = [
  {
    id: 1,
    name: 'Call with Dave',
    status: TASK_STATUS.NEW,
    startDate: '2021-11-13T22:05:36.694Z',
    endDate: '2021-112-25T22:05:36.694Z',
    createdAt: '2021-11-13T22:05:36.694Z',
    updatedAt: '2021-11-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Lunch meeting',
    status: TASK_STATUS.NEW,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Argon Dashboard Launch',
    status: TASK_STATUS.IN_PROGESS,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Winter Hackaton',
    status: TASK_STATUS.DONE,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
];

export const M_TASKS_2 = [
  {
    id: 1,
    name: 'Call with Lode',
    status: TASK_STATUS.IN_PROGRESS,
    startDate: '2021-11-13T22:05:36.694Z',
    endDate: '2021-112-25T22:05:36.694Z',
    createdAt: '2021-11-13T22:05:36.694Z',
    updatedAt: '2021-11-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Daily meeting',
    status: TASK_STATUS.NEW,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Environment configuration',
    status: TASK_STATUS.DONE,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Stagign server configuration',
    status: TASK_STATUS.DONE,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
];

export const M_TASKS_3 = [
  {
    id: 1,
    name: 'Call with Hamdi',
    status: TASK_STATUS.NEW,
    startDate: '2021-11-13T22:05:36.694Z',
    endDate: '2021-112-25T22:05:36.694Z',
    createdAt: '2021-11-13T22:05:36.694Z',
    updatedAt: '2021-11-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Discuss with team',
    status: TASK_STATUS.DONE,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Deployment',
    status: TASK_STATUS.DONE,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
  {
    id: 1,
    name: 'Writing documentation',
    status: TASK_STATUS.IN_PROGRESS,
    startDate: '2021-12-13T22:05:36.694Z',
    endDate: '2021-12-25T22:05:36.694Z',
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
  },
];
