import React from 'react';

const Tick = () => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      className="mr-2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.35 0.0522703C19.2 -0.0227297 19.05 -0.0227295 18.9 0.0897705L7.875 8.86477L2.85 5.26477C2.7 5.15227 2.5125 5.18977 2.3625 5.30227L0.1125 7.55227C-0.0375 7.70227 -0.0375 7.92727 0.1125 8.07727L7.6125 16.3273C7.6875 16.4023 7.8 16.4398 7.875 16.4398C7.9875 16.4398 8.0625 16.4023 8.1375 16.3273L21.6375 2.07727C21.7125 2.00227 21.75 1.88977 21.75 1.77727C21.75 1.66477 21.675 1.55227 21.6 1.51477L19.35 0.0522703Z"
        fill="#219653"
      />
    </svg>
  );
};

export default Tick;
