import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Container, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';

const ModalAddUserTags = ({ modalShow, closeModal, userid }) => {
  const Modalstyle = {
    maxHeight: '90vh',
    paddingBottom: '3rem',
    marginTop: '-1rem',
    display: 'flex',
    flexDirection: 'column',
  };

  const [selectedTag, setSelectedTag] = useState('');
  const [createNewTag, setCreateNewTag] = useState(false);
  const [tagName, setTagName] = useState('');
  const { languageState, connectState } = useContext(AppContext);

  const { getUserTags, addTagsToUser, createOwnerTag } = useConnectService();

  useEffect(() => {
    getUserTags();
  }, []);

  const handleSubmit = async () => {
    if (selectedTag === '') {
      return;
    }
    const result = await addTagsToUser({
      id: selectedTag,
      user: userid,
    });

    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.TAGS_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      closeModal();
      getUserTags();
    }
  };

  const addNewTag = async () => {
    if (tagName === '') return;
    const result = await createOwnerTag({
      name: tagName,
    });
    if (result && result.status === 201) {
      toast.success(languageState.translation.ALERT.TAGS_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      getUserTags();
      setCreateNewTag(false);
    }
  };

  const handleChange = (event) => {
    setTagName(event.target.value);
  };

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      {!createNewTag ? (
        <>
          <ModalHeader
            close={
              <button className="close " onClick={closeModal}>
                ×
              </button>
            }
          >
            {languageState.translation.CONNECT.ADD_USER_TAGS}
            <p> {languageState.translation.CONNECT.ADD_USER_TAGS_PARAGRAPH}</p>
          </ModalHeader>
          <ModalBody
            // @ts-ignore
            style={Modalstyle}
          >
            <Select
              isClearable
              isSearchable
              name="colors"
              options={connectState?.userTags?.map((tag) => {
                return {
                  value: tag?.id,
                  label: tag?.name,
                };
              })}
              onChange={(tag) => setSelectedTag(tag?.value)}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            <Container>
              <Button
                className=" mt-5 ml-auto btn-exprimary"
                color="primary"
                onClick={handleSubmit}
              >
                {languageState.translation.COMMON.SUBMIT}
              </Button>
              <Button
                className=" mt-5 ml-auto btn-exprimary"
                onClick={() => {
                  setCreateNewTag(true);
                }}
              >
                {languageState.translation.CONNECT.CREATE_NEW_TAG}
              </Button>
            </Container>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader
            close={
              <button
                className="close "
                onClick={() => {
                  closeModal();
                  setCreateNewTag(false);
                }}
              >
                ×
              </button>
            }
          >
            {languageState.translation.CONNECT.ADD_TAG}
          </ModalHeader>
          <ModalBody>
            <Input
              id="tagName"
              name="text"
              type="text"
              onChange={handleChange}
            />
            <Container>
              <Button
                disabled={tagName.trim() === ''}
                className=" mt-5 ml-auto btn-exprimary"
                color="primary"
                onClick={addNewTag}
              >
                {languageState.translation.COMMON.SUBMIT}
              </Button>
              <Button
                className=" mt-5 ml-auto btn-exprimary"
                color="primary"
                onClick={() => {
                  setCreateNewTag(false);
                }}
              >
                {languageState.translation.COMMON.BUTTONS.BACK}
              </Button>
            </Container>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default ModalAddUserTags;
