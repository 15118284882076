import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import INVITATION_STATE from 'store/invitations';

import useHttpClient from '../http-client-hook';

const useInvitationService = () => {
  const { httpClient } = useHttpClient();
  const { dispatchInvitationState } = useContext(AppContext);

  const getPendingSentInvitation = async () => {
    const result = await httpClient.get('/invitations/pending');
    if (result && result.status == 200) {
      dispatchInvitationState({
        type: INVITATION_STATE.types.GET_PENDING_SENT_INVITATIONS,
        pendingSentInvitations: result.data,
      });
    }
  };
  const getMyPendingInvitation = async () => {
    const result = await httpClient.get('/invitations/pending/target/me');
    if (result && result.status == 200) {
      console.log('getMyPendingInvitation', result.data);
      dispatchInvitationState({
        type: INVITATION_STATE.types.GET_PENDING_INVITATIONS,
        pendingInvitations: result.data,
      });
    }
  };
  const getMyDecLinedInvitation = async () => {
    const result = await httpClient.get('/invitations/declined/target/me');
    if (result && result.status == 200) {
      dispatchInvitationState({
        type: INVITATION_STATE.types.GET_MY_DECLINED_INVITATIONS,
        declinedInvitations: result.data,
      });
    }
  };
  const getSentDecLinedInvitation = async () => {
    const result = await httpClient.get('/invitations/declined/');
    if (result && result.status == 200) {
      dispatchInvitationState({
        type: INVITATION_STATE.types.GET_SENT_DECLINED_INVITATIONS,
        declinedSentInvitations: result.data,
      });
    }
  };
  const checkIfInvitationIsSent = (userid) => {
    return httpClient.get('/invitations/check_user_connection/' + userid);
  };
  const sendInvitation = async (iduser) => {
    return httpClient.post('/invitations/' + iduser);
  };
  const declineInvitation = async (idInvitation) => {
    return httpClient.patch('/invitations/declined/' + idInvitation);
  };
  const acceptInvitation = async (idInvitation) => {
    return httpClient.patch('/invitations/accept/' + idInvitation);
  };
  return {
    getPendingSentInvitation,
    getMyPendingInvitation,
    sendInvitation,
    declineInvitation,
    acceptInvitation,
    getMyDecLinedInvitation,
    getSentDecLinedInvitation,
    checkIfInvitationIsSent,
  };
};
export default useInvitationService;
