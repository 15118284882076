import TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_NOTIFICATION: {
      return { ...state, notifications: action.notifications };
    }
  }
};

export default reducer;
