const { default: useHttpClient } = require('services/http-client-hook');

const useAddContactService = () => {
  const { httpClient } = useHttpClient();

  const addContact = async (payload) =>
    httpClient.post('/users/singel', payload);

  return { addContact };
};

export default useAddContactService;
