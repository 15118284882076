import reducer from './reducer';
import TYPES from './types';

const INITIAL_STATE = {
  pendingInvitations: null,
  declinedInvitations: null,
  pendingSentInvitations: null,
  declinedSentInvitations: null,
};
const INVITATION_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};
export default INVITATION_STATE;
