import React from 'react';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap';
import useSettingsService from 'services/settings/settings.service';
import { AppContext } from 'store/app.context';
import { getDuration } from 'utils';

const Educations = () => {
  const { getEducations, deleteEducation } = useSettingsService();

  const { educationState, languageState } = useContext(AppContext);

  const [educations, setEducations] = useState([]);

  useEffect(() => {
    getEducations();
  }, []);

  useEffect(() => {
    if (educationState && educationState.educations) {
      const educationsWithDuration = educationState.educations.map((item) => {
        const duration = getDuration(
          item.startDate,
          item.endDate,
          languageState
        );

        return {
          ...item,
          duration,
        };
      });
      setEducations(educationsWithDuration);
    }
  }, [educationState]);

  const onDeleteEducation = async (education) => {
    const result = await deleteEducation(education);
    if (result && result.status === 200) {
      getEducations();
    }
  };

  return (
    <>
      {educations && educations.length > 0 && (
        <div
          className="timeline timeline-one-side"
          data-timeline-axis-style="dashed"
          data-timeline-content="axis"
        >
          {educations.map((education) => (
            <Row key={education?.id}>
              <Col md="6">
                <div className="timeline-block" key={education.id}>
                  <span className="timeline-step badge-danger">
                    <i className="ni ni-ruler-pencil" />
                  </span>
                  <div className="timeline-content">
                    <div className="d-flex justify-content-between pt-1">
                      <div>
                        <span className="text-muted text-sm font-weight-bold">
                          {education.degree}
                        </span>
                      </div>
                      <div className="text-right">
                        <small className="text-muted">
                          <i className="ni ni-pin-3" /> {education.university}
                        </small>
                      </div>
                    </div>
                    <h6 className="text-sm mt-1 mb-0">
                      {education.description || 'No description added !'}
                    </h6>
                    <div className="text-left">
                      <small className="text-muted">{education.duration}</small>
                    </div>
                    <div className="text-center">
                      <small className="text-muted">
                        <i className="ni ni-pin-3" />
                        {`${moment(education.startDate).format(
                          'DD/MM/YYYY'
                        )} - ${education.endDate
                          ? moment(education.endDate).format('DD/MM/YYYY')
                          : languageState.translation.COMMON.TODAY
                          }`}
                      </small>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <Button
                  size="sm"
                  color="danger"
                  data-dz-remove
                  onClick={() => onDeleteEducation(education)}
                >
                  <i className="fas fa-trash" />
                </Button>
              </Col>
            </Row>
          ))}
        </div>
      )}
      {educations && !educations.length && (
        <div className="d-flex justify-content-center">
          <h5>{languageState.translation.SETTINGS.NO_EDUCATIONS}</h5>
        </div>
      )}
    </>
  );
};

export default Educations;
