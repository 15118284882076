import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';

const ModalNotes = ({ modalShow, closeModal, userid }) => {
  const { languageState } = useContext(AppContext);
  const { AddNoteToUser } = useConnectService();

  const [notes, setNotes] = useState('');
  const Modalstyle = {
    maxHeight: '90vh',
    paddingBottom: '3rem',
    marginTop: '-1rem',
    display: 'flex',
    flexDirection: 'column',
  };
  const handleChange = (e) => {
    setNotes(e.target.value);
  };
  const handleSubmit = async () => {
    const result = await AddNoteToUser(userid, notes);
    if (result && result.status === 201) {
      toast.success(languageState.translation.ALERT.NOTES_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    closeModal();
  };
  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        {languageState.translation.CONNECT.ADD_NOTES}
      </ModalHeader>
      <ModalBody
        // @ts-ignore
        style={Modalstyle}
      >
        <Input
          id="exampleText"
          name="text"
          type="textarea"
          rows="5"
          onChange={handleChange}
        />
        <Button
          className=" mt-5 ml-auto btn-exprimary"
          color="primary"
          disabled={notes.length < 3}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalBody>
    </Modal>
  );
};
export default ModalNotes;
