import React, { useContext } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from 'store/app.context';

const DeleteModalGeneric = ({
  title,
  onClick,
  modalShow,
  closeModal,
  body,
}) => {
  const { languageState } = useContext(AppContext);

  return (
    <div>
      <Modal
        isOpen={modalShow}
        toggle={closeModal}
        toggle={closeModal}
        centered
      >
        <ModalHeader>
          <h2>{title}</h2>
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button onClick={closeModal}>
            {languageState.translation.COMMON.BUTTONS.CANCEL}
          </Button>
          <Button color="danger" onClick={onClick}>
            {languageState.translation.COMMON.BUTTONS.REMOVE}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default DeleteModalGeneric;
