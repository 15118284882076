import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, FormGroup, InputGroup, Input, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { Field, Formik } from 'formik';
import schema from './schema';
import { AppContext } from 'store/app.context';
import useSettingsService from 'services/settings/settings.service';

const AddExperience = () => {
  const [company, setCompany] = useState(false);
  const [position, setPosition] = useState(false);
  const [description, setDescription] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);

  const [untilNow, setUntilNow] = useState(false);
  const [initialValues, setInitialValues] = useState({
    position: '',
    description: '',
    company: '',
    startDate: new Date(),
    endDate: new Date(),
  });

  const { languageState } = useContext(AppContext);

  const { getExperiences, addExperience } = useSettingsService();

  useEffect(() => { }, [untilNow]);

  const addNewExperience = async (values, resetForm) => {
    let payload = {
      ...values,
      department: 'DEPARTMENT',
      startDate: values.startDate.toISOString(),
    };
    if (values.endDate) {
      payload = {
        ...payload,
        endDate: values.endDate.toISOString(),
      };
    } else {
      delete payload.endDate;
    }
    const result = await addExperience(payload);
    if (result && result.status === 201) {
      getExperiences();
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema(languageState.translation.FORM_VALIDATION)}
      onSubmit={(values, { resetForm }) => addNewExperience(values, resetForm)}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        resetForm,
      }) => (
        <>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className=" form-control-label">
                  {languageState.translation.COMMON.START_DATE}
                </label>
                <span className="invalid-feedback"> *</span>
                <InputGroup
                  className={classnames('input-group-merge', {
                    focused: startDate,
                  })}
                >
                  <ReactDatetime
                    closeOnSelect
                    initialValue={values.startDate ? values.startDate : new Date()}
                    dateFormat={'DD-MM-YYYY'}
                    inputProps={{
                      placeholder: languageState.translation.COMMON.START_DATE,
                    }}
                    onChange={(date) => setFieldValue('startDate', date)}
                    timeFormat={false}
                    onFocus={(e) => setStartDate(true)}
                    onBlur={(e) => setStartDate(false)}
                  />
                </InputGroup>
                {errors.startDate && touched.startDate && (
                  <div className="invalid-feedback">{errors.startDate}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className=" form-control-label">
                  {languageState.translation.COMMON.END_DATE}
                </label>
                <InputGroup
                  className={classnames('input-group-merge', {
                    focused: endDate,
                  })}
                >
                  <ReactDatetime
                    closeOnSelect
                    dateFormat={'DD-MM-YYYY'}
                    initialValue={values.endDate ? values.endDate : new Date()}
                    inputProps={{
                      placeholder: languageState.translation.COMMON.END_DATE,
                    }}
                    onChange={(date) => setFieldValue('endDate', date)}
                    timeFormat={false}
                    onFocus={(e) => setEndDate(true)}
                    onBlur={(e) => setEndDate(false)}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className=" form-control-label">
                  {languageState.translation.COMMON.POSITION}
                </label>
                <span className="invalid-feedback"> *</span>
                <InputGroup
                  className={classnames('input-group-merge', {
                    focused: position,
                  })}
                >
                  <Field
                    className="form-control"
                    placeholder={languageState.translation.COMMON.POSITION}
                    name="position"
                    type="text"
                    onFocus={(e) => setPosition(true)}
                    onBlur={(e) => setPosition(false)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="ni ni-badge" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {errors.position && touched.position && (
                  <div className="invalid-feedback">{errors.position}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className=" form-control-label">
                  {languageState.translation.COMMON.COMPANY}
                </label>
                <span className="invalid-feedback"> *</span>
                <InputGroup
                  className={classnames('input-group-merge', {
                    focused: company,
                  })}
                >
                  <Field
                    className="form-control"
                    placeholder={languageState.translation.COMMON.COMPANY}
                    name="company"
                    type="text"
                    onFocus={(e) => setCompany(true)}
                    onBlur={(e) => setCompany(false)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="ni ni-pin-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {errors.company && touched.company && (
                  <div className="invalid-feedback">{errors.company}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label className=" form-control-label">
                  {languageState.translation.COMMON.DESCRIPTION}
                </label>
                <span className="invalid-feedback"> *</span>
                <InputGroup
                  className={classnames('input-group-merge', {
                    focused: description,
                  })}
                >
                  <Input
                    name="description"
                    rows="3"
                    type="textarea"
                    value={values.description}
                    onChange={handleChange}
                    onFocus={(e) => setDescription(true)}
                    onBlur={(e) => setDescription(false)}
                  />
                </InputGroup>
                {errors.description && touched.description && (
                  <div className="invalid-feedback">{errors.description}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Button
            color="primary"
            className=" d-flex align-items-center  ml-auto btn-exprimary"
            type="submit"
            onClick={handleSubmit}
          >
            <span className="mt-1 mr-1">
              <i className="ni ni-fat-add" />
            </span>
            <span>{languageState.translation.COMMON.BUTTONS.ADD}</span>
          </Button>
        </>
      )}
    </Formik>
  );
};

export default AddExperience;
