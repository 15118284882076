import TYPES from './types';
import reducer from './reducer';

const INITIAL_STATE = {
    subscriptions: []

};

const SUBSCRIPTIONS_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer,
};

export default SUBSCRIPTIONS_STATE;
