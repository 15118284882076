import useHttpClient from '../http-client-hook';

const useOnboardingService = () => {
  const { httpClient } = useHttpClient();

  const addOnboarding = (data) => {
    return httpClient.post('/onboarding', data);
  };
  return {
    addOnboarding,
  };
};

export default useOnboardingService;
