import React, { useContext, useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Badge,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import ModalGroup from 'components/modalGroup/ModalGroup';

const CardsHeaderGroup = ({ groupeNumbers, setMode, mode }) => {
  const [modalShow, setModalShow] = useState(false);

  const { languageState } = useContext(AppContext);

  return (
    <>
      <div className="header bg-exprimary pb-7 pt-3 mt--7 ">
        <Container
          fluid
          className="d-flex flex-row justify-content-between custom-header mt-7		align-items-center "
        >
          <div>
            <h1 className="text-white big-writing text-capitalize">
              {' '}
              {languageState.translation.CONNECT.GROUPS}
            </h1>

            {mode === 'private' && (
              <p className="text-white font-weight-bold">
                {groupeNumbers}
                {` ${languageState.translation.CONNECT.PRIVATE_GROUPS}`}
              </p>
            )}
            {mode === 'archive' && (
              <p className="text-white font-weight-bold">
                {groupeNumbers}
                {` ${languageState.translation.CONNECT.ARCHIVED_GROUPS}`}
              </p>
            )}
            {mode === 'public' && (
              <p className="text-white font-weight-bold">
                {groupeNumbers}
                {` ${languageState.translation.CONNECT.PUBLIC_GROUPS}`}
              </p>
            )}
            <div className="d-flex gap-1">
              {mode !== 'archive' && (
                <Badge
                  className="badge-lg"
                  color="warning"
                  size="sm"
                  type="button"
                  onClick={() => setMode('archive')}
                >
                  {languageState.translation.CONNECT.ARCHIVED}
                </Badge>
              )}
              {mode !== 'private' && (
                <Badge
                  className="badge-lg"
                  color="success"
                  size="sm"
                  type="button"
                  onClick={() => setMode('private')}
                >
                  {languageState.translation.CONNECT.PRIVATE}
                </Badge>
              )}
              {mode !== 'public' && (
                <Badge
                  className="badge-lg"
                  color="secondary"
                  size="sm"
                  type="button"
                  onClick={() => setMode('public')}
                >
                  {languageState.translation.CONNECT.PUBLIC}
                </Badge>
              )}
            </div>
          </div>
          <Button
            className="h-max-content pt-2 pb-2  pl-5 pr-5 ml-3"
            onClick={() => {
              setModalShow(true);
            }}
          >
            {languageState.translation.CONNECT.CREATE_NEW_GROUP}
          </Button>
        </Container>
      </div>
      <ModalGroup
        modalShow={modalShow}
        closeModal={() => {
          setModalShow(false);
        }}
      />
    </>
  );
};

export default CardsHeaderGroup;
