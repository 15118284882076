import TYPES from './missions.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_MISSIONS:
      return {
        ...state,
        missions: action.missions,
      };
    case TYPES.DELETE_MISSION:
      return {
        ...state,
        missions: state.missions.filter(
          (mission) => mission.id !== action.mission.id
        ),
      };
    case TYPES.UPDATE_MISSION:
      return {
        ...state,
        missions: state.missions.map((mission) =>
          mission.id === action.mission.id ? action.mission : mission
        ),
      };
    case TYPES.SELECT_MISSION:
      return {
        ...state,
        selectedMission: action.mission,
      };
    case TYPES.SET_TEAM:
      return {
        ...state,
        team: action.team,
      };
    case TYPES.SET_EVENTS:
      return {
        ...state,
        events: action.events,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
