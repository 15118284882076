import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import axios from 'axios';
import { API_BASE_URL } from 'config/core';
import LOADER_STATE from 'store/loader';
import SHARED_STATE from 'store/shared';
import USER_STATE from 'store/user';
import { MODULES } from 'config/core';
import { useHistory } from 'react-router-dom';

let counter = 0;

const useHttpClient = () => {
  const {
    languageState,
    dispatchLoaderState,
    dispatchSharedState,
    dispatchUserState,
  } = useContext(AppContext);
  const history = useHistory();
  const httpClient = axios.create({
    baseURL: `${API_BASE_URL}`,
    timeout: 60000,
  });
  const logout = () => {
    dispatchUserState({
      type: USER_STATE.types.RESET_USER_INFO,
    });
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.AUTH,
    });
    dispatchLoaderState({
      type: LOADER_STATE.types.HIDE_LOADER,
    });
    history.push('/login');
  };

  httpClient.interceptors.request.use(
    (config) => {
      dispatchLoaderState({
        type: LOADER_STATE.types.SHOW_LOADER,
      });
      counter += 1;
      if (localStorage.getItem('userInfo') && localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`;
      }
      const language = languageState.defaultLanguage;
      if (language) {
        config.headers['Accept-Language'] = language;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  httpClient.interceptors.response.use(
    (config) => {
      counter -= 1;
      if (counter <= 0) {
        dispatchLoaderState({
          type: LOADER_STATE.types.HIDE_LOADER,
        });
      }
      return config;
    },
    (error) => {
      if (
        error.response?.data?.statusCode === 401 ||
        error.response?.data?.statusCode === 403
      ) {
        counter = 0;
        logout();
      }
      else {
        counter -= 1;
        if (counter <= 0) {
          dispatchLoaderState({
            type: LOADER_STATE.types.HIDE_LOADER,
          });
        }
        // if user is not active
        if (error?.response?.data?.response?.error === "User Alrady exists but still not active") {
          dispatchSharedState({
            type: SHARED_STATE.types.SET_MODAL_OPEN,
            email: error?.response?.data?.response?.email,
          });
          dispatchSharedState({
            type: SHARED_STATE.types.SET_MODAL_SHARED_OPEN,
            msg: languageState.translation.ALERT.SIGNIN.USER_NOT_ACTIVE,
          });
        }
        else if (error?.response?.data?.message === "INVALID_UUID") {
          dispatchSharedState({
            type: SHARED_STATE.types.SET_MODAL_SHARED_OPEN,
            msg: languageState.translation.COMMON.ERRORS.INVALID_UUID,
          });
        }
        else {
          dispatchSharedState({
            type: SHARED_STATE.types.SET_MODAL_SHARED_OPEN,
            msg:
              error?.response?.data?.message ||
              languageState.translation.COMMON.ERRORS.ERROR,
          });
        }
      }

      return Promise.reject(error);
    }
  );

  return { httpClient };
};

export default useHttpClient;
