import classNames from 'classnames';
import ExEditor from 'components/editor/editor';
import ModalAddTask from 'components/modaltask/ModalAddTask';
import ModalEditTask from 'components/modaltask/ModalEditTask';
import MultipleImageUpload from 'components/multiple-image-upload/multiple-image-upload';
import HeaderRecruiter from 'components/Recruiter/HeaderRecruiter';
import { IMG_PREFIX } from 'config/core';
import { TASK_STATUS } from 'config/core';
import { MODULES } from 'config/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import useFilesService from 'services/files/files.services';
import useMissionService from 'services/missions/mission.service';
import useOnboardingService from 'services/onboarding/onboarding.service';
import { AppContext } from 'store/app.context';
import PROJECT_STATE from 'store/projects';
import SHARED_STATE from 'store/shared';

export default function Shareonboarding() {
  const [html, setHTML] = useState('');
  const history = useHistory()
  const [modal, setModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);

  const { dispatchSharedState, dispatchProjectState, languageState, projectState, missionState } = useContext(AppContext);
  const [files, setFiles] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedMission, setSelectedMission] = useState(null);
  const [selectedFreelancer, setSelectedFreelancer] = useState('');

  const [freelancers, setFreelancers] = useState([]);
  const [taskEdit, setTaskEdit] = useState();
  const { addOnboarding } = useOnboardingService();
  const [taskIndex, setTaskIndex] = useState(-1);
  const { uploadFile } = useFilesService();

  const { getAllMisions } = useMissionService();
  const [listTask, setListtasks] = useState([]);
  useEffect(() => {
    setSelectedProject(projectState.selectedProject);
  }, [projectState]);
  const [images, setImages] = useState([]);
  const deleteImage = (img) => {
    const updatedFiles = files.filter((item) => item !== img);
    setFiles(updatedFiles);
  };
  useEffect(() => {
    if (projectState.projects) {
      const project = projectState.projects;
      dispatchProjectState({
        type: PROJECT_STATE.types.SELECT_PROJECT,
        project,
      });
    }
  }, [projectState.projects]);
  useEffect(() => {
    getAllMisions();
    dispatchSharedState({
      module: MODULES.RECRUITER,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  useEffect(() => {
    if (history && history.location && history.location.state) {
      setSelectedMission(history.location.state)
    }
  }, [history])
  const handleChangeMissionValue = (e) => {
    const mission = missionState.allMissions.find(
      (data) => data.id == e.value
    );
    setSelectedMission(e);
    setFreelancers(mission.freelancers);
  };
  const handleAddTask = (data) => {
    setListtasks([{ ...data }, ...listTask]);
  };
  const handleDeleteFromTask = (index) => {
    const newlist = [...listTask];
    newlist.splice(index, 1);
    setListtasks(newlist);
  };
  const handleEditTask = (task) => {
    const newlist = [...listTask];
    newlist[taskIndex] = task;
    setListtasks(newlist);
  };
  const submitOnboarding = async () => {
    if (selectedFreelancer && selectedMission) {
      let image = [];
      if (images && images.length > 0) {
        for (let index = 0; index < images.length; index++) {
          const myimage = await uploadFile(images[index]);
          if (myimage.data) image = [...image, myimage.data.filename];
        }
      }
      const result = await addOnboarding({
        mission: selectedMission?.value,
        freelancer: selectedFreelancer,
        description: html,
        task: listTask,
        image,
      });
      if (result && result.status === 201) {
        toast.success(languageState.translation.ALERT.SUCCESS_ADD_ONBOARDING, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setListtasks([]);
        setSelectedFreelancer(null);
        setSelectedMission(null);
        setFiles([]);
        setImages([]);
      }
    }
  };

  const CustomOption = ({ innerProps, label, data }) => {
    return (
      <div {...innerProps} style={{ padding: '.3rem .5rem' }}>
        <img src={data.info.profileImage ? IMG_PREFIX + data.info.profileImage : require('assets/img/man.png').default} alt={''} style={{ marginRight: '8px', height: '24px', width: '24px' }} />
        {label}
      </div>
    );
  };

  return (
    <div>
      <HeaderRecruiter namePage="SHARE_ON_BOARDING" />

      <Container fluid className="mt-4 ">
        <Row>
          <Col lg="7">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect3"
              >
                {languageState.translation.RECRUITER.SELECT_MISSION}
              </label>
              <Select
                placeholder={languageState.translation.RECRUITER.SELECT_MISSION}
                isSearchable
                isClearable
                name="mission"
                id="mission"
                value={selectedMission}
                onChange={handleChangeMissionValue}
                className="basic-multi-select mb-4"
                classNamePrefix="select"
                options={missionState && missionState.allMissions && missionState.allMissions.map(el => { return { value: el.id, label: el.functionTitle } })}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect3"
              >
                {languageState.translation.RECRUITER.SELECT_FREELANCER}
              </label>
              <Select
                placeholder={languageState.translation.RECRUITER.SELECT_FREELANCER}
                isSearchable
                isClearable
                name="freelancers"
                id="freelancers"
                components={{ Option: CustomOption }}
                onChange={(e) => { setSelectedFreelancer(e.value); }}
                className="basic-multi-select mb-4"
                classNamePrefix="select"
                isDisabled={!freelancers.length}
                options={freelancers && freelancers.map(el => { return { value: el.id, label: `${el.firstName} ${el.lastName.charAt(0)}.`, info: el } })}
              />
            </FormGroup>
            <Card>
              <CardHeader className="font-weight-bold">
                {languageState.translation.RECRUITER.ADD_ONBOARD_INFO}{' '}
              </CardHeader>
              <CardBody>
                <ExEditor placeholder="Quill WYSIWYG" onChangeContent={(html) => setHTML(html)} />
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="font-weight-bold">
                {' '}
                {languageState.translation.SETTINGS.UPLOAD_IMAGES}
              </CardHeader>
              <CardBody>
                <MultipleImageUpload
                  defaultImages={files}
                  onChanges={(e) => setImages(e)}
                  onDeleteImage={(e) => deleteImage(e)}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="5">
            <Card className="mb-2 pb-4 min-height-20 card-height-tasks ">
              <CardHeader className="d-flex justify-content-between w-100 align-items-center">
                <h5 className="h3 mb-0">
                  {languageState.translation.FOCUS.DASHBOARD.TO_DO.TO_DO_LIST}
                </h5>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => setModal(true)}
                >
                  {' '}
                  {languageState.translation.FOCUS.DASHBOARD.ADD_ITEM}
                </Button>
              </CardHeader>

              <CardBody className="p-0">
                <ListGroup data-toggle="checklist" flush>
                  {listTask &&
                    listTask.map((task, index) => (
                      <ListGroupItem key={`${index}-task`} className="checklist-entry flex-column align-items-start py-4 px-4">
                        <div
                          className={classNames(
                            'checklist-item  ',
                            {
                              'checklist-item-success checklist-item-checked':
                                task.status === TASK_STATUS.DONE,
                            },
                            {
                              'checklist-item-warning':
                                task.status === TASK_STATUS.IN_PROGRESS,
                            }
                          )}
                        >
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">
                              {' '}
                              {task.name}
                            </h5>
                            <small>
                              {moment(task?.createdAt).format('LLL')}
                            </small>
                          </div>
                          <div>
                            <div
                              className={classNames(
                                'custom-control custom-checkbox',
                                {
                                  'custom-checkbox-success':
                                    task.status === TASK_STATUS.DONE,
                                },
                                {
                                  'custom-checkbox-warning':
                                    task.status === TASK_STATUS.IN_PROGRESS,
                                }
                              )}
                            >
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  setEditModal(true);
                                  setTaskIndex(index);
                                  setTaskEdit(task);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => handleDeleteFromTask(index)}
                              >
                                Remove
                              </Button>
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </CardBody>
            </Card>
            <Button
              color="primary"
              className="d-block ml-auto w-100"
              onClick={submitOnboarding}
            >
              {languageState.translation.RECRUITER.SAVE_ONBOARDING}
            </Button>
          </Col>
        </Row>
      </Container>
      <ModalAddTask
        modalShow={modal}
        closeModal={() => {
          setModal(false);
        }}
        handleAddTask={handleAddTask}
      />
      <ModalEditTask
        modalShow={editmodal}
        closeModal={() => {
          setEditModal(false);
        }}
        handleEditTask={handleEditTask}
        task={taskEdit}
      />
    </div>
  );
}
