import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';

const ModalError = () => {
  const { languageState, sharedState, dispatchSharedState } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    setModalShow(sharedState.ModalErrorShared);
  }, [sharedState]);
  const closeModal = () => {
    dispatchSharedState({
      type: SHARED_STATE.types.SET_MODAL_SHARED_CLOSE,
    });
  };
  const formatMessage = (message) => {
    if (message) {
      if (Array.isArray(message))
        return message[0].replaceAll(' ', '_').toUpperCase();
      return message.replaceAll(' ', '_').toUpperCase();
    }
  };
  if (modalShow)
    return (
      <Modal isOpen={modalShow} toggle={closeModal} centered>
        <ModalBody className="">
          <h3 className="text-center display-1 text-red">
            <i className="fa fa-exclamation"></i>
          </h3>
          <p className="h2 text-break text-center" dangerouslySetInnerHTML={{ __html: languageState.translation.BACKEND[formatMessage(sharedState.msg)] ? languageState.translation.BACKEND[formatMessage(sharedState.msg)] : sharedState.msg }}></p>
          <Button
            color="danger"
            className="mt-4 d-block ml-auto mr-auto"
            onClick={closeModal}
          >
            {languageState.translation.COMMON.CLOSE}
          </Button>
        </ModalBody>
      </Modal>
    );
  return <></>;
};

export default ModalError;
