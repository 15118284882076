import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';

const DeleteModal = ({ userid, modalShow, closeModal }) => {
  const { deleteUserFromMyNetwork } = useConnectService();
  const { languageState } = useContext(AppContext);
  const { getConnectedUsers } = useConnectService();

  const deleteUser = async () => {
    const result = await deleteUserFromMyNetwork(userid);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.CONNECTION_DELETE, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      closeModal();
      getConnectedUsers();
    }
  };
  return (
    <div>
      <Modal
        isOpen={modalShow}
        toggle={closeModal}
        toggle={closeModal}
        centered
      >
        <ModalHeader>
          <h2>{languageState.translation.CONNECT.DELETE_CONNECTION}</h2>
        </ModalHeader>
        <ModalBody>{languageState.translation.CONNECT.DO_YOU_REALLY}</ModalBody>
        <ModalFooter>
          <Button onClick={closeModal}>
            {languageState.translation.COMMON.BUTTONS.CANCEL}
          </Button>
          <Button color="danger" onClick={deleteUser}>
            {languageState.translation.COMMON.BUTTONS.REMOVE}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default DeleteModal;
