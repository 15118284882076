import { useField } from 'formik';
import React from 'react';
import Select from 'react-select';
import { Control, IconOption } from './Option';

const Reactselect = ({ options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  return (
    <Select
      options={options}
      isClearable
      name={field.name}
      value={props?.defaultValue}
      onChange={(option) => setValue(option.value)}
      components={{ Option: IconOption, Control: Control }}
      isSearchable
    />
  );
};

export default Reactselect;
