export const contact = [
  {
    name: 'Charlie Watson',
    lastMessage: 'It contains a lot of good lessons about effective practices',
    updatedDate: '24min ago',
    active: true,
    profileImage: 'image_Eric-f7e3.jpeg',
  },
  {
    name: 'mehdi Watson',
    lastMessage: 'It contains a lot of good lessons about effective practices',
    updatedDate: '24min ago',
    active: true,
  },
  {
    name: 'hamma Watson',
    lastMessage: 'It contains a lot of good lessons about effective practices',
    updatedDate: '24min ago',
    active: true,
  },
  {
    name: 'sadek Watson',
    lastMessage: 'It contains a lot of good lessons about effective practices',
    updatedDate: '24min ago',
    active: true,
  },
  {
    name: 'Charlie Watson',
    lastMessage: 'It contains a lot of good lessons about effective practices',
    updatedDate: '24min ago',
    active: true,
  },
  {
    name: 'Charlie Watson',
    lastMessage: 'It contains a lot of good lessons about effective practices',
    updatedDate: '24min ago',
    active: true,
  },
];
export const messages = [
  {
    message: 'It contains a lot of good lessons about effective practices ',
    isOwner: false,
    time: '4:40pm',
  },
  {
    message: 'It contains a lot of good lessons about effective practices ',
    isOwner: true,
    time: '4:40pm',
  },
  {
    image: 'image_Eric-f7e3.jpeg',
    isOwner: false,
    time: '4:40pm',
  },
  {
    message:
      'It contains  containsa lot of good lessons contains about effective contains practices  ?',
    isOwner: true,
    time: '4:40pm',
  },
  {
    message:
      'It contains a lot of good lessons about effective contains practices ',
    isOwner: true,
    time: '4:40pm',
  },
  {
    message: 'It contains a lot of good lessons about effective practices ',
    isOwner: false,
    time: '4:40pm',
  },
  {
    message: 'It contains a lot of good lessons about effective practices ',
    isOwner: true,
    time: '4:40pm',
  },
];
export const tableFileData = [
  {
    nomFile: 'image.png',
    sharedDate: '3/17/2022',
    sentBy: 'Ayoub Sayah',
  },
  {
    nomFile: 'test.pdf',
    sharedDate: '3/17/2022',
    sentBy: 'lode Mhiri',
  },
  {
    nomFile: 'test.pdf',
    sharedDate: '3/17/2022',
    sentBy: 'Sadok Mhiri',
  },
  {
    nomFile: 'test.pdf',
    sharedDate: '3/17/2022',
    sentBy: 'hammaa mallat',
  },
  {
    nomFile: 'bankerise.pdf',
    sharedDate: '3/17/2022',
    sentBy: 'Sadok Mhiri',
  },
];
export const tableHeaders = ['FILENAME', 'SHAREDDATE', 'SENTBY', 'ACTION'];
