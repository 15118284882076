const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();
const d = today.getDate();

const TIME_SLOTS = {
  0: '08',
  5: '09',
  10: '10',
  15: '11',
  20: '12',
  25: '13', // Object.values({}) * 5
  30: '14',
  35: '15',
  40: '16',
  45: '17',
  50: '18',
  55: '19',
  60: '20',
  65: '21',
  70: '22',
  75: '23',
  80: '00',
  85: '01',
  90: '02',
  95: '03',
  100: '04',
};

const events = [
  {
    id: 1,
    title: 'Call with Dave',
    start: new Date(y, m, 1),
    allDay: true,
    className: 'bg-red',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 2,
    title: 'Lunch meeting',
    start: new Date(y, m, d - 1, 10, 30),
    allDay: true,
    className: 'bg-orange',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 3,
    title: 'All day conference',
    start: new Date(y, m, d + 7, 12, 0),
    allDay: true,
    className: 'bg-green',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 4,
    title: 'Meeting with Mary',
    start: new Date(y, m, d - 2),
    allDay: true,
    className: 'bg-blue',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 5,
    title: 'Winter Hackaton',
    start: new Date(y, m, d + 1, 19, 0),
    allDay: true,
    className: 'bg-red',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 6,
    title: 'Digital event',
    start: new Date(y, m, 21),
    allDay: true,
    className: 'bg-warning',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 7,
    title: 'Marketing event',
    start: new Date(y, m, 21),
    allDay: true,
    className: 'bg-purple',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 8,
    title: 'Dinner with Family',
    start: new Date(y, m, 19),
    allDay: true,
    className: 'bg-red',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 9,
    title: 'Black Friday',
    start: new Date(y, m, 23),
    allDay: true,
    className: 'bg-blue',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },

  {
    id: 10,
    title: 'Cyber Week',
    start: new Date(y, m, 2),
    allDay: true,
    className: 'bg-yellow',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },
];

const widgetEvents = [
  {
    title: 'Lunch meeting',
    start: `${new Date().getFullYear()}-11-21`,
    end: `${new Date().getFullYear()}-11-22`,
    className: 'bg-orange',
  },
  {
    title: 'All day conference',
    start: new Date(y, m - 1, 28),
    allDay: true,
    className: 'bg-green',
  },
  {
    title: 'Meeting with Mary',
    start: new Date(y, m, 2),
    allDay: true,
    className: 'bg-blue',
  },
  {
    title: 'Winter Hackaton',
    start: new Date(y, m, 4),
    allDay: true,
    className: 'bg-red',
  },
  {
    title: 'Digital event',
    start: new Date(y, m, 8),
    end: new Date(y, m, 10),
    allDay: true,
    className: 'bg-warning',
  },
  {
    title: 'Marketing event',
    start: new Date(y, m, 11),
    allDay: true,
    className: 'bg-purple',
  },
  {
    title: 'Dinner with Family',
    start: new Date(y, m, 20),
    allDay: true,
    className: 'bg-red',
  },
  {
    title: 'Black Friday',
    start: new Date(y, m, 24),
    allDay: true,
    className: 'bg-blue',
  },
  {
    title: 'Cyber Week',
    start: new Date(y, m, 3),
    allDay: true,
    className: 'bg-yellow',
  },
];

const dataTable = [
  {
    name: 'Tiger Nixon',
    position: 'System Architect',
    office: 'Edinburgh',
    age: '61',
    start_date: '2011/04/25',
    salary: '$320,800',
  },
  {
    name: 'Garrett Winters',
    position: 'Accountant',
    office: 'Tokyo',
    age: '63',
    start_date: '2011/07/25',
    salary: '$170,750',
  },
  {
    name: 'Ashton Cox',
    position: 'Junior Technical Author',
    office: 'San Francisco',
    age: '66',
    start_date: '2009/01/12',
    salary: '$86,000',
  },
  {
    name: 'Cedric Kelly',
    position: 'Senior Javascript Developer',
    office: 'Edinburgh',
    age: '22',
    start_date: '2012/03/29',
    salary: '$433,060',
  },
  {
    name: 'Airi Satou',
    position: 'Accountant',
    office: 'Tokyo',
    age: '33',
    start_date: '2008/11/28',
    salary: '$162,700',
  },
  {
    name: 'Brielle Williamson',
    position: 'Integration Specialist',
    office: 'New York',
    age: '61',
    start_date: '2012/12/02',
    salary: '$372,000',
  },
  {
    name: 'Herrod Chandler',
    position: 'Sales Assistant',
    office: 'San Francisco',
    age: '59',
    start_date: '2012/08/06',
    salary: '$137,500',
  },
  {
    name: 'Rhona Davidson',
    position: 'Integration Specialist',
    office: 'Tokyo',
    age: '55',
    start_date: '2010/10/14',
    salary: '$327,900',
  },
  {
    name: 'Colleen Hurst',
    position: 'Javascript Developer',
    office: 'San Francisco',
    age: '39',
    start_date: '2009/09/15',
    salary: '$205,500',
  },
  {
    name: 'Sonya Frost',
    position: 'Software Engineer',
    office: 'Edinburgh',
    age: '23',
    start_date: '2008/12/13',
    salary: '$103,600',
  },
  {
    name: 'Jena Gaines',
    position: 'Office Manager',
    office: 'London',
    age: '30',
    start_date: '2008/12/19',
    salary: '$90,560',
  },
  {
    name: 'Quinn Flynn',
    position: 'Support Lead',
    office: 'Edinburgh',
    age: '22',
    start_date: '2013/03/03',
    salary: '$342,000',
  },
  {
    name: 'Charde Marshall',
    position: 'Regional Director',
    office: 'San Francisco',
    age: '36',
    start_date: '2008/10/16',
    salary: '$470,600',
  },
  {
    name: 'Haley Kennedy',
    position: 'Senior Marketing Designer',
    office: 'London',
    age: '43',
    start_date: '2012/12/18',
    salary: '$313,500',
  },
  {
    name: 'Tatyana Fitzpatrick',
    position: 'Regional Director',
    office: 'London',
    age: '19',
    start_date: '2010/03/17',
    salary: '$385,750',
  },
  {
    name: 'Michael Silva',
    position: 'Marketing Designer',
    office: 'London',
    age: '66',
    start_date: '2012/11/27',
    salary: '$198,500',
  },
  {
    name: 'Paul Byrd',
    position: 'Chief Financial Officer (CFO)',
    office: 'New York',
    age: '64',
    start_date: '2010/06/09',
    salary: '$725,000',
  },
  {
    name: 'Gloria Little',
    position: 'Systems Administrator',
    office: 'New York',
    age: '59',
    start_date: '2009/04/10',
    salary: '$237,500',
  },
  {
    name: 'Bradley Greer',
    position: 'Software Engineer',
    office: 'London',
    age: '41',
    start_date: '2012/10/13',
    salary: '$132,000',
  },
  {
    name: 'Dai Rios',
    position: 'Personnel Lead',
    office: 'Edinburgh',
    age: '35',
    start_date: '2012/09/26',
    salary: '$217,500',
  },
  {
    name: 'Jenette Caldwell',
    position: 'Development Lead',
    office: 'New York',
    age: '30',
    start_date: '2011/09/03',
    salary: '$345,000',
  },
  {
    name: 'Yuri Berry',
    position: 'Chief Marketing Officer (CMO)',
    office: 'New York',
    age: '40',
    start_date: '2009/06/25',
    salary: '$675,000',
  },
  {
    name: 'Caesar Vance',
    position: 'Pre-Sales Support',
    office: 'New York',
    age: '21',
    start_date: '2011/12/12',
    salary: '$106,450',
  },
  {
    name: 'Doris Wilder',
    position: 'Sales Assistant',
    office: 'Sidney',
    age: '23',
    start_date: '2010/09/20',
    salary: '$85,600',
  },
  {
    name: 'Angelica Ramos',
    position: 'Chief Executive Officer (CEO)',
    office: 'London',
    age: '47',
    start_date: '2009/10/09',
    salary: '$1,200,000',
  },
  {
    name: 'Gavin Joyce',
    position: 'Developer',
    office: 'Edinburgh',
    age: '42',
    start_date: '2010/12/22',
    salary: '$92,575',
  },
  {
    name: 'Jennifer Chang',
    position: 'Regional Director',
    office: 'Singapore',
    age: '28',
    start_date: '2010/11/14',
    salary: '$357,650',
  },
  {
    name: 'Brenden Wagner',
    position: 'Software Engineer',
    office: 'San Francisco',
    age: '28',
    start_date: '2011/06/07',
    salary: '$206,850',
  },
  {
    name: 'Fiona Green',
    position: 'Chief Operating Officer (COO)',
    office: 'San Francisco',
    age: '48',
    start_date: '2010/03/11',
    salary: '$850,000',
  },
  {
    name: 'Shou Itou',
    position: 'Regional Marketing',
    office: 'Tokyo',
    age: '20',
    start_date: '2011/08/14',
    salary: '$163,000',
  },
  {
    name: 'Michelle House',
    position: 'Integration Specialist',
    office: 'Sidney',
    age: '37',
    start_date: '2011/06/02',
    salary: '$95,400',
  },
  {
    name: 'Suki Burks',
    position: 'Developer',
    office: 'London',
    age: '53',
    start_date: '2009/10/22',
    salary: '$114,500',
  },
  {
    name: 'Prescott Bartlett',
    position: 'Technical Author',
    office: 'London',
    age: '27',
    start_date: '2011/05/07',
    salary: '$145,000',
  },
  {
    name: 'Gavin Cortez',
    position: 'Team Leader',
    office: 'San Francisco',
    age: '22',
    start_date: '2008/10/26',
    salary: '$235,500',
  },
  {
    name: 'Martena Mccray',
    position: 'Post-Sales support',
    office: 'Edinburgh',
    age: '46',
    start_date: '2011/03/09',
    salary: '$324,050',
  },
  {
    name: 'Unity Butler',
    position: 'Marketing Designer',
    office: 'San Francisco',
    age: '47',
    start_date: '2009/12/09',
    salary: '$85,675',
  },
  {
    name: 'Howard Hatfield',
    position: 'Office Manager',
    office: 'San Francisco',
    age: '51',
    start_date: '2008/12/16',
    salary: '$164,500',
  },
  {
    name: 'Hope Fuentes',
    position: 'Secretary',
    office: 'San Francisco',
    age: '41',
    start_date: '2010/02/12',
    salary: '$109,850',
  },
  {
    name: 'Vivian Harrell',
    position: 'Financial Controller',
    office: 'San Francisco',
    age: '62',
    start_date: '2009/02/14',
    salary: '$452,500',
  },
  {
    name: 'Timothy Mooney',
    position: 'Office Manager',
    office: 'London',
    age: '37',
    start_date: '2008/12/11',
    salary: '$136,200',
  },
  {
    name: 'Jackson Bradshaw',
    position: 'Director',
    office: 'New York',
    age: '65',
    start_date: '2008/09/26',
    salary: '$645,750',
  },
  {
    name: 'Olivia Liang',
    position: 'Support Engineer',
    office: 'Singapore',
    age: '64',
    start_date: '2011/02/03',
    salary: '$234,500',
  },
  {
    name: 'Bruno Nash',
    position: 'Software Engineer',
    office: 'London',
    age: '38',
    start_date: '2011/05/03',
    salary: '$163,500',
  },
  {
    name: 'Sakura Yamamoto',
    position: 'Support Engineer',
    office: 'Tokyo',
    age: '37',
    start_date: '2009/08/19',
    salary: '$139,575',
  },
  {
    name: 'Thor Walton',
    position: 'Developer',
    office: 'New York',
    age: '61',
    start_date: '2013/08/11',
    salary: '$98,540',
  },
  {
    name: 'Finn Camacho',
    position: 'Support Engineer',
    office: 'San Francisco',
    age: '47',
    start_date: '2009/07/07',
    salary: '$87,500',
  },
  {
    name: 'Serge Baldwin',
    position: 'Data Coordinator',
    office: 'Singapore',
    age: '64',
    start_date: '2012/04/09',
    salary: '$138,575',
  },
  {
    name: 'Zenaida Frank',
    position: 'Software Engineer',
    office: 'New York',
    age: '63',
    start_date: '2010/01/04',
    salary: '$125,250',
  },
  {
    name: 'Zorita Serrano',
    position: 'Software Engineer',
    office: 'San Francisco',
    age: '56',
    start_date: '2012/06/01',
    salary: '$115,000',
  },
  {
    name: 'Jennifer Acosta',
    position: 'Junior Javascript Developer',
    office: 'Edinburgh',
    age: '43',
    start_date: '2013/02/01',
    salary: '$75,650',
  },
  {
    name: 'Cara Stevens',
    position: 'Sales Assistant',
    office: 'New York',
    age: '46',
    start_date: '2011/12/06',
    salary: '$145,600',
  },
  {
    name: 'Hermione Butler',
    position: 'Regional Director',
    office: 'London',
    age: '47',
    start_date: '2011/03/21',
    salary: '$356,250',
  },
  {
    name: 'Lael Greer',
    position: 'Systems Administrator',
    office: 'London',
    age: '21',
    start_date: '2009/02/27',
    salary: '$103,500',
  },
  {
    name: 'Jonas Alexander',
    position: 'Developer',
    office: 'San Francisco',
    age: '30',
    start_date: '2010/07/14',
    salary: '$86,500',
  },
  {
    name: 'Shad Decker',
    position: 'Regional Director',
    office: 'Edinburgh',
    age: '51',
    start_date: '2008/11/13',
    salary: '$183,000',
  },
  {
    name: 'Michael Bruce',
    position: 'Javascript Developer',
    office: 'Singapore',
    age: '29',
    start_date: '2011/06/27',
    salary: '$183,000',
  },
  {
    name: 'Donna Snider',
    position: 'Customer Support',
    office: 'New York',
    age: '27',
    start_date: '2011/01/25',
    salary: '$112,000',
  },
];

const languagesList = [
  { value: 'ab', label: 'Abkhaz', nativeName: 'аҧсуа' },
  { value: 'aa', label: 'Afar', nativeName: 'Afaraf' },
  { value: 'af', label: 'Afrikaans', nativeName: 'Afrikaans' },
  { value: 'ak', label: 'Akan', nativeName: 'Akan' },
  { value: 'sq', label: 'Albanian', nativeName: 'Shqip' },
  { value: 'am', label: 'Amharic', nativeName: 'አማርኛ' },
  { value: 'ar', label: 'Arabic', nativeName: 'العربية' },
  { value: 'an', label: 'Aragonese', nativeName: 'Aragonés' },
  { value: 'hy', label: 'Armenian', nativeName: 'Հայերեն' },
  { value: 'as', label: 'Assamese', nativeName: 'অসমীয়া' },
  { value: 'av', label: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
  { value: 'ae', label: 'Avestan', nativeName: 'avesta' },
  { value: 'ay', label: 'Aymara', nativeName: 'aymar aru' },
  { value: 'az', label: 'Azerbaijani', nativeName: 'azərbaycan dili' },
  { value: 'bm', label: 'Bambara', nativeName: 'bamanankan' },
  { value: 'ba', label: 'Bashkir', nativeName: 'башҡорт теле' },
  { value: 'eu', label: 'Basque', nativeName: 'euskara, euskera' },
  { value: 'be', label: 'Belarusian', nativeName: 'Беларуская' },
  { value: 'bn', label: 'Bengali', nativeName: 'বাংলা' },
  { value: 'bh', label: 'Bihari', nativeName: 'भोजपुरी' },
  { value: 'bi', label: 'Bislama', nativeName: 'Bislama' },
  { value: 'bs', label: 'Bosnian', nativeName: 'bosanski jezik' },
  { value: 'br', label: 'Breton', nativeName: 'brezhoneg' },
  { value: 'bg', label: 'Bulgarian', nativeName: 'български език' },
  { value: 'my', label: 'Burmese', nativeName: 'ဗမာစာ' },
  { value: 'ca', label: 'Catalan; Valencian', nativeName: 'Català' },
  { value: 'ch', label: 'Chamorro', nativeName: 'Chamoru' },
  { value: 'ce', label: 'Chechen', nativeName: 'нохчийн мотт' },
  {
    value: 'ny',
    label: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  { value: 'zh', label: 'Chinese', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
  { value: 'cv', label: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
  { value: 'kw', label: 'Cornish', nativeName: 'Kernewek' },
  { value: 'co', label: 'Corsican', nativeName: 'corsu, lingua corsa' },
  { value: 'cr', label: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { value: 'hr', label: 'Croatian', nativeName: 'hrvatski' },
  { value: 'cs', label: 'Czech', nativeName: 'česky, čeština' },
  { value: 'da', label: 'Danish', nativeName: 'dansk' },
  { value: 'dv', label: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
  { value: 'nl', label: 'Dutch', nativeName: 'Nederlands, Vlaams' },
  { value: 'en', label: 'English', nativeName: 'English' },
  { value: 'eo', label: 'Esperanto', nativeName: 'Esperanto' },
  { value: 'et', label: 'Estonian', nativeName: 'eesti, eesti keel' },
  { value: 'ee', label: 'Ewe', nativeName: 'Eʋegbe' },
  { value: 'fo', label: 'Faroese', nativeName: 'føroyskt' },
  { value: 'fj', label: 'Fijian', nativeName: 'vosa Vakaviti' },
  { value: 'fi', label: 'Finnish', nativeName: 'suomi, suomen kieli' },
  { value: 'fr', label: 'French', nativeName: 'français, langue française' },
  {
    value: 'ff',
    label: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  { value: 'gl', label: 'Galician', nativeName: 'Galego' },
  { value: 'ka', label: 'Georgian', nativeName: 'ქართული' },
  { value: 'de', label: 'German', nativeName: 'Deutsch' },
  { value: 'el', label: 'Greek, Modern', nativeName: 'Ελληνικά' },
  { value: 'gn', label: 'Guaraní', nativeName: 'Avañeẽ' },
  { value: 'gu', label: 'Gujarati', nativeName: 'ગુજરાતી' },
  {
    value: 'ht',
    label: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
  },
  { value: 'ha', label: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { value: 'he', label: 'Hebrew (modern)', nativeName: 'עברית' },
  { value: 'hz', label: 'Herero', nativeName: 'Otjiherero' },
  { value: 'hi', label: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
  { value: 'ho', label: 'Hiri Motu', nativeName: 'Hiri Motu' },
  { value: 'hu', label: 'Hungarian', nativeName: 'Magyar' },
  { value: 'ia', label: 'Interlingua', nativeName: 'Interlingua' },
  { value: 'id', label: 'Indonesian', nativeName: 'Bahasa Indonesia' },
  {
    value: 'ie',
    label: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  { value: 'ga', label: 'Irish', nativeName: 'Gaeilge' },
  { value: 'ig', label: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { value: 'ik', label: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
  { value: 'io', label: 'Ido', nativeName: 'Ido' },
  { value: 'is', label: 'Icelandic', nativeName: 'Íslenska' },
  { value: 'it', label: 'Italian', nativeName: 'Italiano' },
  { value: 'iu', label: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  {
    value: 'ja',
    label: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  { value: 'jv', label: 'Javanese', nativeName: 'basa Jawa' },
  {
    value: 'kl',
    label: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  { value: 'kn', label: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { value: 'kr', label: 'Kanuri', nativeName: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
  { value: 'kk', label: 'Kazakh', nativeName: 'Қазақ тілі' },
  { value: 'km', label: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  { value: 'ki', label: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
  { value: 'rw', label: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
  { value: 'ky', label: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
  { value: 'kv', label: 'Komi', nativeName: 'коми кыв' },
  { value: 'kg', label: 'Kongo', nativeName: 'KiKongo' },
  {
    value: 'ko',
    label: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  { value: 'ku', label: 'Kurdish', nativeName: 'Kurdî, كوردی‎' },
  { value: 'kj', label: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
  { value: 'la', label: 'Latin', nativeName: 'latine, lingua latina' },
  {
    value: 'lb',
    label: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  { value: 'lg', label: 'Luganda', nativeName: 'Luganda' },
  {
    value: 'li',
    label: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  { value: 'ln', label: 'Lingala', nativeName: 'Lingála' },
  { value: 'lo', label: 'Lao', nativeName: 'ພາສາລາວ' },
  { value: 'lt', label: 'Lithuanian', nativeName: 'lietuvių kalba' },
  { value: 'lu', label: 'Luba-Katanga', nativeName: '' },
  { value: 'lv', label: 'Latvian', nativeName: 'latviešu valoda' },
  { value: 'gv', label: 'Manx', nativeName: 'Gaelg, Gailck' },
  { value: 'mk', label: 'Macedonian', nativeName: 'македонски јазик' },
  { value: 'mg', label: 'Malagasy', nativeName: 'Malagasy fiteny' },
  { value: 'ms', label: 'Malay', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
  { value: 'ml', label: 'Malayalam', nativeName: 'മലയാളം' },
  { value: 'mt', label: 'Maltese', nativeName: 'Malti' },
  { value: 'mi', label: 'Māori', nativeName: 'te reo Māori' },
  { value: 'mr', label: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
  { value: 'mh', label: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
  { value: 'mn', label: 'Mongolian', nativeName: 'монгол' },
  { value: 'na', label: 'Nauru', nativeName: 'Ekakairũ Naoero' },
  {
    value: 'nv',
    label: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  { value: 'nb', label: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
  { value: 'nd', label: 'North Ndebele', nativeName: 'isiNdebele' },
  { value: 'ne', label: 'Nepali', nativeName: 'नेपाली' },
  { value: 'ng', label: 'Ndonga', nativeName: 'Owambo' },
  { value: 'nn', label: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
  { value: 'no', label: 'Norwegian', nativeName: 'Norsk' },
  { value: 'ii', label: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
  { value: 'nr', label: 'South Ndebele', nativeName: 'isiNdebele' },
  { value: 'oc', label: 'Occitan', nativeName: 'Occitan' },
  { value: 'oj', label: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  {
    value: 'cu',
    label:
      'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  { value: 'om', label: 'Oromo', nativeName: 'Afaan Oromoo' },
  { value: 'or', label: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { value: 'os', label: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
  { value: 'pa', label: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { value: 'pi', label: 'Pāli', nativeName: 'पाऴि' },
  { value: 'fa', label: 'Persian', nativeName: 'فارسی' },
  { value: 'pl', label: 'Polish', nativeName: 'polski' },
  { value: 'ps', label: 'Pashto, Pushto', nativeName: 'پښتو' },
  { value: 'pt', label: 'Portuguese', nativeName: 'Português' },
  { value: 'qu', label: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
  { value: 'rm', label: 'Romansh', nativeName: 'rumantsch grischun' },
  { value: 'rn', label: 'Kirundi', nativeName: 'kiRundi' },
  { value: 'ro', label: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
  { value: 'ru', label: 'Russian', nativeName: 'русский язык' },
  { value: 'sa', label: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
  { value: 'sc', label: 'Sardinian', nativeName: 'sardu' },
  { value: 'sd', label: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
  { value: 'se', label: 'Northern Sami', nativeName: 'Davvisámegiella' },
  { value: 'sm', label: 'Samoan', nativeName: 'gagana faa Samoa' },
  { value: 'sg', label: 'Sango', nativeName: 'yângâ tî sängö' },
  { value: 'sr', label: 'Serbian', nativeName: 'српски језик' },
  { value: 'gd', label: 'Scottish Gaelic; Gaelic', nativeName: 'Gàidhlig' },
  { value: 'sn', label: 'Shona', nativeName: 'chiShona' },
  { value: 'si', label: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
  { value: 'sk', label: 'Slovak', nativeName: 'slovenčina' },
  { value: 'sl', label: 'Slovene', nativeName: 'slovenščina' },
  { value: 'so', label: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
  { value: 'st', label: 'Southern Sotho', nativeName: 'Sesotho' },
  {
    value: 'es',
    label: 'Spanish; Castilian',
    nativeName: 'español, castellano',
  },
  { value: 'su', label: 'Sundanese', nativeName: 'Basa Sunda' },
  { value: 'sw', label: 'Swahili', nativeName: 'Kiswahili' },
  { value: 'ss', label: 'Swati', nativeName: 'SiSwati' },
  { value: 'sv', label: 'Swedish', nativeName: 'svenska' },
  { value: 'ta', label: 'Tamil', nativeName: 'தமிழ்' },
  { value: 'te', label: 'Telugu', nativeName: 'తెలుగు' },
  { value: 'tg', label: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { value: 'th', label: 'Thai', nativeName: 'ไทย' },
  { value: 'ti', label: 'Tigrinya', nativeName: 'ትግርኛ' },
  {
    value: 'bo',
    label: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  { value: 'tk', label: 'Turkmen', nativeName: 'Türkmen, Түркмен' },
  { value: 'tl', label: 'Tagalog', nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { value: 'tn', label: 'Tswana', nativeName: 'Setswana' },
  { value: 'to', label: 'Tonga (Tonga Islands)', nativeName: 'faka Tonga' },
  { value: 'tr', label: 'Turkish', nativeName: 'Türkçe' },
  { value: 'ts', label: 'Tsonga', nativeName: 'Xitsonga' },
  { value: 'tt', label: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا‎' },
  { value: 'tw', label: 'Twi', nativeName: 'Twi' },
  { value: 'ty', label: 'Tahitian', nativeName: 'Reo Tahiti' },
  { value: 'ug', label: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { value: 'uk', label: 'Ukrainian', nativeName: 'українська' },
  { value: 'ur', label: 'Urdu', nativeName: 'اردو' },
  { value: 'uz', label: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
  { value: 've', label: 'Venda', nativeName: 'Tshivenḓa' },
  { value: 'vi', label: 'Vietnamese', nativeName: 'Tiếng Việt' },
  { value: 'vo', label: 'Volapük', nativeName: 'Volapük' },
  { value: 'wa', label: 'Walloon', nativeName: 'Walon' },
  { value: 'cy', label: 'Welsh', nativeName: 'Cymraeg' },
  { value: 'wo', label: 'Wolof', nativeName: 'Wollof' },
  { value: 'fy', label: 'Western Frisian', nativeName: 'Frysk' },
  { value: 'xh', label: 'Xhosa', nativeName: 'isiXhosa' },
  { value: 'yi', label: 'Yiddish', nativeName: 'ייִדיש' },
  { value: 'yo', label: 'Yoruba', nativeName: 'Yorùbá' },
  { value: 'za', label: 'Zhuang, Chuang', nativeName: 'Saɯ cueŋƅ, Saw cuengh' },
];

const cities_belgium = [
  {
    name: 'Brussel',
    postalCode: '1000',
  },
  {
    name: 'Laken',
    postalCode: '1020',
  },
  {
    name: 'Schaarbeek',
    postalCode: '1030',
  },
  {
    name: 'Etterbeek',
    postalCode: '1040',
  },
  {
    name: 'Elsene',
    postalCode: '1050',
  },
  {
    name: 'Sint-Gillis',
    postalCode: '1060',
  },
  {
    name: 'Anderlecht',
    postalCode: '1070',
  },
  {
    name: 'Sint-Jans-Molenbeek',
    postalCode: '1080',
  },
  {
    name: 'Koekelberg',
    postalCode: '1081',
  },
  {
    name: 'Sint-Agatha-Berchem',
    postalCode: '1082',
  },
  {
    name: 'Ganshoren',
    postalCode: '1083',
  },
  {
    name: 'Jette',
    postalCode: '1090',
  },
  {
    name: 'Neder-Over-Heembeek',
    postalCode: '1120',
  },
  {
    name: 'Haren',
    postalCode: '1130',
  },
  {
    name: 'Evere',
    postalCode: '1140',
  },

  {
    name: 'Sint-Pieters-Woluwe',
    postalCode: '1150',
  },
  {
    name: 'Oudergem',
    postalCode: '1160',
  },
  {
    name: 'Watermaal-Bosvoorde',
    postalCode: '1170',
  },
  {
    name: 'Ukkel',
    postalCode: '1180',
  },
  {
    name: 'Vorst',
    postalCode: '1190',
  },
  {
    name: 'Sint-Lambrechts-Woluwe',
    postalCode: '1200',
  },
  {
    name: 'Sint-Joost-ten-Node',
    postalCode: '1210',
  },
];

const currencies = [
  {
    label: 'United Arab Emirates Dirham',
    value: 'AED',
  },
  {
    label: 'Afghan Afghani',
    value: 'AFN',
  },
  {
    label: 'Albanian Lek',
    value: 'ALL',
  },
  {
    label: 'Armenian Dram',
    value: 'AMD',
  },
  {
    label: 'Netherlands Antillean Guilder',
    value: 'ANG',
  },
  {
    label: 'Angolan Kwanza',
    value: 'AOA',
  },
  {
    label: 'Argentine Peso',
    value: 'ARS',
  },
  {
    label: 'Australian Dollar',
    value: 'AUD',
  },
  {
    label: 'Aruban Florin',
    value: 'AWG',
  },
  {
    label: 'Azerbaijani Manat',
    value: 'AZN',
  },
  {
    label: 'Bosnia-Herzegovina Convertible Mark',
    value: 'BAM',
  },
  {
    label: 'Barbadian Dollar',
    value: 'BBD',
  },
  {
    label: 'Bangladeshi Taka',
    value: 'BDT',
  },
  {
    label: 'Bulgarian Lev',
    value: 'BGN',
  },
  {
    label: 'Bahraini Dinar',
    value: 'BHD',
  },
  {
    label: 'Burundian Franc',
    value: 'BIF',
  },
  {
    label: 'Bermudan Dollar',
    value: 'BMD',
  },
  {
    label: 'Brunei Dollar',
    value: 'BND',
  },
  {
    label: 'Bolivian Boliviano',
    value: 'BOB',
  },
  {
    label: 'Brazilian Real',
    value: 'BRL',
  },
  {
    label: 'Bahamian Dollar',
    value: 'BSD',
  },
  {
    label: 'Bitcoin',
    value: 'BTC',
  },
  {
    label: 'Bhutanese Ngultrum',
    value: 'BTN',
  },
  {
    label: 'Botswanan Pula',
    value: 'BWP',
  },
  {
    label: 'Belarusian Ruble',
    value: 'BYN',
  },
  {
    label: 'Belize Dollar',
    value: 'BZD',
  },
  {
    label: 'Canadian Dollar',
    value: 'CAD',
  },
  {
    label: 'Congolese Franc',
    value: 'CDF',
  },
  {
    label: 'Swiss Franc',
    value: 'CHF',
  },
  {
    label: 'Chilean Unit of Account (UF)',
    value: 'CLF',
  },
  {
    label: 'Chilean Peso',
    value: 'CLP',
  },
  {
    label: 'Chinese Yuan (Offshore)',
    value: 'CNH',
  },
  {
    label: 'Chinese Yuan',
    value: 'CNY',
  },
  {
    label: 'Colombian Peso',
    value: 'COP',
  },
  {
    label: 'Costa Rican Colón',
    value: 'CRC',
  },
  {
    label: 'Cuban Convertible Peso',
    value: 'CUC',
  },
  {
    label: 'Cuban Peso',
    value: 'CUP',
  },
  {
    label: 'Cape Verdean Escudo',
    value: 'CVE',
  },
  {
    label: 'Czech Republic Koruna',
    value: 'CZK',
  },
  {
    label: 'Djiboutian Franc',
    value: 'DJF',
  },
  {
    label: 'Danish Krone',
    value: 'DKK',
  },
  {
    label: 'Dominican Peso',
    value: 'DOP',
  },
  {
    label: 'Algerian Dinar',
    value: 'DZD',
  },
  {
    label: 'Egyptian Pound',
    value: 'EGP',
  },
  {
    label: 'Eritrean Nakfa',
    value: 'ERN',
  },
  {
    label: 'Ethiopian Birr',
    value: 'ETB',
  },
  {
    label: 'Euro',
    value: 'EUR',
  },
  {
    label: 'Fijian Dollar',
    value: 'FJD',
  },
  {
    label: 'Falkland Islands Pound',
    value: 'FKP',
  },
  {
    label: 'British Pound Sterling',
    value: 'GBP',
  },
  {
    label: 'Georgian Lari',
    value: 'GEL',
  },
  {
    label: 'Guernsey Pound',
    value: 'GGP',
  },
  {
    label: 'Ghanaian Cedi',
    value: 'GHS',
  },
  {
    label: 'Gibraltar Pound',
    value: 'GIP',
  },
  {
    label: 'Gambian Dalasi',
    value: 'GMD',
  },
  {
    label: 'Guinean Franc',
    value: 'GNF',
  },
  {
    label: 'Guatemalan Quetzal',
    value: 'GTQ',
  },
  {
    label: 'Guyanaese Dollar',
    value: 'GYD',
  },
  {
    label: 'Hong Kong Dollar',
    value: 'HKD',
  },
  {
    label: 'Honduran Lempira',
    value: 'HNL',
  },
  {
    label: 'Croatian Kuna',
    value: 'HRK',
  },
  {
    label: 'Haitian Gourde',
    value: 'HTG',
  },
  {
    label: 'Hungarian Forint',
    value: 'HUF',
  },
  {
    label: 'Indonesian Rupiah',
    value: 'IDR',
  },
  {
    label: 'Israeli New Sheqel',
    value: 'ILS',
  },
  {
    label: 'Manx pound',
    value: 'IMP',
  },
  {
    label: 'Indian Rupee',
    value: 'INR',
  },
  {
    label: 'Iraqi Dinar',
    value: 'IQD',
  },
  {
    label: 'Iranian Rial',
    value: 'IRR',
  },
  {
    label: 'Icelandic Króna',
    value: 'ISK',
  },
  {
    label: 'Jersey Pound',
    value: 'JEP',
  },
  {
    label: 'Jamaican Dollar',
    value: 'JMD',
  },
  {
    label: 'Jordanian Dinar',
    value: 'JOD',
  },
  {
    label: 'Japanese Yen',
    value: 'JPY',
  },
  {
    label: 'Kenyan Shilling',
    value: 'KES',
  },
  {
    label: 'Kyrgystani Som',
    value: 'KGS',
  },
  {
    label: 'Cambodian Riel',
    value: 'KHR',
  },
  {
    label: 'Comorian Franc',
    value: 'KMF',
  },
  {
    label: 'North Korean Won',
    value: 'KPW',
  },
  {
    label: 'South Korean Won',
    value: 'KRW',
  },
  {
    label: 'Kuwaiti Dinar',
    value: 'KWD',
  },
  {
    label: 'Cayman Islands Dollar',
    value: 'KYD',
  },
  {
    label: 'Kazakhstani Tenge',
    value: 'KZT',
  },
  {
    label: 'Laotian Kip',
    value: 'LAK',
  },
  {
    label: 'Lebanese Pound',
    value: 'LBP',
  },
  {
    label: 'Sri Lankan Rupee',
    value: 'LKR',
  },
  {
    label: 'Liberian Dollar',
    value: 'LRD',
  },
  {
    label: 'Lesotho Loti',
    value: 'LSL',
  },
  {
    label: 'Libyan Dinar',
    value: 'LYD',
  },
  {
    label: 'Moroccan Dirham',
    value: 'MAD',
  },
  {
    label: 'Moldovan Leu',
    value: 'MDL',
  },
  {
    label: 'Malagasy Ariary',
    value: 'MGA',
  },
  {
    label: 'Macedonian Denar',
    value: 'MKD',
  },
  {
    label: 'Myanma Kyat',
    value: 'MMK',
  },
  {
    label: 'Mongolian Tugrik',
    value: 'MNT',
  },
  {
    label: 'Macanese Pataca',
    value: 'MOP',
  },
  {
    label: 'Mauritanian Ouguiya',
    value: 'MRU',
  },
  {
    label: 'Mauritian Rupee',
    value: 'MUR',
  },
  {
    label: 'Maldivian Rufiyaa',
    value: 'MVR',
  },
  {
    label: 'Malawian Kwacha',
    value: 'MWK',
  },
  {
    label: 'Mexican Peso',
    value: 'MXN',
  },
  {
    label: 'Malaysian Ringgit',
    value: 'MYR',
  },
  {
    label: 'Mozambican Metical',
    value: 'MZN',
  },
  {
    label: 'Namibian Dollar',
    value: 'NAD',
  },
  {
    label: 'Nigerian Naira',
    value: 'NGN',
  },
  {
    label: 'Nicaraguan Córdoba',
    value: 'NIO',
  },
  {
    label: 'Norwegian Krone',
    value: 'NOK',
  },
  {
    label: 'Nepalese Rupee',
    value: 'NPR',
  },
  {
    label: 'New Zealand Dollar',
    value: 'NZD',
  },
  {
    label: 'Omani Rial',
    value: 'OMR',
  },
  {
    label: 'Panamanian Balboa',
    value: 'PAB',
  },
  {
    label: 'Peruvian Nuevo Sol',
    value: 'PEN',
  },
  {
    label: 'Papua New Guinean Kina',
    value: 'PGK',
  },
  {
    label: 'Philippine Peso',
    value: 'PHP',
  },
  {
    label: 'Pakistani Rupee',
    value: 'PKR',
  },
  {
    label: 'Polish Zloty',
    value: 'PLN',
  },
  {
    label: 'Paraguayan Guarani',
    value: 'PYG',
  },
  {
    label: 'Qatari Rial',
    value: 'QAR',
  },
  {
    label: 'Romanian Leu',
    value: 'RON',
  },
  {
    label: 'Serbian Dinar',
    value: 'RSD',
  },
  {
    label: 'Russian Ruble',
    value: 'RUB',
  },
  {
    label: 'Rwandan Franc',
    value: 'RWF',
  },
  {
    label: 'Saudi Riyal',
    value: 'SAR',
  },
  {
    label: 'Solomon Islands Dollar',
    value: 'SBD',
  },
  {
    label: 'Seychellois Rupee',
    value: 'SCR',
  },
  {
    label: 'Sudanese Pound',
    value: 'SDG',
  },
  {
    label: 'Swedish Krona',
    value: 'SEK',
  },
  {
    label: 'Singapore Dollar',
    value: 'SGD',
  },
  {
    label: 'Saint Helena Pound',
    value: 'SHP',
  },
  {
    label: 'Sierra Leonean Leone',
    value: 'SLL',
  },
  {
    label: 'Somali Shilling',
    value: 'SOS',
  },
  {
    label: 'Surinamese Dollar',
    value: 'SRD',
  },
  {
    label: 'South Sudanese Pound',
    value: 'SSP',
  },
  {
    label: 'São Tomé and Príncipe Dobra (pre-2018)',
    value: 'STD',
  },
  {
    label: 'São Tomé and Príncipe Dobra',
    value: 'STN',
  },
  {
    label: 'Salvadoran Colón',
    value: 'SVC',
  },
  {
    label: 'Syrian Pound',
    value: 'SYP',
  },
  {
    label: 'Swazi Lilangeni',
    value: 'SZL',
  },
  {
    label: 'Thai Baht',
    value: 'THB',
  },
  {
    label: 'Tajikistani Somoni',
    value: 'TJS',
  },
  {
    label: 'Turkmenistani Manat',
    value: 'TMT',
  },
  {
    label: 'Tunisian Dinar',
    value: 'TND',
  },
  {
    label: "Tongan Pa'anga",
    value: 'TOP',
  },
  {
    label: 'Turkish Lira',
    value: 'TRY',
  },
  {
    label: 'Trinidad and Tobago Dollar',
    value: 'TTD',
  },
  {
    label: 'New Taiwan Dollar',
    value: 'TWD',
  },
  {
    label: 'Tanzanian Shilling',
    value: 'TZS',
  },
  {
    label: 'Ukrainian Hryvnia',
    value: 'UAH',
  },
  {
    label: 'Ugandan Shilling',
    value: 'UGX',
  },
  {
    label: 'United States Dollar',
    value: 'USD',
  },
  {
    label: 'Uruguayan Peso',
    value: 'UYU',
  },
  {
    label: 'Uzbekistan Som',
    value: 'UZS',
  },
  {
    label: 'Venezuelan Bolívar Fuerte (Old)',
    value: 'VEF',
  },
  {
    label: 'Venezuelan Bolívar Soberano',
    value: 'VES',
  },
  {
    label: 'Vietnamese Dong',
    value: 'VND',
  },
  {
    label: 'Vanuatu Vatu',
    value: 'VUV',
  },
  {
    label: 'Samoan Tala',
    value: 'WST',
  },
  {
    label: 'CFA Franc BEAC',
    value: 'XAF',
  },
  {
    label: 'Silver Ounce',
    value: 'XAG',
  },
  {
    label: 'Gold Ounce',
    value: 'XAU',
  },
  {
    label: 'East Caribbean Dollar',
    value: 'XCD',
  },
  {
    label: 'Special Drawing Rights',
    value: 'XDR',
  },
  {
    label: 'CFA Franc BCEAO',
    value: 'XOF',
  },
  {
    label: 'Palladium Ounce',
    value: 'XPD',
  },
  {
    label: 'CFP Franc',
    value: 'XPF',
  },
  {
    label: 'Platinum Ounce',
    value: 'XPT',
  },
  {
    label: 'Yemeni Rial',
    value: 'YER',
  },
  {
    label: 'South African Rand',
    value: 'ZAR',
  },
  {
    label: 'Zambian Kwacha',
    value: 'ZMW',
  },
  {
    label: 'Zimbabwean Dollar',
    value: 'ZWL',
  },
];

const skillLevels = [
  { label: 'Beginner', value: 'BEGINNER' },
  { label: 'Intermediate', value: 'INTERMEDIATE' },
  { label: 'professional', value: 'PROFESSIONAL' },
  { label: 'Native', value: 'NATIVE' },
];

export {
  events,
  widgetEvents,
  dataTable,
  languagesList,
  skillLevels,
  cities_belgium,
  TIME_SLOTS,
  currencies,
};
