export const professions = ["FULL_TIME_FREELANCER", "PART_TIME_FREELANCER", "CONTRACTOR", "STUDENT"];
export const workTypes = ["REMOTE", "HYBRID", "ON_LOCATION"];
export const workPreferations = ["DAILY_BASED", "HOURLY_BASED", "PROJECT_BASED"];
export const statuses = ["OPEN_FOR_PROJECT", "OPEN_FOR_PART_TIME", "NOT_AVAILABLE"];
export const statusesColors = {
    "OPEN_FOR_PROJECT": '#2DCE89',
    "OPEN_FOR_PART_TIME": '#F2994A',
    "NOT_AVAILABLE": '#FA5252',
    // TODO: to be removed after clean data we have found only "OPEN_FOR_PROJECT", "OPEN_FOR_PART_TIME", "NOT_AVAILABLE" as availaibility status
    "not available": '#FA5252',
    "AVAILABLE": "2DCE89",
    "available": '#2DCE89',
}