import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import useAuthService from 'services/auth/auth.service';

import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';
const ModalSendMail = () => {
  const { languageState, sharedState, dispatchSharedState } =
    useContext(AppContext);
  const { resendVerif } = useAuthService();
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    setModalShow(sharedState.ModalError);
  }, [sharedState]);
  const closeModal = () => {
    dispatchSharedState({
      type: SHARED_STATE.types.SET_MODAL_CLOSE,
    });
  };
  const resend = async () => {
    const result = await resendVerif({ email: sharedState.email });
    if (result.status === 201) {
      toast.success(languageState.translation.AUTH.SIGNIN.EMAIL_ENVOYE, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      dispatchSharedState({
        type: SHARED_STATE.types.SET_MODAL_CLOSE,
      });
    }
  };
  if (modalShow)
    return (
      <Modal isOpen={modalShow} toggle={closeModal} centered>
        <ModalHeader
          close={
            <button className="close " onClick={closeModal}>
              ×
            </button>
          }
          className="mb-0"
        >
          {languageState.translation.AUTH.SIGNIN.EMAIL_NOT_ACTIVATED}
        </ModalHeader>
        <ModalBody className="mt-0 pt-0">
          <h3>
            {languageState.translation.AUTH.SIGNIN.YOU_DIDNT_RECEIVE_EMAIL}
          </h3>
          <Button color="primary" onClick={() => resend()}>
            {languageState.translation.AUTH.SIGNIN.RESEND_ACTIVATION_EMAIL}
          </Button>
        </ModalBody>
      </Modal>
    );
  return <></>;
};

export default ModalSendMail;
