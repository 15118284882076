import CardsHeaderFocus from 'components/cards/card-header-focus';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, Container, CustomInput, Label, Row } from 'reactstrap';
import { AppContext } from 'store/app.context';
import JobCard from './JobCard';
import JobsContainer from '../JobsContainer';
import useJobsService from 'services/jobs/jobs.service';
import { getQueryParamsFromObject } from 'utils';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './job-card.css';
import useSettingsService from 'services/settings/settings.service';
import Empty from 'components/Empty/Empty';
import { useHistory } from 'react-router-dom';

const JobBoard = () => {
  const history = useHistory();
  const [useFilter, setUseFilter] = useState(true)
  const { jobsState, languageState, skillsState, userState } = useContext(AppContext);
  const [skills, setSkills] = useState(localStorage.getItem("skills") ? JSON.parse(localStorage.getItem("skills")) : []);
  const [skill, setSkill] = useState('')
  const [paginationList, setPagination] = useState([]);
  const [filters, setFilters] = useState(localStorage.getItem("filters") ? JSON.parse(localStorage.getItem("filters")) : {
    type: '',
    skills: skillsState && skillsState.mine && skillsState.mine.map((item) => item.id),
    tags: [],
    missionLocation: '',
    showUnread: false
  });
  const { getSkills, getMySkills } = useSettingsService();
  const [options, setOptions] = useState([]);
  const { getJobs, postViewJob } = useJobsService();
  const [jobs, setJobs] = useState([])
  const [modal, setModal] = useState(false)

  useEffect(() => {
    const reqData = getQueryParamsFromObject({ ...filters, page: 1 });
    getJobs(reqData);
  }, [filters]);

  const openDetails = (id,) => {
    if (userState && userState.userInfo) {
      postViewJob(id)
      localStorage.setItem('filters', JSON.stringify(filters))
      localStorage.setItem('skills', JSON.stringify(skills))
      history.push('/private/jobs/job-details/' + id)
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    if (userState && userState.userInfo) {
      getMySkills();
    }
    getSkills();
  }, [userState])

  useEffect(() => {
    if (jobsState && jobsState.jobs) {
      setJobs(jobsState.jobs)
      getPaginate();
    }
  }, [jobsState]);

  useEffect(() => {
    if (localStorage.getItem('filters') && localStorage.getItem("skills")) {
      localStorage.getItem('filters') && setFilters(JSON.parse(localStorage.getItem('filters')))
      localStorage.getItem("skills") && setSkills(JSON.parse(localStorage.getItem('skills')) || [])
      setTimeout(() => {
        localStorage.removeItem('filters')
        localStorage.removeItem('skills')
      }, 4000)
    } else {
      if (skillsState && skillsState.skills) {
        setOptions(skillsState.skills.map(skill => ({
          value: skill.id,
          label: skill.key
        })));
      }
      if (skillsState.mine && useFilter) {
        setFilters({ ...filters, skills: skillsState.mine.map((item) => item.id) })
        setSkills([...skillsState.mine.map((item) => {
          return {
            value: item.id,
            label: item.key,
          };
        })]);
      } else {
        setFilters({
          type: '',
          skills: [],
          tags: [],
          missionLocation: ''
        })
        setSkills([])
      }
    }
  }, [skillsState, useFilter])

  const getMoreJobs = (page) => {
    if (page !== jobsState.config.currentPage) {
      const reqData = getQueryParamsFromObject({ ...filters, page });
      getJobs(reqData)
      window.scrollTo(0, 0);
    }
  };

  const getPaginate = () => {
    const listpag = [];
    if (jobsState.config && jobsState.config.totalPages) {
      if (jobsState.config?.currentPage > 1) {
        listpag.push(jobsState.config?.currentPage - 1);
      }
      listpag.push(jobsState.config?.currentPage);
      if (jobsState.config?.currentPage < jobsState.config.totalPages) {
        listpag.push(jobsState.config?.currentPage + 1);
      }
      setPagination(listpag);
    }
  };

  const nextPage = () => {
    if (jobsState.config.currentPage < jobsState.config.totalPages) {
      const page = jobsState.config.currentPage + 1;
      const reqData = getQueryParamsFromObject({ ...filters, page });
      getJobs(reqData)
      window.scrollTo(0, 0);
    }
  };
  const prevPage = () => {
    if (jobsState.config?.currentPage > 1) {
      const page = jobsState.config.currentPage - 1;
      const reqData = getQueryParamsFromObject({ ...filters, page });
      getJobs(reqData)
      window.scrollTo(0, 0);
    }
  };
  const hasNext = () => {
    if (jobsState.config && jobsState.config.currentPage) {
      if (jobsState.config.currentPage < jobsState.config.totalPages) {
        return false;
      }
    }
    return true;
  };
  const hasPrev = () => {
    if (jobsState.config && jobsState.config.currentPage) {
      if (jobsState.config?.currentPage > 1) {
        return false;
      }
      return true;
    }
  };

  return (
    <JobsContainer>
      <CardsHeaderFocus
        name={languageState.translation.COMMON.JOB_OVERVIEW}
        parentName="Dashboards"
        subName={`${jobsState?.config?.totalItems} ${languageState.translation.COMMON.OPEN_POSITIONS}`}
      />
      <Container className="mt-3" fluid>
        <Row>
          {/* block filter */}
          <Col lg={12} className='d-flex align-items-center justify-content-between py-3'>
            <div>
              <h3 className='font-weight-bold'>
                {languageState.translation.JOBBOARD.FILTER_JOBBOARD_TITLE}
              </h3>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <span className='font-weight-bold mr-5'>{languageState.translation.JOBBOARD.USE_FILTER_LABEL}</span>
              <CustomInput
                type="switch"
                label={<Fragment>
                  <span className={`${useFilter ? 'yes-label' : 'no-label'} switch-icon-left text-capitalize`}>{useFilter ? languageState.translation.COMMON.YES : languageState.translation.COMMON.NO}</span>
                </Fragment>}
                className={`${useFilter ? 'switch-on' : 'switch-off'}`}
                id={'view_filter'}
                name={'view_filter'}
                inline
                checked={useFilter}
                onChange={(e) => setUseFilter(e.target.checked)}
              />
            </div>
          </Col>
          {useFilter && (
            <Col className="col-lg-12 col-sm-12 col-12">
              <Card>
                <CardBody>
                  <Row className='m-0'>
                    <Col lg={6} md={6} sm={12} className='d-flex align-items-start px-0 pb-1'>
                      <Col lg={6} className='p-2'>
                        <Label className="mb-0 font-weight-bold">
                          {languageState.translation.JOBBOARD.LOOKING_FOR}
                        </Label>
                      </Col>
                      <Select
                        className='width-45'
                        options={[{ value: 'FULL_TIME', label: languageState.translation.COMMON.FULL_TIME }, { value: 'PART_TIME', label: languageState.translation.COMMON.PART_TIME }]}
                        isSearchable
                        isClearable
                        value={filters.type === 'FULL_TIME' ? { value: 'FULL_TIME', label: languageState.translation.COMMON.FULL_TIME } : filters.type === 'PART_TIME' ? { value: 'PART_TIME', label: languageState.translation.COMMON.PART_TIME } : null}
                        placeholder={languageState.translation.COMMON.MAKE_A_CHOICE}
                        onChange={(e) => setFilters({ ...filters, type: e && e.value })}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12} className='d-flex px-0 pb-1'>
                      <Col lg={6} className='p-2'>
                        <Label className="mb-0 font-weight-bold">
                          {languageState.translation.JOBBOARD.USE_FOLLOWING_SKILLS}
                        </Label>
                      </Col>
                      {/* <CreatableSelect
                        noOptionsMessage={() => languageState.translation.COMMON.SKILLS_SELECT_EMPTY}
                        onInputChange={(e) => setSkill(e)}
                        isMulti
                        value={skills}
                        options={skills.length ? options.filter((el) => {
                          return !skills.includes(el)
                        }) : skill ? options.filter(el => el.label.toUpperCase().includes(skill.toUpperCase())) : []}
                        isClearable
                        className="w-100"
                        placeholder={languageState.translation.COMMON.SKILLS_SELECT_PLACEHOLDER}
                        onChange={(e) => {
                          setSkills(e);
                          const tagsArray = [];
                          const skillsArray = []
                          e.map(el => {
                            el.type === 'typed' ? tagsArray.push(el.value) : skillsArray.push(el.value)
                          })
                          setFilters({ ...filters, skills: skillsArray, tags: tagsArray })
                        }}
                      onCreateOption={(e) => {
                        setSkills([...skills, { value: e, label: e, type: 'typed' }]);
                        setFilters({ ...filters, tags: [...filters.tags, e] })
                      }}
                      /> */}
                      <Select
                        className='w-100'
                        options={options}
                        isSearchable
                        isMulti
                        isClearable
                        value={skills}
                        placeholder={languageState.translation.COMMON.SKILLS_SELECT_PLACEHOLDER}
                        onChange={(e) => {
                          setSkills(e)
                          setFilters({ ...filters, skills: e && e.map(item => item.value) })
                        }}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12} className='d-none px-0 pb-1'>
                      <Col lg={6} className='p-0'>
                        <Label className="mb-0 font-weight-bold">
                          {languageState.translation.JOBBOARD.WHERE_CAN_WORK}
                        </Label>
                      </Col>
                      <Select
                        className='width-45'
                        options={[{ value: 'REMOTE', label: languageState.translation.COMMON.REMOTE }, { value: 'ON_SITE', label: languageState.translation.COMMON.ON_SITE }]}
                        isSearchable
                        isClearable
                        value={filters.missionLocation === 'REMOTE' ? { value: 'REMOTE', label: languageState.translation.COMMON.REMOTE } : filters.missionLocation === 'ON_SITE' ? { value: 'ON_SITE', label: languageState.translation.COMMON.ON_SITE } : null}
                        placeholder={`${languageState.translation.COMMON.REMOTE} ${languageState.translation.COMMON.ON_SITE}`}
                        onChange={(e) => setFilters({ ...filters, mi: e && e.value })}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12} className='d-flex align-items-center justify-content-between py-3' >
            <div>
              <h3 className='font-weight-bold'>
                {languageState.translation.JOBBOARD.RESULT_JOBS_FIRST_PARA} {jobsState?.config?.totalItems} {languageState.translation.JOBBOARD.RESULT_JOBS_SECOND_PARA}
              </h3>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <span className='font-weight-bold mr-5'>{languageState.translation.JOBBOARD.SHOW_UNREAD}</span>
              <CustomInput
                type="switch"
                label={<Fragment>
                  <span className={`${filters.showUnread ? 'yes-label' : 'no-label'} switch-icon-left text-capitalize`}>{filters.showUnread ? languageState.translation.COMMON.YES : languageState.translation.COMMON.NO}</span>
                </Fragment>}
                className={`${filters.showUnread ? 'switch-on' : 'switch-off'}`}
                id={'show_unread'}
                name={'show_unread'}
                inline
                checked={filters.showUnread}
                onChange={(e) => setFilters({ ...filters, showUnread: e.target.checked })}
              />
            </div>
          </Col>
          <Col>
            {jobs && jobs.length ? jobs.map((data, index) => {
              return <Card key={`job-${index}`}>
                <JobCard {...data} openDetails={openDetails} modal={modal} setModal={setModal} />
              </Card>
            }) : <Empty />}
          </Col>
        </Row>
        {jobsState.config?.totalItems > 8 && (
          <ButtonGroup className="d-flex justify-content-center gap-1">
            <Button
              className=" flex-grow-0 rounded-circle paginate-button"
              type="button"
              color="primary"
              onClick={() => prevPage()}
              disabled={hasPrev()}
            >
              <i className="fas fa-angle-left" />
            </Button>
            {paginationList &&
              paginationList.map((data, index) => (
                <Button
                  className={
                    jobsState.config?.currentPage == data
                      ? '  flex-grow-0 rounded-circle paginate-button active shadow-lg'
                      : 'flex-grow-0 rounded-circle paginate-button shadow-lg'
                  }
                  color="primary"
                  key={index}
                  type="button"
                  onClick={() => getMoreJobs(data)}
                >
                  {data}
                </Button>
              ))}
            {jobsState.config?.totalPages - jobsState.config?.currentPage > 1 &&
              jobsState.config?.currentPage + 1 !== jobsState.config?.totalPages && (
                <>
                  <span
                    style={{
                      alignSelf: 'center',
                    }}
                  >
                    ...
                  </span>
                  <Button
                    className={
                      jobsState.config?.currentPage == jobsState.config?.totalPages
                        ? '  flex-grow-0 rounded-circle paginate-button active shadow-lg'
                        : 'flex-grow-0 rounded-circle paginate-button shadow-lg'
                    }
                    color="primary"
                    type="button"
                    onClick={() => getMoreJobs(jobsState.config?.totalPages)}
                  >
                    {jobsState.config?.totalPages}
                  </Button>
                </>
              )}
            <Button
              className=" flex-grow-0 rounded-circle paginate-button "
              type="button"
              color="primary"
              onClick={() => nextPage()}
              disabled={hasNext()}
            >
              <i className="fas fa-angle-right" />
            </Button>
          </ButtonGroup>
        )}
      </Container>
    </JobsContainer>
  );
};

export default JobBoard;
