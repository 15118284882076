import { Field, Formik } from 'formik';
import { userInformationSchema } from 'pages/profile/schema';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';

const UserInformation = () => {
  const { languageState, userState } = useContext(AppContext);

  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } = useUserService();

  const [initialValues, setValues] = useState({
    firstName: userState?.userInfo?.firstName || '',
    lastName: userState?.userInfo?.lastName || '',
    email: userState?.userInfo?.email || '',
    phoneNumber: userState?.userInfo?.phoneNumber || '',
  });

  const handleUserInfoSubmit = async (values) => {
    const result = await updateBasicInfos({ ...values, chatToken: userState?.chatToken });
    if (result && result.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
      toast.success(languageState.translation.ALERT.SUCCESS_CHANGE_INFO, {
        position: 'bottom-right',
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              {languageState.translation.PROFILE.USER_INFO}
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={initialValues}
          validationSchema={userInformationSchema(
            languageState.translation.FORM_VALIDATION
          )}
          onSubmit={(values) => {
            console.log('UserInformation', values);
            handleUserInfoSubmit(values);
          }}
        >
          {({ errors, touched, values, handleSubmit, isSubmitting }) => (
            <div>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-firstname"
                        placeholder={'Firstname'}
                        type="text"
                        name="firstName"
                      />
                      {errors.firstName && touched.firstName && (
                        <div className="invalid-feedback">
                          {errors.firstName}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-last-name-name"
                        placeholder={'Lastname'}
                        type="text"
                        name="lastName"
                      />
                      {errors.lastName && touched.lastName && (
                        <div className="invalid-feedback">
                          {errors.lastName}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-email"
                        placeholder={'Email address'}
                        type="text"
                        name="email"
                      />
                      {errors.email && touched.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-phone-number"
                        placeholder={'Phone number'}
                        type="text"
                        name="phoneNumber"
                      />
                      {errors.phoneNumber && touched.phoneNumber && (
                        <div className="invalid-feedback">
                          {errors.phoneNumber}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE_INFO}
              </button>
            </div>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default UserInformation;
