import { Field } from 'formik';
import React, { useContext, useState } from 'react';
import { FormGroup, Label, InputGroup } from 'reactstrap';
import Select from 'react-select';
import { AppContext } from 'store/app.context';

const ExtraInfoContact = ({ values, errors, touched, setFieldValue }) => {
  const { languageState, connectState } = useContext(AppContext);

  return (
    <>
      <div className="inner-add-contact-container">
        <div
          style={{
            flex: 1,
          }}
        >
          <FormGroup>
            <Label className="label-style">
              {languageState.translation.ADD_CONTACT.JOB_TITLE}
            </Label>

            <InputGroup className="input-group-merge input-group-alternative">
              <Field
                className="form-control"
                name="jobTitle"
                type="text"
                value={values.jobTitle}
                onChange={(e) => {
                  setFieldValue('jobTitle', e.target.value);
                }}
              />
            </InputGroup>
            <small className="text-red">
              {errors.jobTitle && touched.jobTitle && errors.jobTitle}
            </small>
          </FormGroup>
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <FormGroup>
            <Label className="label-style">
              {languageState.translation.ADD_CONTACT.PORTFOLIO}
            </Label>
            <InputGroup className="input-group-merge input-group-alternative">
              <Field
                className="form-control"
                name="portfolio"
                type="text"
                value={values.portfolio}
                onChange={(e) => {
                  setFieldValue('portfolio', e.target.value);
                }}
              />
            </InputGroup>
            <small className="text-red">
              {errors.portfolio && touched.portfolio && errors.portfolio}
            </small>
          </FormGroup>
        </div>
      </div>
      <FormGroup
        style={{
          flex: 1,
        }}
      >
        <Label className="label-style">
          {languageState.translation.ADD_CONTACT.PRIVATE_NOTES}
        </Label>
        <InputGroup className="input-group-merge input-group-alternative">
          <Field
            className="form-control private-notes-text-field"
            name="privateNotes"
            placeholder={languageState.translation.ADD_CONTACT.PRIVATE_NOTES_EXAMPLE}
            type="text"
          />
        </InputGroup>
        <small className="text-red">
          {errors.privateNotes && touched.privateNotes && errors.privateNotes}
        </small>
      </FormGroup>

      <FormGroup
        style={{
          flex: 1,
        }} className="d-none"
      >
        <Label className="label-style">
          {languageState.translation.ADD_CONTACT.ADD_TAG}
        </Label>

        <Select
          isClearable
          isSearchable
          name="colors"
          options={connectState?.tags?.map((tag) => {
            return {
              value: tag?.id,
              label: tag?.name,
            };
          })}
          onChange={(tag) => values.personTag = tag && tag.value}
          className="basic-multi-select"
          classNamePrefix="select"
          menuPlacement='top'
        />
        <small className="text-red">
          {errors.personTag && touched.personTag && errors.personTag}
        </small>
      </FormGroup>
      <strong>{languageState.translation.ADD_CONTACT.MISSING_INFO}</strong>
      <div className="custom-control custom-checkbox mb-3">
        <input
          id="sentMail"
          className="custom-control-input"
          name="sentMail"
          type="checkbox"
          checked={values.sentMail}
          onChange={(e) => {
            setFieldValue('sentMail', e.target.checked);
          }}
        />
        <label className="custom-control-label" htmlFor="sentMail">
          {languageState.translation.ADD_CONTACT.SEND_EMAIL}
        </label>
      </div>
    </>
  );
};

export default ExtraInfoContact;
