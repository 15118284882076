import TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SHOW_LOADER:
      return {
        ...state,
        showLoader: true,
      };
    case TYPES.HIDE_LOADER:
      return {
        ...state,
        showLoader: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
