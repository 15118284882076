import { IMG_PREFIX } from 'config/core';
import { useContext, useEffect } from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import useInvitationService from 'services/invitations/invitation.service';
import { AppContext } from 'store/app.context';

const DeclinedSentInvitations = () => {
  const { getSentDecLinedInvitation } = useInvitationService();
  const {
    invitationsState: { declinedSentInvitations },
    languageState,
  } = useContext(AppContext);
  useEffect(() => {
    getSentDecLinedInvitation();
  }, []);
  return (
    <>
      <ListGroup className="list mt-2" flush>
        {declinedSentInvitations &&
          declinedSentInvitations.length > 0 &&
          declinedSentInvitations.map((data, index) => (
            <ListGroupItem key={index} className="px-0">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      src={`${IMG_PREFIX}${data.target?.profileImage}`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          require('assets/img/man.png').default;
                      }}
                    />
                  </a>
                </Col>
                <div className="col">
                  <h4 className="mb-0">
                    {' '}
                    {data.target?.firstName + ' ' + data.target?.lastName.charAt(0) + '.'}{' '}
                  </h4>
                  <small>{data.target?.kind}</small>
                </div>
              </Row>
            </ListGroupItem>
          ))}
      </ListGroup>
      {declinedSentInvitations && declinedSentInvitations.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center h-100 display-1 mt--4 ">
          <i className="fas fa-user-friends"></i>
          <p className=" font-weight-bold">
            {
              languageState.translation.CONNECT
                .YOU_HAVE_NO_DECLINED_SENT_INVITATION
            }
          </p>
        </div>
      )}
    </>
  );
};
export default DeclinedSentInvitations;
