import React, { useContext, useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from 'reactstrap';
import AuthHeader from 'components/layout/header/auth-header';
import { AppContext } from 'store/app.context';
import { Formik, Field, Form } from 'formik';
import schema from './schema';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import useAuthService from 'services/auth/auth.service';
import Tick from 'components/icons/Tick';
import SHARED_STATE from 'store/shared';
import './index.scss'

// core components
const Register = () => {
  const { signup } = useAuthService();

  const history = useHistory();
  let { userType } = useParams();

  const { languageState, dispatchSharedState } = useContext(AppContext);
  const [kind, setKind] = useState('FREELANCER');
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [emailAccepted, setEmailAccepted] = useState(false)
  const [agreeAcceptEmail, setAgreeAcceptEmail] = useState(false);
  const [focusedFirstName, setfocusedFirstName] = useState(false);
  const [focusedLastName, setfocusedLastName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedConfirmPassword, setfocusedConfirmPassword] = useState(false);
  useEffect(() => {
    if (userType === 'other') {
      setKind('OTHER');
    }
  }, []);
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  };

  const onChangeKind = (type) => {
    setKind(type);
  };

  const onChangePrivacy = () => {
    if (!privacyAccepted) {
      setShowPrivacy(false);
    }
    setPrivacyAccepted(!privacyAccepted);
  };

  const onChangeGDPR = () => {
    if (!emailAccepted) {
      setAgreeAcceptEmail(false)
    }
    setEmailAccepted(!emailAccepted)
  }

  const submitForm = async (values) => {
    const requestData = {
      name: values.name,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      kind,
      language: languageState && languageState.defaultLanguage,
      receiveEmail: emailAccepted
    };

    if (!emailAccepted) {
      setAgreeAcceptEmail(true);
      return;
    }

    if (!privacyAccepted) {
      setShowPrivacy(true);
      return;
    }

    const result = await signup(requestData);
    if (result?.data?.status === 409) {
      dispatchSharedState({
        type: SHARED_STATE.types.SET_MODAL_SHARED_OPEN,
        msg:
          result?.data?.message ||
          languageState.translation.COMMON.ERRORS.ERROR,
      });
      return;
    }

    if (result && result.status === 201) {
      Swal.fire(
        languageState.translation.ALERT.SUCCESS_REGISTER,
        languageState.translation.ALERT.VERIF_INBOX,
        'success'
      ).then(() => {
        history.push('/login');
      });
    }
  };

  return (
    <div className="auth-container auth-register-container">
      <Row className="m-0">
        <Col lg="12" className="p-0">
          <AuthHeader title={languageState.translation.AUTH.SIGNUP.CREATE_AN_ACCOUNT}
          />
          <Container fluid>
            <Row>
              <div className="col-lg-12 mt--2 pb-5">
                <Formik
                  initialValues={initialValues}
                  validationSchema={schema(
                    languageState.translation.FORM_VALIDATION
                  )}
                  onSubmit={(values) => submitForm(values)}
                >
                  {({ errors, values, touched, handleSubmit }) => (
                    <Row className="justify-content-center">
                      <Col lg="10" md="12">
                        <div className="text-white d-flex flex-column mt--8 ">
                          <span className="fs-2  ">
                            {languageState.translation.REGISTER.REGISTER_CLICKS}{' '}
                            <br />
                          </span>
                          <div className="d-flex align-items-center mt-3">
                            <img
                              className="width-4 d-none"
                              src={require('assets/img/logo_bgred.png').default}
                              alt="avatar"
                            />
                          </div>
                          <span className="text-white ">

                            <ul>
                              <li>

                                {languageState.translation.REGISTER.USP1}{' '}
                              </li>
                              <li>
                                {languageState.translation.REGISTER.USP2}{' '}

                              </li>
                              <li>
                                {languageState.translation.REGISTER.USP3}{' '}

                              </li>
                            </ul>

                          </span>
                          <span className="text-left">
                            {languageState.translation.REGISTER.TEST}{' '}
                          </span>
                        </div>
                        <Card className="bg-secondary border-0">
                          <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-left mt-2 mb-4">
                              <small>
                                {
                                  languageState.translation.AUTH.SIGNUP
                                    .SIGNUP_TYPE
                                }
                              </small>
                            </div>
                            <div className="text-center d-flex justify-content-around">
                              {kind !== 'OTHER' && (
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    defaultChecked={
                                      kind !== 'OTHER' ? true : false
                                    }
                                    className="custom-control-input"
                                    id="customRadio5"
                                    name="custom-radio-1"
                                    type="radio"
                                    onChange={(e) => onChangeKind('FREELANCER')}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customRadio5"
                                  >
                                    {
                                      languageState.translation.COMMON
                                        .FREELANCER
                                    }
                                  </label>
                                </div>
                              )}
                              {/* <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id="customRadio6"
                                  name="custom-radio-1"
                                  type="radio"
                                  onChange={(e) => onChangeKind('CLIENT')}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customRadio6"
                                >
                                  {languageState.translation.COMMON.SUPPLIER}
                                </label>
                              </div> */}
                              {kind !== 'OTHER' && (
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="customRadio7"
                                    name="custom-radio-1"
                                    type="radio"
                                    onChange={(e) => onChangeKind('COMPANY')}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customRadio7"
                                  >
                                    {languageState.translation.COMMON.COMPANY}
                                  </label>
                                </div>
                              )}
                              {kind === 'OTHER' && (
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="customRadio7"
                                    defaultChecked={
                                      kind === 'OTHER' ? true : false
                                    }
                                    name="custom-radio-1"
                                    type="radio"
                                    onChange={(e) => onChangeKind('OTHER')}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customRadio7"
                                  >
                                    {languageState.translation.COMMON.OTHER}
                                  </label>
                                </div>
                              )}
                            </div>
                          </CardHeader>
                          <CardBody className="px-lg-2 py-lg-2">
                            <Form>
                              {/* <FormGroup
                                className={classnames({
                                  focused: focusedFirstName,
                                })}
                              >
                                <InputGroup className="input-group-merge input-group-alternative mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-hat-3" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Field
                                    className="form-control"
                                    placeholder={
                                      languageState.translation.COMMON.NAME
                                    }
                                    type="text"
                                    name="name"
                                    onFocus={() => setfocusedFirstName(true)}
                                    onBlur={() => setfocusedFirstName(false)}
                                  />
                                </InputGroup>
                                {errors.name && touched.name && (
                                  <div className="invalid-feedback">
                                    {errors.name}
                                  </div>
                                )}
                              </FormGroup> */}

                              <div className="row m-0">
                                <Col lg="6">
                                  <FormGroup
                                    className={classnames({
                                      focused: focusedFirstName,
                                    })}
                                  >
                                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                                      <Field
                                        className="form-control"
                                        placeholder={
                                          languageState.translation.COMMON
                                            .FIRST_NAME
                                        }
                                        type="text"
                                        name="firstName"
                                        onFocus={() =>
                                          setfocusedFirstName(true)
                                        }
                                        onBlur={() =>
                                          setfocusedFirstName(false)
                                        }
                                      />
                                    </InputGroup>
                                    {errors.firstName && touched.firstName && (
                                      <div className="invalid-feedback">
                                        {errors.firstName}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup
                                    className={classnames({
                                      focused: focusedLastName,
                                    })}
                                  >
                                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                                      <Field
                                        className="form-control"
                                        placeholder={
                                          languageState.translation.COMMON
                                            .LAST_NAME
                                        }
                                        type="text"
                                        name="lastName"
                                        onFocus={() => setfocusedLastName(true)}
                                        onBlur={() => setfocusedLastName(false)}
                                      />
                                    </InputGroup>
                                    {errors.lastName && touched.lastName && (
                                      <div className="invalid-feedback">
                                        {errors.lastName}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col lg="12">
                                  <FormGroup
                                    className={classnames({
                                      focused: focusedEmail,
                                    })}
                                  >
                                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-email-83" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Field
                                        className="form-control"
                                        placeholder={
                                          languageState.translation.COMMON.EMAIL
                                        }
                                        type="email"
                                        name="email"
                                        onFocus={() => setfocusedEmail(true)}
                                        onBlur={() => setfocusedEmail(false)}
                                      />
                                    </InputGroup>
                                    {errors.email && touched.email && (
                                      <div className="invalid-feedback">
                                        {errors.email}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                                {kind === 'OTHER' && (
                                  <Col lg="12">
                                    <FormGroup>
                                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON
                                              .FUNCTION_TITLE
                                          }
                                          type="text"
                                          name="functionTitle"
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                )}
                                {
                                  <Col lg="12">
                                    <FormGroup
                                      className={classnames({
                                        focused: focusedPassword,
                                      })}
                                    >
                                      <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                          <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                          </InputGroupText>
                                        </InputGroupAddon>
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON
                                              .PASSWORD
                                          }
                                          type="password"
                                          name="password"
                                          onFocus={() =>
                                            setfocusedPassword(true)
                                          }
                                          onBlur={() =>
                                            setfocusedPassword(false)
                                          }
                                        />
                                      </InputGroup>
                                      {errors.password && touched.password && (
                                        <div className="invalid-feedback">
                                          {errors.password}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                }
                                {
                                  <Col lg="12">
                                    <FormGroup
                                      className={classnames({
                                        focused: focusedConfirmPassword,
                                      })}
                                    >
                                      <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                          <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                          </InputGroupText>
                                        </InputGroupAddon>
                                        <Field
                                          className="form-control"
                                          placeholder={
                                            languageState.translation.COMMON
                                              .CONFIRM_PASSWORD
                                          }
                                          type="password"
                                          name="confirmPassword"
                                          onFocus={() =>
                                            setfocusedConfirmPassword(true)
                                          }
                                          onBlur={() =>
                                            setfocusedConfirmPassword(false)
                                          }
                                        />
                                      </InputGroup>
                                      {errors.confirmPassword &&
                                        touched.confirmPassword && (
                                          <div className="invalid-feedback">
                                            {errors.confirmPassword}
                                          </div>
                                        )}
                                    </FormGroup>
                                  </Col>
                                }
                                {/* <div className="text-muted font-italic">
         <small>
           password strength:{' '}
           <span className="text-success font-weight-700">
             strong
           </span>
         </small>
       </div> */}
                                <Col lg="12">
                                  <Row className="my-4">
                                    <Col xs="12">
                                      <div className="custom-control custom-control-alternative custom-checkbox">
                                        <input
                                          className="custom-control-input"
                                          id="customCheckRegister"
                                          type="checkbox"
                                          onChange={() => onChangePrivacy()}
                                        />
                                        <label
                                          className="custom-control-label h-auto"
                                          htmlFor="customCheckRegister"
                                        >
                                          <span className="text-muted text-prewrap">
                                            {
                                              languageState.translation.AUTH
                                                .SIGNUP.AGREE_GENERAL_CONDITIONS
                                            }

                                          </span>
                                        </label>
                                        <a
                                          className='ml-1 text-muted text-danger text-underline text-prewrap'
                                          target="_blank"
                                          href="https://extraexpertise.be/algemene-voorwaarden.html"

                                        >
                                          {
                                            languageState.translation.COMMON
                                              .GENERAL_CONDITIONS
                                          }

                                        </a>

                                        <span className="text-muted text-prewrap">
                                          {
                                            languageState.translation.AUTH
                                              .SIGNUP.AGREE_PRIVACY_POLICY
                                          }

                                        </span>

                                        <a
                                          className='ml-1 text-muted text-prewrap text-danger text-underline'
                                          target="_blank"
                                          href="https://extraexpertise.be/privacy-verklaring.html"

                                        >
                                          {
                                            languageState.translation.COMMON
                                              .PRIVARY_POLICY
                                          }

                                        </a>

                                      </div>
                                      {showPrivacy && (
                                        <div className="invalid-feedback text-prewrap">
                                          {
                                            languageState.translation
                                              .FORM_VALIDATION.PRIVACY_POLICY
                                          }
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg="12">
                                  <div className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                      className="custom-control-input"
                                      id="customCheckRegisterGDPR"
                                      type="checkbox"
                                      onChange={() => onChangeGDPR()}
                                    />
                                    <label
                                      className="custom-control-label h-auto"
                                      htmlFor="customCheckRegisterGDPR"
                                    >
                                      <span className="text-muted text-prewrap">
                                        {languageState.translation.AUTH.SIGNUP.GDPR}
                                      </span>
                                    </label>
                                  </div>
                                  {agreeAcceptEmail && (
                                    <div className="invalid-feedback text-prewrap">
                                      {
                                        languageState.translation
                                          .FORM_VALIDATION.GDPR
                                      }
                                    </div>
                                  )}
                                </Col>
                                <Col lg="12">
                                  <div className="text-center">
                                    <Button
                                      className="mt-4"
                                      color="success"
                                      type="button"
                                      onClick={() => handleSubmit()}
                                    >
                                      {
                                        languageState.translation.AUTH.SIGNUP
                                          .CREATE_ACCOUNT
                                      }
                                    </Button>
                                  </div>
                                </Col>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                        <Row className="mt-3">
                          <Col xs="12">
                            <small>
                              {languageState.translation.AUTH.SIGNIN.SIGNIN}{' '}
                            </small>
                            <Link
                              className="text-danger"
                              to="/login"
                              onClick={(e) => history.push('/login')}
                            >
                              {' '}
                              <small> {languageState.translation.AUTH.SIGNIN.LOGINLINK}{' '}</small>
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Formik>
              </div>
            </Row>
          </Container>
        </Col>
        <Col lg="3" className="bg-dark d-none">
          <div className="d-flex flex-column gap-1 justify-content-center h-100 text-white">
            <div className="fs-2 mb-2 font-weight-bold mt--6 no-m">
              {languageState.translation.AUTH.SIGNUP.SIDBAR_TITLE}?{' '}
            </div>
            <div>
              <Tick />
              {languageState.translation.AUTH.SIGNUP.SIDBAR_USP1}?{' '}
            </div>
            <div>
              {' '}
              <Tick />
              {languageState.translation.AUTH.SIGNUP.SIDBAR_USP2}?{' '}
            </div>
            <div>
              {' '}
              <Tick />
              {languageState.translation.AUTH.SIGNUP.SIDBAR_USP3}?{' '}
            </div>
            <div>
              {' '}
              <Tick />
              {languageState.translation.AUTH.SIGNUP.SIDBAR_USP4}?{' '}
            </div>
            <div>
              {' '}
              <Tick />
              {languageState.translation.AUTH.SIGNUP.SIDBAR_USP5}?{' '}
            </div>
            <div>
              {' '}
              <Tick />
              {languageState.translation.AUTH.SIGNUP.SIDBAR_USP6}?{' '}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
