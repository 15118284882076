import * as yup from 'yup';
import moment from 'moment';
const schema = (validationMessages) =>
  yup.object({
    firstName: yup
      .string()
      .min(
        2,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      ).max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      ).required(validationMessages.REQUIRED_FIELD),
    lastName: yup.string().required(validationMessages.REQUIRED_FIELD),
    email: yup
      .string()
      .email(validationMessages.EMAIL)
      .required(validationMessages.REQUIRED_FIELD),
    company: yup.string().required(validationMessages.REQUIRED_FIELD),
    functionTitle: yup.string().required(validationMessages.REQUIRED_FIELD),
    startDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue ? moment(originalValue, 'DD-MM-YYYY', true).toDate() : null;
      })
      .typeError(validationMessages.INVALID_DATE)
      .test(
        'is-valid-date',
        validationMessages.INVALID_DATE,
        function (value) {
          return !value || moment(value, 'DD-MM-YYYY', true).isValid();
        }
      )
      .test(
        'is-before-end-date',
        validationMessages.START_DATE_BEFORE_END_DATE,
        function (value) {
          const { endDate } = this.parent;
          return !endDate || !moment(endDate, 'DD-MM-YYYY', true).isValid() || moment(value).isSameOrBefore(moment(endDate));
        }
      ),
    endDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue ? moment(originalValue, 'DD-MM-YYYY', true).toDate() : null;
      })
      .typeError(validationMessages.INVALID_DATE)
      .test(
        'is-valid-date',
        validationMessages.INVALID_DATE,
        function (value) {
          return !value || moment(value, 'DD-MM-YYYY', true).isValid();
        }
      ),
  });

export default schema;
