import TYPES from './types';
import reducer from './reducer';

const INITIAL_STATE = {
  notifications: null,
};

const NOTIFICATION_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default NOTIFICATION_STATE;
