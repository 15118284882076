import * as yup from 'yup';
const schema = (validationMessages) =>
  yup.object({
    name: yup.string().required(validationMessages.REQUIRED_FIELD),
    // startDate: yup.string().required(validationMessages.REQUIRED_FIELD),
    // endDate: yup.string().required(validationMessages.REQUIRED_FIELD),

    endDate: yup.date().min(yup.ref('startDate'), validationMessages.END_DATE),
  });
export default schema;
