import TYPES from './skills.types';
import reducer from './skills.reducer';

const INITIAL_STATE = {
  skills: null,
  mine: null,
};

const SKILLS_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default SKILLS_STATE;
