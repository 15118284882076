import React, { useContext, useEffect } from 'react';
import { ModalBody, Row, Col, Button, ModalFooter, Card, CardBody } from 'reactstrap';
import { AppContext } from 'store/app.context';
import STEPPER_STATE from 'store/stepper';
import { toast } from 'react-toastify';

const ThirdStepperScreen = ({ actionAfterOnboarding, setActionAfterOnboarding }) => {
  const { dispatchStepperState, languageState } = useContext(AppContext);

  useEffect(() => {
    if (actionAfterOnboarding) {
      thirdStepperSubmitHandler()
    }

  }, [actionAfterOnboarding])

  const thirdStepperSubmitHandler = async () => {
    if (!actionAfterOnboarding) {
      toast.warning(languageState.translation.STEPPER.THIRD_STEP_PLACEHOLDERS_FR.WARNING_SELECT_GOAL, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return
    }

    dispatchStepperState({
      type: STEPPER_STATE.types.CHANGE_CURRENT_STEPPER_STATE,
      currentStepPayload: 3,
    });
  };

  return (
    <>
      {/* style={{ maxHeight: '70vh', overflowY: 'auto' }} */}
      <ModalBody className='pt-0'>
        <Row>
          <Col xs="12" sm="12" md="12" lg="4" xl="4" className='mb-4'>
            <Card className={`h-100 ${actionAfterOnboarding === 1 ? 'third-step-card-active' : "third-step-card"}`}
              onClick={() => {
                setActionAfterOnboarding((prevValue) => prevValue === 1 ? null : 1)
              }}>
              <CardBody className="p-0">
                <div className="font-weight-bold text-center third-step-header-card">
                  {languageState.translation.STEPPER.THIRD_STEP_PLACEHOLDERS_FR.TITLE_CARD_1}
                </div>
                <div className="third-step-content-card">
                  {languageState.translation.STEPPER.THIRD_STEP_PLACEHOLDERS_FR.DESCRIPTION_CARD_1}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="12" lg="4" xl="4" className='mb-4'>
            <Card className={`h-100 ${actionAfterOnboarding === 2 ? 'third-step-card-active' : "third-step-card"}`}
              onClick={() => {
                setActionAfterOnboarding((prevValue) => prevValue === 2 ? null : 2)
              }}>
              <CardBody className="p-0">
                <div className="font-weight-bold text-center third-step-header-card">
                  {languageState.translation.STEPPER.THIRD_STEP_PLACEHOLDERS_FR.TITLE_CARD_2}
                </div>
                <div className="third-step-content-card">
                  {languageState.translation.STEPPER.THIRD_STEP_PLACEHOLDERS_FR.DESCRIPTION_CARD_2}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="12" lg="4" xl="4" className='mb-4'>
            <Card className={`h-100 ${actionAfterOnboarding === 3 ? 'third-step-card-active' : "third-step-card"}`}
              onClick={() => {
                setActionAfterOnboarding((prevValue) => prevValue === 3 ? null : 3)
              }}>
              <CardBody className="p-0">
                <div className="font-weight-bold text-center third-step-header-card">
                  {languageState.translation.STEPPER.THIRD_STEP_PLACEHOLDERS_FR.TITLE_CARD_3}
                </div>
                <div className="third-step-content-card">
                  {languageState.translation.STEPPER.THIRD_STEP_PLACEHOLDERS_FR.DESCRIPTION_CARD_3}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='mb-2 d-flex justify-content-end'>
        <Button
          className="btn-next-link"
          color="link"
          onClick={() => {
            // handle Submit form
            thirdStepperSubmitHandler();
          }}
        >
          {languageState.translation.COMMON.ONBOARDING_BTN_STEP2_NEXT}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ThirdStepperScreen;
