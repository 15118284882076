import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Card, CardBody, Col, Container, Row, CardHeader, Button, Input, FormGroup, InputGroup, ListGroup, ListGroupItem, Badge, Label } from 'reactstrap';
import { AppContext } from 'store/app.context';
import 'rc-slider/assets/index.css';
import { TIME_SLOTS } from 'config/const';
import useSettingsService from 'services/settings/settings.service';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import useUserService from 'services/profile/user.service';
import Availability from 'components/availability/availability';
import Select from 'react-select';
import { professions } from 'components/modalStepper/onboarding.constant';
import ReactSelect from 'react-select';
import { statuses } from 'components/modalStepper/onboarding.constant';

const Availibilty = () => {
  const [category, setCategory] = useState('');
  const [days, setDays] = useState([]);
  const [daysRange, setDaysRange] = useState([]);
  const [myAvailibility, setMyAvailibility] = useState([]);
  const [workTime, setWorkTime] = useState('FULL_TIME');
  const [profession, setProfession] = useState('')
  const [extraInfo, setExtraInfo] = useState('');
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [startHolidaysDate, setStartHolidaysDate] = useState(new Date());
  const [endHolidaysDate, setEndHolidaysDate] = useState(new Date());
  const [holidays, setHolidays] = useState([]);
  const [statusAviability, setStatusAviability] = useState();
  const { languageState, userState } = useContext(AppContext);
  const [options] = useState(professions.map(el => ({ value: el, label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[el] })))
  const [errorDates, setErrorDates] = useState('')

  const { addAvailibility, getMyAvailibility, deleteAvalibility, getHolidays, addHoliday, deleteHoliday } = useSettingsService();
  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } = useUserService();

  const onDeleteAvailibility = async (id) => {
    const result = await deleteAvalibility(id);
    if (result && result.status === 200) {
      getAvailibilties();
    }
  };

  const onDayClick = (day) => {
    const isDayExist = days.find((item) => item == day);
    const clickedDay = daysRange.find((item) => item.day === day);
    if (isDayExist && clickedDay) {
      const filtredDays = days.filter((item) => item != day);
      const filteredDaysRange = daysRange.filter((item) => item.day !== day);
      setDaysRange(filteredDaysRange);
      setDays(filtredDays);
      if (clickedDay.id) {
        onDeleteAvailibility(clickedDay.id);
      }
    } else {
      setDays([...days, day]);
      const dayConfig = {
        day,
        range: [5, 45],
      };
      setDaysRange([...daysRange, dayConfig]);
    }
  };

  const onRangeChange = (day, range) => {
    const pureRange = Array.from(new Set(range));
    const dayConfig = {
      day,
      range: pureRange,
    };
    const filteredDaysRange = daysRange.filter((item) => item.day !== day);

    setDaysRange([...filteredDaysRange, dayConfig]);
  };

  const onDeleteHoliday = async (id) => {
    const result = await deleteHoliday(id);
    if (result && result.status === 200) {
      getMyHolidays();
    }
  };

  const getMyHolidays = async () => {
    const result = await getHolidays();
    if (result && result.status === 200) {
      setHolidays(result.data);
    }
  };

  const getAvailibilties = async () => {
    const result = await getMyAvailibility();
    if (result && result.status === 200) {
      const onlyWorkingDays = result.data.filter(
        (item) => item.status === 'ON_WORK'
      );

      // Working days
      const days = [];
      const daysConfig = [];
      onlyWorkingDays.forEach((item) => {
        if (!days.includes(item.day)) {
          days.push(item.day);
          const dayConfig = {
            id: item.id,
            day: item.day,
            range: item.workdiary,
          };
          daysConfig.push(dayConfig);
        }
      });
      setDays(days);
      setMyAvailibility(daysConfig);
    }
  };

  const addNewHoliday = async (payload) => {
    const result = await addHoliday(payload);
    if (result && result.status === 201) {
      getMyHolidays();
    }
  };

  const onAddNewHoliday = () => {
    if (endHolidaysDate > new Date() && startHolidaysDate >= new Date()) {
      setErrorDates('')
      const reqData = {
        startDate: startHolidaysDate.toISOString(),
        endDate: endHolidaysDate.toISOString(),
      };
      addNewHoliday(reqData);
    } else {
      endHolidaysDate <= new Date() && startHolidaysDate < new Date() ?
        setErrorDates(languageState.translation.COMMON.END_START_DATE_ERROR) :
        endHolidaysDate <= new Date() ?
          setErrorDates(languageState.translation.COMMON.END_DATE_ERROR) :
          startHolidaysDate < new Date() ?
            setErrorDates(languageState.translation.COMMON.START_DATE_ERROR) :
            setErrorDates('')
    }
  };

  const getMyWorkingDiaries = async () => {
    const result = await getMyAvailibility();
    if (result && result.data && result.status === 200) {
      // const days = [];
      // const daysConfig = [];
      // result.data.forEach((item) => {
      //   console.log(new Date(item.workDiary[0]).getHours());
      //   days.push(item.day);
      //   const dayConfig = {
      //     day: item.day,
      //     range: 'ok', // HERE
      //   };
      //   daysConfig.push(dayConfig);
      // });
      // setDays(days);
      // setMyAvailibility(daysConfig);
    }
  };

  useEffect(() => {
    if (userState && userState.userInfo) {
      setExtraInfo(userState.userInfo.extraInfo || '');
      setWorkTime(userState.userInfo.workingTime || 'FULL_TIME');
      setProfession(userState.userInfo.profession || '')
      setCategory(userState.userInfo.workType || '');
      setStatusAviability(userState.userInfo.statusAviability || undefined);
      getMyHolidays();
      getAvailibilties();
    }
  }, [userState]);

  useEffect(() => {
    if (myAvailibility && myAvailibility.length) {
      const daysRange = [];
      myAvailibility.forEach((item) => {
        const dayRange = {
          id: item.id,
          day: item.day,
          range: getDayRange(item.day),
        };
        daysRange.push(dayRange);
      });
      setDaysRange(daysRange);
    }
  }, [holidays, days, myAvailibility]);

  useEffect(() => {
    if (workTime === 'PART_TIME') {
      getMyWorkingDiaries();
    }
  }, [workTime]);

  const updateWorkingTime = async (workingTime) => {
    setWorkTime(workingTime);
    const reqData = {
      workingTime,
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
    }
  };

  const updateProfession = async (profession) => {
    setProfession(profession);
    const reqData = {
      profession,
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
    }
  };

  const updateWorkType = async (workType) => {
    setCategory(workType);
    const reqData = {
      workType,
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
    }
  };
  const updateStatusAviability = async (status) => {
    setStatusAviability(status);
    const reqData = {
      statusAviability: status,
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
    }
  };
  const updateExtraInfo = async () => {
    const reqData = {
      extraInfo,
      workingTime: workTime,
    };
    const result2 = await updateBasicInfos(reqData);
    if (result2 && result2.status === 200) {
      autoUpdateUserInfo();
      getUserInfo()
    }
  };

  const addWorkDiary = async (payload) => {
    const result = await addAvailibility(payload);

    if (result && result.status === 201) {
    }
  };

  const saveWorkDiary = () => {
    daysRange.forEach((item) => {
      const slotsNumber = item.range.length;
      let start = [];
      let end = [];
      if (slotsNumber === 4) {
        const firstStart = new Date();
        const secondStart = new Date();
        const firstStop = new Date();
        const secondStop = new Date();
        firstStart.setHours(+TIME_SLOTS[item.range[0]] + 1);
        firstStart.setMinutes(0);
        firstStart.setSeconds(0);
        secondStart.setHours(+TIME_SLOTS[item.range[2]] + 1);
        secondStart.setMinutes(0);
        secondStart.setSeconds(0);
        firstStop.setHours(+TIME_SLOTS[item.range[1]] + 1);
        firstStop.setMinutes(0);
        firstStop.setSeconds(0);
        secondStop.setHours(+TIME_SLOTS[item.range[3]] + 1);
        secondStop.setMinutes(0);
        secondStop.setSeconds(0);

        start.push(firstStart.toISOString());
        start.push(secondStart.toISOString());
        end.push(firstStop.toISOString());
        end.push(secondStop.toISOString());
      } else {
        if (item.range.length === 3) {
          item.range = item.range.filter((item, index) => index !== 1);
        }
        const firstStart = new Date();
        const firstStop = new Date();
        firstStart.setHours(+TIME_SLOTS[item.range[0]] + 1);
        firstStop.setHours(+TIME_SLOTS[item.range[1]] + 1);
        firstStart.setMinutes(0);
        firstStart.setSeconds(0);
        firstStop.setMinutes(0);
        firstStop.setSeconds(0);
        start.push(firstStart.toISOString());
        end.push(firstStop.toISOString());
      }
      let workDiary = [];
      start.forEach((item, index) => {
        const slot = {
          start: item,
          end: end[index],
          isWorkingHours: true,
        };
        workDiary.push(slot);
      });

      const reqData = {
        day: item.day,
        status: 'ON_WORK',
        extraInfo: 'string',
        workDiary,
      };

      addWorkDiary(reqData);
    });
  };

  const getDayRange = (day) => {
    const dayConfig = myAvailibility.find((item) => item.day === day);
    if (dayConfig) {
      const slotsNumber = dayConfig.range.length;
      const keys = Object.keys(TIME_SLOTS);
      const items = Object.values(TIME_SLOTS);

      if (slotsNumber) {
        const startDate = new Date(dayConfig.range[0].start).getHours() - 1;
        const endDate = new Date(dayConfig.range[0].end).getHours() - 1;
        const startIndex = items.findIndex((item) => +item === startDate);
        const endIndex = items.findIndex((item) => +item === endDate);

        if (slotsNumber === 1) {
          return [
            +keys[startIndex],
            +keys[endIndex],
            +keys[endIndex],
            +keys[endIndex],
          ];
        } else {
          const secondStartDate =
            new Date(dayConfig.range[1].start).getHours() - 1;
          const secondEndDate = new Date(dayConfig.range[1].end).getHours() - 1;
          const secondStartIndex = items.findIndex(
            (item) => +item === secondStartDate
          );
          const secondEndIndex = items.findIndex(
            (item) => +item === secondEndDate
          );
          return [
            +keys[startIndex],
            +keys[endIndex],
            +keys[secondStartIndex],
            +keys[secondEndIndex],
          ];
        }
      }
    }
    return [10, 45, 45, 45];
  };

  const categories = [
    { value: "CONSULTANT", label: languageState.translation.COMMON.CONSULTANT },
    { value: "FREELANCER", label: languageState.translation.COMMON.FREELANCER }
  ]

  const workTimes = [
    { value: "FULL_TIME", label: languageState.translation.COMMON.FULL_TIME },
    { value: "PART_TIME", label: languageState.translation.COMMON.PART_TIME }
  ]

  return (
    <>
      <Container className="mt-3" fluid>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.CONNECT.CURRENT_STATUS}
            </h3>
          </CardHeader>
          <CardBody>
            <ReactSelect
              className="w-100"
              placeholder={languageState.translation.STEPPER.FIRST_STEP_PLACEHOLDERS.AVAIBLITY}
              options={statuses.map((el) => {
                return {
                  label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[el],
                  value: el,
                }
              })}
              value={statusAviability && languageState.translation.STEPPER.FIRST_STEP_OPTIONS[statusAviability] ? {
                label: languageState.translation.STEPPER.FIRST_STEP_OPTIONS[statusAviability],
                value: statusAviability
              } : null}
              onChange={({ value }) => {
                updateStatusAviability(value)
              }}
            />
          </CardBody>
        </Card>
        <Card className="d-none">
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.FREELANCE_TYPE_CONSIDERATION}{' '}
            </h3>
          </CardHeader>
          <CardBody>
            <Select
              isClearable
              isSearchable
              className="basic-multi-select mt-2 mb-2"
              classNamePrefix="select"
              id='category'
              name="category"
              onChange={(e) => updateWorkType(e.value)}
              options={categories}
              defaultValue={categories.filter(el => el.value === category)[0]}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {' '}
              {languageState.translation.SETTINGS.WORK_AS}{' '}
            </h3>
          </CardHeader>
          <CardBody>
            <Select
              isClearable
              isSearchable
              className="basic-multi-select mt-2 mb-2"
              classNamePrefix="select"
              id='category'
              name="category"
              placeholder={languageState.translation.COMMON.SELECT_TIME}
              onChange={(e) => updateWorkingTime(e.value)}
              options={workTimes}
              value={workTimes.find(el => el.value === workTime)}
            />
          </CardBody>
        </Card>
        {workTime === 'PART_TIME' && (
          <React.Fragment>
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.SETTINGS.DAYS_OF_WEEK}
                </h3>
              </CardHeader>
              <CardBody>
                <Availability days={myAvailibility} />
              </CardBody>
            </Card>
          </React.Fragment>
        )}
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.PROFESSION}
            </h3>
          </CardHeader>
          <CardBody>
            <Select
              isClearable
              isSearchable
              className="basic-multi-select mt-2 mb-2"
              classNamePrefix="select"
              id='category'
              name="category"
              placeholder={languageState.translation.COMMON.SELECT_PROFESSION}
              onChange={(e) => updateProfession(e.value)}
              options={options}
              value={options.filter(el => el.value === profession)[0]}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.SETTINGS.YEARLY_HOLIDAYS}
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              {holidays &&
                holidays.length > 0 && holidays.map((item) => (
                  <ListGroup className="mx-auto mb-2" key={item.id}>
                    <ListGroupItem className="m-auto d-flex justify-content-between align-items-center">
                      <span>
                        <span>
                          {' '}
                          {languageState.translation.COMMON.FROM + ':'}{' '}
                        </span>
                        <span className="fw-600">
                          {moment(item.startDate).format('DD-MM-yyyy')}{' '}
                        </span>
                      </span>
                      <span className="ml-4">
                        <span>
                          {languageState.translation.COMMON.TO + ':'}{' '}
                        </span>
                        <span className="fw-600">
                          {moment(item.endDate).format('DD-MM-yyyy')}{' '}
                        </span>
                      </span>
                      <Badge
                        color="danger"
                        className="ml-4 c-pointer"
                        pill
                        onClick={() => onDeleteHoliday(item.id)}
                      >
                        x
                      </Badge>
                    </ListGroupItem>
                  </ListGroup>
                ))}
            </Row>
            <Label className="mt-4 mb-3 fw-700  fs-2">
              {languageState.translation.SETTINGS.ADD_HOLIDAYS + ' :'}
            </Label>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className=" form-control-label">
                    {languageState.translation.COMMON.START_DATE}{' '}
                    <span className="invalid-feedback">*</span>
                  </label>
                  <InputGroup
                    className={classnames('input-group-merge', {
                      focused: startDate,
                    })}
                  >
                    <ReactDatetime
                      closeOnSelect
                      initialValue={new Date()}
                      inputProps={{
                        placeholder:
                          languageState.translation.COMMON.START_DATE,
                      }}
                      onChange={(date) => setStartHolidaysDate(date)}
                      timeFormat={false}
                      dateFormat={'DD-MM-YYYY'}
                      onFocus={(e) => setStartDate(true)}
                      onBlur={(e) => setStartDate(false)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label className=" form-control-label">
                    {languageState.translation.COMMON.END_DATE}{' '}
                    <span className="invalid-feedback">*</span>
                  </label>
                  <InputGroup
                    className={classnames('input-group-merge', {
                      focused: endDate,
                    })}
                  >
                    <ReactDatetime
                      closeOnSelect
                      initialValue={new Date()}
                      inputProps={{
                        placeholder: languageState.translation.COMMON.END_DATE,
                      }}
                      onChange={(date) => setEndHolidaysDate(date)}
                      timeFormat={false}
                      dateFormat={'DD-MM-YYYY'}
                      onFocus={(e) => setEndDate(true)}
                      onBlur={(e) => setEndDate(false)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {
                errorDates !== '' && (
                  <Col>
                    <span className='invalid-feedback font-weight-bold '>{errorDates}</span>
                  </Col>
                )
              }
            </Row>
            <Button
              className="mt-4 d-block ml-auto mb-4 btn-exprimary"
              color="primary"
              onClick={() => onAddNewHoliday()}
            >
              {languageState.translation.COMMON.BUTTONS.ADD}
            </Button>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h3 className="mb-0">
              {languageState.translation.COMMON.EXTRA_INFO}
            </h3>
          </CardHeader>
          <CardBody>
            <Input
              id="exampleFormControlTextarea1"
              rows="3"
              type="textarea"
              value={extraInfo}
              placeholder={
                languageState.translation.PLACEHOLDERS.EXTRA_INFO_PLACEHOLDER
              }
              onChange={(e) => setExtraInfo(e.target.value)}
            />{' '}
            <Button
              color="primary"
              className="mt-4 d-block ml-auto mb-4 btn-exprimary"
              onClick={() => updateExtraInfo()}
            >
              {languageState.translation.COMMON.BUTTONS.SAVE}
            </Button>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Availibilty;
