import React, { useContext } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import USER_STATE from 'store/user';
import AuthHeader from 'components/layout/header/auth-header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import { Formik, Field } from 'formik';
import schema from './schema';
import useAuthService from 'services/auth/auth.service';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const history = useHistory();
  const { languageState, dispatchUserState } = useContext(AppContext);
  const { forgotPassword } = useAuthService();
  const [focusedEmail, setfocusedEmail] = React.useState(false);

  const initialValues = {
    email: '',
  };

  const submitForm = async (values) => {
    const result = await forgotPassword(values.email);
    if (result && (result.status === 201 || result.status === 200)) {
      toast.success(languageState.translation.AUTH.FORGOT_PWD.REQUEST_SUCCESS, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push('/login');
    }
  };

  return (
    <div className="auth-container">
      <AuthHeader
        title={languageState.translation.AUTH.FORGOT_PWD.RESET_PASSWORD}
        lead="Use these awesome forms to login or create new account in your project for free."
      />

      <Container className="mt--8 pb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={(values) => submitForm(values)}
        >
          {({ errors, touched, values, handleSubmit }) => (
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 ">
                      <h2>Paswoord vergeten? Vul onderstaand formulier in</h2>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>
                        {languageState.translation.AUTH.FORGOT_PWD.TYPE_EMAIL}
                      </small>
                    </div>
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          className="form-control"
                          placeholder={languageState.translation.COMMON.EMAIL}
                          name="email"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                        />
                      </InputGroup>
                      {errors.email && touched.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        className="my-4 btn-exprimary"
                        color="info"
                        onClick={() => handleSubmit()}
                        type="button"
                      >
                        {languageState.translation.COMMON.SUBMIT}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link
                      className="text-dark"
                      to="/login"
                      onClick={(e) => history.push('/login')}
                    >
                      <small>
                        {languageState.translation.AUTH.SIGNIN.SIGNIN}
                      </small>
                    </Link>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link
                      className="text-dark"
                      to="/register"
                      onClick={(e) => history.push('/register')}
                    >
                      <small>
                        {
                          languageState.translation.AUTH.SIGNIN
                            .CREATE_NEW_ACCOUNT
                        }
                      </small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default ForgotPassword;
