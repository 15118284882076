import React, { useContext } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    CardHeader,
    CardImg,
    CardTitle,
    CardText,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    ButtonGroup,
} from 'reactstrap';
import { AppContext } from 'store/app.context';
import IframeResizer from 'iframe-resizer-react'



const FreelancerDeals = () => {
    const { languageState } = useContext(AppContext);

    return (<>
        <div > Overview of all the dealsfor our members < /div>
 <p>In the future this section will contain interesting offers for freelancers and companies </p>
        </>
    );
};



export default FreelancerDeals;
