import BreadcrumbComponent from 'components/breadcrumb';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import { IMG_PREFIX } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Button, Col, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import useInvitationService from 'services/invitations/invitation.service';
import { AppContext } from 'store/app.context';
import { isAuthorized } from 'utils';

const CardHeaderConnect = ({ name, iduser, user }) => {
  const { languageState, userState } = useContext(AppContext);
  const { sendInvitation, checkIfInvitationIsSent } = useInvitationService();
  const [invSent, SetInvSent] = useState();
  const [isInvitationSent, setIsInvSent] = useState(false);
  const history = useHistory();

  const handleSendInivtation = async () => {
    if (!isAuthorized()) {
      history.push('/login');
      return;
    }
    const result = await sendInvitation(iduser);
    if (result && result.status === 201) {
      toast.success(languageState.translation.ALERT.SUCCESS_INVITATION, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setIsInvSent(true);
      checkifInvitation();
    }
  };
  useEffect(() => {
    if (!isAuthorized()) return;
    checkifInvitation();
    return () => {
      // to change the title of browser page
      document.title = 'ExtraExpertise';
    }
  }, []);
  const checkifInvitation = async () => {
    const result = await checkIfInvitationIsSent(iduser);
    if (result && result.status === 200) {
      SetInvSent(result.data.status);
    }
  };
  const formatNumber = (phoneNumber) => {
    if (!isAuthorized()) {
      return '';
    }
    if (phoneNumber) {
      let ch = phoneNumber.substring(phoneNumber.length, phoneNumber.length - 2);
      ch = phoneNumber.substring(phoneNumber.length - 2, phoneNumber.length - 4) + ' ' + ch;
      ch = phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length - 6) + ' ' + ch;
      ch = phoneNumber.substring(phoneNumber.length - 6, 0) + ' ' + ch;
      return ch.replace('+32', '0');
    }
    return '';
  };

  const makeCall = () => {
    window.location.href = "whatsapp://send?phone=" + formatNumber(user?.phoneNumber);
  }

  return (
    <>
      <div className="header pb-6 rounded px-3 shadow">
        <div className='return-overview-page-link' >
          <BreadcrumbComponent breadCrumbActive={name} breadCrumbLinks={[{ label: languageState.translation.COMMON.RETURN_TO_OVERVIEW, link: APP_ROUTES.CONNECT.ALL_NETWORK, type: 'return_to_previous' }]} />
        </div>
        <Container
          className="d-flex justify-content-between pt-7"
          fluid
        >
          <Col className="d-flex" lg="8" md="8" sm="12">
            <div>
              <h1 className="display-2 mb-3 text-white">{name}</h1>
              {user?.profession && <h2 className="mb-3 text-white">{languageState.translation.STEPPER.FIRST_STEP_OPTIONS[user.profession]}</h2>}
              {!history.location.pathname.includes('/public') ? ((userState?.userInfo?.kind === 'COMPANY' || userState?.userInfo?.kind === 'COMPANY') || (userState.userInfo.id === user.id)) && user?.phoneNumber && (
                <div className="btn-secondary text-blue pl-2 pr-2 pt-1 pb-1 rounded display-4 text-center phone-number-text" onClick={makeCall}>
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  {formatNumber(user?.phoneNumber)}
                </div>
              ) : null}
              <div className='my-2'></div>
              {
                user.region
                  ? <small className="text-muted d-flex align-items-center mt-2" style={{ gap: 5 }}>
                    <i className="ni ni-world" />
                    {user.city
                      ? <label>{`${user.city},`}</label>
                      : null
                    }
                    <label>{user.region}</label>
                  </small>
                  : null
              }
              {user?.workingTime && (
                <p className="text-white mt-2">
                  <span className="font-weight-bold font-small-4">{languageState.translation.COMMON.TYPE} :</span>{' '}
                  {user?.workingTime === 'FULL_TIME'
                    ? 'Full Time'
                    : 'Part Time'}{' '}
                  {languageState.translation.COMMON.FREELANCER}
                </p>
              )}
              {!history.location.pathname.includes('/public') ? ((userState?.userInfo?.kind === 'ADMIN') || (userState?.userInfo?.kind === 'COMPANY') || (userState.userInfo.id === user.id)) && user?.resume && (
                <p className="text-white mt-2">
                  <span className="font-weight-bold font-small-4">CV :</span>{' '}
                  <Badge className='bg-primary-light'>
                    <i className='fa fa-link mr-2' />
                    <a className='text-primary' target='_blank' href={`${IMG_PREFIX}${user.resume}`}>{user.resume}</a>
                  </Badge>
                </p>
              ) : null}
            </div>
          </Col>
          <Col lg="4" md="4" sm="12">
            {userState?.userInfo?.id !== iduser && <div className="d-flex flex-column">
              <Button
                className="mb-2 mr-0"
                color={'secondary'}
                onClick={(e) => handleSendInivtation()}
                disabled={invSent === 'PENDING' || invSent === 'ACCEPTED' || userState?.userInfo?.id === iduser}
              >
                {invSent === 'PENDING'
                  ? languageState.translation.CONNECT.PENDING
                  : invSent === 'ACCEPTED'
                    ? languageState.translation.CONNECT.CONNECTED
                    : languageState.translation.CONNECT.CONNECT_WITH +
                    name.toUpperCase()}{' '}
              </Button>
              {isAuthorized() && (
                <Button
                  className="mb-2"
                  color={'secondary'}
                  onClick={(e) =>
                    history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
                      user: user,
                    })
                  }
                  disabled={invSent !== 'ACCEPTED' && (userState?.userInfo?.kind !== 'ADMIN' && userState?.userInfo?.kind !== 'COMPANY')}
                >
                  {languageState.translation.CONNECT.CHAT_WITH}
                  {` ${name.toUpperCase()}`}
                </Button>
              )}
            </div>}
          </Col>
        </Container>
        <Modal isOpen={isInvitationSent} centered>
          <ModalHeader>
            <h3>{languageState.translation.CONNECT.CONNECTION_SENT}</h3>
          </ModalHeader>
          <ModalBody>
            <div>
              <div
                style={{
                  lineHeight: '2rem',
                  marginBottom: '1.5rem',
                }}
              >
                {languageState.translation.CONNECT.WHY_CONNECT}
              </div>
              <div
                style={{
                  lineHeight: '2rem',
                  marginBottom: '1.5rem',
                }}
              >
                {languageState.translation.CONNECT.WHY_CONNECT_BODY}
              </div>
            </div>

            <Button
              color="primary"
              onClick={() => history.push('/private/connect/network')}
            >
              {languageState.translation.CONNECT.LIST_OF_FREELANCERS}
            </Button>
            <Button onClick={() => setIsInvSent(false)}>
              {languageState.translation.COMMON.CLOSE}
            </Button>
          </ModalBody>
        </Modal>
      </div >
    </>
  );
};

export default CardHeaderConnect;
