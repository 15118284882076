import TYPES from './educations.types';
import reducer from './educations.reducer';

const INITIAL_STATE = {
  educations: null,
};

const EDUCATIONS_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default EDUCATIONS_STATE;
