import React from 'react';
import Calendar from 'components/calendar/index';

const HomeCalendar = () => {
  return (
    <div>
      <Calendar />
    </div>
  );
};

export default HomeCalendar;
