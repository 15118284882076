import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import classnames from 'classnames';
import ChatHeader from 'components/chat/ChatHeader';
import Files from 'components/chat/files';
import Message from 'components/chat/Message';
import { AppContext } from 'store/app.context';
import { ChatSocketContext } from 'store/chat.context';
import { CHAT_EVENTS } from 'config/core';
import useChatService from 'services/chat/chat.service';
import moment from 'moment';
import { useRef } from 'react';
import CHAT_STATE from 'store/chat';
import { getRoomName } from 'utils';
import { markRoomAsSeen } from 'utils';
import { isOnline } from 'utils';
import { getTargetUserRoom } from 'utils';
import useUserService from 'services/profile/user.service';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const Conversation = ({ room }) => {
  const history = useHistory()
  const { languageState, chatState, userState, dispatchChatState } = useContext(AppContext);
  const { sendNotificationMessage } = useUserService();
  const [textValue, setTextValue] = useState('');
  const [isFilePage, setisFilePage] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isImportant, setIsImportant] = useState(false);

  useEffect(() => {
    if (history && history.location && history.location.state && history.location.state.pathJob) {
      setTextValue(history.location.state.pathJob)
    }
  }, [history])

  const ws = useContext(ChatSocketContext);

  const ref = React.useRef(null);
  const roomRef = useRef(room);

  const { getMessagesByRoom, uploadMessageFile } = useChatService();

  useEffect(() => {
    roomRef.current = room;
    setMessages([]);
    getMessagesByRoom(room?.id);
  }, [room]);

  useEffect(() => {
    ws.on(CHAT_EVENTS.NEW_MESSAGE, (data) => {
      if (roomRef.current.id === data.room.id) {
        pushNewMessageRecieved(data.message);
      }
    });

    ws.on('SEEN_ROOM', (data) => { });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (chatState?.messages) {
      setMessages(chatState?.messages);
    }
  }, [chatState?.messages]);

  const pushNewMessageSent = (message) => {
    const old = [...messages];
    if (!old.length) {
      const msg = {
        day: new Date().toISOString(),
        messages_result: [],
      };
      old.push(msg);
    }
    old[old.length - 1].messages_result.push(message);

    setMessages(old);
    const rooms = [...chatState?.rooms];
    const targetRoom = rooms.find((item) => item.id === room.id);
    targetRoom.messages = [message];
    const targetRoomIndex = rooms.findIndex((item) => item.id === room.id);
    rooms.splice(targetRoomIndex, 1);
  };

  const pushNewMessageRecieved = (message) => {
    setMessages((oldMessages) => {
      const old = [...oldMessages];
      if (!old?.length) {
        const msg = {
          day: new Date().toISOString(),
          messages_result: [],
        };
        old.push(msg);
      }
      old[old.length - 1].messages_result.push(message);
      console.log("history", history);
      if (history?.location?.pathname?.includes('/chat')) {
        dispatchChatState({
          type: CHAT_STATE.types.SET_NEW_MESSAGE_NOTIFICATION,
          newMessage: null,
        });
      }

      return old;
    });
  };

  const sendMessage = () => {
    if (textValue) {
      toast.dismiss();
      const newMessagePayload = {
        room: room?.id,
        content: textValue,
        isImportant
      };
      ws.emit(CHAT_EVENTS.NEW_MESSAGE, newMessagePayload);
      const newMessage = {
        content: textValue,
        author: {
          ...userState?.userInfo,
          id: chatState?.chatUserId,
        },
        newMessage: true,
        createdAt: new Date().toISOString(),
        isImportant
      };

      if (!isOnline(room, chatState?.onlineUsers, chatState?.chatUserId) && isImportant) {
        sendNotificationMessage({
          reciver: getTargetUserRoom(room, chatState?.chatUserId),
          sender: userState?.userInfo,
          message: textValue,
          isImportant
        });
      }
      pushNewMessageSent(newMessage);
      setTextValue('');
    }
  };

  const scrollToBottom = () => {
    if (ref && ref.current) {
      ref.current?.scrollIntoView(false);
      markConversationAsSeen();
    }
  };

  const markConversationAsSeen = () => {
    ws.emit(CHAT_EVENTS.SEEN_ROOM, { room_id: roomRef.current.id });
    markRoomAsSeen(roomRef.current, chatState?.chatUserId);
  };

  const sendFileMessage = async (event) => {
    const result = await uploadMessageFile(event.target.files[0]);
    if (result && result.status === 201) {
      const newMessagePayload = {
        room: room?.id,
        isImportant,
        attachments: [
          {
            type: 'FILE',
            file: result.data[0].id,
          },
        ],
      };
      ws.emit(CHAT_EVENTS.NEW_MESSAGE, newMessagePayload);
      const newMessage = {
        author: {
          ...userState?.userInfo,
          id: chatState?.chatUserId,
        },
        newMessage: true,
        createdAt: new Date().toISOString(),
      };
      pushNewMessageSent(newMessage);
      getMessagesByRoom(room?.id);
    }
  };

  return (
    <Card className=" chat-shadow">
      <CardHeader className="pb-3">
        <ChatHeader room={room} openFiles={() => setisFilePage(true)} />
      </CardHeader>
      <CardBody className="d-flex flex-column gap-3 m-3 contact-message-height overflow-y-auto">
        {isFilePage ? (
          <Files
            goBack={() => setisFilePage(false)}
            roomId={roomRef.current.id}
          />
        ) : (
          <div ref={ref} className="d-flex flex-column gap-2 ">
            {messages && messages?.length === 0 && (
              <div>
                <span>
                  {languageState.translation.CHAT.SAY_HELLO} {getRoomName(room, chatState?.chatUserId) || languageState.translation.CHAT.UNKNOWN_USER}
                </span>
              </div>
            )}
            {messages?.map((messageDay, index) => (
              <React.Fragment key={index}>
                <span className="d-flex justify-content-center align-items-center gap-1 text-muted text-sm" key={index}>
                  <span
                    style={{
                      flexGrow: 1,
                      height: '3px',
                      background: '#dee2e6',
                    }}
                  ></span>
                  {moment(messageDay?.day).format('DD-MM-yyyy')}{' '}
                  <span
                    style={{
                      flexGrow: 1,
                      height: '3px',
                      background: '#dee2e6',
                    }}
                  ></span>
                </span>
                {messageDay?.messages_result?.map((message, index) => (
                  <Message message={message} room={room} key={index} />
                ))}
              </React.Fragment>
            ))}
            {/* {messages &&
              messages?.length > 0 &&
              isMessageSeenByOtherUsers(room, chatState?.chatUserId) && (
                <span>seen</span>
              )} */}
            {/* <Typing /> */}
          </div>
        )}
      </CardBody>
      <CardFooter className={classnames({ 'pb-6': isFilePage })}>
        {!isFilePage ? (
          <FormGroup className="bg-chat">
            <InputGroup
              className={classnames('input-group-merge', {
                focused: sentMessage,
              })}
            >
              <InputGroupAddon addonType="prepend" className="c-pointer ">
                <Label className="m-0 p-0 c-pointer " for="message-file">
                  <InputGroupText className="h-100 bg-gradient-primary text-white">
                    <i className="fas fa-file " />
                  </InputGroupText>
                  <input
                    type="file"
                    id="message-file"
                    className="d-none"
                    onChange={sendFileMessage}
                  />
                </Label>
                <Label
                  className="m-0 p-0 c-pointer "
                  onClick={() => {
                    setIsImportant(!isImportant);
                  }}
                >
                  <InputGroupText className="h-100 px-3 bg-red text-white">
                    !
                  </InputGroupText>
                </Label>
              </InputGroupAddon>
              <div className="important-container">
                {isImportant && (
                  <div className="important text-red text-bold">!{languageState.translation.CHAT.IMPORTANT}</div>
                )}
                <Input
                  style={{ height: '100%' }}
                  placeholder={languageState.translation.CHAT.MESSAGE}
                  type="text"
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                  onFocus={(e) => { setSentMessage(true) }}
                  onBlur={(e) => setSentMessage(false)}
                  onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)}
                  className={isImportant ? 'pad-6' : 'pl-4 boder-0'}
                />
              </div>
              <InputGroupAddon
                addonType="append"
                className="c-pointer"
                onClick={() => sendMessage()}
              >
                <InputGroupText>
                  <i className="fas fa-paper-plane" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        ) : (
          <div className="mb-3"></div>
        )}
      </CardFooter>
    </Card>
  );
};

export default Conversation;
