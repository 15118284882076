import React, { useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import { AppContext } from 'store/app.context';
import './Empty.scss';

const Empty = ({ icon, message }) => {
  const { languageState } = useContext(AppContext);
  return (
    <Card className="card-empty">
      <CardBody className="card-body-empty">
        <div className="text-center">
          <i className={icon || "ni ni-box-2"}></i>
          <p className=" font-weight-bold display-4">
            {message || languageState.translation.COMMON.NO_RESULT_SEARCH_NO_RESULTS}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default Empty;
