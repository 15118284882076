import { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AppContext } from "store/app.context";

const { Modal, ModalBody } = require("reactstrap")

const NotLoggedPopUp = ({ modal, setModal }) => {
    const { languageState } = useContext(AppContext)
    return (
        <Modal isOpen={modal} centered toggle={() => setModal(false)}>
            <ModalBody>
                <p>{languageState.translation.COMMON.NOT_LOGGED_IN_FIRST_PARA} <Link to={'/register'} className='font-weight-bold'>{languageState.translation.AUTH.SIGNUP.CREATE_AN_ACCOUNT}</Link> </p>
            </ModalBody>
        </Modal>
    )
}

export default NotLoggedPopUp;