import TYPES from './positions.types';
import reducer from './positions.reducer';

const INITIAL_STATE = {
  positions: null,
};

const POSITIONS_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default POSITIONS_STATE;
