import React, { useContext, useEffect } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AppContext } from 'store/app.context';
import LANGUAGE_STATE from 'store/language';
import './index.scss'
import useUserService from 'services/profile/user.service';

const DropwdownFlags = ({ direction, color }) => {
  const { languageState, dispatchChangeLanguage, userState } = useContext(AppContext);
  const { updateBasicInfos } = useUserService();

  useEffect(() => {
    if (userState && userState.userInfo) {
      dispatchChangeLanguage(
        {
          language: userState.userInfo.language,
          type: LANGUAGE_STATE.types.CHANGE_LANGUAGE
        }
      )
    }
  }, [userState])


  const changeLanguage = (event, language) => {
    event.preventDefault();
    localStorage.setItem('language', language);
    if (userState && userState.userInfo) updateBasicInfos({ language })
    dispatchChangeLanguage({
      language,
      type: LANGUAGE_STATE.types.CHANGE_LANGUAGE,
    });
  };

  return (
    <UncontrolledDropdown className="flags" direction={direction}>
      {languageState.defaultLanguage && (
        <DropdownToggle caret color="secondary" id="navbarDropdownMenuLink2" style={color ? { color } : {}}>
          <img
            className="mr-2"
            alt="..."
            src={require(`assets/img/icons/flags/${languageState.defaultLanguage.toUpperCase()}.png`).default}
          />
          {languageState.translation.COMMON.LANGUAGES[languageState.defaultLanguage]}
        </DropdownToggle>
      )}
      <DropdownMenu aria-labelledby="navbarDropdownMenuLink2">
        <DropdownItem active={languageState && languageState.defaultLanguage === 'NL'} onClick={(e) => changeLanguage(e, 'NL')}>
          <img
            className="mr-2"
            alt="..."
            src={require('assets/img/icons/flags/NL.png').default}
          />
          {languageState.translation.COMMON.LANGUAGES.NL}
        </DropdownItem>
        <DropdownItem active={languageState && languageState.defaultLanguage === 'EN'} onClick={(e) => changeLanguage(e, 'EN')}>
          <img
            className="mr-2"
            alt="..."
            src={require('assets/img/icons/flags/EN.png').default}
          />
          {languageState.translation.COMMON.LANGUAGES.EN}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown >
  );
};

export default DropwdownFlags;
