import moment from 'moment';
import { tableHeaders } from 'pages/chat/Mock/mocj';
import { tableFileData } from 'pages/chat/Mock/mocj';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import useChatService from 'services/chat/chat.service';
import { AppContext } from 'store/app.context';
import download from 'downloadjs';
import { ChatSocketContext } from 'store/chat.context';
import { CHAT_EVENTS } from 'config/core';
const Files = ({ goBack, roomId }) => {
  const {
    languageState,
    chatState,
    userState: { userInfo },
  } = useContext(AppContext);
  const [attachemnts, setAttachemnts] = useState([]);
  const { getMessageFile } = useChatService();
  const ws = useContext(ChatSocketContext);

  useEffect(() => {
    if (chatState?.messages && chatState.messages.length > 0) {
      setAttachemnts(
        chatState.messages
          .map((data) => {
            return [
              ...data.messages_result
                .filter((mesg) => mesg.attachments.length > 0)
                .map((mesg) => {
                  return {
                    messageId: mesg.id,
                    sentById: mesg?.author.id,
                    sentBy:
                      mesg?.author.first_name + ' ' + mesg?.author?.last_name,
                    sharedDate: mesg.created_at,
                    attachemnts: mesg.attachments[0],
                  };
                }),
            ];
          })
          .flat(1)
      );
    }
  }, [chatState.messages]);
  useEffect(() => { }, [attachemnts]);
  const formatDate = (date) => moment(date).format('MM/DD/YYYY HH:mm');

  const downloadFile = async (path) => {
    if (path) {
      const blob = await getMessageFile(path);

      downloadmyFile(URL.createObjectURL(blob.data));
    }
  };
  function downloadmyFile(fileToDownload) {
    // const a = document.createElement('a');
    // a.href = fileToDownload;
    // a.download = 'file'
    // a.click();
    download(fileToDownload);
  }

  return (
    <div>
      <Button
        className="d-flex align-items-center pl-4 pr-4 display-4 mb-3 text-dark"
        size="sm"
        color="secondary"
        outline
        onClick={goBack}
      >
        {' '}
        <i className="far fa-angle-left display-4 mr-1 p-0 mr-2 "></i>
        <span className="h4 mb-1 font-weight-bold">Back</span>
      </Button>

      <Table striped>
        <thead>
          <tr>
            <th>#</th>
            {tableHeaders.map((data, index) => (
              <th key={index}>{languageState.translation.CHAT[data]} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {attachemnts &&
            attachemnts.length > 0 &&
            attachemnts.map((data, index) => {
              return (
                data.attachemnts?.file && (
                  <tr key={index}>
                    <th scope="row">{index + 1} </th>
                    <th>{data.attachemnts?.file?.originalname}</th>
                    <th>{formatDate(data.sharedDate)}</th>

                    <th>{data.sentBy}</th>
                    <th className="c-pointer">
                      <i
                        className="fas fa-download"
                        onClick={() => downloadFile(data.attachemnts?.url)}
                      ></i>
                      {data.sentById === userInfo.id && (
                        <i
                          className="fas fa-trash-xmark"
                          style={{ color: '#c91313' }}
                          onClick={async () => {
                            ws.emit(CHAT_EVENTS.DELETE_MESSAGE, {
                              messageId: data.messageId,
                              roomId,
                            });
                            setAttachemnts(
                              attachemnts.filter((val, i) => i !== index)
                            );
                          }}
                        ></i>
                      )}
                    </th>
                  </tr>
                )
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default Files;
