import TYPES from './missions.types';
import reducer from './missions.reducer';

const INITIAL_STATE = {
  missions: null,
  allMissions: null,
  selectedMission: null,
  team: null,
  events: null,
};

const MISSION_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default MISSION_STATE;
