import React, { useContext, useEffect, useRef, useState } from 'react';
// react library for routing
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import routes, {
  innerRoutesCompany,
  innerRoutesFreelancer,
} from '../../navigation/routes';
import AdminNavbar from './navbar/admin-navbar';
import AdminFooter from './footer/admin-footer';
import Sidebar from './sidebar/sidebar';
import APP_ROUTES from 'navigation/app.routes';
import useFocusService from 'services/focus/focus.service';
import { AppContext } from 'store/app.context';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import StepperModal from 'components/modalStepper/modal-stepper';
import useUserService from 'services/profile/user.service';
import PerrmissionProvider from 'config/core/PerrmissionProvider';
import { isAuthorized } from 'utils';
import { MODULES } from 'config/core';

const Admin = () => {
  const mainContentRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [routesByRole, setRoutesByRole] = useState(null);
  const [innerRoutesByRole, setInnerRoutesByRole] = useState(null);
  const { getUserInfo } = useUserService();
  const { missionState, sharedState, userState } = useContext(AppContext);
  const { getProjects, getMisions } = useFocusService();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [document]);

  useEffect(() => {
    if (!isAuthorized()) {
      return;
    }
    initiateSidenav()
    getProjects();
    getMisions();
    getUserInfo();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && routes && routes.length) {
      const filteredRoutes = routes.filter(
        (route) => route.role === userInfo?.kind
      );
      setRoutesByRole(filteredRoutes);
      setInnerRoutesByRole(
        userInfo?.kind === 'FREELANCER'
          ? innerRoutesFreelancer
          : innerRoutesCompany
      );
    }
  }, [routes]);

  useEffect(() => {
    //const focus = routes.find((item) => (item.name = 'FOCUS'));
    routes.forEach((item) => {
      if (missionState.missions && item.name == 'FOCUS') {
        item.views = missionState.missions;
      }
    });
  }, [routes, missionState]);
  useEffect(() => {
    if (location.pathname.includes('profile') && !isAuthorized()) {
      history.push(
        `/public${APP_ROUTES.PUBLIC_ROUTE.PROFILE.PROFILE_DETAILS
        }${location.pathname.slice(
          location.pathname.lastIndexOf('/'),
          location.pathname.length
        )}`
      );
    }
  }, [location]);
  const getRoutes = (routes) =>
    routes && routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/private') {
        if (sharedState.currentModule == MODULES.MISSION_OVERVIEW)
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              exact={true}
            />
          );
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact ? true : false}
          />
        );
      }
      return null;
    });

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };

  // toggles collapse between mini sidenav and normal
  const initiateSidenav = () => {
    if (document.body.classList.contains('g-sidenav-hidden')) {
      document.body.classList.add('g-sidenav-show');
      document.body.classList.remove('g-sidenav-hidden');
      document.body.classList.add('g-sidenav-pinned');
    }
  };
  const getNavbarTheme = () =>
    location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';

  return (
    <>
      <PerrmissionProvider>
        {
          /* hide this as of breadcrumbs are ready */
          <Sidebar
            allRoutes={routes}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            logo={{
              innerLink: '/',
              imgSrc: require('../../assets/img/brand/ee_logo_whitebg.png')
                .default,
              imgAlt: 'ExtraExpertise.',
            }}
          />
        }
        <div className="main-content" ref={mainContentRef}>
          <AdminNavbar
            theme={getNavbarTheme()}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
          />

          {routesByRole && (
            <Switch>
              {getRoutes(routesByRole)}
              {getRoutes(innerRoutesByRole)}
              <Route
                exact
                path="/private/linkedin"
                component={LinkedInCallback}
              />
              <Redirect from="*" to={`/private${APP_ROUTES.HOME}`} />
            </Switch>
          )}

          <AdminFooter />
        </div>
        {userState.userInfo && <StepperModal userInfo={userState.userInfo} />}
        {sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={toggleSidenav} />
        ) : null}
      </PerrmissionProvider>
    </>
  );
};

export default Admin;
