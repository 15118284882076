import ProfileDetails from "pages/connect/inner-pages/profile-details";
import APP_ROUTES from "./app.routes";
import Jobboard from 'pages/jobs/jobboard';
import JobDetails from "pages/jobs/job-details/JobDetails";
import AddEditMission from 'pages/recruiter/Addmission/Addmission.jsx';

const routes = [
    {
        path: APP_ROUTES.PUBLIC_ROUTE.PROFILE.PROFILE_DETAILS + "/:id",
        name: "Profile Details",
        component: ProfileDetails,
        layout: "/public",
        parent: "profile",
    },
    {
        path: APP_ROUTES.PUBLIC_ROUTE.JOBS.BOARD,
        name: 'BOARD',
        component: Jobboard,
        layout: "/public",
    },
    {
        path: APP_ROUTES.PUBLIC_ROUTE.JOBS.JOB_DETAILS,
        name: 'JOB_DETAILS',
        miniName: 'Jb',
        component: JobDetails,
        layout: '/public'
    },
    {
        collapse: false,
        path: APP_ROUTES.RECRUITER.ADD_MISSION,
        name: 'ADD_MISSION',
        layout: '/public',
        component: AddEditMission,
    }
]
export default routes