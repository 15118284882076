import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'store/app.context';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import { Button, Container } from 'reactstrap';
import CardsFocusTeam from 'components/cards/card-focus-team';
import SHARED_STATE from 'store/shared';
import { MODULES } from 'config/core';
import PROJECT_STATE from 'store/projects';
import { useLocation, useParams } from 'react-router-dom';
import useFocusService from 'services/focus/focus.service';
const person = {
  name: 'Bart Delveaux',
  work: 'Digital Specialist',
  jobtype: 'Freelance',
  adresse: 'Demo street 123',
  phone: '+21625834123',
};

const person2 = {
  name: 'Riet Verachtert',
  work: 'Content marketeer',
  jobtype: 'Freelance',
  adresse: 'Demo street 123',
  phone: '+21625834123',
};

const person3 = {
  name: 'Ben Driessen',
  work: 'SEO copywriter',
  jobtype: 'Freelance',
  adresse: 'Demo street 123',
  phone: '+21625834123',
};

const Team = () => {
  const {
    languageState,
    projectState,
    dispatchSharedState,
    dispatchProjectState,
    missionState,
  } = useContext(AppContext);

  const [selectedProject, setSelectedProject] = useState(null);
  const { getTeam, getTeamMission } = useFocusService();
  const location = useLocation();

  const { id } = useParams();

  useEffect(() => {
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.FOCUS,
    });
  }, []);

  useEffect(() => {
    if (id && selectedProject && selectedProject.type === 'PROJET') getTeam(id);
    else if (id && selectedProject && selectedProject.type !== 'PROJET')
      getTeamMission(id);
  }, [id, selectedProject]);
  useEffect(() => {
    if (missionState.missions) {
      const project = missionState.missions.find((project) => project.id == id);
      dispatchProjectState({
        type: PROJECT_STATE.types.SELECT_PROJECT,
        project,
      });
    }
  }, [missionState.missions]);

  useEffect(() => {
    setSelectedProject(projectState.selectedProject);
  }, [projectState]);

  return (
    <>
      {selectedProject && (
        <>
          <CardsHeaderFocus
            name={selectedProject.name}
            parentName="Teams"
            type={selectedProject?.type}
          />
          <Container className="mt-3" fluid>
            <div className="bg-secondary d-flex justify-content-between">
              <p className="f ">
                <span className="text-info ">
                  {' '}
                  {languageState.translation.FOCUS.TEAMS.NAV.HOME}{' '}
                </span>{' '}
                / {languageState.translation.FOCUS.TEAMS.NAV.CONTACTS}{' '}
              </p>{' '}
            </div>{' '}
            <div className="team-grid ">
              {missionState &&
                missionState.team &&
                missionState.team.map((data, index) => (
                  <CardsFocusTeam key={index} person={data} type={selectedProject?.type} />
                ))}{' '}
            </div>{' '}
          </Container>{' '}
        </>
      )}{' '}
    </>
  );
};

export default Team;
