import React, { useContext } from 'react';
import { ModalBody, Button } from 'reactstrap';
import { AppContext } from 'store/app.context';

const LastScreenModal = ({ disableStepper }) => {
  const { languageState } = useContext(AppContext);

  return (
    <ModalBody
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='last-screen-icon'>
        🎉
      </div>
      <h1
        style={{
          marginTop: '3rem',
          marginBottom: '1rem',
          textAlign: "center"
        }}
      >
        {languageState.translation.STEPPER.LAST_STEP_CONTINUE}
      </h1>
      <Button onClick={() => {
        disableStepper()
      }
      }>
        {languageState.translation.COMMON.ONBOARDING_BTN_STEP4_NEXT}
      </Button>
    </ModalBody>
  );
};

export default LastScreenModal;
