import Avatar from 'components/avatar/avatar';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Table } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { IMG_PREFIX } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import './tagged-users-card.css';

const TaggedUsersCard = ({ users }) => {
  const { languageState } = useContext(AppContext);
  const { deleteUserFromTag, getAllTags } = useConnectService();
  const [alert, setAlert] = useState(null);
  const history = useHistory();
  const styleP = {
    lineHeight: '2rem',
  };
  const containerFlex = {
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '.5rem',
    gap: '.3rem',
    display: 'flex',
  };
  const deleteUserTag = async (tagId, userId) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => {
          setAlert(null);
        }}
        onCancel={async () => {
          const result = await deleteUserFromTag({
            tagId,
            userId,
          });

          if (result && result?.status === 200) {
            toast.success(
              languageState.translation.ALERT.USER_REMOVE_FROM_TAG,
              {
                position: 'bottom-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              }
            );
            setAlert(null);
            getAllTags();
          }
        }}
        confirmBtnCssClass="btn-secondary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Cancel"
        cancelBtnText="Yes, delete it"
        showCancel
        btnSize=""
      >
        {languageState.translation.CONNECT.DELETE_TAG}
      </ReactBSAlert>
    );
  };

  const viewUserProfile = (userId) => {
    history.push(
      '/private' + APP_ROUTES.CONNECT.PROFILE_DETAILS + '/' + userId
    );
  };

  return (
    <Card
      style={{
        width: '100%',
        overflow: 'auto',
      }}
    >
      <CardBody>
        <hr />
        <Table borderless>
          <thead style={{ backgroundColor: '#F8F9FE' }}>
            <tr>
              <th>{languageState.translation.CONNECT.FULL_NAME}</th>
              <th>{languageState.translation.CONNECT.JOB_TITLE}</th>
              <th>{languageState.translation.CONNECT.TAG}</th>
              <th></th>
            </tr>
          </thead>

          {users && users.length ? (
            <tbody>
              {users.map((user, index) => (
                <tr
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                  key={`${user?.id}-${index}`}
                >
                  <td>
                    <div
                      className="d-flex align-items-center gap-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        viewUserProfile(user?.id);
                      }}
                    >
                      {user && user.profileImage ? (
                        <img
                          alt="..."
                          className="rounded-circle avatar "
                          src={`${IMG_PREFIX}${user.profileImage}`}
                        />
                      ) : (
                        <Avatar
                          firstName={user && user.firstName}
                          lastName={user && user.lastName}
                          name={user && user.name}
                          forNavbar
                        />
                      )}
                      <div className="d-flex flex-column ml-2">
                        <span>
                          {user?.firstName} {user?.lastName.charAt(0)}.
                        </span>
                        <small style={{ color: 'gray', marginTop: -5 }}>
                          {user?.email}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p style={styleP}>{user?.kind}</p>
                  </td>
                  <td className='d-flex gap-1 flex-wrap'>
                    {user.tags && user.tags.length && user.tags.map(tag => (
                      <div className="tag-label" key={tag.id}>{tag.name}</div>
                    )
                    )}
                  </td>
                  <td>
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        color="danger"
                        onClick={() => deleteUserTag(user?.tag?.id, user?.id)}
                        outline
                        className="action-icon"
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </Table>
        {!users || !users.length ? (
          <div className="nothing-to-show">
            <p>{languageState.translation.CONNECT.NOTHING_TO_SHOW}</p>
          </div>
        ) : null}
      </CardBody>
      {alert}
    </Card>
  );
};

export default TaggedUsersCard;
