import reducer from './reducer';
import TYPES from './types';

const INITIAL_STATE = {
  socketId: '',
  chatUserId: '',
  rooms: null,
  onlineUsers: null,
  users: null,
  status: 'NOT_CONNECTED',
  conversationId: null,
  conversationDetails: null,
  messages: null,
  page: 1,
  hasMoreConversations: false,
  hasMoreMessages: false,
  globalSeen: true,
  newMessage: null,
};

const CHAT_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default CHAT_STATE;
