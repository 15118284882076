import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import ModalNotes from 'components/modalNotes/ModalNotes';
import UserCard from 'components/user-card/user-card';
import { Container, Row, Col, Card, CardHeader, CardBody, Badge, ButtonGroup, Button, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import { getQueryParamsFromObject } from 'utils';
import { Range } from 'rc-slider';
import { MODULES } from 'config/core';
import SHARED_STATE from 'store/shared';
import CardFavoris from 'components/cards/card-favoris';
import _, { isEqual } from 'lodash';
import useJobsService from 'services/jobs/jobs.service';
import { statuses } from 'components/modalStepper/onboarding.constant';
import { professions } from 'components/modalStepper/onboarding.constant';

const Favorites = () => {
  const { getFavorites } = useConnectService();
  const { getCategories } = useJobsService();
  const { languageState, connectState, dispatchSharedState, jobsState } = useContext(AppContext);
  const [minSlider, setMinSlider] = useState(0);
  const [maxSlider, setMaxSlider] = useState(100);
  const [modalShow, setModalShow] = useState(false);
  const [noDataFound, setNotDataFound] = useState(false);
  const [paginationList, setPagination] = useState([]);
  const [userid, setUserId] = useState(null);
  const [filters, setFilters] = useState({
    dailyRateMin: 0,
    dailyRateMax: 100,
    profession: [],
    experienceLevel: [],
    categories: [],
    name: '',
    statusAviability: ''
  });

  const filterCached = useMemo(() => filters, []);
  const [experienceLevelString, setExperienceLevelString] = useState([]);
  const [nameToSearch, setNameToSearch] = useState('');
  const timer = useRef(null);

  const getMoreUsers = (page) => {
    if (page !== connectState.config.currentPage) {
      const reqData = getQueryParamsFromObject({ ...filters, page });
      getFavorites(reqData);
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    dispatchSharedState({
      module: MODULES.CONNECT_YOUR_NETWORK,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
    getFavorites();
    getCategories();
  }, []);

  useEffect(() => {
    if (connectState.favorites && connectState.configFavorites) getPaginate();
  }, [connectState.favorites]);

  useEffect(() => {
    const reqData = getQueryParamsFromObject(filters);
    getFavorites(reqData);
  }, [filters]);

  useEffect(() => {
    if (!isEqual(filterCached, filters)) {
      if (connectState && connectState.favorites?.length === 0) {
        setNotDataFound(true);
      } else {
        setNotDataFound(false);
      }
    }
  }, [filters, connectState]);

  const changeCategoryHandler = (category) => {
    const oldCategories = filters.categories;
    let newCategories = [];
    if (oldCategories.includes(category)) {
      newCategories = oldCategories.filter((item) => item !== category);
    } else {
      newCategories = [...oldCategories, category];
    }
    setFilters({
      ...filters,
      categories: newCategories,
    });
  };

  const searchByName = () => {
    setFilters((previousFiler) => {
      return {
        ...previousFiler,
        name: nameToSearch.toLowerCase().trim(),
      };
    });
  };

  const changeExperienceLevelsFilter = (type) => {
    const oldExperienceLevels = experienceLevelString;
    let newExperienceLevels = [];
    if (oldExperienceLevels.includes(type)) {
      newExperienceLevels = oldExperienceLevels.filter((item) => item !== type);
    } else {
      newExperienceLevels = [...oldExperienceLevels, type];
    }

    setExperienceLevelString(newExperienceLevels);

    setFilters({
      ...filters,
      experienceLevel: newExperienceLevels,
    });
  };

  const onSliderChange = (e) => {
    setMinSlider(e[0]);
    setMaxSlider(e[1]);
  };
  const getPaginate = () => {
    const listpag = [];
    if (connectState.config && connectState.configFavorites.totalPages) {
      for (
        let index = 0;
        index < connectState.configFavorites.totalPages;
        index++
      ) {
        listpag.push(index + 1);
      }
    }
    setPagination(listpag);
  };

  const resetFilter = () => {
    setNameToSearch('')
    setExperienceLevelString([])
    setFilters({
      dailyRateMin: 0,
      dailyRateMax: 100,
      profession: [],
      experienceLevel: [],
      categories: ['All'],
      name: '',
      statusAviability: ''
    });
  }
  return (
    <div className='network-list'>
      <CardFavoris numberOfusers={connectState.favorites?.filter(el => el.kind !== "COMPANY")?.length} />
      <Container className="mt-3" fluid>
        <Row>
          <Col xl={3} lg={3} md={4} sm={12}>
            <Card className='card-filter'>
              <CardHeader>
                <h3>{languageState.translation.CONNECT.SEARCH_BY_NAME}</h3>
              </CardHeader>
              <CardBody>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        style={{ color: '#675cff', fontSize: 18 }}
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={nameToSearch}
                    onChange={(e) => {
                      setNameToSearch(e.target.value);
                    }}
                    style={{ backgroundColor: 'white', color: 'Black' }}
                    placeholder={languageState.translation.CONNECT.ENTER_NAME}
                    onKeyUp={() => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(searchByName, 500);
                    }}
                  ></Input>
                </InputGroup>
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.AVAILIBILITY}
                </h3>
              </CardHeader>
              <CardBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {
                  statuses.map((status) => {
                    return <Col md="12">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={status}
                          type="checkbox"
                          checked={filters.statusAviability === status}
                          onChange={() =>
                            setFilters({
                              ...filters,
                              statusAviability: status
                            })
                          }
                        />
                        <label className="custom-control-label" htmlFor={status}>
                          {languageState.translation.STEPPER.FIRST_STEP_OPTIONS[status]}
                        </label>
                      </div>
                    </Col>
                  })
                }
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.TYPE}
                </h3>
              </CardHeader>
              <CardBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {
                  professions.map((prof) => {
                    return <Col md="12">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={prof}
                          type="checkbox"
                          checked={filters?.profession?.includes(prof)}
                          onChange={() => {
                            const selectedProfessions = filters?.profession || []
                            setFilters({
                              ...filters,
                              profession: selectedProfessions.includes(prof)
                                ? _.without(selectedProfessions, prof)
                                : [...selectedProfessions, prof]
                            })
                          }
                          }
                        />
                        <label className="custom-control-label" htmlFor={prof}>
                          {languageState.translation.STEPPER.FIRST_STEP_OPTIONS[prof]}
                        </label>
                      </div>
                    </Col>
                  })
                }
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.EXPERIENCE_LEVEL}{' '}
                </h3>
              </CardHeader>
              <CardBody>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck3"
                      type="checkbox"
                      onChange={() => changeExperienceLevelsFilter('0-2')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck3"
                    >
                      0-2 {languageState.translation.COMMON.YEARS}{' '}
                    </label>
                  </div>
                </Col>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck4"
                      type="checkbox"
                      onChange={() => changeExperienceLevelsFilter('2-5')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck4"
                    >
                      2-5 {languageState.translation.COMMON.YEARS}
                    </label>
                  </div>
                </Col>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck5"
                      type="checkbox"
                      onChange={() => changeExperienceLevelsFilter('5-10')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck5"
                    >
                      5-10 {languageState.translation.COMMON.YEARS}
                    </label>
                  </div>
                </Col>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck6"
                      type="checkbox"
                      onChange={() => changeExperienceLevelsFilter('10-10')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck6"
                    >
                      10+ {languageState.translation.COMMON.YEARS}
                    </label>
                  </div>
                </Col>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <h3 className="mb-0">
                    {languageState.translation.COMMON.HOURLY_RATE}
                  </h3>
                  <Badge className="mt-1 ml-4" color="secondary">
                    {minSlider} - {maxSlider}
                  </Badge>
                </Row>
              </CardHeader>
              <CardBody>
                <Range
                  marks={{
                    0: 0,
                    25: 25,
                    50: 50,
                    75: 75,
                    100: 100,
                  }}
                  count={2}
                  trackStyle={[
                    { backgroundColor: '#5e72e4' },
                    { backgroundColor: '#5e72e4' },
                  ]}
                  defaultValue={[0, 100]}
                  step={5}
                  onChange={(e) => onSliderChange(e)}
                />
                <Badge
                  className="mt-5 d-flex justify-content-center c-pointer btn-exprimary"
                  color="primary"
                  onClick={(e) =>
                    setFilters({
                      ...filters,
                      dailyRateMin: minSlider,
                      dailyRateMax: maxSlider,
                    })
                  }
                >
                  {languageState.translation.COMMON.BUTTONS.SAVE}
                </Badge>
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.CATEGORY}
                </h3>
              </CardHeader>
              <CardBody>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id={'category'}
                      type="checkbox"
                      checked={filters.categories.includes('All')}
                      onChange={(e) =>
                        changeCategoryHandler('All')
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={'category'}
                    >
                      {languageState.translation.CATEGORIES_FILTER.ALL}
                    </label>
                  </div>
                </Col>
                {(jobsState && jobsState.categories || []).map((category, index) => (
                  <Col md="12" key={index}>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id={'category' + index}
                        type="checkbox"
                        checked={filters.categories.includes(category.id)}
                        onChange={(e) =>
                          changeCategoryHandler(category.id)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={'category' + index}
                      >
                        {category.label}
                      </label>
                    </div>
                  </Col>
                ))}
                {jobsState && jobsState.categories.length == 0 && (
                  <span>{languageState.translation.CONNECT.NO_CATEGORY}</span>
                )}
              </CardBody>
            </Card>

            <Col lg={12} md={12} sm={12}>
              <Button className='w-100' color='primary' onClick={resetFilter} >{languageState.translation.COMMON.BUTTONS.RESET_FILTER}</Button>
            </Col>
          </Col>
          <Col xl={9} lg={9} md={8} sm={12}>
            {connectState.favorites && connectState.favorites.length === 0 && (
              <div className="d-flex justify-content-center h-100 display-1 mt-6 mb-8 bigFontSize">
                <div className=" p-5 text-center  rounded shadow-sm ">
                  <i className="fas fa-user-friends"></i>
                  <p className=" font-weight-bold display-4">
                    {noDataFound
                      ? languageState.translation.COMMON.USER_SEARCH_NO_RESULTS
                      : languageState.translation.CONNECT.YOU_HAVE_NO_USERS}
                  </p>
                </div>
              </div>
            )}
            <Row className='grid-data-users'>
              {connectState.favorites &&
                connectState.favorites.length > 0 &&
                connectState.favorites.map((user) => (
                  user.kind !== 'COMPANY' && (
                    <Col className='p-0'>
                      <UserCard
                        openModalAddNote={() => {
                          setModalShow(true);
                          setUserId(user.id);
                        }}
                        user={user}
                        key={user.id}
                        module="favorites"
                      />
                    </Col>
                  )

                ))}
            </Row>
            <ButtonGroup className="d-flex justify-content-center">
              {paginationList &&
                paginationList.map((data, index) => (
                  <Button
                    key={index}
                    className={
                      connectState.config?.currentPage == data
                        ? ' active flex-grow-0'
                        : 'flex-grow-0'
                    }
                    color="primary"
                    type="button"
                    onClick={() => getMoreUsers(data)}
                  >
                    {data}
                  </Button>
                ))}
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
      <ModalNotes
        modalShow={modalShow}
        userid={userid}
        closeModal={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
};

export default Favorites;
