import { Formik, Field } from 'formik';
import { linksSchema } from 'pages/profile/schema';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardHeader, Row, Col, CardBody, FormGroup } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';

const Links = () => {
  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } = useUserService();

  const { languageState, userState } = useContext(AppContext);

  const [initialValues, setValues] = useState({
    linkedinProfile: userState?.userInfo?.linkedinProfile || '',
    profileWebsite: userState?.userInfo?.profileWebsite || '',
  });
  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">{languageState.translation.COMMON.LINKSPERSONAL}</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={initialValues}
          validationSchema={linksSchema(
            languageState.translation.FORM_VALIDATION
          )}
          onSubmit={async (values) => {
            console.log('UserInformation', values);
            const result2 = await updateBasicInfos(values);
            if (result2 && result2.status === 200) {
              autoUpdateUserInfo();
              getUserInfo()
              toast.success(
                languageState.translation.ALERT.SUCCESS_CHANGE_INFO,
                {
                  position: 'bottom-right',
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                }
              );
            }
          }}
        >
          {({ errors, touched, values, handleSubmit, isSubmitting }) => (
            <div>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-linkedin-profile"
                        placeholder={'Linkedin profile'}
                        type="text"
                        name="linkedinProfile"
                      />
                      {errors.linkedinProfile && touched.linkedinProfile && (
                        <div className="invalid-feedback">
                          {errors.linkedinProfile}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Field
                        className="form-control"
                        id="input-portfolio-website"
                        placeholder={'portfolio website'}
                        type="text"
                        name="profileWebsite"
                      />
                      {errors.profileWebsite && touched.profileWebsite && (
                        <div className="invalid-feedback">
                          {errors.profileWebsite}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE_INFO}
              </button>
            </div>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default Links;
