import { M_TASKS, M_TASKS_2, M_TASKS_3 } from '../tasks';
import { M_TEAMS } from '../teams';
import { M_TOOLS, M_TOOLS_2, M_TOOLS_3 } from '../tools';

export const M_PROJECTS = [
  {
    id: 1,
    name: 'Growth Market',
    startDate: '2015-12-13T22:05:36.694Z',
    endDate: '2017-12-25T22:05:36.694Z',
    tools: M_TOOLS,
    team: M_TEAMS,
    tasks: M_TASKS,
  },
  {
    id: 2,
    name: 'Freelancers App',
    startDate: '2015-12-13T22:05:36.694Z',
    endDate: '2017-12-25T22:05:36.694Z',
    tools: M_TOOLS_2,
    team: M_TEAMS,
    tasks: M_TASKS_2,
  },
  {
    id: 3,
    name: 'BNP Bank Platform',
    startDate: '2015-12-13T22:05:36.694Z',
    endDate: '2017-12-25T22:05:36.694Z',
    tools: M_TOOLS_3,
    team: M_TEAMS,
    tasks: M_TASKS_3,
  },
];
