import ExEditor from 'components/editor/editor';
import { Formik } from 'formik';
import { aboutMeSchema } from 'pages/profile/schema';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';

const AboutMe = () => {
  const { languageState, userState } = useContext(AppContext);
  const { updateBasicInfos, autoUpdateUserInfo, getUserInfo } = useUserService();

  const [initialValues, setValues] = useState({
    aboutMe: userState?.userInfo?.aboutMe || '',
  });
  return (
    <Card>
      <CardHeader>
        {' '}
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              {languageState.translation.PROFILE.ABOUT_ME}
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            const result = await updateBasicInfos(values);
            if (result && result.status === 200) {
              autoUpdateUserInfo();
              getUserInfo();
              toast.success(
                languageState.translation.ALERT.SUCCESS_CHANGE_INFO,
                {
                  position: 'bottom-right',
                  hideProgressBar: false,
                  autoClose: 4000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                }
              );
            }
          }}
          validationSchema={aboutMeSchema}
        >
          {({ errors, values, handleSubmit, handleChange, touched }) => (
            <div className="pl-lg-4">
              <FormGroup>
                <ExEditor
                  name="aboutMe"
                  initialContent={values.aboutMe}
                  placeholder={languageState.translation.PROFILE.ABOUT_ME}
                  onChangeContent={(html) => values.aboutMe = html} />
                {errors.aboutMe && touched.aboutMe && (
                  <div className="invalid-feedback">{errors.aboutMe}</div>
                )}
              </FormGroup>
              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE_INFO}
              </button>
            </div>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default AboutMe;
