const { default: TYPES } = require("./types");

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.subscriptions,
            };
        default:
            return {
                ...state,
            };
    }
}

export default reducer;