import moment from "moment";

export const calculateTimeRemaining = (targetDate) => {
    const now = moment();
    const duration = moment.duration(now.diff(targetDate));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return { hours, minutes, seconds };
};

export const calculateTimeLeft = (prevDuration) => {
    const newDuration = prevDuration.subtract(1, 'seconds');
    const hours = newDuration.hours();
    const minutes = newDuration.minutes();
    const seconds = newDuration.seconds();
    return { newDuration, hours, minutes, seconds };
};