import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import { AppContext } from 'store/app.context';
import { Formik } from 'formik';
import schema from './validators';

const ModalEditTask = ({ modalShow, closeModal, handleEditTask, task }) => {
  const { languageState } = useContext(AppContext);
  const [endDate, setEndDate] = useState(false);
  const [startdDate, setStartDate] = useState(false);

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
        className="mb-0"
      >
        {languageState.translation.RECRUITER.TASKS.ADD_TASK}
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <Formik
          initialValues={{
            ...task,
          }}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={(values) => {
            handleEditTask(values);
            closeModal();
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            setFieldValue,
          }) => (
            <div>
              <Label className="mt-n4">task name</Label>
              <Input
                placeholder="task name"
                name="name"
                onChange={handleChange}
                defaultValue={values.name}
              />
              <small className="text-red">
                {' '}
                {errors.name && touched.name && errors.name}
              </small>
              <Label className="d-block mt-1">Description</Label>
              <Input
                type="textarea"
                placeholder="Description"
                name="description"
                onChange={handleChange}
                defaultValue={values.description}
              />
              <Label className="d-block">
                {' '}
                {languageState.translation.COMMON.START_DATE}
              </Label>
              <ReactDatetime
                closeOnSelect
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('startDate', date)}
                timeFormat={false}
                dateFormat={'DD-MM-YYYY'}
                initialValue={new Date()}
                onFocus={(e) => setStartDate(true)}
                onBlur={(e) => setStartDate(false)}
                value={
                  values.startDate
                    ? moment(values.startDate).format('MM-DD-YYYY')
                    : moment()
                }
              />
              <small className="text-red">
                {errors.startDate && touched.startDate && errors.startDate}
              </small>
              <Label className="d-block">
                {languageState.translation.COMMON.END_DATE}
              </Label>
              <ReactDatetime
                closeOnSelect
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('endDate', date)}
                timeFormat={false}
                dateFormat={'DD-MM-YYYY'}
                initialValue={new Date()}
                onFocus={(e) => setEndDate(true)}
                onBlur={(e) => setEndDate(false)}
                value={moment(values.endDate).format('MM-DD-YYYY')}
              />
              <small className="text-red">
                {errors.endDate && touched.endDate && errors.endDate}
              </small>

              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                Save
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditTask;
