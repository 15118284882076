import React, { useContext } from 'react';
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';
import { AppContext } from 'store/app.context';

const AdminFooter = () => {
  const { languageState } = useContext(AppContext);

  return (
    <>
      <Container fluid className="mt-auto">
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted">
                © {new Date().getFullYear()}{' '}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.creative-tim.com?ref=adpr-admin-footer"
                  target="_blank"
                >
                  ExtraExpertise
                </a>
              </div>
            </Col>
            <Col lg="6">
              <Nav className="nav-footer justify-content-center justify-content-lg-end">
                <NavItem>
                  <NavLink href="https://extraexpertise.be/" target="_blank">
                    ExtraExpertise
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href=" https://extraexpertise.be/over-ons.html"
                    target="_blank"
                  >
                    {languageState.translation.COMMON.ABOUT_US}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://extraexpertise.be/handleiding.html"
                    target="_blank"
                  >
                    {languageState.translation.COMMON.README}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://www.creative-tim.com/license?ref=adpr-admin-footer"
                    target="_blank"
                  >
                    {languageState.translation.COMMON.LICENCE}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
};

export default AdminFooter;
