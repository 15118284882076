import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input, Label, ModalBody, ModalHeader, Modal } from 'reactstrap';
import useFocusService from 'services/focus/focus.service';
import { AppContext } from 'store/app.context';
import schema from './validators';

const ModalAddUser = ({ modalShow, closeModal }) => {
  const { languageState } = useContext(AppContext);
  const [endDate, setEndDate] = useState(false);
  const [startdDate, setStartDate] = useState(false);
  const { id } = useParams();

  const { addTemMember, getTeam } = useFocusService();

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
        className="mb-0"
      >
        {languageState.translation.RECRUITER.TASKS.ADD_TASK}
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <Formik
          initialValues={{
            name: '',
            role: '',
            email: '',
            phoneNumber: '',
            address: '',
          }}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={async (values) => {
            const result = await addTemMember({ ...values, project: id });
            if (result && result.status === 201) {
              toast.success(languageState.translation.ALERT.SUCCESS_ADD_USER, {
                position: 'bottom-right',
                hideProgressBar: false,
                autoClose: 4000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
              getTeam(id);
              closeModal();
            }
          }}
        >
          {({ errors, touched, handleChange, handleSubmit, isSubmitting }) => (
            <div>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.NAME}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.NAME}
                name="name"
                onChange={handleChange}
              />
              <small className="text-red">
                {' '}
                {errors.name && touched.name && errors.name}
              </small>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.EMAIL}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.EMAIL}
                name="email"
                onChange={handleChange}
              />
              <small className="text-red">
                {' '}
                {errors.email && touched.email && errors.email}
              </small>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.ROLE}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.ROLE}
                name="role"
                onChange={handleChange}
              />
              <small className="text-red">
                {' '}
                {errors.role && touched.role && errors.role}
              </small>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.FOCUS.TEAMS.ADDRESS}
              </Label>
              <Input
                placeholder={languageState.translation.FOCUS.TEAMS.ADDRESS}
                name="address"
                onChange={handleChange}
              />
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.PHONE_NUMBER}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.PHONE_NUMBER}
                name="phoneNumber"
                onChange={handleChange}
              />
              <small className="text-red">
                {' '}
                {errors.phoneNumber &&
                  touched.phoneNumber &&
                  errors.phoneNumber}
              </small>

              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalAddUser;
