import { Card, CardBody } from 'reactstrap';
export function CardRecruiter({ background, nomcard, onClick }) {
  const CardStyle = {
    maxWidth: '40rem',
  };
  return (
    <>
      <Card className={background} style={CardStyle}>
        <CardBody className="recruiter-card" onClick={() => onClick()}>
          <p className="h2 text-white p-4">{nomcard}</p>
        </CardBody>
      </Card>
    </>
  );
}
