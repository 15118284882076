import classnames from 'classnames';
import Avatar from 'components/avatar/avatar';
import { ROOM_TYPES } from 'config/core';
import { IMG_PREFIX } from 'config/core';
import React, { useContext, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledDropdown } from 'reactstrap';
import { AppContext } from 'store/app.context';
import { isOnline } from 'utils';
import { getRoomName } from 'utils';

const ChatHeader = ({ room, openFiles }) => {
  const { languageState, chatState } = useContext(AppContext);
  const [search, setSearch] = useState(false);

  return (
    <div className="d-flex gap-1 chat-header ">
      {' '}
      {
        // <span className="avatar avatar-sm rounded-circle">
        //   <img alt="..." src={`${IMG_PREFIX}${message.author.photo_url}`} />
        // </span>
      }
      <Avatar
        forNavbar={true}
        name={getRoomName(room, chatState?.chatUserId) || languageState.translation.CHAT.UNKNOWN_USER}
        className="avatar mr-2 rounded-circle custom-avatar "
      />
      <div className="d-flex flex-column ">
        <span className="h2 mb-1 ">
          {getRoomName(room, chatState?.chatUserId) || languageState.translation.CHAT.UNKNOWN_USER}
        </span>

        <div className="d-flex flex-column text-muted w-100 overflow-hidden">
          {room?.type === ROOM_TYPES.DIRECT &&
            (isOnline(room, chatState?.onlineUsers, chatState?.chatUserId) ? (
              <div>
                <span
                  className="d-flex gap-1 "
                  style={{ color: '#67cf8a', alignItems: 'center' }}
                >
                  <span className="text-sm">Online</span>
                  <span
                    style={{
                      background: '#67cf8a',
                      width: '.8rem',
                      borderRadius: '2rem',
                      height: '.8rem',
                    }}
                  ></span>
                </span>
              </div>
            ) : (
              <span
                className="d-flex gap-1 "
                style={{ color: '#f5365c', alignItems: 'center' }}
              >
                <span className="text-sm">{languageState.translation.CHAT.OFFLINE}</span>{' '}
                <span
                  style={{
                    background: '#f5365c',
                    width: '.8rem',
                    borderRadius: '2rem',
                    height: '.8rem',
                  }}
                ></span>
              </span>
            ))}
        </div>
      </div>
      <FormGroup className="bg-chat m-auto w-input search-chat ">
        <InputGroup
          className={classnames('input-group-merge rounded', {
            focused: search,
          })}
        >
          <Input
            placeholder={languageState.translation.CHAT.SEARCH_MESSAGE}
            type="text"
            onFocus={(e) => setSearch(true)}
            onBlur={(e) => setSearch(false)}
            className="rounded"
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <i className="fas fa-search" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <UncontrolledDropdown className="icon-network" nav>
        <DropdownToggle className="nav-link pr-0" color="" tag="a">
          <div className="ml-auto text-blue c-pointer mt-3">
            <i className="fas fa-ellipsis-h" />
          </div>
        </DropdownToggle>
        <DropdownMenu right>
          {/* <DropdownItem>{languageState.translation.CHAT.PROFILE}</DropdownItem>
          <DropdownItem>{languageState.translation.CHAT.BLOCK}</DropdownItem> */}
          <DropdownItem onClick={openFiles}>
            {languageState.translation.CHAT.FILES}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default ChatHeader;
