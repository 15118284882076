import React from 'react';
import { languagesList as LANGUAGES } from 'config/const/index';
import { Button } from 'reactstrap';
import useSettingsService from 'services/settings/settings.service';

const LanguageList = ({ type, languages, languageState }) => {
  const { getLanguages, deleteLanguage } = useSettingsService();

  const getLanguageName = (language) => {
    const lang = LANGUAGES.find(
      (item) => item.value.toUpperCase() === language.key
    );
    return lang.label;
  };

  const onDeleteLangugage = async (language) => {
    const result = await deleteLanguage(language.id);
    if (result && result.status === 200) {
      getLanguages();
    }
  };

  return (
    <React.Fragment>
      {languages && languages.length > 0 && (
        <div>
          {languages.map((language) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              key={language.id}
            >
              <div>
                <h3>{getLanguageName(language)}</h3>
              </div>
              <div key={language.id}>
                <h5>
                  {languageState.translation.COMMON.LEVELS[language.level]}
                </h5>
              </div>
              {type === 'SETTINGS' && (
                <Button
                  className="ml-5"
                  size="sm"
                  color="danger"
                  onClick={() => onDeleteLangugage(language)}
                >
                  <i className="fas fa-trash" />
                </Button>
              )}
            </div>
          ))}
        </div>
      )}
      {languages && !languages.length && (
        <div className="d-flex justify-content-center">
          <h5>
            {type === 'PROFILE'
              ? languageState.translation.CONNECT.NO_LANGUAGES
              : languageState.translation.SETTINGS.NO_LANGUAGES}
          </h5>
        </div>
      )}
    </React.Fragment>
  );
};

export default LanguageList;
