import { useContext, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { AppContext } from 'store/app.context';

import DeclinedSentInvitations from './DeclinedSentInvitations';
import MyDeclinedInvitations from './MyDeclinedInvitation';

const ModalDeclined = ({ modalShow, closeModal }) => {
  const [numTab, SetNumTab] = useState('1');
  const { languageState } = useContext(AppContext);

  const Modalstyle = {
    height: '30rem',
    maxHeight: '90vh',
    paddingBottom: '3rem',
    marginTop: '-1rem',
    zIndex: 50,
  };
  const paneContent = {
    overflowY: 'auto',
    overflowX: 'hidden',
  };
  const ActivePanel = {
    height: '98%',
  };

  return (
    <Modal isOpen={modalShow} toggle={closeModal}>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        {languageState.translation.CONNECT.DECLINED_INVITATIONS}
      </ModalHeader>
      <ModalBody style={Modalstyle}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={
                numTab === '1'
                  ? '  bg-exprimary text-white active p-2 fs-1'
                  : 'p-2 fs-1'
              }
              role="button"
              onClick={() => {
                SetNumTab('1');
              }}
              to={undefined}
            >
              {languageState.translation.CONNECT.DECLINED_SENT_INVITATIONS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                numTab === '2'
                  ? '  bg-exprimary text-white active p-2 fs-1'
                  : 'p-2 fs-1'
              }
              role="button"
              onClick={() => {
                SetNumTab('2');
              }}
              to={undefined}
            >
              {languageState.translation.CONNECT.MY_DECLINED_INVITATIONS}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={numTab} className=" h-100" style={paneContent}>
          <TabPane tabId="1" style={ActivePanel}>
            <DeclinedSentInvitations />
          </TabPane>
          <TabPane tabId="2" style={ActivePanel}>
            <MyDeclinedInvitations />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};
export default ModalDeclined;
