import { innerRoutesFreelancer } from 'navigation/routes';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import APP_ROUTES from 'navigation/app.routes';
import { AppContext } from 'store/app.context';
import { MODULES } from 'config/core';
import SHARED_STATE from 'store/shared';
import useFocusService from 'services/focus/focus.service';
import PROJECT_STATE from 'store/projects';

const FocusContainer = () => {
  const [focusInnerRoutes, setFocusInnerRoutes] = useState([]);

  const {
    dispatchSharedState,
    projectState,
    dispatchProjectState,
    missionState,
  } = useContext(AppContext);

  const location = useLocation();

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.FOCUS,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  useEffect(() => {
    if (missionState.missions) {
      const splittedPath = location.pathname.split('/');
      const id = splittedPath[splittedPath.length - 1];
      const project = missionState.missions.find((project) => project.id == id);
      dispatchProjectState({
        type: PROJECT_STATE.types.SELECT_PROJECT,
        project,
      });
    }
  }, [missionState.missions]);

  const getInnerRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.parent === MODULES.FOCUS) {
        return (
          <Route
            path={prop.layout + prop.path + '/:id'}
            component={prop.component}
            key={`${key}-${prop.name}`}
          />
        );
      }
      return null;
    });

  useEffect(() => {
    const routes = getInnerRoutes(innerRoutesFreelancer);
    setFocusInnerRoutes(routes);
  }, []);

  return (
    <>
      <Switch>
        <Redirect from="*" to={`/private${APP_ROUTES.FOCUS.DASHBOARD}`} />
      </Switch>
    </>
  );
};

export default FocusContainer;
