import AvailabilityDay from 'components/availability-day/availability-day';
import React, { useEffect, useState } from 'react';
import useSettingsService from 'services/settings/settings.service';

const Availability = () => {
  const { getMyAvailibility, deleteAvalibility } = useSettingsService();

  const [selectedDays, setSelectedDays] = useState([]);
  const [availability, setAvailability] = useState([]);

  const getAvailibilties = async () => {
    const result = await getMyAvailibility();
    if (result && result.status === 200) {
      const onlyWorkingDays = result.data.filter(
        (item) => item.status === 'ON_WORK'
      );

      // Working days
      const days = [];
      const daysConfig = [];
      onlyWorkingDays.forEach((item) => {
        if (!days.includes(item.day)) {
          days.push(item.day);
          const dayConfig = {
            id: item.id,
            day: item.day,
            range: item.workdiary,
          };
          daysConfig.push(dayConfig);
        }
      });
      setSelectedDays(days);
      setAvailability(daysConfig);
    }
  };

  const onDeleteAvailibility = async (id) => {
    const result = await deleteAvalibility(id);
    if (result && result.status === 200) {
      getAvailibilties();
    }
  };

  const selectDay = (day) => {
    setSelectedDays([...selectedDays, day]);
  };

  const removeDay = (day) => {
    const filteredDays = selectedDays.filter((item) => item != day);
    setSelectedDays(filteredDays);
    const id = availability.find((item) => item.day === day)?.id;
    if (id) {
      onDeleteAvailibility(id);
    }
  };

  const getInitialSlotsByDay = (day) => {
    const selectedDay = availability.find((item) => item.day === day);
    if (selectedDay) {
      return selectedDay?.range;
    } else return [];
  };

  const getAvailability = () => {
    getAvailibilties();
  };

  useEffect(() => {
    getAvailibilties();
  }, []);

  return (
    <React.Fragment>
      <AvailabilityDay
        day="SUNDAY"
        selected={selectedDays.includes('SUNDAY')}
        onSelectDay={() => selectDay('SUNDAY')}
        onRemoveDay={() => removeDay('SUNDAY')}
        getAvailability={() => getAvailability()}
        intialSlots={getInitialSlotsByDay('SUNDAY')}
      />
      <AvailabilityDay
        day="MONDAY"
        selected={selectedDays.includes('MONDAY')}
        onSelectDay={() => selectDay('MONDAY')}
        onRemoveDay={() => removeDay('MONDAY')}
        getAvailability={() => getAvailability()}
        intialSlots={getInitialSlotsByDay('MONDAY')}
      />
      <AvailabilityDay
        day="TUESDAY"
        selected={selectedDays.includes('TUESDAY')}
        onSelectDay={() => selectDay('TUESDAY')}
        onRemoveDay={() => removeDay('TUESDAY')}
        getAvailability={() => getAvailability()}
        intialSlots={getInitialSlotsByDay('TUESDAY')}
      />
      <AvailabilityDay
        day="WEDNESDAY"
        selected={selectedDays.includes('WEDNESDAY')}
        onSelectDay={() => selectDay('WEDNESDAY')}
        onRemoveDay={() => removeDay('WEDNESDAY')}
        getAvailability={() => getAvailability()}
        intialSlots={getInitialSlotsByDay('WEDNESDAY')}
      />
      <AvailabilityDay
        day="THURSDAY"
        selected={selectedDays.includes('THURSDAY')}
        onSelectDay={() => selectDay('THURSDAY')}
        onRemoveDay={() => removeDay('THURSDAY')}
        getAvailability={() => getAvailability()}
        intialSlots={getInitialSlotsByDay('THURSDAY')}
      />
      <AvailabilityDay
        day="FRIDAY"
        selected={selectedDays.includes('FRIDAY')}
        onSelectDay={() => selectDay('FRIDAY')}
        onRemoveDay={() => removeDay('FRIDAY')}
        getAvailability={() => getAvailability()}
        intialSlots={getInitialSlotsByDay('FRIDAY')}
      />
      <AvailabilityDay
        day="SATURDAY"
        selected={selectedDays.includes('SATURDAY')}
        onSelectDay={() => selectDay('SATURDAY')}
        onRemoveDay={() => removeDay('SATURDAY')}
        getAvailability={() => getAvailability()}
        intialSlots={getInitialSlotsByDay('SATURDAY')}
      />
    </React.Fragment>
  );
};

export default Availability;
