import React, { useContext, useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Button, Container } from 'reactstrap';
import { AppContext } from 'store/app.context';
import ModalAddUser from 'components/ModalAddUser/ModalAddUser';
import BreadcrumbComponent from 'components/breadcrumb';
import APP_ROUTES from 'navigation/app.routes';

const CardsHeaderFocus = ({ name, parentName, type, subName, breadcrumb, className, published }) => {
  const { languageState } = useContext(AppContext);
  const [showModal, setModalShow] = useState(false);
  return (
    <>
      <div className="header bg-exprimary pb-7 pt-3">
        <Container fluid className="custom-header" >
          {breadcrumb && (
            <div className={`${className} return-overview-page-link`} >
              <BreadcrumbComponent jobDetail={{ label: languageState.translation.RECRUITER.MISSIONS.JOB_OVERVIEW, link: `/private${APP_ROUTES.JOBS.BOARD}` }} breadCrumbActive={name} homeJobs={languageState.translation.COMMON.JOBS} />
            </div>
          )
          }
          <h1 className="text-white big-writing"> {name}</h1>
          {published && (
            <span className='published-job'>{languageState.translation.COMMON.PUBLISHED}: {published}</span>
          )}
          {parentName === 'Teams' && type === 'PROJET' && (
            <Button
              className="h-max-content pt-2 pb-2 rounded-pill pl-6 pr-6 ml-3"
              onClick={() => {
                setModalShow(true);
              }}
            >
              {parentName === 'Teams' &&
                languageState.translation.FOCUS.TEAMS.ADD_TEAM}
            </Button>
          )}
        </Container>
        <Container fluid>
          {subName && <p className='text-bold text-white '>{subName}</p>
          }
        </Container>
        <ModalAddUser
          modalShow={showModal}
          closeModal={() => setModalShow(false)}
        />
      </div>
    </>
  );
};

CardsHeaderFocus.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeaderFocus;
