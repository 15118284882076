import { Field, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FormGroup, InputGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import useAddContactService from 'services/add-contact/add-contact.service';
import { AppContext } from 'store/app.context';
import ExtraInfoContact from './ExtraInfoContact';
import { addContactSchema } from './schema';
import useConnectService from 'services/connect/connect.service';

const ModalAddContact = ({ modalShow, closeModal }) => {
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const { languageState } = useContext(AppContext);
  const { getAllTags } = useConnectService();

  const { addContact } = useAddContactService();

  useEffect(() => {
    getAllTags();
  }, [])

  const addContactHandler = async (values) => {
    const { firstName, lastName, email, privateNotes, jobTitle, personTag, portfolio, sentMail } = values;

    let requestPayload;

    if (showExtraInfo) {
      requestPayload = {
        firstName,
        lastName,
        email,
        note: privateNotes,
        profession: jobTitle,
        personTag,
        portfilioTitle: portfolio,
        sentMail,
      };
    } else {
      requestPayload = {
        firstName,
        lastName,
        email,
      };
    }

    const result = await addContact(requestPayload);
    if (result && result.status === 201) {
      toast.success(languageState.translation.COMMON.SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      closeModal();
    }
  };
  const formikRef = useRef();
  return (
    <Modal centered isOpen={modalShow} className="add-contact-width">
      <ModalHeader>
        {languageState.translation.ADD_CONTACT.COMPLETE_FORM}
      </ModalHeader>
      <ModalBody>
        <Formik
          innerRef={formikRef}
          initialValues={{
            linkedinProfile: '',
            firstName: '',
            lastName: '',
            portfolio: '',
            email: '',
            jobTitle: '',
            privateNotes: '',
            personTag: '',
            showExtraInfoValue: false,
            sentMail: false,
          }}
          validationSchema={addContactSchema(languageState.translation.FORM_VALIDATION)}
          onSubmit={(values) => { addContactHandler(values); }}
        >
          {({ errors, values, setFieldValue, handleSubmit, touched }) => {
            return (
              <div className="add-contact-container">
                <div className="inner-add-contact-container">
                  <FormGroup
                    style={{
                      flex: 1,
                    }}
                  >
                    <Label className="label-style">
                      {languageState.translation.COMMON.FIRST_NAME}
                    </Label>

                    <InputGroup className="input-group-merge input-group-alternative">
                      <Field
                        className="form-control"
                        name="firstName"
                        type="text"
                        value={values.firstName}
                        onChange={(e) => {
                          setFieldValue('firstName', e.target.value);
                        }}
                      />
                    </InputGroup>
                    <small className="text-red">
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                    </small>
                  </FormGroup>

                  <FormGroup
                    style={{
                      flex: 1,
                    }}
                  >
                    <Label className="label-style">
                      {languageState.translation.COMMON.LAST_NAME}
                    </Label>

                    <InputGroup className="input-group-merge input-group-alternative">
                      <Field
                        className="form-control"
                        name="lastName"
                        type="text"
                        value={values.lastName}
                        onChange={(e) => {
                          setFieldValue('lastName', e.target.value);
                        }}
                      />
                    </InputGroup>
                    <small className="text-red">
                      {errors.lastName && touched.lastName && errors.lastName}
                    </small>
                  </FormGroup>
                </div>
                <FormGroup
                  style={{
                    flex: 1,
                  }}
                >
                  <Label className="label-style">
                    {languageState.translation.COMMON.EMAIL}
                  </Label>

                  <InputGroup className="input-group-merge input-group-alternative">
                    <Field
                      className="form-control"
                      name="email"
                      type="text"
                      value={values.email}
                      onChange={(e) => {
                        setFieldValue('email', e.target.value);
                      }}
                    />
                  </InputGroup>
                  <small className="text-red">
                    {errors.email && touched.email && errors.email}
                  </small>
                </FormGroup>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    id="show-extra-info"
                    type="checkbox"
                    checked={showExtraInfo}
                    onChange={(e) => {
                      setShowExtraInfo(e.target.checked);
                      setFieldValue('showExtraInfoValue', e.target.checked);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="show-extra-info"
                  >
                    {languageState.translation.ADD_CONTACT.ADD_EXTRA_INFO}
                  </label>
                </div>
                {showExtraInfo && (
                  <ExtraInfoContact
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                )}
                <div className="footer-contact-modal">
                  <button
                    className="btn btn-close-contact"
                    onClick={() => {
                      closeModal();
                      setShowExtraInfo(false)
                    }}
                  >
                    {languageState.translation.COMMON.CLOSE}
                  </button>
                  <button
                    className="btn btn-submit-contact"
                    onClick={() => handleSubmit()}
                  >
                    {languageState.translation.ADD_CONTACT.SAVE_CONTACT}
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalAddContact;
