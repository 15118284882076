import * as yup from 'yup';
const schema = (validationMessages) =>
  yup.object({
    name: yup.string().required(validationMessages.REQUIRED_FIELD),
    phoneNumber: yup
      .string(),
    email: yup
      .string()
      .email(validationMessages.EMAIL)
      .required(validationMessages.REQUIRED_FIELD),
  });
export default schema;
