import DropwdownFlags from 'components/flags-dropdown';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Nav, NavItem } from 'reactstrap';
import { AppContext } from 'store/app.context';

const PublicNavbar = () => {
  const { languageState } = useContext(AppContext);
  const history = useHistory();
  return (
    <div className="navbar-top navbar-expand border-bottom zindex navbar-dark bg-exprimary">
      <div className="navbar__logo"></div>
      <Container fluid>
        <Nav
          className="align-items-center ml-md-auto py-4 justify-content-end"
          navbar
        >
          <Button
            color="primary"
            className="btn-icon"
            onClick={() => {
              history.push('/login');
            }}
          >
            {languageState.translation.COMMON.LOGIN}
          </Button>
          <DropwdownFlags direction="down" />
        </Nav>
      </Container>
    </div>
  );
};
export default PublicNavbar;
