import TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_PENDING_INVITATIONS:
      return {
        ...state,
        pendingInvitations: action.pendingInvitations,
      };
    case TYPES.GET_PENDING_SENT_INVITATIONS:
      return {
        ...state,
        pendingSentInvitations: action.pendingSentInvitations,
      };
    case TYPES.GET_MY_DECLINED_INVITATIONS:
      return {
        ...state,
        declinedInvitations: action.declinedInvitations,
      };
    case TYPES.GET_SENT_DECLINED_INVITATIONS:
      return {
        ...state,
        declinedSentInvitations: action.declinedSentInvitations,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
