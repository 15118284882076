import React, { useContext } from 'react';
import { Container, Button } from 'reactstrap';
import { AppContext } from 'store/app.context';

const CardFavoris = ({ numberOfusers }) => {
  const { languageState } = useContext(AppContext);

  return (
    <div className="header bg-exprimary pb-7 pt-3 mt--7	">
      <Container
        fluid
        className="d-flex flex-row justify-content-between custom-header mt-7		align-items-center "
      >
        <div>
          <h1 className="text-white big-writing">
            {' '}
            {languageState.translation.CONNECT.FAVORITES}
          </h1>
          <p className="text-white font-weight-bold">
            {' '}
            {numberOfusers} {languageState.translation.CONNECT.ACTIVE_USERS}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default CardFavoris;
