import React from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationComponent = (props) => {
  const { totalItems, pageSize, currentPage, setCurrentPage, type, setDown, setUp } = props;
  const pageCount = Math.ceil(totalItems / pageSize);

  const handleClick = (e, index) => {
    if (index > 0 && index < pageCount + 1) {
      setCurrentPage(index);
      setDown && setDown((currentPage - 1) * pageSize);
      setUp && setUp(currentPage * pageSize);
    }
  };
  // pagination is like < 1...2  3 4 5 6 ...22 >
  const renderPaginationItems = () => {
    const paginationItems = [];

    if (pageCount <= 4) {
      for (let i = 1; i <= pageCount; i++) {
        paginationItems.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink onClick={(e) => {
              e.preventDefault();
              handleClick(e, i)
            }} href="#">
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 4; i++) {
          paginationItems.push(
            <PaginationItem key={i} active={i === currentPage}>
              <PaginationLink onClick={(e) => {
                e.preventDefault();
                handleClick(e, i)
              }} href="#">
                {i}
              </PaginationLink>
            </PaginationItem>
          );
        }
        paginationItems.push(
          <PaginationItem key="ellipsis-next">
            <PaginationLink disabled>...</PaginationLink>
          </PaginationItem>
        );
        paginationItems.push(
          <PaginationItem key={pageCount} active={pageCount === currentPage}>
            <PaginationLink onClick={(e) => {
              e.preventDefault();
              handleClick(e, pageCount)
            }} href="#">
              {pageCount}
            </PaginationLink>
          </PaginationItem>
        );
      } else if (currentPage >= pageCount - 1) {
        paginationItems.push(
          <PaginationItem key={1} active={1 === currentPage}>
            <PaginationLink onClick={(e) => {
              e.preventDefault();
              handleClick(e, 1)
            }} href="#">
              1
            </PaginationLink>
          </PaginationItem>
        );
        paginationItems.push(
          <PaginationItem key="ellipsis-prev">
            <PaginationLink disabled>...</PaginationLink>
          </PaginationItem>
        );
        for (let i = pageCount - 3; i <= pageCount; i++) {
          paginationItems.push(
            <PaginationItem key={i} active={i === currentPage}>
              <PaginationLink onClick={(e) => {
                e.preventDefault();
                handleClick(e, i)
              }} href="#">
                {i}
              </PaginationLink>
            </PaginationItem>
          );
        }
      } else {
        paginationItems.push(
          <PaginationItem key={1} active={1 === currentPage}>
            <PaginationLink onClick={(e) => {
              e.preventDefault();
              handleClick(e, 1)
            }} href="#">
              1
            </PaginationLink>
          </PaginationItem>
        );
        paginationItems.push(
          <PaginationItem key="ellipsis-prev">
            <PaginationLink disabled>...</PaginationLink>
          </PaginationItem>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          paginationItems.push(
            <PaginationItem key={i} active={i === currentPage}>
              <PaginationLink onClick={(e) => {
                e.preventDefault();
                handleClick(e, i)
              }} href="#">
                {i}
              </PaginationLink>
            </PaginationItem>
          );
        }
        paginationItems.push(
          <PaginationItem key="ellipsis-next">
            <PaginationLink disabled>...</PaginationLink>
          </PaginationItem>
        );
        paginationItems.push(
          <PaginationItem key={pageCount} active={pageCount === currentPage}>
            <PaginationLink onClick={(e) => {
              e.preventDefault();
              handleClick(e, pageCount)
            }} href="#">
              {pageCount}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }
    return paginationItems;
  };

  return (
    <Row noGutters className='col-12 col-md-auto justify-content-center mt-3 pr-0'>
      <Pagination className='d-flex mt-2'>
        <PaginationItem disabled={currentPage === 1} className='prev-item mx-4'>
          <PaginationLink onClick={e => {
            e.preventDefault();
            handleClick(e, currentPage - 1)
          }} href='#'></PaginationLink>
        </PaginationItem>
        <Row>
          {renderPaginationItems()}
        </Row>
        <PaginationItem disabled={currentPage === pageCount} className='next-item mx-4'>
          <PaginationLink onClick={e => {
            e.preventDefault();
            handleClick(e, currentPage + 1)
          }} href='#'></PaginationLink>
        </PaginationItem>
      </Pagination>
    </Row>
  );
};

export default PaginationComponent;
