import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import useSettingsService from 'services/settings/settings.service';
import { AppContext } from 'store/app.context';

const AvailabilityDay = ({ selected, day, intialSlots, onRemoveDay, onSelectDay, getAvailability }) => {
  const [slots, setSlots] = useState([]);

  const { languageState } = useContext(AppContext);

  const { addAvailibility } = useSettingsService();

  const addWorkDiary = async (payload) => {
    const result = await addAvailibility(payload);

    if (result && result.status === 201) {
      getAvailability();
    }
  };

  const onAddNewSlot = () => {
    const slot = {
      start: null,
      end: null,
      id: slots.length + 1,
      isWorkingHours: true,
    };
    setSlots([...slots, slot]);
  };

  const onDeleteSlot = (id) => {
    const filteredSlots = slots.filter((item) => item.id !== id);
    setSlots(filteredSlots);
  };

  const changeDayStatus = (e) => {
    if (e.target.checked) {
      onSelectDay();
    } else {
      onRemoveDay();
    }
  };

  const setStartTime = (id, e) => {
    const slot = slots.find((item) => item.id === id);
    slot.start = getDateFromTime(e.target.value);
  };

  const setEndTime = (id, e) => {
    const slot = slots.find((item) => item.id === id);
    slot.end = getDateFromTime(e.target.value);
  };

  const onSaveDay = () => {
    if (selected) {
      const workDiary = slots.map((item) => ({
        start: item.start,
        end: item.end,
        isWorkingHours: true,
      }));

      const reqData = {
        day: day,
        status: 'ON_WORK',
        extraInfo: 'string',
        workDiary,
      };

      addWorkDiary(reqData);
    } else {
      onRemoveDay();
    }
  };

  const getDateFromTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date.toISOString();
  };

  const getTimeFromDate = (date) => {
    if (date) {
      const hours = new Date(date).getHours();
      const minutes = new Date(date).getMinutes();
      return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes
        }`;
    }
    return null;
  };

  useEffect(() => {
    setSlots(intialSlots);
  }, [intialSlots]);

  return (
    <div className="m-2">
      <div className="d-flex justify-content-between">
        <div className="custom-control custom-checkbox d-flex align-items-center  flex-wrap">
          <input
            className="custom-control-input"
            id={'custom-check' + day}
            type="checkbox"
            checked={selected}
            onChange={(e) => changeDayStatus(e)}
          />
          <label
            className="custom-control-label mr-4"
            htmlFor={'custom-check' + day}
            style={{ width: 85 }}
          >
            {languageState.translation.COMMON.DAYS[day]}
          </label>
          {selected && (
            <div className="d-flex align-items-center  flex-wrap">
              {slots &&
                slots.length > 0 &&
                slots.map((slot, index) => (
                  <div
                    key={day + slot.id}
                    className={
                      index !== 0
                        ? 'ml-4 d-flex align-items-center'
                        : 'd-flex align-items-center'
                    }
                  >
                    <input
                      className="form-control"
                      type="time"
                      onChange={(e) => setStartTime(slot.id, e)}
                      value={slot.end ? getTimeFromDate(slot.start) : null}
                    />
                    <span className="mx-2">-</span>
                    <input
                      className="form-control ml-2"
                      type="time"
                      onChange={(e) => setEndTime(slot.id, e)}
                      value={slot.end ? getTimeFromDate(slot.end) : null}
                    />
                    <Button
                      className="ml-2"
                      size="sm"
                      color="danger"
                      onClick={() => onDeleteSlot(slot.id)}
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </div>
                ))}
            </div>
          )}
          {!selected && (
            <span className="text-gray ml-4 mt-2">
              {languageState.translation.COMMON.UNAVAILABLE}
            </span>
          )}
          {selected && slots.length === 0 && (
            <span className="text-gray ml-4 mt-2">
              {languageState.translation.COMMON.NO_TIME_SLOT}
            </span>
          )}
        </div>
        <div className="ml-5 d-flex align-items-center">
          <Button
            className=""
            size="sm"
            color="primary"
            disabled={slots.length >= 2 || !selected}
            onClick={() => onAddNewSlot()}
          >
            <i className="fas fa-plus" />
          </Button>
          <Button
            className="btn-exprimary"
            size="sm"
            color="primary"
            onClick={() => onSaveDay()}
            disabled={!slots || slots.length === 0 || !selected}
          >
            {languageState.translation.COMMON.BUTTONS.SAVE}
          </Button>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default AvailabilityDay;
