import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { AppContext } from 'store/app.context';
import { Formik } from 'formik';
import schema from './validators';
import useFocusService from 'services/focus/focus.service';
import { toast } from 'react-toastify';
import moment from 'moment';

const ModalFocusEditTask = ({ modalShow, closeModal, projectId, data }) => {
  const { languageState } = useContext(AppContext);
  const [endDate, setEndDate] = useState(false);
  const [startdDate, setStartDate] = useState(false);
  const { editTask, getMisions } = useFocusService();

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
        className="mb-0"
      >
        {languageState.translation.RECRUITER.TASKS.EDIT_TASK}
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <Formik
          initialValues={data}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={async (values) => {
            const result = await editTask({
              ...values,
              projet: projectId,
              id: data.id,
            });
            if (result && result.status === 200) {
              closeModal();
              await getMisions();
              toast.success(
                languageState.translation.FOCUS.DASHBOARD.Task_ADDED,
                {
                  position: 'bottom-right',
                  hideProgressBar: false,
                  autoClose: 4000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                }
              );
            }
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.NAME}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.NAME}
                name="name"
                onChange={handleChange}
                defaultValue={data.name}
              />
              <small className="text-red">
                {' '}
                {errors.name && touched.name && errors.name}
              </small>
              <Label className="d-block mt-1">
                {' '}
                {languageState.translation.COMMON.DESCRIPTION}
              </Label>
              <Input
                type="textarea"
                placeholder={languageState.translation.COMMON.DESCRIPTION}
                name="description"
                defaultValue={data.description}
                onChange={handleChange}
              />
              <Label className="d-block">
                {' '}
                {languageState.translation.COMMON.START_DATE}
              </Label>
              <ReactDatetime
                closeOnSelect
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('startDate', date)}
                timeFormat={false}
                dateFormat={'DD-MM-YYYY'}
                onFocus={(e) => setStartDate(true)}
                initialValue={data.startDate ? moment(data.startDate).format('MM-D-YYYY') : new Date()}
                onBlur={(e) => setStartDate(false)}
              />
              <small className="text-red">
                {errors.startDate && touched.startDate && errors.startDate}
              </small>

              <Label className="d-block">
                {languageState.translation.COMMON.END_DATE}
              </Label>
              <ReactDatetime
                closeOnSelect
                inputProps={{
                  placeholder: languageState.translation.COMMON.DATE,
                }}
                onChange={(date) => setFieldValue('endDate', date)}
                timeFormat={false}
                dateFormat={'DD-MM-YYYY'}
                onFocus={(e) => setEndDate(true)}
                initialValue={data.endDate ? moment(data.endDate).format('MM-D-YYYY') : new Date()}
                onBlur={(e) => setEndDate(false)}
              />
              <small className="text-red">
                {errors.endDate && touched.endDate && errors.endDate}
              </small>

              <button
                className="btn bg-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalFocusEditTask;
