import ModalGroup from 'components/modalGroup/ModalGroup';
import { IconOption, Control } from 'components/ModalAddToGroup/SelectProps';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Button, Label } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';

const ModalAddToGroup = ({ modalShow, closeModal, userId }) => {
  const [selected, setSelected] = useState('');
  const [modalGroupShow, setModalGroupShow] = useState(false);

  const { addUserToGroup, getGroups } = useConnectService();
  const {
    languageState,
    connectState: { groups },
  } = useContext(AppContext);
  useEffect(() => {
    getAllGroups();
  }, []);
  const getAllGroups = async () => {
    await getGroups();
  };
  const handleAddUserToGroup = async () => {
    const result = await addUserToGroup(selected, userId);
    if (result && result.status === 201) {
      toast.success(languageState.translation.ALERT.ADD_USER_GROUP, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    closeModal();
  };
  const formatOption = (listGroups) => {
    if (listGroups && listGroups.length > 0) {
      return listGroups.map((data) => {
        return {
          value: data.id,
          label: `${data.groupName}`,
          icon: data.icon,
        };
      });
    }
    return [];
  };
  return (
    <>
      <Modal isOpen={modalShow} toggle={closeModal} centered>
        <ModalHeader
          close={
            <button className="close " onClick={closeModal}>
              ×
            </button>
          }
        >
          {languageState.translation.CONNECT.ADD_TO_GROUP}
        </ModalHeader>
        <ModalBody>
          <Label className="mt-n3">Select un Group</Label>

          <Select
            isClearable
            isSearchable
            options={formatOption(groups)}
            onChange={(e) => setSelected(e.value)}
            components={{ Option: IconOption, Control: Control }}
          />
          <div className="d-flex align-items-center mt-5">
            <Button
              color="primary"
              className="btn-exprimary"
              disabled={selected == '' ? true : false}
              onClick={handleAddUserToGroup}
            >
              {languageState.translation.COMMON.BUTTONS.SAVE}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setModalGroupShow(true);
              }}
            >
              {languageState.translation.CONNECT.CREATE_NEW_GROUP}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <ModalGroup
        modalShow={modalGroupShow}
        closeModal={() => {
          setModalGroupShow(false);
        }}
      />
    </>
  );
};

export default ModalAddToGroup;
