// @ts-ignore
import { Formik, Field } from 'formik';
import schema from './schema';
import React, { useContext, useState } from 'react';
import { CirclePicker } from 'react-color';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, InputGroup, Label } from 'reactstrap';
// @ts-ignore
import useConnectService from 'services/connect/connect.service';
// @ts-ignore
import { AppContext } from 'store/app.context';
import { toast } from 'react-toastify';
import Reactselect from './Reactselect';
import useFilesService from '../../services/files/files.services';

const ModalGroup = ({ modalShow, closeModal }) => {
  const { languageState } = useContext(AppContext);
  const { addGroup, getGroups } = useConnectService();
  const [color, setColor] = useState('#03a9f4');
  const [backgroundImage, setBackgroundImage] = useState('');
  const { uploadFile } = useFilesService();

  const groupeTypeOptions = [
    {
      value: 'public',
      label: languageState.translation.CONNECT.PUBLIC,
    },
    {
      value: 'private',
      label: languageState.translation.CONNECT.PRIVATE,
    },
  ];

  const initialValues = {
    groupName: '',
    color: '#03a9f4',
    icon: '',
    type: '',
  };

  const submitForm = async (data) => {
    let reqData;
    if (backgroundImage) {
      const resultFile = await uploadFile(backgroundImage);
      if (resultFile && resultFile.status === 201) {
        reqData = { ...data, icon: resultFile.data.filename };
        const result = await addGroup(reqData);
        if (result && result.status === 201) {
          toast.success(languageState.translation.ALERT.GROUP_SAVED, {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          await getGroups();
          closeModal();
        }
      }
    } else {
      reqData = { ...data };
      const result = await addGroup(reqData);
      if (result && result.status === 201) {
        toast.success(languageState.translation.ALERT.GROUP_SAVED, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        await getGroups();
        closeModal();
      }
    }
  };

  return (
    <Modal
      isOpen={modalShow}
      toggle={closeModal}
      centered
      className="groupe-modal"
    >
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        <p className="font-weight-bold w-100">
          {languageState.translation.CONNECT.CREATE_NEW_GROUP}
        </p>
        <p className="groupe-modal-header-text-details">
          {languageState.translation.INNER_NAVIGATION.GROUPS.GROUP_PURPOSE}
        </p>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={(values) => submitForm({ ...values, color })}
        >
          {({ errors, touched, values, handleSubmit, setFieldValue }) => {
            return (
              <>
                <div className="groupe-container">
                  <div className="groupe-first-block">
                    <FormGroup>
                      <Label className="label-style">
                        {languageState.translation.CONNECT.GROUPE_NAME}
                      </Label>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <Field
                          className="form-control"
                          placeholder={
                            languageState.translation.CONNECT.GROUPE_NAME
                          }
                          name="groupName"
                          type="text"
                        />
                      </InputGroup>
                      {errors.groupName && touched.groupName && (
                        <div className="invalid-feedback">
                          {errors.groupName}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="label-style">
                        {
                          languageState.translation.INNER_NAVIGATION.GROUPS
                            .GROUPE_TYPE
                        }
                      </Label>
                      <Reactselect options={groupeTypeOptions} name="type" />
                      {errors.type && touched.type && (
                        <div className="invalid-feedback">{errors.type}</div>
                      )}
                    </FormGroup>
                  </div>
                  <div className="groupe-second-block">
                    <FormGroup>
                      <div className="file-title-container">
                        <Label className="label-style">
                          {' '}
                          {
                            languageState.translation.INNER_NAVIGATION.GROUPS
                              .GROUPE_BACKGROUND_IMAGE
                          }
                        </Label>
                        <Label className="label-style">
                          {
                            languageState.translation.INNER_NAVIGATION.GROUPS
                              .GROUPE_FILE_SIZE
                          }
                        </Label>
                      </div>
                      <div className="input-file-container">
                        <label className="file-label">
                          <input
                            type={'file'}
                            style={{ display: 'none' }}
                            name="icon"
                            onChange={(e) => {
                              setFieldValue('icon', e.target.files[0]);
                              setBackgroundImage(e.target.files[0]);
                            }}
                          />
                          {backgroundImage === ''
                            ? languageState.translation.INNER_NAVIGATION.GROUPS
                              .GROUPE_FILE_SIZE
                            : backgroundImage.name}
                        </label>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="label-style">
                        {' '}
                        {
                          languageState.translation.INNER_NAVIGATION.GROUPS
                            .GROUPE_INSPIRATION
                        }
                      </Label>
                      <div>
                        <CirclePicker
                          color={color}
                          onChangeComplete={(color) => setColor(color.hex)}
                        />
                      </div>
                    </FormGroup>
                  </div>

                  {/* <FormGroup>
      <Label>{languageState.translation.COMMON.ICON}</Label>
      <Reactselect options={options} name="icon" />
    </FormGroup> */}
                </div>
                <div className="buttons-container">
                  <Button
                    onClick={() => handleSubmit()}
                    className="btn-exprimary"
                    color="primary"
                  >
                    {
                      languageState.translation.INNER_NAVIGATION.GROUPS
                        .SAVE_GROUPE
                    }
                  </Button>
                  <button className="custom-button" onClick={closeModal}>
                    {languageState.translation.COMMON.CLOSE}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalGroup;
