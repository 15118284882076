import TYPES from './home.types';
import reducer from './home.reducer';

const INITIAL_STATE = {
  quickLinks: [],
  events: [],
  feeds: [],
  nbNewJobs: null
};

const HOME_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default HOME_STATE;
