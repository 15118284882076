import React, { useContext, useEffect, useState } from 'react';
// node.js library that concatenates classes (strings)
import classnames from 'classnames';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';

// core components
import CardsFocus from 'components/cards/card-focus';
import { AppContext } from 'store/app.context';
import FocusCalendar from 'pages/focus/calendar';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import { useLocation, useParams } from 'react-router-dom';
import moment, { lang } from 'moment';
import classNames from 'classnames';
import { TASK_STATUS } from 'config/core';
import PROJECT_STATE from 'store/projects';
import SHARED_STATE from 'store/shared';
import { MODULES } from 'config/core';
import ExEditor from 'components/editor/editor';
import ModalFocusAddTask from 'components/modalFocusAddtask/ModalAddTask';
import ModalMainInfo from 'components/modalmaininfo/ModalMainInfo';
import ModalFocusEditTask from 'components/modalFocusAddtask/ModalEditTask';
import useFocusService from 'services/focus/focus.service';
import { toast } from 'react-toastify';

const FocusDashboard = () => {
  const {
    languageState,
    missionState,
    projectState,
    dispatchProjectState,
    dispatchSharedState,
  } = useContext(AppContext);
  const [selectedProject, setSelectedProject] = useState(null);
  const [html, setHtml] = useState();
  const [idproject, setIdProject] = useState('');
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [data, setData] = useState({});

  const [modalInfoShow, setModalInfoShow] = useState(false);
  const idParam = useParams();
  const [mainInfo, setmainInfo] = useState('');
  useEffect(() => {
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.FOCUS,
    });
  }, []);
  const { deleteTask, getMisions } = useFocusService();

  useEffect(() => {
    const splittedPath = location.pathname.split('/');
    const id = splittedPath[splittedPath.length - 1];
    setIdProject(id);
    if (missionState.missions) {
      const project = missionState.missions.find((project) => project.id == id);
      setSelectedProject(project);
      dispatchProjectState({
        type: PROJECT_STATE.types.SELECT_PROJECT,
        project,
      });
    }
    // @ts-ignore
  }, [missionState.missions, idParam.id]);

  const params = useParams();

  const [activeNav, setActiveNav] = React.useState(1);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };
  const DeleteTask = async (id) => {
    const result = await deleteTask(id);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.TASK_DELETED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      getMisions();
    }
  };
  if (window.Chart) {
    //parseOptions(Chart, chartOptions());
  }
  return (
    <>
      {selectedProject && (
        <>
          <CardsHeaderFocus
            name={selectedProject.name}
            parentName="Dashboards"
          />
          <Container className="mt-3" fluid>
            <div className="parent  mb-3">
              {selectedProject.tools.map((tool, index) => (
                <CardsFocus
                  name={tool.name}
                  key={tool.id}
                  link={tool.link}
                  id={tool?.id}
                  idproject={idproject}
                />
              ))}
              <CardsFocus
                name="AJOUTER NOUVEAU SHORTCUT"
                idproject={idproject}
              />
            </div>
            <Row>
              <Col xl="4">
                <Card className="height-30">
                  <CardHeader className="d-flex justify-content-between w-100 align-items-center">
                    <h5 className="h3 mb-0">
                      {
                        languageState.translation.FOCUS.DASHBOARD.TO_DO
                          .TO_DO_LIST
                      }
                    </h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => setModalShow(true)}
                      className="btn-exprimary"
                    >
                      {' '}
                      {languageState.translation.FOCUS.DASHBOARD.ADD_ITEM}
                    </Button>
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup data-toggle="checklist" flush>
                      {selectedProject.tasks.map((task, index) => (
                        <ListGroupItem key={index} className="checklist-entry flex-column align-items-start py-4 px-4">
                          <div
                            className={classNames(
                              'checklist-item  ',
                              {
                                'checklist-item-success checklist-item-checked':
                                  task.status === TASK_STATUS.DONE,
                              },
                              {
                                'checklist-item-warning':
                                  task.status === TASK_STATUS.IN_PROGRESS,
                              }
                            )}
                          >
                            <div className="checklist-info">
                              <h5 className="checklist-title mb-0">
                                {' '}
                                {task.name}
                              </h5>
                              <small>
                                {moment(task?.createdAt).format('LLL')}
                              </small>
                            </div>
                            <div>
                              <div
                                className={classNames(
                                  'custom-control custom-checkbox',
                                  {
                                    'custom-checkbox-success':
                                      task.status === TASK_STATUS.DONE,
                                  },
                                  {
                                    'custom-checkbox-warning':
                                      task.status === TASK_STATUS.IN_PROGRESS,
                                  }
                                )}
                              >
                                <UncontrolledDropdown
                                  className="icon-network"
                                  nav
                                >
                                  <DropdownToggle
                                    className="nav-link pr-0"
                                    color=""
                                    tag="a"
                                  >
                                    <div className="p-1 pl-2 pr-2  text-black rounded   shadow c-pointer ">
                                      <i className="fas fa-ellipsis-h"></i>
                                    </div>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem
                                      onClick={() => {
                                        setModalEditShow(true);
                                        setData(task);
                                      }}
                                    >
                                      {
                                        languageState.translation.COMMON.BUTTONS
                                          .EDIT
                                      }
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        DeleteTask(task.id);
                                      }}
                                    >
                                      {
                                        languageState.translation.COMMON.BUTTONS
                                          .DELETE
                                      }
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setmainInfo(task?.description);
                                        setModalInfoShow(true);
                                      }}
                                    >
                                      {
                                        languageState.translation.COMMON
                                          .OPEN_DETAILS
                                      }
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col className="custom-calendar height-30" xl="5">
                <FocusCalendar className="overflow-hidden height-30" />
              </Col>
              <Col xl="3">
                <Card className="height-30">
                  <CardHeader className="font-weight-bold">
                    {languageState.translation.FOCUS.DASHBOARD.QUICK_NOTES}
                  </CardHeader>
                  <CardBody className="d-flex flex-column ">
                    <ExEditor
                      placeholder="Quill WYSIWYG"
                      initialContent="Quill WYSIWYG"
                      onChangeContent={(html) => setHtml(html)}
                    />
                    <Button
                      className="mt-auto w-max-content ml-auto btn-exprimary"
                      color="primary"
                    >
                      {languageState.translation.FOCUS.DASHBOARD.SAVE_NOTE}{' '}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ModalFocusAddTask
              modalShow={modalShow}
              closeModal={() => setModalShow(false)}
              projectId={idproject}
            />
            <ModalFocusEditTask
              modalShow={modalEditShow}
              closeModal={() => setModalEditShow(false)}
              projectId={idproject}
              data={data}
            />
            <ModalMainInfo
              modalShow={modalInfoShow}
              closeModal={() => setModalInfoShow(false)}
              mainInfo={mainInfo}
              header={
                languageState.translation.FOCUS.DASHBOARD.TASK_DESCRIPTION
              }
            />
          </Container>
        </>
      )}
    </>
  );
};

export default FocusDashboard;
