import TYPES from './skills.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_SKILLS:
      return {
        ...state,
        skills: action.skills,
      };
    case TYPES.SET_SKILLS_MINE:
      return {
        ...state,
        mine: action.mine,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
