import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@fullcalendar/common/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/scss/argon-dashboard-pro-react.scss?v1.2.0';
import 'react-toastify/dist/ReactToastify.css';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import AppProvider from 'store/app.context';

const elem = (
  <AppProvider>
    <App />
  </AppProvider>
);

ReactDOM.render(elem, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
