import TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case TYPES.SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.project,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
