import React from 'react';

const SingelImageUpload = ({ onChanges, onDeleteImage }) => {
  return (
    <div className="custom-file">
      <input
        className="custom-file-input"
        id="customFileLang"
        lang="en"
        type="file"
        onChange={(e) => {
          onChanges(e.target.files[0]);
        }}
      />
      <label className="custom-file-label" htmlFor="customFileLang">
        Selecteer en laad jouw C.V. op
      </label>
    </div>
  );
};

export default SingelImageUpload;
