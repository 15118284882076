export const M_USERS = [
  {
    id: 'b8c53d13-1cd1-4bce-be8c-c559be8c93bd',
    email: 'sayah_avion@outlook.fr',
    password: '$2b$10$fCKwTnS7i9M/aEgUV8To1ezMDqCo..i9ViUXERFlpksbx3Vsxq22O',
    kind: 'FREELANCER',
    isMailVerfied: true,
    name: 'Ahmed',
    userName: null,
    firstName: null,
    lastName: null,
    resume: null,
    address: null,
    region: null,
    country: null,
    postalCode: null,
    VATNumber: null,
    aboutMe: null,
    profileImage: null,
    profileCover: null,
    phoneNumber: null,
    birthDate: null,
    hourlyRate: 10,
    dailyRate: 10,
    experienceYears: 0,
    extraInfo: null,
    workType: null,
    workingTime: 'FULL_TIME',
    skills: [],
    position: null,
  },
  {
    id: '6d5ad548-7ce5-4f99-997a-a07273fc6151',
    email: 'sayah_avion@outlook.com',
    password: '$2b$10$UuQyZK3N8asBGHlhyNJNEeqy8GDAwn1XbDdh.oVWBSQdH341CIxNS',
    kind: 'FREELANCER',
    isMailVerfied: true,
    name: 'mehdi bay',
    userName: null,
    firstName: null,
    lastName: null,
    resume: null,
    address: null,
    region: null,
    country: null,
    postalCode: null,
    VATNumber: null,
    aboutMe: null,
    profileImage: null,
    profileCover: null,
    phoneNumber: null,
    birthDate: null,
    hourlyRate: 10,
    dailyRate: 10,
    experienceYears: 0,
    extraInfo: null,
    workType: null,
    workingTime: 'FULL_TIME',
    skills: [],
    position: null,
  },
  {
    id: 'e738fe59-a25d-4435-a867-7dcce2de2892',
    email: 'med.mallat.official@gmail.com',
    password: '$2b$10$FZUMNltx.B1mno2qDHL/.OmDvZufWkIV15vV/qSUCCtMwvtsQmOhu',
    kind: 'FREELANCER',
    isMailVerfied: true,
    name: 'Med Ben Outhmen',
    userName: null,
    firstName: null,
    lastName: null,
    resume: null,
    address: null,
    region: null,
    country: null,
    postalCode: null,
    VATNumber: null,
    aboutMe: null,
    profileImage: null,
    profileCover: '4a92c5d1-d23c-4505-a2ac-d585e94e09c9-f9d7.jpg',
    phoneNumber: null,
    birthDate: null,
    hourlyRate: 10,
    dailyRate: 10,
    experienceYears: 0,
    extraInfo: null,
    workType: null,
    workingTime: 'FULL_TIME',
    skills: [],
    position: null,
  },
  {
    id: 'b2d2ce06-5ef7-4674-90ed-719cdc3b1e8a',
    email: 'sayahayoub9827@gmail.com',
    password: '$2b$10$0lto3XCo.3upgGKNQp61yej1xY2eEhAKLfh4ns0zcxYqHF282lf6S',
    kind: 'FREELANCER',
    isMailVerfied: true,
    name: 'Ayoub Sayah',
    userName: null,
    firstName: 'Ayoub',
    lastName: 'Sayah',
    resume: 'AyoubSayah(1)-84f9.pdf',
    address: '',
    region: 'Brussel',
    country: '',
    postalCode: '1000',
    VATNumber: '14002096',
    aboutMe:
      'im  a  frontend developer with 2 years of experience i built projects with react and angular  ',
    profileImage: '119044370_2724713107806101_3440834244355790783_n-11b7.jpg',
    profileCover: 'image-10488.jpg',
    phoneNumber: '+3225834026',
    birthDate: null,
    hourlyRate: 10,
    dailyRate: 10,
    experienceYears: 4,
    extraInfo: null,
    workType: null,
    workingTime: 'PART_TIME',
    skills: [
      {
        id: 'a6ddccb2-ef13-4c66-8e6f-6822484cbb45',
        key: 'react',
      },
      {
        id: 'ae7769c5-8d6c-498c-927c-1ed7f7cd8e9c',
        key: 'angular',
      },
      {
        id: '74204986-8c88-4297-8f35-ccfcaf6268d4',
        key: 'html',
      },
      {
        id: 'f74544f8-d5ae-45e6-a9ee-914739b976ff',
        key: 'css',
      },
      {
        id: '5a619938-463e-40cf-ba98-7dbd3b57d402',
        key: 'javascript',
      },
    ],
    position: {
      id: 'eec168a0-8292-4df2-a5ef-2de31238ecc5',
      label: 'frontend',
    },
  },
  {
    id: 'ff658b95-ae0b-4dd0-a57a-44545238fe1e',
    email: 'sadokrec@yopmail.com',
    password: '$2b$10$GmZFeQXwuWW1z6VuFlX69.mYnrs.XZksHxsXizRs4IdyIgb/y0SXy',
    kind: 'COMPANY',
    isMailVerfied: true,
    name: 'Sadokrec',
    userName: null,
    firstName: null,
    lastName: null,
    resume: null,
    address: null,
    region: null,
    country: null,
    postalCode: null,
    VATNumber: null,
    aboutMe: null,
    profileImage: null,
    profileCover: null,
    phoneNumber: null,
    birthDate: null,
    hourlyRate: 10,
    dailyRate: 10,
    experienceYears: 0,
    extraInfo: null,
    workType: null,
    workingTime: 'FULL_TIME',
    skills: [],
    position: null,
  },
  {
    id: 'cf67ba23-2b4a-4a81-ac48-4eaea0110e5d',
    email: 'mohamedmallat40@gmail.com',
    password: '$2b$10$2RAmZqSvhJndGmmgtirjbe36gGk5SaTTWpLmbDIOt45I409FNN30m',
    kind: 'FREELANCER',
    isMailVerfied: true,
    name: 'Mohamed Mallat',
    userName: null,
    firstName: 'Mohamed',
    lastName: 'Mallat',
    resume: 'Hamdisakkacv-f455.pdf',
    address: 'rue abou elkacem chebbi',
    region: 'Laken',
    country: '',
    postalCode: '1020',
    VATNumber: '11911328',
    aboutMe:
      'Am Mallat Full stack web developer with more than 5 years of experiences',
    profileImage: '103783729_3218057184906840_1419426775664461657_n-9ae3.jpg',
    profileCover: 'background-med-0571.jpg',
    phoneNumber: '',
    birthDate: null,
    hourlyRate: 32,
    dailyRate: 10,
    experienceYears: 6,
    extraInfo: 'extraaaa',
    workType: null,
    workingTime: 'PART_TIME',
    skills: [
      {
        id: 'ae7769c5-8d6c-498c-927c-1ed7f7cd8e9c',
        key: 'angular',
      },
    ],
    position: {
      id: 'eec168a0-8292-4df2-a5ef-2de31238ecc5',
      label: 'frontend',
    },
  },
];
