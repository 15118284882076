import AddTagOwnerModal from 'components/add-tag-owner-modal/add-tag-owner-modal';
import React, { useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';

const CardHeaderTagged = ({ numberOfTaggedUsers }) => {
  const [showModal, setShowModal] = useState(false);
  const { languageState } = useContext(AppContext);

  return (
    <>
      <div className="header bg-exprimary pb-7 pt-3 mt--7	">
        <Container
          fluid
          className="d-flex flex-row justify-content-between custom-header mt-7		align-items-center "
        >
          <Row>
            <Col>
              <div>
                <h1 className="text-white big-writing text-xl">
                  {languageState.translation.CONNECT.TAGGED_FREELANCERS}
                </h1>
                <div>
                  <h1 className="text-white big-writing text-xs">
                    {numberOfTaggedUsers}{' '}
                    {languageState.translation.CONNECT.TAGGED_FREELANCERS}
                  </h1>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <h1 className="text-white big-writing text-xl">
                  <Button
                    className="big-writing text-xs rounded"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {languageState.translation.CONNECT.CREATE_NEW_TAG}
                  </Button>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <AddTagOwnerModal
          modalShow={showModal}
          closeModal={() => {
            setShowModal(false);
          }}
        />
      </div>
    </>
  );
};

export default CardHeaderTagged;
