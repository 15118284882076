import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';

const AddTagOwnerModal = ({ modalShow, closeModal }) => {
  const { languageState } = useContext(AppContext);

  const [tagName, setTagName] = useState('');

  const { createOwnerTag, getAllTags } = useConnectService();

  const handleChange = (event) => {
    setTagName(event.target.value);
  };

  const handleSubmit = async () => {
    if (tagName === '') return;
    const result = await createOwnerTag({
      name: tagName,
    });
    if (result && result.status === 201) {
      toast.success(languageState.translation.ALERT.TAGS_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      closeModal();
      getAllTags();
    }
  };
  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
      >
        {languageState.translation.CONNECT.ADD_TAG}
      </ModalHeader>
      <ModalBody
      // @ts-ignore
      >
        <Input id="tagName" name="text" type="text" onChange={handleChange} />
        <Button
          disabled={tagName.trim() === ''}
          className=" mt-5 ml-auto btn-exprimary"
          color="primary"
          onClick={handleSubmit}
        >
          {languageState.translation.COMMON.BUTTONS.SUBMIT}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default AddTagOwnerModal;
