import TYPES from './types';

const deleteFirstLaunchCookie = () => {
  document.cookie = 'firstLaunch=;path=/';
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.OPEN_STEPPER:
      return {
        ...state,
        openStepper: true,
      };

    case TYPES.CLOSE_STEPPER:
      deleteFirstLaunchCookie();
      return {
        ...state,
        openStepper: false,
      };

    case TYPES.INITIATE_STEP:
      return {
        ...state,
        currentStep: 0,
      };

    case TYPES.INCREMENT_STEP:
      if (state.currentStep + 1 <= 3) {
        return {
          ...state,
          currentStep: state.currentStep + 1,
        };
      }

    case TYPES.CHANGE_CURRENT_STEPPER_STATE:
      const stepperIndex = state.stepperStatus.findIndex(
        ({ step }) => step === action.currentStepPayload
      );

      const stepperStatusClone = [...state.stepperStatus];
      if (stepperIndex !== -1) {
        stepperStatusClone[stepperIndex].isCompleted = true;
        if (action.currentStepPayload === 3) {
          return {
            ...state,
            isStepperEnded: true,
          };
        } else if (action.currentStepPayload + 1 <= 3) {
          return {
            ...state,
            stepperStatus: stepperStatusClone,
            currentStep: state.currentStep + 1,
          };
        }
      }
      return { ...state };
  }
};

export default reducer;
