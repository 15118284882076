import { KINDS } from 'config/core';
import { M_DEGREES } from '../degrees';
import { M_EXPERIENCES } from '../experiences';
import { M_LANGUAGES } from '../languages';
import { M_SKILLS } from '../skills';

export const M_TEAMS = [
  {
    id: 1,
    email: 'bagi@mailinator.com',
    userName: 'medmlt',
    kind: KINDS.FREELANCER,
    role: 'Project Manager',
    firstName: 'Med',
    lastName: 'Mallat',
    address: '7 Rue Bouali Lahouar Sousse',
    city: 'Sousse',
    country: 'Tunisia',
    postalCode: '4000',
    VATNumber: 'RC185024JSX',
    aboutMe:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
    profileImage: 'assets/img/theme/team-3.png',
    coverImage: 'assets/img/theme/team-3.png',
    phoneNumber: '+21697200300',
    dailyRate: 350,
    hourlyRate: 45,
    experienceYears: 3,
    isAvailable: true,
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',

    birthDate: '1992-05-13T22:05:36.694Z',
    skills: M_SKILLS,
    experiences: M_EXPERIENCES,
    languages: M_LANGUAGES,
    degree: M_DEGREES,
  },
  {
    id: 1,
    email: 'voxy@mailinator.com',
    userName: 'martinlot',
    kind: KINDS.FREELANCER,
    role: 'Project Manager',
    firstName: 'Marthin',
    lastName: 'Luthinburg',
    address: '7 Avenue la liberté',
    city: 'Liege',
    country: 'Belgium',
    postalCode: '4000',
    VATNumber: 'RC192000JSX',
    aboutMe:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
    profileImage: 'assets/img/theme/team-4.png',
    coverImage: 'assets/img/theme/team-4.png',
    phoneNumber: '+302964558528',
    dailyRate: 230,
    hourlyRate: 20,
    experienceYears: 1,
    isAvailable: true,
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    birthDate: '1995-12-13T22:05:36.694Z',
    skills: M_SKILLS,
    experiences: M_EXPERIENCES,
    languages: M_LANGUAGES,
    degree: M_DEGREES,
  },
  {
    id: 1,
    email: 'riley.daniels@example.com',
    userName: 'medmlt',
    kind: KINDS.FREELANCER,
    role: 'Project Manager',
    firstName: 'Riley',
    lastName: 'Daniels',
    address: '4995 Samaritan Dr',
    city: 'Rome',
    country: 'Italy',
    postalCode: '4000',
    VATNumber: 'RC185024JSX',
    aboutMe:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem sapiente quisquam vero alias iusto! Officiis quibusdam ex, debitis voluptate vero animi, similique fugit ut repellat vel facilis, eius pariatur quos.',
    profileImage: 'assets/img/theme/team-3.png',
    coverImage: 'assets/img/theme/team-3.png',
    phoneNumber: '(342)-842-1848',
    dailyRate: 600,
    hourlyRate: 68,
    experienceYears: 6,
    isAvailable: false,
    createdAt: '2021-12-13T22:05:36.694Z',
    updatedAt: '2021-12-13T22:05:36.694Z',
    birthDate: '1989-12-13T22:05:36.694Z',
    skills: M_SKILLS,
    experiences: M_EXPERIENCES,
    languages: M_LANGUAGES,
    degree: M_DEGREES,
  },
];
