import { MODULES } from 'config/core';
import React, { Fragment, useContext, useEffect } from 'react';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';

const JobsContainer = ({ children }) => {
  const { dispatchSharedState } = useContext(AppContext);

  useEffect(() => {
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.JOBS,
    });
  }, []);
  return (
    <Fragment>
      <div className='jobs-container'>
        {children}
      </div>
    </Fragment>
  );
};

export default JobsContainer;
