import * as yup from 'yup';

const schema = (validationMessages) =>
  yup.object({
    name: yup
      .string()
      .min(
        3,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      )
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      )
      .required(validationMessages.REQUIRED_FIELD),
    email: yup
      .string()
      .email(validationMessages.EMAIL)
      .required(validationMessages.REQUIRED_FIELD),
    firstName: yup
      .string()
      .required('required')
      .min(
        3,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      )
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      ),
    lastName: yup
      .string()
      .min(
        3,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      )
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      ),
    address: yup
      .string()
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      ),
    region: yup.string(),
    country: yup.string(),
    VATNumber: yup
      .string()
      .min(
        4,
        `${validationMessages.MIN_LENGTH_FIELD} 4 ${validationMessages.CHARS}`
      )
      .max(
        16,
        `${validationMessages.MAX_LENGTH_FIELD} 16 ${validationMessages.CHARS}`
      ),
    postalCode: yup.string(),
    phoneNumber: yup
      .string()
      .matches(/^(?:\+?04\d{2}(?:\s?\d{2}){3})$/, validationMessages.PHONE),
    aboutMe: yup
      .string()
      .max(
        100,
        `${validationMessages.MAX_LENGTH_FIELD} 100 ${validationMessages.CHARS}`
      ),
  });

export const basicInformationAddressSchema = yup.object().shape({});
export const userInformationSchema = (validationMessages) =>
  yup.object().shape({
    email: yup
      .string()
      .email(validationMessages.EMAIL)
      .required(validationMessages.REQUIRED_FIELD),
    firstName: yup
      .string()

      .min(
        3,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      )
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      ),
    lastName: yup
      .string()
      .min(
        3,
        `${validationMessages.MIN_LENGTH_FIELD} 3 ${validationMessages.CHARS}`
      )
      .max(
        32,
        `${validationMessages.MAX_LENGTH_FIELD} 32 ${validationMessages.CHARS}`
      ),
  });
export const aboutMeSchema = yup.object().shape({});
export const linksSchema = (validationMessages) =>
  yup.object().shape({
    linkedinProfile: yup.string().required(validationMessages.REQUIRED_FIELD),

    profileWebsite: yup.string().required(validationMessages.REQUIRED_FIELD),
  });
export const passwordSchema = (validationMessages) =>
  yup.object().shape({
    currentPassword: yup.string().required(validationMessages.REQUIRED_FIELD),

    newPassword: yup.string().required(validationMessages.REQUIRED_FIELD),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], validationMessages.PASSWORD_MATCH),
  });

export const dailyHourlyRateSchema = (
  validationMessages,
  hourlyBased,
  dailyBased
) =>
  yup.object().shape({
    // paymentType: yup.string().required(validationMessages.REQUIRED_FIELD),
    paymentType: yup.string().when('shouldBeVisible', {
      is: (value) => !value,
      then: yup.string().required(validationMessages.REQUIRED_FIELD),
    }),
    currency: yup.string().when('paymentType', {
      is: (value) => value === hourlyBased || value === dailyBased,
      then: yup.string().required(validationMessages.REQUIRED_FIELD),
    }),
    amount: yup
      .number()
      .when('paymentType', {
        is: (value) => {
          return value === hourlyBased || value === dailyBased;
        },
        then: yup
          .number()
          .min(1, validationMessages.HIGHER_THAN_ZERO)
          .required(validationMessages.REQUIRED_FIELD),
      })
      .when('paymentType', {
        is: (value) => {
          return value && value === dailyBased;
        },
        then: yup
          .number()
          .min(50, validationMessages.HIGHER_THAN_FIVE_TEEN)
          .required(validationMessages.REQUIRED_FIELD),
      }),
  });

export default schema;
