import React, { useContext } from 'react';
// nodejs library to set properties for components

import PropTypes from 'prop-types';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { AppContext } from 'store/app.context';

const CardsHeaderUser = ({ title }) => {
  const { languageState } = useContext(AppContext);

  return (
    <>
      <div className="header bg-exprimary pb-7 pt-3 mt--7	">
        <Container
          fluid
          className="d-flex flex-row justify-content-between custom-header mt-7		align-items-center "
        >
          <div>
            <h1 className="text-white big-writing">{title}</h1>
          </div>
          {/* <Button className="h-max-content pt-2 pb-2  pl-5 pr-5 ml-2">
            ADD NEW PROJECT{' '}
          </Button> */}
        </Container>
      </div>
    </>
  );
};

export default CardsHeaderUser;
